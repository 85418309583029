<template>
    <el-tabs v-model="activeName" @tab-click="changeTab">

      <!--活动列表-->
      <el-tab-pane label="活动列表" name="first">
            <!--搜索栏-->
            <el-form :inline="true" :model="searchActivityParams" label-width="90px" class="demo-form-inline">
                <el-form-item prop="name" class="ml-10">
                    <el-input v-model="searchActivityParams.name" placeholder="活动名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button icon="el-icon-search" type="primary" @click="getActivityList()" :loading="isLoading">查询</el-button>
                    <el-button icon="el-icon-plus" type="primary" @click="createActivityDialog()" :loading="isLoading">添加活动</el-button>
                </el-form-item>
            </el-form>

            <!--表格信息-->
            <el-table :data="activityList" stripe style="width: 100%" row-key="id" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
                <el-table-column prop="id" label="ID" min-width="50%"></el-table-column>
                <el-table-column prop="activity_name" label="活动名称"></el-table-column>
                <el-table-column prop="banner_pic" label="活动Banner图" min-width="35%">
                    <template slot-scope="scope">
                        <image-preview :file="scope.row.banner_pic"/>
                    </template>
                </el-table-column>
                <el-table-column prop="background_pic" label="活动背景图" min-width="30%">
                  <template slot-scope="scope">
                    <image-preview :file="scope.row.background_pic"/>
                  </template>
                </el-table-column>
                <el-table-column prop="activity_status" label="活动状态" min-width="40%">
                  <template slot-scope="scope">
                    <a v-if="scope.row.activity_status == 0">关闭</a>
                    <a v-if="scope.row.activity_status == 1">开启</a>
                    <a v-if="scope.row.activity_status == 2">已结束</a>
                  </template>
                </el-table-column>
                <el-table-column prop="weight" label="排序" min-width="30%"></el-table-column>
                <el-table-column prop="effective_time" label="过期时间" min-width="70%">
                  <template slot-scope="scope">
                    {{scope.row.effective_time|time}}
                  </template>
                </el-table-column>
                <el-table-column prop="create_time" label="创建时间" min-width="70%">
                    <template slot-scope="scope">
                        {{scope.row.create_time|time}}
                    </template>
                </el-table-column>

                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" :disabled="setButtonStatus(scope.row.activity_status)"  @click="setActivityStatus(scope.row.id,scope.row.activity_status)">
                          {{scope.row.activity_status == 0 ? "开启活动" : scope.row.activity_status == 1 ? "关闭活动" : "活动已结束"}}
                        </el-button>
                        <el-button type="primary" size="mini" @click="editActivityInfo(scope.$index)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--翻页-->
            <el-pagination
                background
                @size-change="handleActivitySizeChange"
                @current-change="handleActivityCurrentChange"
                :current-page.sync="searchActivityParams.page"
                :page-sizes="[10, 15, 20,50,100]"
                :page-size="searchActivityParams.size"
                layout="total,sizes, prev, pager, next"
                :total="searchActivityParams.count">
            </el-pagination>
            <!--弹窗,通过el-dialog来进行限制-->
            <el-dialog :title="activityDialogTitle" :visible.sync="activityDialogFormVisible"  :close-on-click-modal="false" width="50%">
                <el-form :model="activity.form" label-width="110px">
                    <!--输入框-->
                    <el-form-item label="活动名称" required="">
                        <el-col :span="8">
                            <el-input v-model="activity.form.activity_name" clearable ></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="活动Banner图" required="">
                      <el-upload
                          action="/common/upload/multipart/"
                          list-type="picture-card"
                          :on-preview="handleBannerPictureCardPreview"
                          :on-remove="handleRemoveBannerPic"
                          :on-success="handleBannerPictureSuccess"
                          :limit="1"
                          :file-list = "banner_pic">
                        <i class="el-icon-plus"></i>
                      </el-upload>
                      <el-dialog :visible.sync="dialogBannerPicVisible" append-to-body width="40%">
                        <img width="100%" :src="bannerPicUrl" alt="">
                      </el-dialog>
                    </el-form-item>

                    <el-form-item label="活动背景图" required="">
                      <el-upload
                          action="/common/upload/multipart/"
                          list-type="picture-card"
                          :on-preview="handleBackgroundPictureCardPreview"
                          :on-remove="handleRemoveBackgroundPic"
                          :on-success="handleBackgroundPictureSuccess"
                          :limit="1"
                          :file-list = "background_pic">
                        <i class="el-icon-plus"></i>
                      </el-upload>
                      <el-dialog :visible.sync="dialogBackgroundPicVisible" append-to-body width="40%">
                        <img width="100%" :src="backgroundPicUrl" alt="">
                      </el-dialog>
                    </el-form-item>

                    <el-form-item label="活动状态" required="" >
                      <el-radio-group v-model="activity.form.activity_status">
                        <el-radio :label=0>关闭</el-radio>
                        <el-radio :label=1>开启</el-radio>
                      </el-radio-group>
                    </el-form-item>

                    <el-form-item label="排序权重" required="">
                        <el-col :span="8">
                            <el-input-number style="width: 270px" v-model="activity.form.weight"></el-input-number>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="活动天数" required="" >
                      <el-col :span="8">
                        <el-input-number style="width: 270px" v-model="activity.form.day" ></el-input-number>
                      </el-col>
                    </el-form-item>
                    <el-form-item label="活动说明" required="" >
                      <el-col :span="15">
                        <el-input v-model="activity.form.activity_desc" :rows="15" type="textarea" placeholder="请输入活动说明"></el-input>
                      </el-col>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary"  @click="createActivity">立即创建</el-button>
                        <el-button  @click="cancelForm('activity')">取消</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>

        </el-tab-pane>
      <!--活动礼包-->
      <el-tab-pane label="活动礼包" name="second">
        <!--搜索栏-->
        <el-form :inline="true" :model="searchActivityGiftBagParams" ref="searchForm" label-width="90px" class="demo-form-inline">
          <el-form-item prop="name" class="ml-10">
            <el-input v-model="searchActivityGiftBagParams.name" placeholder="礼包名称"></el-input>
          </el-form-item>
          <el-form-item prop="activity_id" class="ml-10">
            <el-select v-model="searchActivityGiftBagParams.activity_id" placeholder="活动列表">
              <el-option label="全部" value= 0 ></el-option>
              <el-option
                  v-for="item in activityList"
                  :key="item.id"
                  :label="item.activity_name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="getActivityGiftBagList()" :loading="isLoading">查询</el-button>
            <el-button icon="el-icon-plus" type="primary" @click="createGiftBagDialog()" :loading="isLoading">添加礼包</el-button>
          </el-form-item>

        </el-form>
        <!--活动礼包列表信息-->
        <el-table :data="activityGiftBagList" stripe ref="activityGiftBagTable" @expand-change="expandChange" style="width: 100%" row-key="id" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
          <!----展开层--->
          <el-table-column type="expand">
            <template slot-scope="scope" >
              <el-form>
                <el-form-item>
                  <el-button icon="el-icon-plus" size="small" type="primary" @click="createActivityGiftBagRewardDialog(scope.row.id,scope.row.activity_id)" :loading="isLoading">增加奖励</el-button>
                </el-form-item>
              </el-form>
              <el-table :data="activityGiftBagRewardList">
                <el-table-column label="ID" prop="id"></el-table-column>
                <el-table-column label="奖励名称" prop="sort_name"></el-table-column>
                <el-table-column label="奖励图片">
                  <template slot-scope="scope">
                    <image-preview :file="scope.row.cover"/>
                  </template>
                </el-table-column>
                <el-table-column label="更新时间" prop="update_time"></el-table-column>
                <el-table-column label="添加时间" prop="create_time"></el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="editActivityGiftBagReward(scope.$index)">编辑</el-button>
                    <el-button type="danger" size="mini" @click="deleteActivityGiftBagReward(scope.row.id,scope.row.gift_bag_id,scope.row.activity_id)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <!----展开层--->
          <el-table-column prop="id" label="ID" min-width="20%"></el-table-column>
          <el-table-column prop="activity_name" label="活动名称" min-width="30%"></el-table-column>
          <el-table-column prop="gift_bag_name" label="礼包名称" min-width="30%"></el-table-column>
          <el-table-column prop="gift_bag_num" label="礼包号" min-width="50%"></el-table-column>
          <el-table-column prop="gift_bag_pic" label="礼包封面" min-width="20%">
            <template slot-scope="scope">
              <image-preview :file="scope.row.gift_bag_pic"/>
            </template>
          </el-table-column>
          <el-table-column prop="gift_bag_price" label="安卓/IOS价格" min-width="26%">
            <template slot-scope="scope">
              <span>{{scope.row.android_price +"/"+ scope.row.ios_price}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="weight" label="排序" min-width="20%"></el-table-column>
          <el-table-column prop="gift_bag_status" label="状态" min-width="15%">
            <template slot-scope="scope">
              <a v-if="scope.row.gift_bag_status == 0">禁用</a>
              <a v-if="scope.row.gift_bag_status == 1">开启</a>
            </template>
          </el-table-column>
          <el-table-column prop="created" label="更新时间" min-width="45%">
            <template slot-scope="scope">
              {{scope.row.update_time|time}}
            </template>
          </el-table-column>
          <el-table-column prop="created" label="创建时间" min-width="45%">
            <template slot-scope="scope">
              {{scope.row.create_time|time}}
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="handleActivityGiftBagToogleExpand(scope.row,scope.row.id,scope.row.activity_id)">礼包内容</el-button>
              <el-button type="primary" size="mini" @click="setActivityGiftBagStatus(scope.row.id)">
                <a v-if="scope.row.gift_bag_status == 0">启用礼包</a>
                <a v-if="scope.row.gift_bag_status == 1">禁用礼包</a>
              </el-button>
              <el-button type="primary" size="mini" @click="editActivityGiftBagInfo(scope.$index)">编辑</el-button>
              <el-button type="danger" size="mini" @click="deleteActivityGiftBag(scope.row.id,scope.row.activity_id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!--翻页-->
        <el-pagination
            background
            @size-change="handleActivityGiftBagSizeChange"
            @current-change="handleActivityGiftBagCurrentChange"
            :current-page.sync="searchActivityGiftBagParams.page"
            :page-sizes="[10, 15, 20,50,100]"
            :page-size="searchActivityGiftBagParams.size"
            layout="total,sizes, prev, pager, next"
            :total="searchActivityGiftBagParams.count">
        </el-pagination>

        <!--活动礼包表单信息弹窗,通过el-dialog来进行限制-->
        <el-dialog :title="activityGiftBagDialogTitle" :visible.sync="activityGiftBagDialogFormVisible" :close-on-click-modal="false" width="50%">
          <el-form :model="activityGiftBag.form" label-width="80px">
            <!--输入框-->
            <el-form-item label="礼包名称" required="">
              <el-col :span="8">
                <el-input v-model="activityGiftBag.form.gift_bag_name" clearable></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="礼包号" required="">
              <el-col :span="8">
                <el-input v-model="activityGiftBag.form.gift_bag_num" clearable></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="所属活动" prop="activity_id" required="">
              <el-select v-model="activityGiftBag.form.activity_id" placeholder="请选择活动" style="min-width: 277px">
                <el-option
                    v-for="item in activityList"
                    :key="item.id"
                    :label="item.activity_name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="礼包图片" required="">
              <el-upload
                  action="/common/upload/multipart/"
                  list-type="picture-card"
                  :on-preview="handleActivityGiftBagPictureCardPreview"
                  :on-remove="handleRemoveActivityGiftBagPic"
                  :on-success="handleActivityGiftBagPictureSuccess"
                  :limit="1"
                  :file-list="activity_gift_bag_pic">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="activityGiftBagPicVisible" append-to-body width="40%">
                <img width="100%" :src="activityGiftBagPicUrl" alt="">
              </el-dialog>
            </el-form-item>
            <el-form-item label="安卓价格" required="">
              <el-col :span="8">
                <el-input v-model="activityGiftBag.form.android_price" oninput = "value=value.replace(/[^\d.]/g, '0.01')" clearable></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="IOS价格" required="">
              <el-col :span="8">
                <el-input v-model="activityGiftBag.form.ios_price" oninput = "value=value.replace(/[^\d.]/g, '0.01')" clearable></el-input>
              </el-col>
            </el-form-item>
            <el-form-item label="排序权重" required="">
              <el-col :span="8">
                <el-input-number style="width: 277px" v-model="activityGiftBag.form.weight"></el-input-number>
              </el-col>
            </el-form-item>
            <el-form-item label="礼包状态" required="" >
              <el-radio-group v-model="activityGiftBag.form.gift_bag_status">
                <el-radio :label=0>禁用</el-radio>
                <el-radio :label=1>开启</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item>
              <el-button type="primary"  @click="createActivityGiftBag">添加礼包</el-button>
              <el-button  @click="cancelForm('activityGiftBag')">取消</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
        <!--礼包内容dialog-->
        <el-dialog title="编辑礼包内容" :visible.sync="activityGiftBagRewardDialogFormVisible" :close-on-click-modal="false" width="50%">
          <el-form :model="activityGiftBagReward" label-width="80px">
            <el-form-item label="选择贴纸" prop="sort_id">
              <el-select v-model="activityGiftBagReward.sort_id" placeholder="请选择">
                <el-option label="请选择" value=0 ></el-option>
                <el-option v-for="item in pasteDataList" :key="item.id" :label="item.sort_name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary"  @click="createActivityGiftBagReward()">保存</el-button>
              <el-button  @click="cancelForm('activityGiftBagReward')">取消</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </el-tab-pane>
    </el-tabs>

</template>

<script>

    import ImagePreview from '../../components/ImagePreview'
    import ImageUploader from '../../components/ImageUploader'

    export default {
        name: "Element",
        components: {
            ImagePreview, ImageUploader
        },
        mounted() {
            this.getActivityList();
            this.getActivityGiftBagList();
        },
        data() {
            return {
                isLoading: false,
                activeName: 'first',
                //活动弹框标题与状态
                activityDialogTitle:'创建活动',
                activityDialogFormVisible:false,
                //活动搜索传参
                searchActivityParams: {
                  name: '',
                  page: 1,
                  count: 0,
                  size: 10,
                },
                //活动弹窗表单属性
                activity:{
                    form:{
                        id : 0,
                        activity_name : '',
                        banner_pic : '',
                        background_pic : '',
                        activity_desc:'',
                        activity_status : 1,
                        weight : 1,
                        day : 1,
                    }
                },
                //获取所有活动(列表展示)
                activityList:[],
                //详情图是否开启大图展示
                dialogBannerPicVisible: false,
                //背景图是否开启大图展示
                dialogBackgroundPicVisible: false,
                //活动详情图
                banner_pic:[],
                //展示活动详情图时对应的图片地址
                bannerPicUrl:'',
                //活动背景图
                background_pic:[],
                //展示活动背景大图时对应的图片地址
                backgroundPicUrl:'',
              /*********************礼包信息***************************/
                //礼包弹框标题与状态
                activityGiftBagDialogTitle:'添加活动礼包',
                activityGiftBagDialogFormVisible:false,
                //礼包搜索传参
                searchActivityGiftBagParams: {
                  name: '',
                  activity_id: '0',
                  page: 1,
                  count: 0,
                  size: 10,
                },
                //礼包弹窗表单属性
                activityGiftBag: {
                  form: {
                    id : 0,
                    activity_id: '',
                    gift_bag_name: '',
                    gift_bag_num: '',
                    gift_bag_pic: '',
                    android_price: 0.01,
                    ios_price: 0.01,
                    gift_bag_status : 1,
                    weight : 1,
                  }
                },
                //礼包内容列表
                activityGiftBagList: [],
                //礼包图是否开启大图展示
                activityGiftBagPicVisible: false,
                activity_gift_bag_pic: [],
                activityGiftBagPicUrl: '',
              /**********************活动奖励***************************/
                //礼包奖励内容列表
                activityGiftBagRewardList:[],
                activityGiftBagRewardDialogFormVisible: false,
                //贴纸列表
                pasteDataList:[],
                expands:[],
                activityGiftBagReward: {
                  id: 0,
                  activity_id: 0,
                  gift_bag_id:0,
                  sort_id: '',
                }
            }
        },
        methods: {
            //切换栏目时触发
            changeTab(tab, event) {

            },
            //获取活动
            getActivityList(){
              this.isLoading = true;
              this.$api.journal.activity('List', this.$copy(this.searchActivityParams)).then(result => {
                if (result.errCode) {
                  this.$message.error(result.message);
                } else {
                  this.activityList = result.data.list;
                  this.searchActivityParams.count = result.data.total;//总条数
                }
              }).catch(error => {
                this.$message.error(error)
              }).then(()=>{
                this.isLoading = false;
              })
            },
            //拉取创建活动弹出框
            createActivityDialog(){
              this.activityDialogFormVisible = true;
              this.banner_pic = [];
              this.background_pic = [];
              this.activity.form = {
                  id : 0,
                  activity_name : '',
                  banner_pic : '',
                  background_pic : '',
                  activity_desc:'',
                  activity_status : 1,
                  weight : 1,
                  day : 1,
              };
            },
            //提交活动信息
            createActivity(){
              this.$api.journal.activity('Save', this.activity.form,'post').then(result => {
                if (result.errCode) {
                  this.$message.error(result.message);
                } else {
                  this.getActivityList();
                  this.activityDialogFormVisible = false;
                }
              }).catch(error => {
                this.$message.error(error);
              }).then(() => {
                this.isLoading = false;
              });
            },
            //编辑活动信息
            editActivityInfo(index){
              this.activityDialogTitle = '编辑活动';
              this.activityDialogFormVisible = true;
              this.banner_pic = [];
              this.background_pic = [];
              this.activity.form = this.$copy(this.activityList[index]);
              //设置放大图片信息
              let bannerPic = new Object();
              let backgroundPic = new Object();
              bannerPic.url = this.bannerPicUrl = this.activity.form.banner_pic;
              backgroundPic.url = this.backgroundPicUrl = this.activity.form.background_pic;
              this.banner_pic.push(bannerPic);
              this.background_pic.push(backgroundPic);
            },
            setActivityStatus(id, type){
              if(!id){
                this.$message.error('格式错误');
                return;
              }
              let title = (0 == type) ? "是否开启活动?" : "是否关闭活动?";
              this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.isLoading = true;
                this.$api.journal.activity("SetActivityStatus", {id:id}, 'post').then(result => {
                  if(result.errCode){
                    this.$message.error(result.message)
                  } else {
                    this.getActivityList();
                    this.$message({type: 'success', message: (0 == type) ? "开启活动成功" : '关闭活动成功'});
                  }
                }).catch(error => {
                  this.$message.error(error)
                }).then(() => {
                  this.isLoading = false;
                })
              }).catch(() => {
                this.$message({type: 'info', message: '取消'});
              })
            },
            setButtonStatus(status){
              if(0 === status || 1 === status){
                return false;
              }
              return true;
            },

            //上传活动Banner图片涉及的绑定事件
            //移除图片
            handleRemoveBannerPic(file) {
              console.log(file);
              this.activity.form.banner_pic = '';
            },
            //图片成功上传
            handleBannerPictureSuccess(result){
              if(result.errCode){
                this.$message.error(result.message);
              }
              //成功的情况进行图片存储
              this.activity.form.banner_pic = result.data.object_url;
            },
            //图片放大观察
            handleBannerPictureCardPreview(file) {
              this.dialogBannerPicVisible = true;
              this.bannerPicUrl = file.url;
            },

            //上传活动背景图片涉及的绑定事件
            //移除图片
            handleRemoveBackgroundPic(file) {
              this.activity.form.background_pic = '';
            },
            //图片成功上传
            handleBackgroundPictureSuccess(result){
              if(result.errCode){
                this.$message.error(result.message);
              }
              //成功的情况进行图片存储
              this.activity.form.background_pic = result.data.object_url;
            },
            //图片放大观察
            handleBackgroundPictureCardPreview(file) {
              this.dialogBackgroundPicVisible = true;
              this.backgroundPicUrl = file.url;
            },

            // 点击分页
            handleActivitySizeChange(val) {
                this.searchActivityParams.size = val;
                this.getActivityList();
            },

            handleActivityCurrentChange(val) {
                this.searchActivityParams.page = val;
                this.getActivityList();
            },
          /**************************礼包逻辑操作内容*******************************/
            getActivityGiftBagList(){
              this.$api.journal.activity_gift_bag('List', this.$copy(this.searchActivityGiftBagParams)).then(result => {
                if(result.errCode){
                  this.$message.error(result.message)
                }else{
                  this.activityGiftBagList = result.data.list;
                  this.searchActivityGiftBagParams.count = result.data.total;
                }
              }).catch(error => {
                this.$message.error(error)
              }).then(() => {
                this.isLoading = false;
              })
            },
            //添加礼包弹出层
            createGiftBagDialog(){
              this.activityGiftBagDialogFormVisible = true;
              this.activity_gift_bag_pic = [];
              this.activityGiftBag.form = {
                id : 0,
                activity_id: '',
                gift_bag_name: "",
                gift_bag_num: "",
                gift_bag_pic: '',
                android_price: 0.01,
                ios_price: 0.01,
                gift_bag_status : 1,
                weight : 1,
              };
            },
            //添加礼包逻辑
            createActivityGiftBag(){
              this.$api.journal.activity_gift_bag('Save', this.activityGiftBag.form,'post').then(result => {
                if (result.errCode) {
                  this.$message.error(result.message);
                } else {
                  this.getActivityGiftBagList();
                  this.activityGiftBagDialogFormVisible = false;
                }
              }).catch(error => {
                this.$message.error(error);
              }).then(() => {
                this.isLoading = false;
              });
            },
            //编辑礼包信息
            editActivityGiftBagInfo(index){
              this.activityGiftBagDialogTitle = '编辑礼包';
              this.activityGiftBagDialogFormVisible = true;
              this.activity_gift_bag_pic = [];
              this.activityGiftBag.form = this.$copy(this.activityGiftBagList[index]);
              //设置放大图片信息
              let pic = new Object();
              pic.url = this.activityGiftBagPicUrl = this.activityGiftBag.form.gift_bag_pic;
              this.activity_gift_bag_pic.push(pic);
            },
            deleteActivityGiftBag(id,activityId){
              if(!id || !activityId){
                this.$message.error('格式错误');
                return;
              }
              this.$confirm("是否删除礼包", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.isLoading = true;
                this.$api.journal.activity_gift_bag("deleteActivityGiftBag", {id:id,activity_id:activityId},'post').then(result => {
                  if(result.errCode){
                    this.$message.error(result.message)
                  } else {
                    this.getActivityGiftBagList();
                    this.$message({type: 'success', message: '删除成功'});
                  }
                }).catch(error => {
                  this.$message.error(error)
                }).then(() =>{
                  this.isLoading = false;
                })
              }).catch(() => {
                this.$message({type: 'info', message: '取消'});
              })
            },
            setActivityGiftBagStatus(id, type){
              if(!id){
                this.$message.error('格式错误');
                return;
              }
              let title = (0 == type) ? "是否开放礼包?" : "是否禁用礼包?";
              this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.isLoading = true;
                this.$api.journal.activity_gift_bag("SetActivityGiftBagStatus", {id:id}, 'post').then(result => {
                  if(result.errCode){
                    this.$message.error(result.message)
                  } else {
                    this.getActivityGiftBagList();
                    this.$message({type: 'success', message: (0 == type) ? "开启礼包成功" : '禁用礼包成功'});
                  }
                }).catch(error => {
                  this.$message.error(error)
                }).then(() => {
                  this.isLoading = false;
                })
              }).catch(() => {
                this.$message({type: 'info', message: '取消'});
              })
            },
            //移除图片
            handleRemoveActivityGiftBagPic(file) {
              this.activityGiftBag.form.gift_bag_pic = '';
            },
            //图片放大观察
            handleActivityGiftBagPictureCardPreview(file) {
              this.activityGiftBagPicVisible = true;
              this.activity_gift_bag_pic = file.url;
            },
            handleActivityGiftBagPictureSuccess(result){
                if(result.errCode){
                    this.$message.error(result.message);
                }
                this.activityGiftBag.form.gift_bag_pic = result.data.object_url;
            },
            // 点击分页
            handleActivityGiftBagSizeChange(val) {
              this.searchActivityGiftBagParams.size = val;
              this.getActivityGiftBagList();
            },

            handleActivityGiftBagCurrentChange(val) {
              this.searchActivityGiftBagParams.page = val;
              this.getActivityGiftBagList();
            },
          /******************************添加礼包内容********************************/
            //获取贴纸
            getPasteList() {
              this.$api.journal.all_sort('GetActivityPaste').then(result => {
                if(result.errCode){
                  this.$message.error(result.message)
                }else{
                  this.pasteDataList = result.data.list;
                }
              }).catch(error => {
                this.$message.error(error)
              }).then(() => {
                this.isLoading = false;
              })
            },
            //展开事件----动态获取内嵌表数据
            expandChange(row, expandedRows){
              // 该处是用于判断是展开还是收起行，只有展开的时候做请求，避免多次请求！
              // 展开的时候expandedRows有值，收起的时候为空.
              if(0 < expandedRows.length){
                if(!row.id || !row.activity_id){
                  this.$message.error('格式错误');
                  return;
                }
                this.getActivityGiftBagRewardList(row.id, row.activity_id);
              }
            },
            handleActivityGiftBagToogleExpand(row, giftBagId, activityId){
              if(!giftBagId || !activityId){
                this.$message.error('格式错误');
                return;
              }
              let $table = this.$refs.activityGiftBagTable;
              this.activityGiftBagList.map((item) => {
                if (row.id != item.id) {
                  $table.toggleRowExpansion(item, false);
                }
              })
              this.getActivityGiftBagRewardList(giftBagId, activityId);
              $table.toggleRowExpansion(row);
            },
            getActivityGiftBagRewardList(giftBagId, activityId){
              if(!giftBagId || !activityId){
                this.$message.error('格式错误');
                return;
              }
              this.$api.journal.activity_gift_bag_reward('List', {gift_id:giftBagId,activity_id:activityId}).then(result => {
                if(result.errCode){
                  this.$message.error(result.message)
                }else{
                  this.activityGiftBagRewardList = result.data.list;
                }
              }).catch(error => {
                this.$message.error(error)
              }).then(() => {
                this.isLoading = false;
              })
            },
            createActivityGiftBagRewardDialog(giftBagId,activityId){
              this.getPasteList();
              this.activityGiftBagRewardDialogFormVisible = true;
              this.activityGiftBagReward = {
                id: 0,
                activity_id: activityId,
                gift_bag_id: giftBagId,
                sort_id: '',
              };
            },
            createActivityGiftBagReward(){
              let giftBagId = this.activityGiftBagReward.gift_bag_id;
              let activityId = this.activityGiftBagReward.activity_id;
              this.$api.journal.activity_gift_bag_reward("Save", this.activityGiftBagReward, 'post').then(result => {
                if (result.errCode) {
                  this.$message.error(result.message);
                } else {
                  this.activityGiftBagRewardDialogFormVisible = false;
                  this.getActivityGiftBagRewardList(giftBagId, activityId);
                }
              }).catch(error => {
                this.$message.error(error)
              }).then(() => {
                this.isLoading = false;
              })
            },
            editActivityGiftBagReward(index){
              this.getPasteList();
              this.activityGiftBagRewardDialogFormVisible = true;
              this.activityGiftBagReward = this.$copy(this.activityGiftBagRewardList[index]);
            },
            deleteActivityGiftBagReward(id,giftBagId,activityId){
              if(!id){
                this.$message.error('格式错误');
                return;
              }
              this.$confirm("是否删除礼包奖励", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.isLoading = true;
                this.$api.journal.activity_gift_bag_reward("deleteActivityGiftBagReward", {id:id,gift_id:giftBagId,activity_id:activityId},'post').then(result => {
                  if(result.errCode){
                    this.$message.error(result.message)
                  } else {
                    this.getActivityGiftBagRewardList(giftBagId,activityId);
                    this.$message({type: 'success', message: '删除成功'});
                  }
                }).catch(error => {
                  this.$message.error(error)
                }).then(() =>{
                  this.isLoading = false;
                })
              }).catch(() => {
                this.$message({type: 'info', message: '取消'});
              })
            },
          /*******************************公共操作**********************************/
            //取消按钮关闭活动弹框
            cancelForm(type){
              switch (type) {
                case "activity":
                  this.activityDialogFormVisible = false;
                  break;
                case "activityGiftBag":
                  this.activityGiftBagDialogFormVisible = false;
                  break;
                case "activityGiftBagReward":
                  this.activityGiftBagRewardDialogFormVisible = false;
                  break;
                default:
                  break;
              }
            },
        }
    }
</script>

