<template>
    <el-tabs v-model="activeName" @tab-click="changeTab">
        <!--家具信息-->
        <el-tab-pane label="家具信息" name="first">
                <!--搜索栏-->
                <el-form :inline="true" :model="searchParams" ref="searchForm" label-width="90px" class="demo-form-inline">
                    <el-form-item prop="keyword" class="ml-10">
                        <el-input v-model="searchParams.keyword" placeholder="关键字"></el-input>
                    </el-form-item>
                    <el-form-item prop="sort_id" class="ml-10">
                        <el-select v-model="searchParams.sort_id" placeholder="家具类别">
                            <el-option label="全部" value= 0 ></el-option>
                            <el-option
                                    v-for="(item,index) in AllSortList"
                                    :key="item.id"
                                    :label="item.sort_name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button icon="el-icon-search" type="primary" @click="getListFurniture()" :loading="isLoading">查询</el-button>
                        <el-button icon="el-icon-plus" type="primary" @click="create('Furniture')" :loading="isLoading">创建家具</el-button>
                    </el-form-item>
                </el-form>
                <!--表单信息-->
                <el-table
                        :data="dataList"
                        stripe
                        style="width: 100%"
                        row-key="id">
                    <el-table-column
                            prop="id"
                            label="ID"
                    >
                    </el-table-column>

                    <el-table-column
                            prop="name"
                            label="家具名称">
                    </el-table-column>

                    <el-table-column
                            prop="sort_name"
                            label="家具类别">
                    </el-table-column>

                    <el-table-column
                            prop="cover"
                            label="家具图片">
                        <template slot-scope="scope">
                            <image-preview :file="scope.row.cover"/>
                        </template>
                    </el-table-column>

                    <el-table-column
                            prop="weight"
                            label="排序权重">
                    </el-table-column>

                    <el-table-column
                            prop="authority_desc"
                            label="使用权限">
                    </el-table-column>

                    <el-table-column
                            prop="pot"
                            label="蜜罐价格">
                    </el-table-column>

                    <el-table-column
                            prop="forbidden_desc"
                            label="是否禁用">
                    </el-table-column>

                    <el-table-column
                            prop="created"
                            label="创建时间">
                        <template slot-scope="scope">
                            {{scope.row.create_time|time}}
                        </template>
                    </el-table-column>

                    <el-table-column
                            label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" class="c-blue" @click="confirmRemove(scope.row.id,'Furniture')" icon="el-icon-help">
                                <a v-if="scope.row.is_forbidden == 0">禁用</a>
                                <a v-if="scope.row.is_forbidden == 1">启用</a>
                            </el-button>
                            <el-button type="text" class="c-blue" @click="editFurnitureInfo(scope.$index)" icon="el-icon-edit-outline">编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!--翻页-->
                <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="searchParams.page"
                        :page-sizes="[10, 15, 20,50,100]"
                        :page-size="searchParams.size"
                        layout="total,sizes, prev, pager, next"
                        :total="searchParams.count">
                </el-pagination>

                <!--弹窗,通过el-dialog来进行限制-->
                <el-dialog :title="dialogTitleFurniture" :visible.sync="dialogFurnitureFormVisible" :close-on-click-modal="false" width="50%">
                    <el-form :model="Furniture.form" label-width="80px">
                        <!--输入框-->
                        <el-form-item label="名称" required="">
                            <el-col :span="8">
                                <el-input v-model="Furniture.form.name" clearable></el-input>
                            </el-col>
                        </el-form-item>

                        <!--select下拉框-->
                        <el-form-item label="归属套装" prop="sort_id" required="">
                            <el-select v-model="Furniture.form.sort_id" placeholder="请选择归属套装">
                                <el-option label="归属套装" value= 0 ></el-option>
                                <el-option
                                        v-for="(item,index) in AllCanSortList"
                                        :key="item.id"
                                        :label="item.sort_name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="归属模块" prop="block_sort" required="">
                            <el-select v-model="Furniture.form.block_sort" placeholder="请选择归属套装">
                                <el-option label="归属模块" value= 0 ></el-option>
                                <el-option
                                        v-for="(item,index) in AllFurnitureBlock"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="归属部件" prop="furniture_type_id" required="">
                            <el-select v-model="Furniture.form.furniture_type_id" placeholder="请选择归属套装">
                                <el-option label="归属部件" value= 0 ></el-option>
                                <el-option
                                        v-for="(item,index) in AllFurnitureType"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <!--单选框 传入的值为label-->
                        <el-form-item label="使用权限" required="">
                            <el-radio-group v-model="Furniture.form.authority">
                                <el-radio
                                        v-for="(item,index) in AllAuthorityList"
                                        :label="index"
                                        :value="index">
                                    {{item}}
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item label="蜜罐价格" required="" v-if="Furniture.form.authority == 5">
                            <el-col :span="8">
                                <el-input v-model.number="Furniture.form.pot"></el-input>
                            </el-col>
                        </el-form-item>

                        <el-form-item label="蜂蜜价格" required="" v-if="Furniture.form.authority == 4">
                            <el-col :span="8">
                                <el-input v-model.number="Furniture.form.honey"></el-input>
                            </el-col>
                        </el-form-item>

                        <el-form-item label="家具图片" required="">
                            <el-upload
                                    action="/common/upload/multipart/"
                                    list-type="picture-card"
                                    :on-preview="handlePictureCardPreview"
                                    :on-remove="handleRemoveFurniture"
                                    :on-success="handlePictureSuccessFurniture"
                                    :limit="1"
                                    :file-list="FurniturePicList"
                            >
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisibleFurniture">
                                <img width="100%" :src="dialogImageUrlFurniture" alt="">
                            </el-dialog>
                        </el-form-item>

                        <el-form-item label="排序权重" required="">
                            <el-col :span="8">
                                <el-input v-model.number="Furniture.form.weight"></el-input>
                            </el-col>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary"  @click="CreateSubmitFurniture('Furniture')">立即创建</el-button>
                            <el-button  @click="CancelSubmitFurniture('Furniture')">取消</el-button>
                        </el-form-item>
                    </el-form>
                </el-dialog>

        </el-tab-pane>
        <!--家具类别-->
        <el-tab-pane label="家具套装" name="second">
            <el-button icon="el-icon-plus" type="primary" @click="create('sort')" :loading="isLoading" style="margin-left:85%">创建套装</el-button>
            <!--表单信息-->
            <el-table
                    :data="AllSortList"
                    stripe
                    style="width: 100%"
                    row-key="id">
                <el-table-column
                        prop="id"
                        label="ID"
                >
                </el-table-column>
                <el-table-column
                        prop="sort_name"
                        label="套装名称">
                </el-table-column>
                <el-table-column
                        prop="cover"
                        label="套装图片">
                    <template slot-scope="scope">
                        <image-preview :file="scope.row.cover"/>
                    </template>
                </el-table-column>

                <el-table-column
                        prop="weight"
                        label="排序权重">
                </el-table-column>

                <el-table-column
                        prop="authority_desc"
                        label="使用权限">
                </el-table-column>

                <el-table-column
                        prop="forbidden_desc"
                        label="是否禁用">
                </el-table-column>

                <el-table-column
                        prop="pot"
                        label="蜜罐价格">
                </el-table-column>

                <el-table-column
                        prop="is_sign_desc"
                        label="签到奖励">
                </el-table-column>

                <el-table-column
                        prop="created"
                        label="创建时间">
                    <template slot-scope="scope">
                        {{scope.row.create_time|time}}
                    </template>
                </el-table-column>

                <el-table-column
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" class="c-blue" @click="confirmRemove(scope.row.id)" icon="el-icon-help">
                            <a v-if="scope.row.is_forbidden == 0">禁用</a>
                            <a v-if="scope.row.is_forbidden == 1">启用</a>
                        </el-button>

                        <el-button type="text" class="c-blue" @click="editSortInfo(scope.$index)" icon="el-icon-edit-outline">编辑
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!--弹窗,通过el-dialog来进行限制-->
            <el-dialog :title="dialogTitleSort" :visible.sync="dialogSortFormVisible" :close-on-click-modal="false" width="50%">
                <el-form :model="AllSort.form" label-width="80px">
                    <!--输入框-->
                    <el-form-item label="名称" required="">
                        <el-col :span="8">
                            <el-input v-model="AllSort.form.sort_name" clearable ></el-input>
                        </el-col>
                    </el-form-item>

                    <!--单选框 传入的值为label-->
                    <el-form-item label="使用权限" required="">
                        <el-radio-group v-model="AllSort.form.authority">
                            <el-radio
                                    v-for="(item,index) in AllAuthorityList"
                                    :label="index"
                                    :value="index">
                                {{item}}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="蜜罐价格" required="" v-if="AllSort.form.authority == 5">
                        <el-col :span="8">
                            <el-input v-model.number="AllSort.form.pot"></el-input>
                        </el-col>
                    </el-form-item>

                    <el-form-item label="蜂蜜价格" required="" v-if="AllSort.form.authority == 4">
                        <el-col :span="8">
                            <el-input v-model.number="AllSort.form.honey"></el-input>
                        </el-col>
                    </el-form-item>

                    <el-form-item label="签到获取" required="">
                        <el-radio v-model="AllSort.form.is_sign_reward" label="0" :disabled=isEdit> 否 </el-radio>
                        <el-radio v-model="AllSort.form.is_sign_reward" label="1" :disabled=isEdit> 是 </el-radio>
                    </el-form-item>


                    <el-form-item label="注册赠送" required="">
                        <el-radio v-model="AllSort.form.is_register_free" label="0" > 否 </el-radio>
                        <el-radio v-model="AllSort.form.is_register_free" label="1" > 是 </el-radio>
                    </el-form-item>

                    <!--时间插件-->
                    <el-form-item label="签到区间" v-if="AllSort.form.is_sign_reward == 1">
                        <el-col :span="11">
                            <el-form-item prop="date1">
                                <el-date-picker type="date" placeholder="起始时间" v-model="AllSort.form.sign_start_time" style="width: 100%;"></el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col class="line" :span="2">-</el-col>
                        <el-col :span="11">
                            <el-form-item prop="date1">
                                <el-date-picker type="date" placeholder="结束时间" v-model="AllSort.form.sign_end_time" style="width: 100%;"></el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-form-item>

                    <el-form-item label="奖励数量" v-if="AllSort.form.is_sign_reward == 1">
                        <el-col :span="8">
                            <el-input v-model.number="AllSort.form.sign_reward_number"></el-input>
                        </el-col>
                    </el-form-item>


                    <el-form-item label="类别图片" required="">
                        <el-upload
                                action="/common/upload/multipart/"
                                list-type="picture-card"
                                :on-preview="handleSortPictureCardPreview"
                                :on-remove="handleRemoveSortPic"
                                :on-success="handleSortPictureSuccess"
                                :limit="1"
                                :file-list = "SortPicList"
                        >
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="dialogSortVisible">
                            <img width="100%" :src="dialogSortImageUrl" alt="">
                        </el-dialog>
                    </el-form-item>


                    <el-form-item label="商城预览" required="">
                        <el-upload
                                action="/common/upload/multipart/"
                                list-type="picture-card"
                                :on-preview="handleSortPictureCardShoppingPreview"
                                :on-remove="handleRemoveSortShoppingPic"
                                :on-success="handleSortPictureShoppingSuccess"
                                :limit="1"
                                :file-list = "SortShoppingPicList"
                        >
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="dialogShoppingVisible">
                            <img width="100%" :src="dialogShoppingImageUrl" alt="">
                        </el-dialog>
                    </el-form-item>



                    <el-form-item label="排序权重" required="">
                        <el-col :span="8">
                            <el-input v-model.number="AllSort.form.weight"></el-input>
                        </el-col>
                    </el-form-item>

                    <el-form-item label="元素图包"  v-if="AllSort.form.id == 0">
<!--                        <el-upload-->
<!--                                style="text-align:left; display: inline-block;"-->
<!--                                action="/common/upload/multipart"-->
<!--                                :on-preview="handlePreviewFile"-->
<!--                                :on-remove="handleRemoveFile"-->
<!--                                :on-success="handleSuccessFile"-->
<!--                                multiple-->
<!--                                :limit="1"-->
<!--                                :on-exceed="handleExceedFile"-->
<!--                                :file-list="elementPatchList"-->
<!--                        >-->
                          <el-upload
                              style="text-align:left; display: inline-block;"
                              action="/common/upload/multipart_compressed_package/"
                              :on-preview="handlePreviewFile"
                              :on-remove="handleRemoveFile"
                              :on-success="handleSuccessFile"
                              multiple
                              :limit="1"
                              :on-exceed="handleExceedFile"
                              :file-list="elementPatchList"
                          >
                            <el-button size="small" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip"></div>
                        </el-upload>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary"  @click="CreateSubmitFurniture('sort')">立即创建</el-button>
                        <el-button  @click="CancelSubmitFurniture('sort')">取消</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>

        </el-tab-pane>
    </el-tabs>

</template>

<script>

    import ImagePreview from '../../components/ImagePreview'

    export default {
        name: "FeedbackList",
        components: {
            ImagePreview
        },
        mounted() {
            this.getListFurniture();
            this.getAllFurnitureSort();
            this.getAllAuthorityList();
            this.getAllFurnitureBlock();
            this.getAllFurnitureType();
        },
        data() {
            return {
                isLoading: false,
                //搜索传参
                searchParams: {
                    keyword: '',
                    sort_id: '0',
                    page: 1,
                    count: 0,
                    size: 10,
                },
                //弹窗表单
                dialogTitleFurniture:'创建家具',
                dialogFurnitureFormVisible:false,
                Furniture:{
                    form:{
                        id : 0,
                        name : '',
                        sort_id : '0',
                        cover : '',
                        authority : '0',
                        weight : '0',
                        is_forbidden : '0',
                        pot:0,
                        furniture_type_id:'0',
                        block_sort:'0'
                    }
                },
                //家具基础列表
                dataList: [],
                activeName: 'first',
                //图片上传相关
                dialogImageUrlFurniture:'',
                dialogVisibleFurniture: false,
                FurniturePicList:[],
                //类别相关
                //类别基础列表
                sortList:[],
                dialogTitleSort:'创建类别',
                dialogSortFormVisible:false,
                AllSort:{
                    form:{
                        id : 0,
                        sort_name : '',
                        cover : '',
                        sort_type : 1,
                        authority : '1',
                        weight : 0,
                        is_forbidden : 0,
                        pot: 0,
                        honey: 0,
                        is_sign_reward: '0',
                        sign_start_time:'',
                        sign_end_time:'',
                        sign_reward_number:'',
                        is_register_free: '0',
                        resource:'',
                        shopping_cover:''
                    }
                },
                //获取家具的所有分类(列表展示)
                AllSortList:[],
                //创建家具时，筛选出可用分类（筛选使用）
                AllCanSortList:[],
                //展示大图时对应的图片地址
                dialogSortImageUrl:'',
                //是否开启大图展示
                dialogSortVisible: false,
                //类别图封面上传
                SortPicList:[],
                //获取所有权限信息
                AllAuthorityList:[],
                //是否为编辑的情况
                isEdit:false,
                //所有模块
                AllFurnitureBlock:[],
                //所有部件
                AllFurnitureType:[],
                //类别：
                elementPatchList:[],
                //新增商城相关信息
                //类别图商城封面设置
                SortShoppingPicList:[],
                //展示大图时对应的图片地址
                dialogShoppingImageUrl:'',
                //是否开启大图展示
                dialogShoppingVisible: false,
            }
        },
        methods: {
            //切换栏目时触发
            changeTab(tab, event) {

            },
            //获取家具列表
            getListFurniture() {
                this.isLoading = true;
                    this.$api.journal.furniture('List', this.$copy(this.searchParams)).then(result => {
                        if (result.errCode) {
                            this.$message.error(result.message);
                        } else {
                            this.dataList = result.data.list;
                            this.searchParams.count = result.data.total;//总条数
                        }
                    }).catch(error => {
                        this.$message.error(error);
                    }).then(() => {
                        this.isLoading = false;
                    })
            },
            //禁用家具Or类别
            confirmRemove(id,type){
                if(!id){
                    this.$message.error('未查询到反馈信息');
                    return;
                }
                if(type == 'Furniture'){
                    this.$confirm('是否更改使用状态?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        //点击确认回调的函数
                        this.$api.journal.furniture('SetChange', {id:id}).then(result => {
                            if (result.errCode) {
                                this.$message.error(result.message);
                            } else {
                                this.getListFurniture();
                                this.$message({
                                    type: 'success',
                                    message: '操作成功!'
                                });
                            }
                        }).catch(error => {
                            this.$message.error(error);
                        }).then(() => {
                            this.isLoading = false;
                        });
                    }).catch(() => {
                        //点击取消回调的函数
                        this.$message({
                            type: 'info',
                            message: '取消'
                        });
                    });
                }else{
                    this.$confirm('是否更改使用状态?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        //点击确认回调的函数
                        this.$api.journal.all_sort('SetChange', {id:id}).then(result => {
                            if (result.errCode) {
                                this.$message.error(result.message);
                            } else {
                                this.getAllFurnitureSort();
                                this.$message({
                                    type: 'success',
                                    message: '操作成功!'
                                });
                            }
                        }).catch(error => {
                            this.$message.error(error);
                        }).then(() => {
                            this.isLoading = false;
                        });
                    }).catch(() => {
                        //点击取消回调的函数
                        this.$message({
                            type: 'info',
                            message: '取消'
                        });
                    });
                }
            },
            //创建家具or类别（打开弹窗）
            create(type) {
                if(type == 'Furniture') {
                    this.dialogFurnitureFormVisible = true;
                    this.FurniturePicList = [];
                    this.Furniture.form = {
                        id : 0,
                        name : '',
                        sort_id : '0',
                        cover : '',
                        authority : '1',
                        weight : '0',
                        is_forbidden : '0',
                        pot:'0',
                        furniture_type_id:'0',
                        block_sort:'0'
                    };
                }else{
                    this.isEdit = false;
                    this.dialogSortFormVisible = true;
                    this.SortPicList = [];
                    this.SortShoppingPicList = [];
                    this.elementPatchList = [];
                    this.AllSort.form = {
                        id : 0,
                        sort_name : '',
                        cover : '',
                        sort_type : 1,
                        authority : '1',
                        weight : 0,
                        is_forbidden : 0,
                        pot: 0,
                        honey: 0,
                        is_sign_reward: '0',
                        sign_reward_number:'',
                        is_register_free: '0',
                        resource:'',
                        shopping_cover: ''
                    };
                }
            },
            //提交创建的反馈
            CreateSubmitFurniture(type){
                //家具相关
                if(type == 'Furniture'){
                    this.$api.journal.furniture('Save', this.Furniture.form,'post').then(result => {
                        if (result.errCode) {
                            this.dialogFurnitureFormVisible = false;
                            this.$message.error(result.message);
                        } else {
                            this.getListFurniture();
                            this.dialogFurnitureFormVisible = false;
                        }
                    }).catch(error => {
                        this.$message.error(error);
                    }).then(() => {
                        this.isLoading = false;
                        this.dialogFurnitureFormVisible = false;
                    });
                }else{
                    //类别相关
                    this.$api.journal.all_sort('Save', this.AllSort.form,'post').then(result => {
                        if (result.errCode) {
                            this.$message.error(result.message);
                        } else {
                            this.getAllFurnitureSort();
                            this.dialogSortFormVisible = false;
                        }
                    }).catch(error => {
                        this.$message.error(error);
                    }).then(() => {
                        this.isLoading = false;
                    });
                }
            },
            //取消提交
            CancelSubmitFurniture(type){
                if(type == 'Furniture'){
                    this.dialogFurnitureFormVisible = false;
                }else{
                    this.dialogSortFormVisible = false;
                }
            },
            //编辑类别(通过列表直接带入数据信息，无需请求接口的方式)
            editFurnitureInfo(index){
                this.dialogTitleFurniture = '编辑家具';
                this.dialogFurnitureFormVisible = true;
                this.FurniturePicList = [];
                //对应copy的使用
                this.Furniture.form = this.$copy(this.dataList[index]);
                //设置放大图片信息
                this.dialogImageUrlFurniture = this.Furniture.form.cover;
                let obj = new Object();
                obj.url = this.dialogImageUrlFurniture;
                this.FurniturePicList.push(obj);
            },
            //家具图片处理的具体方式
            handleRemoveFurniture() {
                this.Furniture.form.cover = '';
            },
            //放大镜
            handlePictureCardPreview(file) {
                this.dialogImageUrlFurniture = file.url;
                this.dialogVisibleFurniture = true;
            },
            //上传成功
            handlePictureSuccessFurniture(result){
                if(result.errCode){
                    this.$message.error(result.message);
                }
                //成功的情况进行图片存储
                this.Furniture.form.cover = result.data.object_url;
            },


            //类别上传图片涉及的绑定事件
            //移除图片--类别封面
            handleRemoveSortPic() {
                this.AllSort.form.cover = '';
            },

            //移除图片--类别商城
            handleRemoveSortShoppingPic() {
                this.AllSort.form.shopping_cover = '';
            },

            //图片成功上传 --封面
            handleSortPictureSuccess(result){
                if(result.errCode){
                    this.$message.error(result.message);
                }
                //成功的情况进行图片存储
                this.AllSort.form.cover = result.data.object_url;
            },

            //图片成功上传 --商城
            handleSortPictureShoppingSuccess(result){
                if(result.errCode){
                    this.$message.error(result.message);
                }
                //成功的情况进行图片存储
                this.AllSort.form.shopping_cover = result.data.object_url;
            },

            //图片放大观察 -- 封面
            handleSortPictureCardPreview(file) {
                this.dialogSortVisible = true;
                this.dialogSortImageUrl = file.url;
            },

            //图片放大观察 -- 商城
            handleSortPictureCardShoppingPreview(file) {
                this.dialogShoppingVisible = true;
                this.dialogShoppingImageUrl = file.url;
            },


            //获取所有家具类别
            getAllFurnitureSort(){
                let that = this;
                this.$api.journal.all_sort('SortInfo',{'type':1,'scene':1}).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.AllSortList = result.data;
                        that.AllCanSortList = [];
                        this.AllSortList.forEach(function(value){
                            if(value.is_forbidden == 0){
                                that.AllCanSortList.push(value);
                            }
                        });
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                });
            },
            //编辑类别(通过列表直接带入数据信息，无需请求接口的方式)
            editSortInfo(index){
                this.isEdit = true;
                this.dialogTitleSort = '编辑类别';
                this.dialogSortFormVisible = true;
                this.SortPicList = [];
                this.SortShoppingPicList = [];
                this.AllSort.form = this.$copy(this.AllSortList[index]);
                //设置放大图片信息
                this.dialogSortImageUrl = this.AllSort.form.cover;
                //类别商城图片信息
                this.dialogShoppingImageUrl = this.AllSort.form.shopping_cover;
                //类别图初始化
                let obj = new Object();
                obj.url = this.dialogSortImageUrl;
                this.SortPicList.push(obj);

                //新增商城预览
                let obj_shopping = new Object();
                obj_shopping.url = this.dialogShoppingImageUrl;
                if(obj_shopping.url){
                    this.SortShoppingPicList.push(obj_shopping);
                }
            },

            //获取所有权限类别
            getAllAuthorityList(){
                this.$api.journal.all_sort('AuthorityInfo',{flag:1}).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.AllAuthorityList = result.data;
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                });
            },

            //分页相关
            // 点击分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getListFurniture();
            },

            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getListFurniture();
            },

            //获取模块(大类)
            getAllFurnitureBlock(){
                this.$api.journal.furniture('GetFurnitureBlock').then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.AllFurnitureBlock = result.data;
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                });
            },
            //获取部件(小类)
            getAllFurnitureType(){
                this.$api.journal.furniture('GetFurnitureType').then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.AllFurnitureType = result.data;
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                });
            },
            //文件上传相关
            handlePreviewFile(file){
                console.log(file);
            },
            handleRemoveFile(file){
                console.log(file);
                this.AllSort.form.resource = '';
            },
            handleExceedFile(){
                this.$message.error('当前只支持上传一个文件包');
            },
            handleSuccessFile(result){
                if(result.errCode){
                    this.$message.error(result.message);
                }
                this.AllSort.form.resource = result.data.object_url;
            },
        }
    }
</script>

