<template>
    <div>
        <!--搜索栏-->
        <!--inline属性，可以使表单域变为行内表单域-->
        <el-form :inline="true" :model="searchParams" ref="searchForm" label-width="90px" class="demo-form-inline">
            <el-form-item prop="keyword" class="ml-10">
                <el-input v-model="searchParams.keyword" placeholder="关键字"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" @click="getList()" :loading="isLoading">查询</el-button>
                <el-button icon="el-icon-search" type="primary" @click="create" :loading="isLoading">创建反馈</el-button>
                <!--
                <el-button icon="el-icon-search" type="danger" @click="create" :loading="isLoading">页面创建</el-button>
                 -->
            </el-form-item>
        </el-form>
        <el-table
                :data="dataList"
                stripe
                style="width: 100%"
                row-key="id">
            <!--sortable 定义是否排序 -->
            <el-table-column
                    prop="id"
                    label="ID"
                    sortable
            >
            </el-table-column>
            <el-table-column
                    prop="name"
                    label="反馈人">
            </el-table-column>
            <el-table-column
                    prop="feed_info"
                    label="反馈信息">
            </el-table-column>
            <el-table-column
                    prop="pic_info"
                    label="封面">
                <template slot-scope="scope">
                    <image-preview :file="scope.row.pic_cover"/>
                </template>
            </el-table-column>

            <!--多张图展示的场景-->
            <el-table-column
                    prop="pic_info_show"
                    label="所有反馈图">
                <template slot-scope="scope">
                    <el-image style="width: 80px; height: 80px"
                              v-for="item in scope.row.pic_info_show"
                              :src="item"
                              :preview-src-list="scope.row.pic_info_show"
                    >
                    </el-image>
                </template>
            </el-table-column>

            <el-table-column
                    prop="created"
                    label="创建时间">
                <template slot-scope="scope">
                    {{scope.row.create_time|time}}
                </template>
            </el-table-column>

            <el-table-column
                    label="操作">
                <template slot-scope="scope">
                    <!--
                    <el-button type="text" class="c-blue" @click="edit(scope.row.id)"
                               icon="el-icon-edit-outline">编辑
                    </el-button>
                    -->
                    <el-button type="text" class="c-blue" @click="confirmRemove(scope.row.id)" icon="el-icon-delete">删除
                    </el-button>
                    <el-button type="text" class="c-blue" @click="open(scope.row.id)" icon="el-icon-s-promotion">回复
                    </el-button>
                    <el-button type="text" class="c-blue" @click="open(scope.row.id)" icon="el-icon-edit">编辑
                    </el-button>
                </template>
            </el-table-column>
            <!--element 增加弹窗-->

        </el-table>
        <!--翻页-->
        <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="searchParams.page"
                :page-sizes="[10, 15, 20,50,100]"
                :page-size="searchParams.size"
                layout="total,sizes, prev, pager, next"
                :total="searchParams.count">
        </el-pagination>

        <!--弹窗,通过el-dialog来进行限制-->
        <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="50%">
            <!--ref 验证简写-->
            <el-form :model="FeedbackInfo.form" label-width="80px">
            <!--输入框-->
            <el-form-item label="反馈者姓名">
                <el-input v-model="FeedbackInfo.form.name" clearable></el-input>
            </el-form-item>
            <!--反馈者密码-->
            <el-form-item label="反馈者密码">
                <el-input v-model="FeedbackInfo.form.password" show-password></el-input>
            </el-form-item>

            <!--select下拉框-->
            <el-form-item label="反馈类别" prop="feed_sort">
                <el-select v-model="FeedbackInfo.form.feed_sort" placeholder="请选择活动区域">
                    <el-option label="骚扰" value="1"></el-option>
                    <el-option label="信息盗取" value="3"></el-option>
                </el-select>
            </el-form-item>

             <!--复选框进行多选-->
            <el-form-item label="反馈类别" prop="mix_value">
                <el-select v-model="FeedbackInfo.form.mix_value" multiple placeholder="请选择">
                    <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <!--时间插件-->
            <el-form-item label="时间" >
                <el-col :span="11">
                    <el-form-item prop="date1">
                        <el-date-picker type="date" placeholder="选择日期" v-model="FeedbackInfo.form.date1" style="width: 100%;"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col class="line" :span="2">-</el-col>
                <el-col :span="11">
                    <el-form-item prop="date2">
                        <el-time-picker placeholder="选择时间" v-model="FeedbackInfo.form.date2" style="width: 100%;"></el-time-picker>
                    </el-form-item>
                </el-col>
            </el-form-item>

            <!--开关插件-->
            <el-form-item label="后台提交" prop="feed_from">
                <el-switch v-model="FeedbackInfo.form.feed_from"></el-switch>
            </el-form-item>

            <!--禁填栏-->
            <el-form-item label="开关内容" prop="forbid">
                <el-input v-model="FeedbackInfo.form.feed_from" :disabled="true"></el-input>
            </el-form-item>

            <!--复选框测试-->
             <!--待优化为灵活读取的方式-->
             <!--传入的值为radio-->
            <el-form-item label="反馈建议类别">
                <el-checkbox-group v-model="FeedbackInfo.form.type">
                    <!--
                   <el-checkbox label="流畅度的优化" name="type" value="1">流畅度的优化</el-checkbox>
                   <el-checkbox label="bug的修复" name="type" value="2">bug的修复</el-checkbox>
                   <el-checkbox label="静态资源分离" name="type" value="3">静态资源分离</el-checkbox>
                   <el-checkbox label="分布式部署" name="type" value="4">分布式部署</el-checkbox>
                    -->
                    <el-checkbox label="1" name="type" >流畅度的优化</el-checkbox>
                    <el-checkbox label="2" name="type" >bug的修复</el-checkbox>
                    <el-checkbox label="3" name="type" >静态资源分离</el-checkbox>
                    <el-checkbox label="4" name="type" >分布式部署</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <!--单选框 传入的值为label-->
            <el-form-item label="单项选择">
                <el-radio-group v-model="FeedbackInfo.form.radio">
                    <el-radio label="1">线下测试</el-radio>
                    <el-radio label="2">线上测试</el-radio>
                    <el-radio label="3">线上正式</el-radio>
                </el-radio-group>
            </el-form-item>

            <!--文本域输入-->
            <el-form-item label="反馈建议">
                <el-input type="textarea" v-model="FeedbackInfo.form.feed_info"></el-input>
            </el-form-item>

            <!--当前上传图片的形式，多图上传-->

            <el-form-item label="反馈图片">
                <!--
                    el-upload中常用参数的解释:
                    1. action 上传地址
                    2. list-type 上传文件的类型， 此处 picture-card 指代 方块形多图上传
                    3. on-preview 点击文件列表中已上传的文件夹时的钩子
                    4. on-remove 文件列表移除文件时的钩子
                    5. on-success 文件上传成功时的钩子
                    6. limit 限制上传图片数量
                    7. on-exceed 上传文件超出 limit时绑定的钩子
                 -->
                <el-upload
                        action="/common/upload/multipart/"
                        list-type="picture-card"
                        :on-preview="handlePictureCardPreview"
                        :on-remove="handleRemove"
                        :on-success="handlePictureSuccess"
                        :limit="1"
                >
                    <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
            </el-form-item>

            <el-form-item label="文件上传">
            <el-upload
                    style="text-align:left; display: inline-block;"
                    action="/common/upload/multipart/"
                    :on-preview="handlePreviewFile"
                    :on-remove="handleRemoveFile"
                    :before-remove="beforeRemoveFile"
                    :on-success="handleSuccessFile"
                    multiple
                    :limit="1"
                    :on-exceed="handleExceedFile"
                    >
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip"></div>
            </el-upload>
            </el-form-item>

            <el-form-item>
                <el-button type="primary"  @click="CreateSubmit">立即创建</el-button>
                <el-button  @click="CancelSubmit">取消</el-button>
            </el-form-item>
        </el-form>
        </el-dialog>

        <!--
        <div style="width: 200px;text-align: center;" class="zhangsan">
            <div style="text-align: center; margin: 10px 10px 20px 30px;">
                自定义刷新
            </div>
            <div style="margin: 30px 130px 30px 30px ">
                <img src="/data/image/202101/18/4684d74f4549b1f1ac6fbe6104a95f17.jpg" class="pic-huanghe"/>
            </div>
        </div>
        -->

    </div>
</template>

<script>
    import EditAbleContent from '../../components/EditAbleContent'
    import ImagePreview from '../../components/ImagePreview'

    export default {
        name: "FeedbackList",
        components: {
            EditAbleContent, ImagePreview
        },
        mounted() {
            this.getList()
        },
        data() {
            return {
                isLoading: false,
                searchParams: {
                    keyword: '',
                    catalog: this.$route.query.catalog || '',
                    page: 1,
                    count: 0,
                    size: 10,
                },
                dataList: [],
                FeedbackInfo:{
                    form:{
                        id : 0,
                        name : '',
                        feed_info : '',
                        pic_info : [],
                        is_handle : 0,
                        create_time : 0,
                        handle_time : 0,
                        handle_info : '',
                        feed_sort: '1',
                        date1:'',
                        date2:'',
                        feed_from:'2',
                        type: [],
                        radio: 0,
                        other_resource:'',
                        mix_value:[]
                    }
                },
                dialogTitle: '添加反馈',
                dialogFormVisible: false,  //弹窗默认是否展示
                //图片上传相关
                dialogImageUrl:'',
                dialogVisible: false,
                options:[
                    {
                        value: '1',
                        label: '抄袭'
                    }, {
                        value: '2',
                        label: '骚扰'
                    }, {
                        value: '3',
                        label: '信息盗取'
                    }, {
                        value: '4',
                        label: '强制下载'
                    }, {
                        value: '5',
                        label: '垃圾信息'
                    }
                ]
            }
        },
        methods: {
            // 获取列表
            getList() {
                this.isLoading = true;
                this.$api.feedback.feedback('List', this.$copy(this.searchParams)).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.dataList = result.data.list;
                        this.searchParams.count = result.data.total;//总条数
                    }
                }).catch(error => {
                    this.$message.error(error);

                }).then(() => {
                    this.isLoading = false;
                });
            },

            // 移除反馈：
            remove(index) {
                if(!index){
                    this.$message.error('未查询到反馈信息');
                    return;
                }
                //增加软删除的提示框：
                this.$api.feedback.feedback('Delete', {id:index}).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.getList();
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                });
            },
            //删除等重要操作需要确认时，弹出的确认框
            //反馈移除确认弹框：
            confirmRemove(id){
                if(!id){
                    this.$message.error('未查询到反馈信息');
                    return;
                }
                this.$confirm('此操作将永久删除该反馈信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //点击确认回调的函数
                    this.$api.feedback.feedback('Delete', {id:id}).then(result => {
                        if (result.errCode) {
                            this.$message.error(result.message);
                        } else {
                            this.getList();
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }
                    }).catch(error => {
                        this.$message.error(error);
                    }).then(() => {
                        this.isLoading = false;
                    });
                }).catch(() => {
                    //点击取消回调的函数
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },

            //创建反馈(打开反馈提示窗)
            create() {
                this.dialogFormVisible = true;
            },
            //提交创建的反馈（submit）
            CreateSubmit(){
                this.$api.feedback.feedback('Create', this.FeedbackInfo.form,'post').then(result => {
                    if (result.errCode) {
                        this.dialogFormVisible = false;
                        this.$message.error(result.message);
                    } else {
                        this.getList();
                        this.dialogFormVisible = false;
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                    this.dialogFormVisible = false;
                });
            },
            CancelSubmit(){
                this.dialogFormVisible = false;
            },
            //回复
            open(id) {
                this.$prompt('请输入您对该反馈的建议', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }
                ).then(({ value }) => {
                    //继续触发，调用
                    console.log(id);
                    console.log(value);
                    this.$api.feedback.feedback('RemarkCheck', {id:id,remark:value},'post').then(result => {
                        if (result.errCode) {
                            this.$message.error(result.message);
                        } else {
                            this.$message({
                                type: 'success',
                                message: '您给出的建议是: ' + value
                            });
                        }
                    }).catch(error => {
                        this.$message.error(error);
                    }).then(() => {
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消输入'
                    });
                });
            },
            //弹窗编辑的方式
            edit(){

            },

            // 点击分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getList();
            },

            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getList();
            },

            //当图片被移除时，触发的事件钩子
            //此处的file代表的具体的图片信息（其中有一个reponse属性指代执行“action”过后，返回的信息），fileList代表当前图片列表中剩下的图片
            handleRemove(file, fileList) {
                let that = this;
                if(this.FeedbackInfo.form.pic_info.length != 0){
                    this.FeedbackInfo.form.pic_info.forEach(function(value,key){
                        if(value == file.response.data.object_url){
                            that.FeedbackInfo.form.pic_info.splice(key,1);
                        }
                    })
                }
                console.log(file, fileList);
            },

            //点击已经上传成功的图片时，触发的事件钩子（例如图片上传完成后的放大镜）
            //此处的file代表的是具体的图片信息
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            //这里的file参数，代表调用上传图片接口后(action),返回的信息
            handlePictureSuccess(result){
                if(result.errCode){
                    this.$message.error(result.message);
                }
                //成功的情况进行图片存储
                this.FeedbackInfo.form.pic_info.push(result.data.object_url);
                console.log(this.FeedbackInfo.form.pic_info);
            },
            //文件上传（包括图片、txt、doc类文件）
            //点击已经上传成功文件，这里的file对应的是上传的文件信息
            handlePreviewFile(file){
                console.log(1.1);
                console.log(file);
            },
            //移除文件时触发
            handleRemoveFile(file){
                console.log(file);
                this.FeedbackInfo.form.other_resource = '';
            },
            //移除文件前触发的函数
            beforeRemoveFile(file){
                console.log(file);
            },
            handleExceedFile(file){
                console.log(file);
                this.$message.error('当前只支持上传一个文件包');
            },
            //此处的result指代的是 调用上传接口（action）成功后，返回的接口信息
            handleSuccessFile(result){
                if(result.errCode){
                    this.$message.error(result.message);
                }
                //成功的情况进行图片存储
                this.FeedbackInfo.form.other_resource = result.data.object_url;
            },
            /*
            handleClick(tab, event) {
                console.log(tab, event);
            }
             */
        }
    }
</script>

<!--vue 内置样式的方法-->
<style>
    .zhangsan {
        width: 600px;
        height: 100px;
    }

    .pic-huanghe {
        width: 200px;
        height: 150px;
    }
</style>

