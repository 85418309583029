<template>
    <!--基本信息-->
    <div>
        <el-dialog title="编辑客户信息" :visible.sync="dialogFormVisible" :close-on-click-modal="false" @close="closeDialog"
                   width="50%">
            <el-form class="follow-form" ref="customer" :model="customer" label-width="110px">
                <el-form-item label="车牌号码">
                    <el-input v-model="customer.plate_number" placeholder="请输入车牌号码"></el-input>
                </el-form-item>
                <el-form-item label="车辆识别代号">
                    <el-input v-model="customer.vin_number" placeholder="请输入车辆识别代号"></el-input>
                </el-form-item>
                <el-form-item label="发动机号">
                    <el-input v-model="customer.engine_number" placeholder="请输入发动机号"></el-input>
                </el-form-item>
                <el-form-item label="座位数量">
                    <el-input v-model="customer.seats" placeholder="座位数量"></el-input>
                </el-form-item>
                <el-form-item label="品牌型号">
                    <el-input v-model="customer.model" placeholder="品牌型号"></el-input>
                </el-form-item>
                <el-form-item label="保险到期">
                    <el-date-picker
                            v-model="customer.insurance_end_date"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="行驶证正面：" prop="license_image_a">
                    <div class="insurePicture">
                        <div class="item">
                            <el-upload
                                    action="/common/upload/multipart/"
                                    :on-preview="handlePictureCardPreviewA"
                                    :on-success="imageUploadedA"
                                    :on-remove="imageRemoveA">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <div>行驶证正面</div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="行驶证反面：" prop="license_image_b">
                    <div class="insurePicture">
                        <div class="item">
                            <el-upload
                                    action="/common/upload/multipart/"
                                    :on-preview="handlePictureCardPreviewB"
                                    :on-success="imageUploadedB"
                                    :on-remove="imageRemoveB">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <div>行驶证反面</div>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer txt-center">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="onSubmit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        name: "FormCarinfo",
        props: { //接受父组件传递来的数据
            customer: {
                type: Object
            }
        },
        mounted() {
            if (this.customer.insurance_end_date != 0) {
                this.customer.insurance_end_date = moment(this.customer.insurance_end_date * 1000).format('YYYY-MM-DD')
            } else {
                this.customer.insurance_end_date = ""
            }
        },
        data() {
            return {
                dialogFormVisible: true,
            }
        },
        methods: {
            onSubmit() {
                this.$loading.show('保存中');
                this.customer.action = 'edit_baseinfo';
                let data = JSON.parse(JSON.stringify(this.customer))
                if (data.insurance_end_date) {
                    data.insurance_end_date = moment(data.insurance_end_date).valueOf() / 1000
                }
                this.$api.ciCustomer.save(data).then(result => {
                    if (result.errCode) {
                        this.$message.alert(result.message)
                    } else {
                        this.dialogFormVisible = false;
                        this.$emit('submitCarinfo', this.dialogFormVisible)
                    }
                }).catch(error => {
                    this.$message.error(error)
                }).finally(() => {
                    this.$loading.close()
                })
                //this.closeDialog();
            },
            closeDialog() {
                this.dialogFormVisible = false;
                this.$emit('closeCarinfo', this.dialogFormVisible)
            },

            imageRemoveA() {
                this.customer.license_image_a = '';
            },
            handlePictureCardPreviewA(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            imageUploadedA(result) {
                if (result.errCode) {
                    return this.$message.error(result.message)
                }
                this.customer.license_image_a = result.data.object_url// URL.createObjectURL(file.raw);
            },
            imageRemoveB() {
                this.customer.license_image_b = '';
            },
            handlePictureCardPreviewB(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            imageUploadedB(result) {
                if (result.errCode) {
                    return this.$message.error(result.message)
                }
                this.customer.license_image_b = result.data.object_url// URL.createObjectURL(file.raw);
            }
        }
    }
</script>
