<template>
    <div>
        <el-form :model="form" :rules="formRules" status-icon ref="form" label-width="120px" class="">
            <el-form-item label="归属栏目：" prop="catalog_id">
                <catalog-selector :list="catalogList" :parent="catalogId" @changed="onParentChanged"/>
            </el-form-item>
            <el-form-item label="文章标题：" prop="title">
                <el-col :span="10">
                    <el-input type="text" placeholder="请输入文章标题" v-model="form.title"></el-input>
                </el-col>
            </el-form-item>
            <el-form-item label="封面图片：" prop="cover">
                <image-uploader v-model="form.cover" dt="url"/>
            </el-form-item>
            <el-form-item label="文章摘要：" prop="abstract">
                <el-col :span="10">
                    <el-input type="textarea" :rows="5" placeholder="文章摘要" v-model="form.abstract"></el-input>
                </el-col>
            </el-form-item>
            <el-form-item label="文章内容：" prop="content">
                <el-col :span="24">
                    <Editor :content="form.content" @changed="onContentChanged"/>
                </el-col>
            </el-form-item>
            <el-form-item label="栏目排序：" prop="orderby">
                <el-col :span="5">
                    <el-input type="text" placeholder="排序值,越大越靠前" v-model="form.orderby"></el-input>
                </el-col>
            </el-form-item>
        </el-form>
        <div class="txt-center">
            <el-button @click="back">取 消</el-button>
            <el-button type="primary" @click="save()" :loading="isLoading">保 存</el-button>
        </div>
    </div>
</template>

<script>
    import CatalogSelector from '../../components/CatalogSelector'
    import Editor from '../../components/editor/Editor'
    import ImageUploader from '../../components/ImageUploader'

    export default {
        name: "Article",
        components: {
            CatalogSelector, Editor, ImageUploader
        },
        mounted() {
            this.getCatalogList();
            let id = this.$route.query.id;
            if (id) {
                this.detail(id)
            } else {
                let catalog = this.$route.query.catalog || '';
                this.form = {
                    catalog_id: catalog,
                    catalog_parents: []
                };
                this.catalogId = this.$copy(this.form.catalog_id)
            }
        },
        data() {
            return {
                isLoading: false,
                catalogList: [],
                form: {
                    catalog_id: '',
                    catalog_parents: []
                },
                formRules: {
                    catalog_id: {required: true, message: '请选择归属栏目', trigger: 'blur'},
                    title: {required: true, message: '请输入文章标题', trigger: 'blur'},
                    abstract: {required: true, message: '请输入文章摘要', trigger: 'blur'},
                },
                catalogId: ''
            }
        },
        methods: {

            onContentChanged(content) {
                this.form.content = content
            },
            onCoverChanged(file) {
                this.form.cover = file
            },
            onParentChanged(data) {
                this.form.catalog_id = data.id;
                this.form.catalog_parents = data.parents
            },
            detail(id) {
                this.isLoading = true;
                this.$api.article.article('detail', {id: id}).then(result => {
                    if (result.errCode) {
                        this.$message.alert(result.message)
                    } else {
                        this.form = result.data;
                        this.catalogId = this.$copy(this.form.catalog_id)
                    }
                }).catch(error => {
                    this.$message.error(error)
                }).finally(() => {
                    this.isLoading = false
                })
            },
            save() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.isLoading = true;
                        this.$api.article.article('Save', this.form, 'post').then(result => {
                            if (result.errCode) {
                                this.$message.alert(result.message)
                            } else {
                                this.$message.success('保存成功');
                                this.$router.push({path: '/article/list'})
                            }
                        }).catch(error => {
                            this.$message.error(error)
                        }).finally(() => {
                            this.isLoading = false
                        })
                    } else {
                        return false;
                    }
                });
            },
            //获取列表
            getCatalogList() {
                this.isLoading = true;
                this.$api.article.catalog('List', {}).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.catalogList = result.data;
                    }
                }).catch(error => {
                    this.$message.error(error);

                }).then(() => {
                    this.isLoading = false;
                });
            },
            back() {
                this.$router.back()
            }
        }
    }
</script>