<template>
  <div>
    <el-form :model="messageForm" :rules="messageRules" label-width="80px" ref="form">
      <!--输入框-->
      <el-form-item label="标题" required="" prop="title">
        <el-col :span="8" >
          <el-input v-model="messageForm.title" clearable></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="封面图片" required="">
        <el-upload
            action="/common/upload/multipart/"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemovePic"
            :on-success="handlePictureSuccessPic"
            :limit="1" :file-list="picUrl" >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisibleMessage">
          <img width="100%" :src="dialogImageUrlMessage" alt="">
        </el-dialog>
      </el-form-item>





      <el-form-item label="消息内容：" prop="msg_content">
        <el-col :span="24">
          <Editor v-model="messageForm.msg_content" :content="messageForm.msg_content" @changed="onContentChanged"/>
        </el-col>
      </el-form-item>

      <el-form-item>
        <el-button type="primary"  @click="SaveMessage()">保存</el-button>
        <el-button  @click="back()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>

</template>
<script>

import Editor from '../../components/editor/Editor'

export default {
  name: "messageSave",
  components: {
    Editor
  },
  mounted() {
    let id = this.$route.query.id;
    if(id){
      this.detail(id);
    }
  },
  data() {
    return {
      isLoading: false,
      messageForm: {
        id : 0,
        title : '',
        pic : '',
        msg_content : ''
      },
      messageRules: {
        title: {required: true, message: '请输入标题', trigger: 'blur'},
        pic: {required: true, message: '请选择消息封面', trigger: 'blur'},
        msg_content: {required: true, message: '请输入消息内容', trigger: 'blur'},
      },
      //图片上传相关
      dialogImageUrlMessage:'',
      dialogVisibleMessage: false, //放大镜属性
      picUrl:[],
    }
  },
  methods: {
    //添加消息
    SaveMessage(){
      this.$refs['form'].validate((valid) => {
        if(valid){
          this.$api.journal.message('Save', this.messageForm,'post').then(result => {
            if (result.errCode) {
              this.$message.error(result.message);
            }else{
              this.$message.success('保存成功');
              this.$router.push({path: '/message/list'});
            }
          }).catch(error => {
            this.$message.error(error);
          }).then(() => {
            this.isLoading = false;
          });
        }
        return false;
      })
    },
    detail(id) {
      this.isLoading = true;
      this.$api.journal.message('detail', {id: id}).then(result => {
        if (result.errCode) {
          this.$message.alert(result.message);
        } else {
          this.messageForm = this.$copy(result.data);
          this.dialogImageUrlMessage = this.messageForm.pic;
          let obj = new Object();
          obj.url = this.dialogImageUrlMessage;
          this.picUrl.push(obj);
        }
      }).catch(error => {
        this.$message.error(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
    //封面图片处理的具体方式
    handleRemovePic() {
      this.messageForm.pic = '';
    },
    //放大镜
    handlePictureCardPreview(file) {
      this.dialogImageUrlMessage = file.url;
      this.dialogVisibleMessage = true;
    },
    //上传成功
    handlePictureSuccessPic(result){
      if(result.errCode){
        this.$message.error(result.message);
      }
      //成功的情况进行图片存储
      this.messageForm.pic = result.data.object_url;
    },
    onContentChanged(content) {
      this.messageForm.msg_content = content
    },

    //分页
    handleSizeChange(val) {
      this.searchParams.size = val;
      this.getListUserText();
    },

    handleCurrentChange(val) {
      this.searchParams.page = val;
      this.getListUserText();
    },
    back() {
      this.$router.back()
    }

  }
}
</script>