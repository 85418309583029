<template>
  <div>
   <!-- 产品logo   活动名称  -->
    <div class="setname">
    <div class="settopLogo">
      <div class="bannerTitle">奖品图片：</div>
      <div>
        <img
          style="height: 100px; width: 150px"
          :src="giftimg"
          alt=""
          srcset=""
        />
      </div>
      <div v-if="giftimgShow == 1" style="padding-top: 68px; margin-left: 20px">
        <el-button
          @click="setnew(1)"
          class="el-icon-upload2"
          size="small"
          type="primary"
          >上传</el-button
        >
      </div>
      <div v-else style="padding-top: 68px; margin-left: 20px">
        <el-button
          @click="setnew(1)"
          class="el-icon-edit"
          size="small"
          type="primary"
          >修改</el-button
        >
      </div>
      <input
        id="uploadImage1"
        type="file"
        ref="fileInput1"
        accept="image/*"
        @change="getFile(1)"
        style="display: none"
      />

    </div>


      <div class="bannerTitle" style="margin-left: 160px;">活动名字：</div>
      <div>
        <input
          class="nameInp"
          v-model="activity_name"
          placeholder="请输入"
          type="text"
        />
      </div>
    </div>

    <!-- 活动名字   奖品数量  -->
    <div class="setname">
      <div class="bannerTitle">奖品名字：</div>
      <div>
        <input
          class="nameInp"
          v-model="name"
          placeholder="请输入"
          type="text"
        />
      </div>

      <div class="bannerTitle" style="margin-left: 160px">奖品数量：</div>
      <div>
        <input
          class="nameInp"
          v-model="quantity"
          placeholder="请输入"
          type="text"
        />
      </div>
    </div>

    <!-- 热度基数   奖品价值  -->
    <div class="setname">
      <div class="bannerTitle">热度基数：</div>
      <div>
        <input
          class="nameInp"
          v-model="space"
          placeholder="请输入"
          type="text"
        />
      </div>

      <div class="bannerTitle" style="margin-left: 160px">奖品价值：</div>
      <div>
        <input
          class="nameInp"
          v-model="price"
          placeholder="请输入"
          type="text"
        />
      </div>
    </div>

    <!-- 参与人数   参与价格  -->
    <div class="setname">
      <div class="bannerTitle">参与人数：</div>
      <div>
        <input
          class="nameInp"
          v-model="draw_people"
          placeholder="请输入"
          type="text"
        />
      </div>

      <div class="bannerTitle" style="margin-left: 160px">参与价格：</div>
      <div>
        <input
          class="nameInp"
          v-model="consume_koi"
          placeholder="请输入"
          type="text"
        />
      </div>
    </div>

    <!-- 视频获取货币   视频次数  -->
    <div class="setname">
      <div class="bannerTitle">视频获取幸运值：</div>
      <div>
        <input
          class="nameInp"
          v-model="video_add_probability"
          placeholder="请输入"
          type="text"
        />
      </div>

      <div class="bannerTitle" style="margin-left: 160px">视频次数：</div>
      <div>
        <input
          class="nameInp"
          v-model="init_video_times"
          placeholder="请输入"
          type="text"
        />
      </div>
    </div>

    <!-- 好友助力获取幸运值   助力次数  -->
    <div class="setname">
      <div class="bannerTitle">好友助力获取幸运值：</div>
      <div>
        <input
          class="nameInp"
          v-model="friend_add_probability"
          placeholder="请输入"
          type="text"
        />
      </div>

      <div class="bannerTitle" style="margin-left: 160px">助力次数：</div>
      <div>
        <input
          class="nameInp"
          v-model="init_friends_times"
          placeholder="请输入"
          type="text"
        />
      </div>
    </div>

    <!-- 好友助力获取幸运值   助力次数  -->
    <div class="setname">
      <div class="bannerTitle">分享空间获取幸运值：</div>
      <div>
        <input
          class="nameInp"
          v-model="share_add_probability"
          placeholder="请输入"
          type="text"
        />
      </div>

      <div class="bannerTitle" style="margin-left: 160px">分享次数：</div>
      <div>
        <input
          class="nameInp"
          v-model="init_share_times"
          placeholder="请输入"
          type="text"
        />
      </div>
    </div>

    <div style="width: 20%; margin: 40px auto 0px auto">
      <el-button style="width: 100%" type="primary" @click="sure"
        >确定</el-button
      >
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    value: {
      type: Number,
      id: Number,
    },
  },
  name: "Index",
  data() {
    return {
      giftimg: require("@/assets/images/bg.png"), //奖品图logo
      name: "", // 奖品信息
      activity_name:'',//活动名字
      quantity: "", //  奖品数量    默认为1
      space: "", //	间隔人数对应的火热度
      price: "", //	钻石价格
      draw_people: "", //  满多少人抽奖
      consume_koi: "", //  锦鲤价格
      video_add_probability: "", //  看视频提高的幸运值
      init_video_times: "", //  看视频次数
      friend_add_probability: "", //  好友助力提高幸运值
      init_friends_times: "", // 好友助力次数
      share_add_probability: "", // 分享到空间提高的值
      init_share_times: "", // 分享次数限制
    };
  },
  created() {
    console.log(this.value);
    if (this.value.type == 0) {
      this.giftimgShow = 1;
    } else {
      this.name = this.value.obj.name;
      this.activity_name=this.value.obj.activity_name;
      this.quantity = this.value.obj.quantity;
      this.giftimg=this.value.obj.banner;
      this.space = this.value.obj.space;
      this.price = this.value.obj.price;
      this.draw_people = this.value.obj.draw_people;
      this.consume_koi = this.value.obj.consume_koi;  
      this.video_add_probability = this.value.obj.video_add_probability;
      this.init_video_times = this.value.obj.init_video_times;
      this.friend_add_probability = this.value.obj.friend_add_probability;
      this.init_friends_times = this.value.obj.init_friends_times;
      this.share_add_probability = this.value.obj.share_add_probability;
      this.init_share_times = this.value.obj.init_share_times;
      this.topimgShow = 2;
    }
  },
  mounted() {},
  watch: {},
  methods: {
    //触发选图
    setnew: function () {
      console.log(this.$refs);

      this.$refs.fileInput1.click();
    },
    //上传图片
    getFile: function (e) {

      console.log(e);
      var file = "";
      file = document.getElementById("uploadImage1").files[0];

      console.log(file); //由打印的可以看到，图片    信息就在files[0]里面
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (data) => {
        console.log(data);
      };
      let formData = new FormData(); //通过formdata上传
      formData.append("file", file);

      axios.post("/common/upload/multipart", formData).then((res) => {
        console.log(res.data.data);
        //   预览的图片

        this.giftimgShow = 2;
        this.giftimg = res.data.data.object_url;
      });
    },
    // 确定按钮  this.value.type区分新增（0）or编辑（1）
    sure: function () {
      if (this.value.type == 0) {
        this.setoneInfo("");
      } else {
        this.setoneInfo(this.value.obj.id);
      }
    },

    //e是id
    setoneInfo: function (e) {
      let data = {
        name: this.name,
        activity_name:this.activity_name,
        quantity: this.quantity,
        space: this.space,
        price: this.price,
        banner: this.giftimg,
        draw_people: this.draw_people,
        consume_koi: this.consume_koi,
        video_add_probability: this.video_add_probability,
        init_video_times: this.init_video_times,
        friend_add_probability: this.friend_add_probability,
        init_friends_times: this.init_friends_times,
        share_add_probability: this.share_add_probability,
        init_share_times: this.init_share_times,
        id: e,
      };
      this.isLoading = true;
      this.$api.luckDraw
        .activitySave(data)
        .then((result) => {
          console.log(result);
          this.$message.success("修改成功");
          this.$emit("dialogVisible", "false");
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style>
.el-icon-upload2:before {
  float: right;
}
.el-icon-edit:before {
  float: right;
}
.settopLogo {
  display: flex;
  justify-items: center;
}
.bannerTitle {
  width: 80px;
  color: #606266;
  font-size: 14px;
}
.setname {
  display: flex;
  justify-items: center;
  align-items: center;
  margin-top: 20px;
}
.nameInp {
  height: 30px;
  padding-left: 10px;
  width: 223px;
  outline: none;
}
</style>





