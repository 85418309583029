<template>
    <div>
        <!--搜索栏-->
        <el-form :inline="true" :model="searchParams" ref="searchForm" label-width="90px" class="demo-form-inline">
            <el-form-item prop="keyword" class="ml-10">
                <el-input v-model="searchParams.keyword" placeholder="关键字"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" @click="getListUserText()" :loading="isLoading">查询</el-button>
            </el-form-item>
        </el-form>
        <!--表单信息-->
        <el-table
                :data="dataList"
                stripe
                style="width: 100%"
                row-key="id">
            <el-table-column
                    prop="id"
                    label="ID"
            >
            </el-table-column>

            <el-table-column
                    prop="title"
                    label="手账名称">
            </el-table-column>

            <el-table-column
                    prop="cover"
                    label="封面图片">
                <template slot-scope="scope">
                    <image-preview :file="scope.row.cover"/>
                </template>
            </el-table-column>

            <el-table-column
                    prop="created"
                    label="创建时间">
                <template slot-scope="scope">
                    {{scope.row.create_time|time}}
                </template>
            </el-table-column>

            <el-table-column
                    prop="record_time"
                    label="记录时间">
                <template slot-scope="scope">
                    {{scope.row.record_time|time}}
                </template>
            </el-table-column>


            <el-table-column
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" icon="el-icon-sort-up" @click="movingSysTextTpl(scope.$index, scope.row.id)">移动至系统模板
                    </el-button>
                    <el-button type="warning" @click="setStatus(scope.row.id,scope.row.text_status)">
                      <a v-if="scope.row.text_status == 0">禁用</a>
                      <a v-if="scope.row.text_status == 1">启用</a>
                    </el-button>
                </template>
            </el-table-column>


        </el-table>
        <!--翻页-->
        <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="searchParams.page"
                :page-sizes="[10, 15, 20,50,100]"
                :page-size="searchParams.size"
                layout="total,sizes, prev, pager, next"
                :total="searchParams.count">
        </el-pagination>

    </div>


</template>

<script>

    import ImagePreview from '../../components/ImagePreview'

    export default {
        name: "UserTextList",
        components: {
            ImagePreview
        },
        mounted() {
            this.getListUserText();

        },
        data() {
            return {
                isLoading: false,
                //搜索传参
                searchParams: {
                    keyword: '',
                    page: 1,
                    count: 0,
                    size: 10,
                },
                dataList:[]
            }
        },
        methods: {
            //获取封面列表
            getListUserText() {
                this.isLoading = true;
                this.$api.journal.user_text('List', this.$copy(this.searchParams)).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.dataList = result.data.list;
                        this.searchParams.count = result.data.total;//总条数
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                })
            },

            //分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getListUserText();
            },

            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getListUserText();
            },
            movingSysTextTpl(row,id) {
                this.$confirm('是否移动到系统模板','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$api.journal.user_text('MovingSysTextTpl', {id:id} ,'post').then(result => {
                        if(result.errCode){
                            this.$message.error(result.message);
                        }else{
                            this.$message({
                                type: 'success',
                                message: '移动成功!'
                            });
                        }
                    }).catch(error => {
                      this.$message.error(error);
                    }).then(() => {
                      this.isLoading = false;
                    })
                }).catch(() => {
                    //点击取消回调的函数
                    this.$message({
                      type: 'info',
                      message: '取消'
                    });
                });
            },
            setStatus(id,status){
              let msg = status ? '是否禁用模板': '是否取消模板禁用状态';
              this.$confirm(msg,'提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.$api.journal.user_text('SetStatus', {id:id} ,'post').then(result => {
                  if(result.errCode){
                    this.$message.error(result.message);
                  }else{
                    this.$message({
                      type: 'success',
                      message: '设置成功!'
                    });
                    this.getListUserText();
                  }
                }).catch(error => {
                  this.$message.error(error);
                }).then(() => {
                  this.isLoading = false;
                })
              }).catch(() => {
                //点击取消回调的函数
                this.$message({
                  type: 'info',
                  message: '取消'
                });
              });
            }
        }
    }
</script>

