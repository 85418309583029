<template>
    <!--基本信息-->
    <div>
        <el-dialog title="编辑合同信息" :visible.sync="dialogFormVisible" :close-on-click-modal="false" @close="closeDialog">
            <el-form class="follow-form" :rules="formRules" ref="form" :model="formData" label-width="110px">
                <el-form-item label="保险公司" prop="deal_insurance_company_name">
                    <el-select v-model="formData.deal_insurance_company_name" placeholder="保险公司">
                        <el-option label="请选择" value=""></el-option>
                        <el-option label="人保" value="人保"></el-option>
                        <el-option label="太平洋" value="太平洋"></el-option>
                        <el-option label="平安" value="平安"></el-option>
                        <el-option label="阳光" value="阳光"></el-option>
                        <el-option label="安诚" value="安诚"></el-option>
                        <el-option label="安心" value="安心"></el-option>
                        <el-option label="人寿" value="人寿"></el-option>
                        <el-option label="大家" value="大家"></el-option>
                        <el-option label="天安" value="天安"></el-option>
                        <el-option label="利宝" value="利宝"></el-option>
                        <el-option label="锦泰" value="锦泰"></el-option>
                        <el-option label="太平" value="太平"></el-option>
                        <el-option label="永安" value="永安"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="签约时间" prop="deal_date">
                    <el-date-picker
                            v-model="formData.deal_date"
                            type="datetime"
                            placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="生效时间" prop="guarantee_date">
                    <el-date-picker
                            v-model="formData.guarantee_date"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="合同编号" prop="deal_sn">
                    <el-input v-model="formData.deal_sn" placeholder="请输入合同编号"></el-input>
                </el-form-item>

                <el-form-item label="商业险金额" prop="deal_commercial_amount">
                    <el-input v-model="formData.deal_commercial_amount" placeholder="输入原始金额,未购买填写0"></el-input>
                </el-form-item>
                <el-form-item label="交强险金额" prop="deal_compulsory_amount">
                    <el-input v-model="formData.deal_compulsory_amount" placeholder="输入原始金额,未购买填写0"></el-input>
                </el-form-item>
                <el-form-item label="车船税金额" prop="taxes_amount">
                    <el-input v-model="formData.taxes_amount" placeholder="输入原始金额,未购买填写0"></el-input>
                </el-form-item>
                <el-form-item label="驾意险金额" prop="accident_amount">
                    <el-input v-model="formData.accident_amount" placeholder="输入原始金额,未购买填写0"></el-input>
                </el-form-item>
                <el-form-item label="优惠金额" prop="discount_amount">
                    <el-input v-model="formData.discount_amount" placeholder="返点给客户的金额"></el-input>
                </el-form-item>
                <el-form-item label="商业险保单" prop="policy">
                    <file-uploader v-model="formData.policy.commercial" tips="只能上传pdf/jpg/png文件，且不超过2MB" type="pdf"/>
                </el-form-item>
                <el-form-item label="交强险保单" prop="policy">
                    <file-uploader v-model="formData.policy.compulsory" tips="只能上传pdf/jpg/png文件，且不超过2MB" type="pdf"/>
                </el-form-item>
                <el-form-item label="其它保单" prop="policy">
                    <file-uploader v-model="formData.policy.other" tips="只能上传pdf/jpg/png文件，且不超过2MB" type="pdf"/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer txt-center">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="onSubmit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import moment from 'moment'
    import FileUploader from "./FileUploader";

    export default {
        name: "FormContract",
        components: {
            FileUploader
        },
        props: { //接受父组件传递来的数据
            customer: {
                type: Object
            }
        },
        mounted() {
            if (parseInt(this.customer.deal_date)) {
                this.formData.deal_date = moment(this.customer.deal_date * 1000).format('YYYY-MM-DD HH:mm:ss')
            } else {
                this.formData.deal_date = moment().format('YYYY-MM-DD HH:mm:ss')
            }
        },
        data() {
            return {
                formData: {
                    id: this.customer.id,
                    deal_insurance_company_name: this.customer.deal_insurance_company_name,
                    deal_date: !this.customer.deal_date ? moment(this.customer.deal_date * 1000).format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
                    guarantee_date: [],
                    deal_sn: this.customer.deal_sn,
                    deal_commercial_amount: this.customer.deal_commercial_amount || '',
                    deal_compulsory_amount: this.customer.deal_compulsory_amount || '',
                    accident_amount: '',
                    taxes_amount: '',
                    discount_amount: this.customer.discount_amount,
                    manager: this.customer.manager,
                    policy: this.customer.policy
                },
                formRules: {
                    deal_insurance_company_name: {required: true, message: '请选择保险公司', trigger: 'submit'},
                    deal_date: {required: true, message: '请填写签约时间', trigger: 'blur'},
                    guarantee_date: {required: true, message: '请选择生效时间', trigger: 'blur'},
                    deal_sn: {required: true, message: '请填写保单号', trigger: 'blur'},
                    deal_commercial_amount: {required: true, message: '请填写商业险金额', trigger: 'blur'},
                    deal_compulsory_amount: {required: true, message: '请填写交强险金额', trigger: 'blur'},
                    accident_amount: {required: true, message: '请填写驾意险金额', trigger: 'blur'},
                    taxes_amount: {required: true, message: '请填写车船税金额', trigger: 'blur'},
                    discount_amount: {required: true, message: '请填写客户返点金额', trigger: 'blur'},
                },
                dialogFormVisible: true,
            }
        },
        methods: {
            onSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$loading.show('保存中');
                        this.formData.action = 'deal';
                        this.$api.ciCustomer.save(this.formData).then(result => {
                            if (result.errCode) {
                                this.$message.alert(result.message)
                            } else {
                                this.dialogFormVisible = false;
                                this.$emit('submitBaseInfo', this.dialogFormVisible)
                            }
                        }).catch(error => {
                            this.$message.error(error)
                        }).finally(() => {
                            this.$loading.close()
                        })
                    } else {
                        return false
                    }
                })

            },
            closeDialog() {
                this.dialogFormVisible = false;
                this.$emit('closeTransferContract', this.dialogFormVisible)
            }
        }
    }
</script>

<style scoped>

</style>