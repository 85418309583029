<template>
    <div class="">
        <div class="quill-editor" style="line-height: 20px;" ref="toolbar">
            <div id="toolbar" :class={fixedToolbar:isFixed} class="ql-toolbar ql-snow">
                <button @click="submit" class="el-icon-document-checked" type="button" style="font-size: 20px;"></button>
            </div>
        </div>
        <codemirror
                style="line-height: 25px;"
                ref="cmEditor"
                :value="content"
                :options="cmOptions"
                @blur="onCmBlur"
                @ready="onCmReady"
                @focus="onCmFocus"
                @input="onCmCodeChange"
        />
    </div>
</template>

<script>
    import {codemirror} from 'vue-codemirror'
    // import base style
    import 'codemirror/lib/codemirror.css'
    // import language js
    import 'codemirror/mode/htmlembedded/htmlembedded.js'
    import 'codemirror/mode/htmlmixed/htmlmixed.js'
    // import theme style
    import 'codemirror/theme/base16-dark.css'
    import JB from 'js-beautify'

    export default {
        name: "Html",
        props: { //接受父组件传递来的数据
            html: {
                type: String
            }
        },
        components: {
            codemirror
        },
        watch: {
            html: {
                handler(html) {
                    this.content = JB.html_beautify(this.$copy(html).replace(/<p>\s*<\/p>/g, ''))
                }
            }
        },
        mounted() {
            this.toolBarT = this.$refs.toolbar.getBoundingClientRect().x;
            window.addEventListener('scroll', this.handleScroll, true)
        },
        data() {
            return {
                toolBarT: 0,
                isFixed: false,
                editor: null,
                dialogVisible: true,
                content: '',
                cmOptions: {
                    tabSize: 4,
                    mode: "text/html",
                    theme: 'base16-dark',
                    lineNumbers: true,
                    line: true,
                }
            }
        },
        methods: {
             handleScroll() {
                let editT = this.$store.state.editTop;
                let indexT = this.$store.state.indexTop;
                if(Math.abs(editT) >= (this.toolBarT-indexT)){
                    this.isFixed = true;
                }else{
                     this.isFixed = false;
                }
            },
            submit() {
                this.$emit('changed', this.content.replace(/<p>\s*<\/p>/g, ''))
            },
            onCmReady(cm) {
                this.editor = cm
                this.editor.setSize('auto', 'auto');
                this.content = JB.html_beautify(this.$copy(this.html).replace(/<p>\s*<\/p>/g, ''))
            },
            onCmFocus(cm) {
                // console.log('the editor is focused!', cm)
            },
            onCmBlur() {
                //this.submit()
            },
            onCmCodeChange(newCode) {
                //console.log('this is new code', newCode)
                this.content = newCode
            }
        },
        computed: {
            codemirror() {
                return this.$refs.cmEditor.codemirror
            }
        },
        destroyed() {
            window.removeEventListener("scroll",this.handleScroll)
        },
    }
</script>

<style scoped>
    .fixedToolbar{
        position: fixed;
        top: 120px;
        z-index: 10;
        background-color: #fff;
    }
</style>