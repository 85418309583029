<template>
    <div>
        <!--搜索栏-->
        <el-form :inline="true" :model="searchParams" ref="searchForm" label-width="90px" class="demo-form-inline">
            <el-form-item prop="keyword" class="ml-10">
                <el-input v-model="searchParams.keyword" placeholder="关键字"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" @click="getListCover()" :loading="isLoading">查询</el-button>
                <el-button icon="el-icon-plus" type="primary" @click="createCover()" :loading="isLoading">创建封面</el-button>
            </el-form-item>
        </el-form>
        <!--表单信息-->
        <el-table
                :data="dataList"
                stripe
                style="width: 100%"
                row-key="id">
            <el-table-column
                    prop="id"
                    label="ID"
            >
            </el-table-column>

            <el-table-column
                    prop="name"
                    label="封面名称">
            </el-table-column>

            <el-table-column
                    prop="cover"
                    label="封面图片">
                <template slot-scope="scope">
                    <image-preview :file="scope.row.cover"/>
                </template>
            </el-table-column>

            <el-table-column
                    prop="weight"
                    label="排序权重">
            </el-table-column>

            <el-table-column
                    prop="authority_desc"
                    label="使用权限">
            </el-table-column>

            <el-table-column
                    prop="forbidden_desc"
                    label="是否禁用">
            </el-table-column>

            <el-table-column
                    prop="created"
                    label="创建时间">
                <template slot-scope="scope">
                    {{scope.row.create_time|time}}
                </template>
            </el-table-column>

            <el-table-column
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="text" class="c-blue" @click="confirmCoverRemove(scope.row.id)" icon="el-icon-help">
                        <a v-if="scope.row.is_forbidden == 0">禁用</a>
                        <a v-if="scope.row.is_forbidden == 1">启用</a>
                    </el-button>
                    <el-button type="text" class="c-blue" @click="editCoverInfo(scope.$index)" icon="el-icon-edit-outline">编辑
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--翻页-->
        <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="searchParams.page"
                :page-sizes="[10, 15, 20,50,100]"
                :page-size="searchParams.size"
                layout="total,sizes, prev, pager, next"
                :total="searchParams.count">
        </el-pagination>
        <!--弹窗-->
        <el-dialog :title="dialogTitleCover" :visible.sync="dialogCoverFormVisible" :close-on-click-modal="false" width="50%">
            <el-form :model="Cover.form" label-width="80px">
                <!--输入框-->
                <el-form-item label="名称" required="">
                    <el-col :span="8">
                        <el-input v-model="Cover.form.name" clearable></el-input>
                    </el-col>
                </el-form-item>

                <!--单选框 传入的值为label-->
                <el-form-item label="使用权限" required="">
                    <el-radio-group v-model="Cover.form.authority">
                        <el-radio
                                v-for="(item,index) in AllAuthorityList"
                                :label="index"
                                :value="index">
                            {{item}}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="封面图片" required="">
                    <el-upload
                            action="/common/upload/multipart/"
                            list-type="picture-card"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="handleRemoveCover"
                            :on-success="handlePictureSuccessCover"
                            :limit="1"
                            :file-list="CoverPicList"
                    >
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisibleCover">
                        <img width="100%" :src="dialogImageUrlCover" alt="">
                    </el-dialog>
                </el-form-item>

                <el-form-item label="排序权重" required="">
                    <el-col :span="8">
                        <el-input v-model.number="Cover.form.weight"></el-input>
                    </el-col>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary"  @click="CreateSubmitCover()">立即创建</el-button>
                    <el-button  @click="CancelSubmitCover()">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>


</template>

<script>

    import ImagePreview from '../../components/ImagePreview'

    export default {
        name: "JournalCoverList",
        components: {
            ImagePreview
        },
        mounted() {
            this.getListCover();
            this.getAllAuthorityList();
        },
        data() {
            return {
                isLoading: false,
                //搜索传参
                searchParams: {
                    keyword: '',
                    sort_id: '0',
                    page: 1,
                    count: 0,
                    size: 10,
                },
                //弹窗表单
                dialogTitleCover:'创建封面',
                dialogCoverFormVisible:false,
                Cover:{
                    form:{
                        id : 0,
                        name : '',
                        sort_id : '',
                        cover : '',
                        authority : '1',
                        weight : '0',
                        is_forbidden : '0',
                    }
                },
                //封面列表
                dataList: [],
                activeName: 'first',
                //图片上传相关
                dialogImageUrlCover:'',
                dialogVisibleCover: false,
                CoverPicList:[],
                AllAuthorityList:[],
            }
        },
        methods: {
            //获取封面列表
            getListCover() {
                this.isLoading = true;
                this.$api.journal.cover('List', this.$copy(this.searchParams)).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.dataList = result.data.list;
                        this.searchParams.count = result.data.total;//总条数
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                })
            },
            //禁用封面
            confirmCoverRemove(id){
                if(!id){
                    this.$message.error('未查询到反馈信息');
                    return;
                }
                this.$confirm('是否更改使用状态?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //点击确认回调的函数
                    this.$api.journal.cover('SetChange', {id:id}).then(result => {
                        if (result.errCode) {
                            this.$message.error(result.message);
                        } else {
                            this.getListCover();
                            this.$message({
                                type: 'success',
                                message: '操作成功!'
                            });
                        }
                    }).catch(error => {
                        this.$message.error(error);
                    }).then(() => {
                        this.isLoading = false;
                    });
                }).catch(() => {
                    //点击取消回调的函数
                    this.$message({
                        type: 'info',
                        message: '取消'
                    });
                });
            },
            //创建封面（打开弹窗）
            createCover() {
                this.dialogCoverFormVisible = true;
                this.CoverPicList = [];
                this.Cover.form = {
                    id : 0,
                    name : '',
                    cover : '',
                    authority : '1',
                    weight : '0',
                    is_forbidden : '0',
                };
            },
            //提交创建封面
            CreateSubmitCover(){
                this.$api.journal.cover('Save', this.Cover.form,'post').then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.getListCover();
                        this.dialogCoverFormVisible = false;
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                });
            },
            //取消提交
            CancelSubmitCover(){
                this.dialogCoverFormVisible = false;
            },
            //编辑
            editCoverInfo(index){
                this.dialogTitleCover = '编辑封面';
                this.dialogCoverFormVisible = true;
                this.CoverPicList = [];
                //对应copy的使用
                this.Cover.form = this.$copy(this.dataList[index]);
                //设置放大图片信息
                this.dialogImageUrlCover = this.Cover.form.cover;
                let obj = new Object();
                obj.url = this.dialogImageUrlCover;
                this.CoverPicList.push(obj);
            },
            //封面图片处理的具体方式
            handleRemoveCover() {
                this.Cover.form.cover = '';
            },
            //放大镜
            handlePictureCardPreview(file) {
                this.dialogImageUrlCover = file.url;
                this.dialogVisibleCover = true;
            },
            //上传成功
            handlePictureSuccessCover(result){
                if(result.errCode){
                    this.$message.error(result.message);
                }
                //成功的情况进行图片存储
                this.Cover.form.cover = result.data.object_url;
            },
            //分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getListCover();
            },

            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getListCover();
            },
            //获取所有权限类别
            getAllAuthorityList(){
                this.$api.journal.all_sort('AuthorityInfo').then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.AllAuthorityList = result.data;
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                });
            },
        }
    }
</script>

