<template>
    <div>
        <el-table
                :data="dataList"
                stripe
                style="width: 100%"
                row-key="id"
                default-expand-all>
            <el-table-column
                    prop="name"
                    label="栏目名称">
            </el-table-column>
            <el-table-column
                    prop="id"
                    label="栏目ID">
            </el-table-column>
            <el-table-column
                    prop="cover"
                    label="封面">
                <template slot-scope="scope">
                    <image-preview :file="scope.row.cover"/>
                </template>
            </el-table-column>
            <el-table-column
                    prop="description"
                    label="栏目简介">
                <template slot-scope="scope">
                    {{scope.row.description||'--'}}
                </template>
            </el-table-column>
            <el-table-column
                    prop="article_count"
                    label="文章数量">
            </el-table-column>
            <el-table-column
                    prop="orderby"
                    label="排序">
                <template slot-scope="scope">
                    <edit-able-content :content="scope.row.orderby" :index="parseInt(scope.row.id)"
                                       @changed="updateOrderby"/>
                </template>
            </el-table-column>
            <el-table-column
                    label="操作">
                <template slot="header" slot-scope="">
                    <el-col :span="5">
                        <el-button icon="el-icon-plus" type="primary" @click="add('0')" class="ml-20" size="small">新增
                        </el-button>
                    </el-col>
                </template>
                <template slot-scope="scope">
                    <el-button v-if="scope.row.article_count==0" type="text" class="c-blue" @click="add(scope.row.id)"
                               icon="el-icon-plus">添加
                    </el-button>
                    <el-button type="text" class="c-blue" @click="edit(scope.row)"
                               icon="el-icon-edit-outline">编辑
                    </el-button>
                    <el-button v-if="scope.row.article_count==0 && scope.row.children.length==0" type="text"
                               class="c-blue" @click="remove(scope.row)"
                               icon="el-icon-delete">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--弹窗-->
        <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="50%">
            <el-form :model="form" :rules="formRules" status-icon ref="form" label-width="120px" class="">
                <el-form-item label="归属栏目：" prop="parent">
                    <catalog-selector :list="selectList" :parent="catalogParent" @changed="onParentChanged"/>
                </el-form-item>
                <el-form-item label="栏目名称：" prop="name">
                    <el-col :span="12">
                        <el-input type="text" placeholder="请输入栏目名称" v-model="form.name"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="封面图片：" prop="cover">
                    <image-uploader v-model="form.cover"/>
                </el-form-item>
                <el-form-item label="栏目简介：" prop="description">
                    <el-col :span="12">
                        <el-input type="textarea" :rows="3" placeholder="栏目简介" v-model="form.description"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="栏目排序：" prop="orderby">
                    <el-col :span="5">
                        <el-input type="text" placeholder="排序值,越大越靠前" v-model="form.orderby"></el-input>
                    </el-col>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="save()" :loading="isLoading">保 存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import EditAbleContent from '../../components/EditAbleContent'
    import ImageUploader from '../../components/ImageUploader'
    import ImagePreview from '../../components/ImagePreview'
    import CatalogSelector from '../../components/CatalogSelector'

    export default {
        name: "ArticleCatalog",
        components: {
            EditAbleContent, ImagePreview, ImageUploader, CatalogSelector
        },
        data() {
            return {
                isLoading: false,
                dialogFormVisible: false,
                form: {},
                dialogTitle: '添加栏目',
                formRules: {
                    parent: {required: true, message: '请选择归属栏目', trigger: 'blur'},
                    name: {required: true, message: '请输入栏目名称', trigger: 'blur'},
                    description: {required: true, message: '请输入栏目简介', trigger: 'blur'},
                },
                groupList: [],
                searchParams: {
                    keyword: '',
                    parent: this.$route.query.parent || '',
                    page: 1,
                    count: 1,
                    size: 10,
                },
                dataList: [],
                selectList: [],
                catalogParents: [],
                catalogParent: ''
            }
        },
        methods: {
            onCoverChanged(file) {
                this.form.cover = file
            },
            onParentChanged(data) {
                this.form.parent = data.id;
                if (data.id != '0') {
                    this.form.parents = data.parents
                }
            },
            //创建分组
            add(parent) {
                this.dialogTitle = '添加栏目';
                this.dialogFormVisible = true;
                this.form = {
                    parent: parent ? parent : '',
                    parents: [],
                    status: '1',
                    orderby: 0
                };
                this.catalogParent = parent;
                this.catalogParents = []
            },
            edit(form) {
                this.dialogTitle = '编辑栏目';
                this.dialogFormVisible = true;
                this.form = form;
                this.form.parents = this.form.parents || [];
                this.catalogParent = this.$copy(this.form.parent);
                this.catalogParents = this.$copy(this.form.parents)
            },
            updateOrderby(value, id) {
                this.isLoading = true;
                this.$api.article.catalog('Save', {id: id, orderby: value}, 'post').then(result => {
                    if (result.errCode) {
                        this.$message.alert(result.message)
                    } else {
                        this.$message.success('修改成功');
                        this.getList()
                    }
                }).catch(error => {
                    this.$message.error(error)
                }).finally(() => {
                    this.isLoading = false
                })
            },
            save() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.isLoading = true;
                        this.$api.article.catalog('Save', this.form, 'post').then(result => {
                            if (result.errCode) {
                                this.$message.alert(result.message)
                            } else {
                                this.dialogFormVisible = false;
                                this.$message.success('保存成功');
                                this.getList()
                            }
                        }).catch(error => {
                            this.$message.error(error)
                        }).finally(() => {
                            this.isLoading = false
                        })
                    } else {
                        return false;
                    }
                });
            },
            remove(form) {
                let item = form
                if (item.children.length) {
                    return this.$message.error('栏目下存在子栏目,无法删除')
                }
                this.$message.confirm({message: '确定要删除栏目[' + item.name + ']吗?'}, () => {
                    this.$loading.show('删除中')
                    this.$api.article.catalog('Remove', {id: item.id}, 'post').then(result => {
                        if (result.errCode) {
                            this.$message.alert(result.message)
                        } else {
                            this.getList()
                        }
                    }).catch(error => {
                        this.$message.error(error)
                    }).finally(() => {
                        this.$loading.close()
                    })
                })
            },
            //获取列表
            getList() {
                this.isLoading = true;
                this.$api.article.catalog('List', {}).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.dataList = result.data;
                        this.selectList = this.$copy(this.dataList)
                        //this.selectList.unshift({name: '父栏目', id: '0', parent: '', children: []})
                    }
                }).catch(error => {
                    this.$message.error(error);

                }).then(() => {
                    this.isLoading = false;
                });
            }
        },
        mounted() {
            this.getList()

        }
    }
</script>