<template>
  <div>
    <div class="searchbox">
      <el-button size="medium" type="primary" @click="newadd()">新增</el-button>
    </div>
    <el-table :data="tableData" 
  
    @expand-change="zydescription"
   style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="{ row }">
          <el-form label-position="left" inline class="demo-table-expand">
            <!-- <el-form-item label="时间">
              {{ timeValue[0] }} 至 {{ timeValue[1] }} 
            </el-form-item> -->
            <el-button size="mini" type="primary" @click="newWeek(row)">新增</el-button>
            <div v-for="(item,i) in row.sign_reward_list" :key="i">
                    <el-form-item :label="'星期'+item.sign_day">
                      <span v-if="item.reward_type == 1" style="margin-right: 10px;">蜂蜜</span>
                      <span v-else-if="item.reward_type == 2" style="margin-right: 10px;">蜜罐</span>
                      <span v-else-if="item.reward_type == 3" style="margin-right: 10px;">家具</span>
                      <span v-else-if="item.reward_type == 4" style="margin-right: 10px;">贴纸</span>
                    <el-button size="mini" type="primary" @click="eidtWeek(row,item)">编辑</el-button>
                    </el-form-item>
            </div>
    
          </el-form> 
        </template> 
      </el-table-column>

      <el-table-column prop="id" label="序号"> </el-table-column>
      <el-table-column prop="sign_name" label="名字"></el-table-column>
      <el-table-column
        prop="sign_start_time"
        label="开始时间"
      ></el-table-column>
      <el-table-column prop="sign_end_time" label="结束时间"></el-table-column>
      <el-table-column
        prop="more"
        label="操作"
        align="center"
        width="300"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" @click="editInfo(row)"
            >编辑</el-button
          >
          <el-button size="mini" type="danger" @click="delShow(row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 每周配置弹窗  -->
    <el-dialog
      title="操作"
      :visible.sync="dialogVisible"
      width="90%"
      :before-close="handleClose"
    >
      <newSignConfing
        @dialogVisible="childByValue($event)"
        v-if="flag"
        :value="value"
        title="添加"
      >
        <div slot="top"></div>
      </newSignConfing>
    </el-dialog>

    <!-- 新增轮弹窗  -->
    <el-dialog
      title="操作"
      :visible.sync="dialogVisible1"
      width="40%"
      :before-close="handleClose"
    >
      <div>
        <el-date-picker
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="timeValue"
          type="daterange"
          :clearable="false"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div class="week" style="margin-top: 20px">
        <div>姓名:</div>
        <div style="margin-left: 20px">
          <el-input v-model="lunName" placeholder="请输入名字"></el-input>
        </div>
        <div style="margin-left: 80px">
          <el-button type="primary" @click="sure">确定</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 删除 -->
    <el-dialog
      title="提示"
      :visible.sync="delshow"
      width="30%"
      :before-close="handleClose"
    >
      <span>您确定要删除这一条信息吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delshow = false">取 消</el-button>
        <el-button type="primary" @click="del">确 定</el-button>
      </span>
    </el-dialog>

    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="searchParams.page"
      :page-sizes="[10, 15, 20, 50, 100]"
      :page-size="searchParams.size"
      layout="total,sizes, prev, pager, next"
      :total="searchParams.count"
    >
    </el-pagination>
  </div>
</template>

<script>
import newSignConfing from "./newSignConfing";
export default {
  // name: "Index",
  components: {
    newSignConfing,
  },
  data() {
    return {
      dialogVisible: false, //是否显示配置天弹出框
      dialogVisible1: false, //是否显示新增轮弹出框
      tableData: [], //列表数据
      value: {}, //父传子
      flag: true, //刷新子组件
      searchParams: {
        page: 1,
        size: 10,
        count: 0,
      }, //分页
      id: "", //查询id
      delshow: false, //查询
      goshow: false, //查询
      oneId: "", //当前兑换者id
      oneName: "", //当前兑换者name
      timeValue: [], //一周的时间
      type: "", //0是新增1是编辑
      lunName: "", //新增轮的名字
      lunId: "", //新增轮id
    };
  },
  created() {
    this.getlist();
  },
  mounted() {},
  watch: {},
  methods: {
    //获取列表数据
    getlist: function () {
      var that = this;
      that.isLoading = true;
      that.$api.journal
        .signInfo(
          "List",
          { page: that.searchParams.page, size: that.searchParams.size },
          "get"
        )
        .then((result) => {
          console.log(result);
          that.tableData = result.data.list;
          for (let i = 0; i < that.tableData.length; i++) {
            that.tableData[i].sign_start_time = that.FormData(
              Number(that.tableData[i].sign_start_time)
            );
            that.tableData[i].sign_end_time = that.FormData(
              Number(that.tableData[i].sign_end_time)
            );
          }
          console.log(that.tableData);
          that.searchParams.count = result.data.total;
        })
        .catch((error) => {
          that.$message.error(error);
        })
        .finally(() => {
          that.isLoading = false;
        });
    },

     async zydescription(){




      },
    //时间戳转正常时间
    FormData(date) {
      console.log(date);
      let s = new Date(date * 1000);
      let y = s.getFullYear();
      let m =
        s.getMonth() + 1 < 10 ? "0" + (s.getMonth() + 1) : s.getMonth() + 1;
      let dd = s.getDate() < 10 ? "0" + s.getDate() : s.getDate();
      let hh = s.getHours() < 10 ? "0" + s.getHours() : s.getHours();
      let mm = s.getMinutes() < 10 ? "0" + s.getMinutes() : s.getMinutes();
      let ss = s.getSeconds() < 10 ? "0" + s.getSeconds() : s.getSeconds();
      let enddate = y + "-" + m + "-" + dd + " " + hh + ":" + mm + ":" + ss;
      console.log(enddate);
      return enddate;
    },
    //查询
    searchOne: function () {
      this.getlist(this.id);
    },
    //关闭弹出框
    handleClose(done) {
      this.timeValue = [];
      done();
    },

    //新增天 
    newWeek: function (e) {
        console.log(e)
        e.weekid=""
      this.value = {
        obj:e,
        id: 0,
      };
      this.flag = false;
      this.$nextTick(() => {
        this.flag = true;
      });
      this.dialogVisible = true;
    },

    //编辑天
    eidtWeek: function (e,q) {
        console.log(e)
        var x = e.id 
       q.weekid=x
      this.value = {
        obj:q,
        id:1,
      };
      this.flag = false;
      this.$nextTick(() => {
        this.flag = true;
      });
      this.dialogVisible = true;
    },

    //新增轮 弹框
    newadd: function () {
      this.type = 0;
      this.lunId = "";  
      this.lunName = "";
      this.timeValue = [];
      this.dialogVisible1 = true;
    },
    //编辑轮
    editInfo: function (e) {
      console.log(e);
      this.lunName = e.sign_name;
      this.lunId = e.id;
      this.timeValue = [];
      this.timeValue.push(e.sign_start_time);
      this.timeValue.push(e.sign_end_time);
      this.dialogVisible1 = true;
    },

    //添加轮
    sure: function () {
    //   console.log();

      this.isLoading = true;
      this.$api.journal
        .AddSignInfo(
          "AddSignInfo",
          {
            id: this.lunId,
            sign_name: this.lunName,
            sign_start_time: new Date(this.timeValue[0]).getTime() / 1000,
            sign_end_time: new Date(this.timeValue[1].replace("00:00:00","23:59:59")).getTime() / 1000,
          },
          "POST"
        )
        .then((result) => {
          console.log(result);
          if(result.errCode==0){
                this.timeValue = [];
                this.getlist();
          }else{    
              this.$message.error(result.message);
          }
          //   this.tableData = result.data.list;
          //   this.searchParams.count = result.data.total;
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });

      this.dialogVisible1 = false;
    },

    //删除的对话框
    delShow: function (e) {
      this.delshow = true;
      this.lunId = e;
    },
    //删除
    del: function () {
      this.isLoading = true;
      this.$api.journal
        .DeleteSignInfo("DeleteSignInfo", { id: this.lunId }, "post")
        .then((result) => {
          console.log(result);
          this.$message.success("删除成功");
          this.getlist();
          this.delshow = false;
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    //分页
    handleSizeChange(val) {
      this.searchParams.size = val;
      this.getlist();
    },

    handleCurrentChange(val) {
      this.searchParams.page = val;
      this.getlist();
    },

    childByValue: function (childValue) {
      // childValue就是子组件传过来的值
      console.log(childValue);
      this.$nextTick(() => {
        this.flag = true;
      });
      this.dialogVisible = false;
        this.getlist(this.id);
    },
  },
};
</script>

<style>
.searchbox {
  display: flex;
  align-items: center;
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 100%;
}
.week {
  display: flex;
  /* justify-content: center; */
  align-items: center;
 
}
</style>
