<template>
    <div>
        <el-row class="role-title">
            <el-button plain :class="selectedGroup==index?'active':''" v-for="(item,index) in groups" v-on:click="changeGroup(index)" :loading="selectedGroup==index && isLoading">
                {{item.uc_name}}
                <span v-if="item.uc_name != '站长'" class="remove" @click.stop="delGroup(item.uc_id)">x</span>
            </el-button>
            <el-button type="primary" @click="addGroupShow"><i class="el-icon-plus"></i>添加角色</el-button>
        </el-row>
        <el-row class="role-desc">
            <el-form :inline="true" :model="showGroup" ref="showGroup" :rules="rules">
                <el-form-item label="角色名称">
                    <el-input v-if="showGroup.uc_name != '站长'" v-model="showGroup.uc_name" placeholder="角色名称"></el-input>
                    <el-input v-if="showGroup.uc_name == '站长'" :disabled="true" v-model="showGroup.uc_name" placeholder="角色名称"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="showGroup.remark" placeholder="备注"></el-input>
                </el-form-item>
                <el-form-item label="别名">
                    <el-input v-model="showGroup.uc_alisas" placeholder="别名"></el-input>
                </el-form-item>
                <el-form-item label="入口">
                    <el-input v-model="showGroup.index" placeholder="views/home/Home.vue"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="saveRole('showGroup')">修改</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <div class="role-main">
            <div class="role-form" v-for="(item,index) in allNodes" v-bind:key="index">
                <div class="role-form-head">
                    <el-tooltip class="item" effect="light" :content="'['+item.alias+']'+item.remark" placement="top">
                        <el-checkbox :indeterminate="item.indeterminate" v-model="item.check_all" @change="handleCheckAllChange(index)"> {{item.name}}</el-checkbox>
                    </el-tooltip>
                </div>
                <div class="role-form-body">
                    <el-checkbox-group v-model="selectedNodes">
                        <el-tooltip v-for="(node,subIndex) in item.nodes" :key="subIndex" class="item" effect="light" :content="'['+node.alias+']'+node.remark" placement="top">
                            <el-checkbox :label="node.alias" @change="handleCheckedNodesChange(index,subIndex)">{{node.name}}</el-checkbox>
                        </el-tooltip>
                    </el-checkbox-group>
                </div>
            </div>

        </div>
        <div class="txt-center">
            <el-button type="primary" icon="el-icon-circle-check" @click="submitForm('siteForm')" :loading="isLoading">保存</el-button>
            <el-button @click="resetForm('siteForm')">取消</el-button>
        </div>
        <!--添加角色弹框-->
        <el-dialog title="添加角色" :visible.sync="dialogFormVisible" width="30%">
            <el-form :model="form" :rules="rules" status-icon ref="form" label-width="120px" class="">
                <el-form-item label="角色名称：" prop="uc_name">
                    <el-input type="text" placeholder="请输入角色名称" v-model="form.uc_name"></el-input>
                </el-form-item>
                <el-form-item label="备注：" prop="remark">
                    <el-input type="text" placeholder="请输入备注" v-model="form.remark"></el-input>
                </el-form-item>
                <el-form-item label="角色别名：" prop="uc_alisas">
                    <el-input type="text" placeholder="用在创建默认权限分配等场景" v-model="form.uc_alisas"></el-input>
                </el-form-item>
                <el-form-item label="首页入口：" prop="index">
                    <el-input type="text" placeholder="views/home/Home.vue" v-model="form.index"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveRole('form')" :loading="isLoading">添 加</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

    export default {
        name: "Permissions",
        data() {
            return {
                dialogFormVisible: false,
                form: {
                    uc_id: '',
                    uc_name: '',
                    uc_alisas: '',
                    remark: ''
                },
                showGroup: {
                    uc_id: '',
                    uc_name: '',
                    uc_alisas: '',
                    remark: '',
                    index: ''
                },
                rules: {
                    uc_name: {required: true, message: '请输入角色名称', trigger: 'blur'},
                },
                groups: [],
                selectedGroup: 0,
                allNodes: [],
                selectedNodes: [],
                groupId: 0,
                isLoading: false,
                loadingNodes: false
            };
        },
        methods: {
            //创建分组
            addGroupShow() {
                this.form = {
                    uc_id: '',
                    uc_name: '',
                    uc_alisas: '',
                    remark: ''
                };
                this.dialogFormVisible = true;
            },
            //保存角色组
            saveRole(formName) {
                let form = formName == 'form' ? this.form : this.showGroup;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.dialogFormVisible = false;
                        this.isLoading = true;
                        this.$api.system.saveGroup(form).then(result => {
                            if (result.errCode) {
                                this.$message.alert(result.message)
                            } else {
                                this.$message.success('保存成功');
                                this.getGroups(this.selectedGroup)
                            }
                        }).catch(error => {
                            this.$message.error(error)
                        }).finally(() => {
                            this.isLoading = false
                        })
                    } else {
                        return false;
                    }
                });
            },
            delGroup(id) {
                this.$confirm('确认删除该角色吗？', '提示').then(() => {
                    this.$api.system.delGroup({id: id}).then(res => {
                        if (res.errCode) {
                            this.$message.alert(res.message)
                        } else {
                            this.$message.success('删除成功!');
                            this.getGroups()
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            //切换分组
            changeGroup(index) {
                if (this.isLoading) {
                    return
                }
                this.selectedGroup = index
                this.getGroupNodes(this.groups[index])
            },
            //重设选择的权限节点
            resetSelectedNodes() {
                let nodes = []
                this.allNodes.forEach(mainNode => {
                    let checkCount = 0
                    mainNode.nodes.forEach(node => {
                        if (node.checked) {
                            nodes.push(node.alias)
                            checkCount++
                        }
                    })
                    if (checkCount == mainNode.nodes.length && mainNode.nodes.length != 0) {
                        mainNode.indeterminate = false
                        mainNode.check_all = true
                    } else if (checkCount > 0) {
                        mainNode.indeterminate = true
                        mainNode.check_all = false
                    } else {
                        mainNode.indeterminate = false
                    }
                    if (mainNode.check_all || mainNode.indeterminate) {
                        nodes.push(mainNode.alias)
                    }
                })
                this.selectedNodes = nodes
            },
            //全选操作
            handleCheckAllChange(index) {
                this.allNodes[index].indeterminate = false
                if (!this.allNodes[index].nodes.length) {
                    return
                }
                this.allNodes[index].nodes.forEach(item => {
                    if (this.allNodes[index].check_all) {
                        item.checked = true
                    } else {
                        item.checked = false
                    }
                })
                this.resetSelectedNodes()
            },
            //选择操作
            handleCheckedNodesChange(index, subIndex) {
                this.allNodes[index].nodes[subIndex].checked = !this.allNodes[index].nodes[subIndex].checked
                this.resetSelectedNodes()
            },
            //获取分组列表
            getGroups(index) {
                //this.$loading.show('加载中')
                this.isLoading = true
                this.$api.system.getGroups().then(result => {
                    this.isLoading = false;
                    if (result.errCode) {
                        this.$message.alert('加载角色列表失败:' + result.message)
                    } else {
                        this.groups = result.data
                        if (this.groups.length) {
                            var selectNode = index ? this.groups[index] : this.groups[0];
                            this.selectedGroup = index ? index : 0;
                            this.getGroupNodes(selectNode)
                        }
                    }
                }).catch(error => {
                    this.isLoading = false
                    this.$message.error(error)
                }).finally(() => {
                    //this.isLoading = true
                    //this.$loading.close()
                })
            },
            //获取所有可操作权限节点
            getGroupNodes(group) {
                this.groupId = group.uc_id;
                this.showGroup = {
                    uc_id: group.uc_id,
                    uc_name: group.uc_name,
                    uc_alisas: group.uc_alisas,
                    remark: group.remark,
                    index: group.index
                };
                //this.$loading.show('加载中')
                this.isLoading = true
                this.selectedNodes = []
                this.allNodes.forEach(mainNode => {
                    mainNode.indeterminate = false
                    mainNode.check_all = false
                })
                setTimeout(() => {
                    this.$api.system.getGroupNodes(group.uc_id).then(result => {
                        if (result.errCode) {
                            this.$message.alert('加载权限节点失败:' + result.message)
                        } else if (result == '') {
                            this.isLoading = false;
                        } else {
                            this.allNodes = result.data
                            this.resetSelectedNodes()
                        }
                    }).catch(error => {
                        this.$message.error(error)
                    }).finally(() => {
                        //this.$loading.close()
                        this.isLoading = false
                    })
                }, 500)
            },
            //保存权限节点
            submitForm() {
                this.isLoading = true
                this.$api.system.saveGroupNodes({nodes: this.selectedNodes, id: this.groupId}).then(result => {
                    if (result.errCode) {
                        this.$message.alert(result.message)
                    } else {
                        this.$message.success('保存成功')
                    }
                }).catch(error => {
                    this.$message.error(error)
                }).finally(() => {
                    this.isLoading = false
                })
            },
        },
        mounted() {
            this.getGroups()

        }
    }
</script>

<style lang="less" scoped>
    .role-title .el-button {
        margin: 5px;
    }

    .role-title .el-button.active {
        background: #FFF;
        border-color: #409EFF;
        color: #409EFF;
    }

    .el-button.el-button--default {
        position: relative;
    }

    .el-button.el-button--default .remove {
        position: absolute;
        top: -8px;
        right: -8px;
        width: 18px;
        height: 18px;
        background: #f3b1b9;
        color: #fafafa;
        border-radius: 20px;
        cursor: pointer;
    }

    .el-button.el-button--default .remove:hover {
        background: red;
        color: white;
    }

    .role-main {
        margin-top: 20px;
        padding-top: 20px;
        border-top: solid 2px #ddd;
    }

    .role-desc {
        margin-top: 20px;
        padding-top: 20px;
        /*border-top: solid 2px #ddd;*/
    }

    .role-form {
        border: #ddd solid 1px;
        margin-bottom: 20px;

        &-head {
            background-color: rgba(249, 250, 252, 1);
            height: 50px;
            line-height: 50px;
            padding: 0 20px;
            border-bottom: #ddd solid 1px;
        }

        &-body {
            .el-checkbox {
                margin: 15px 20px 15px 20px;
                box-sizing: border-box;
            }

        }
    }
</style>