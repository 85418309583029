<template>
    <div>
        <!--搜索栏-->
        <el-form :inline="true" :model="searchParams" ref="searchForm" label-width="90px" class="demo-form-inline">
            <el-form-item prop="keyword" class="ml-10">
              <div class="block">
                <el-date-picker
                    v-model="searchParams.timeValue"
                    value-format="timestamp"
                    type="datetimerange"
                    align="right"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
              </div>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" @click="getAdvertClickStatistics()" :loading="isLoading">查询</el-button>
            </el-form-item>
        </el-form>
        <div id="myChart" :style="{width: '1000px', height: '650px'}"></div>
    </div>


</template>

<script>
    // 引入基本模板
    let echarts = require('echarts/lib/echarts')
    // 引入柱状图组件
    require('echarts/lib/chart/bar')
    // 引入折现图组件
    require('echarts/lib/chart/line')
    // 引入提示框、title、legend组件
    require('echarts/lib/component/legend')
    require('echarts/lib/component/tooltip')
    require('echarts/lib/component/title')
    // 引入工具箱组件
    require('echarts/lib/component/toolbox')
    // 引入最大、最小值标注点组件
    require('echarts/lib/component/markPoint')
    // 引入最大、最小平均值组件
    require('echarts/lib/component/markLine')

    export default {
        name: "JournalAdvertClickStatistics",
        mounted() {
            this.getAdvertClickStatistics();
        },
        data() {
            return {
                isLoading: false,
                //搜索传参
                searchParams: {
                  timeValue:[]
                },
                statisticsTitle: {
                  text: '广告点击统计图',
                  subtext: '',
                },
                xAxisData:[],
                enterIntoData:[], //开屏广告
                videoData:[],//视频广告
                //统计数据
                data: [],
            }
        },
        methods: {
            //获取封面列表
            getAdvertClickStatistics() {
                this.getTitle();
                this.isLoading = true;
                let startTime = this.searchParams.timeValue[0] ? (this.searchParams.timeValue[0] / 1000): '';
                let endTime = this.searchParams.timeValue[1] ? (this.searchParams.timeValue[1] / 1000) : '';
                this.$api.journal.advert('AdvertClickStatistics', {startTime:startTime,endTime:endTime}).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.data = result.data;
                        var temp = result.data;
                        var tempAxisData = [];
                        var tempEnterIntoData = [];
                        var tempVideoData = [];
                        for (var i=0; i < temp.length; ++i){
                          tempAxisData.push(temp[i].region);
                          tempEnterIntoData.push(temp[i].user.num);
                          tempVideoData.push(temp[i].user.videoNum);
                        }
                        this.xAxisData = tempAxisData;
                        this.enterIntoData = tempEnterIntoData;
                        this.videoData = tempVideoData;
                    }
                    this.drawStatistics();
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                })
            },
            drawStatistics(){
              let myChart = echarts.init(document.getElementById('myChart'));
              var option;
              option = {
                color: ['#5470c6','#91cc75'],
                title: {
                  text: this.statisticsTitle.text,
                  subtext: this.statisticsTitle.subtext,
                },
                tooltip: {
                  trigger: 'axis'
                },
                legend: {
                  data: ['开屏', '视频']
                },
                grid: {
                  top: '15%'
                },
                toolbox: {
                  show: true,
                  feature: {
                    dataView: {show: true, readOnly: false},
                    magicType: {show: true, type: ['line', 'bar']},
                    restore: {show: true},
                    saveAsImage: {show: true}
                  }
                },
                calculable: true,
                xAxis: [
                  {
                    type: 'category',
                    data: this.xAxisData
                  }
                ],
                yAxis: [
                  {
                    type: 'value'
                  }
                ],
                series: [
                  {
                    name: '开屏',
                    type: 'bar',
                    data: this.enterIntoData,
                    markPoint: {
                      data: [
                        {type: 'max', name: '最大值'},
                        {type: 'min', name: '最小值'}
                      ]
                    },
                    markLine: {
                      data: [
                        {type: 'average', name: '平均值'}
                      ]
                    }
                  },
                  {
                    name: '视频',
                    type: 'bar',
                    data: this.videoData,
                    markPoint: {
                      data: [
                        {type: 'max', name: '最大值'},
                        {type: 'min', name: '最小值'}
                      ]
                    },
                    markLine: {
                      data: [
                        {type: 'average', name: '平均值'}
                      ]
                    }
                  }
                ]
              };
              option && myChart.setOption(option);
            },
            getTitle(){
              let todayStartTime = parseInt(new Date(new Date().toLocaleDateString()).getTime() / 1000);
              let todayEndTime = parseInt((new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1) / 1000);
              let startTime = this.searchParams.timeValue[0] ? (this.searchParams.timeValue[0] / 1000) : todayStartTime;
              let endTime = this.searchParams.timeValue[1] ? (this.searchParams.timeValue[1] / 1000) : todayEndTime;
              this.statisticsTitle.subtext = this.date('Y-m-d H:i:s', startTime) +' 至 '+ this.date('Y-m-d H:i:s', endTime) + ' 统计记录';
            },
            date(format, timestamp){
              var jsdate=((timestamp) ? new Date(timestamp*1000) : new Date());
              var pad = function(n, c){
                if((n = n + "").length < c){
                  return new Array(++c - n.length).join("0") + n;
                } else {
                  return n;
                }
              };
              var txt_weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
              var txt_ordin = {1:"st", 2:"nd", 3:"rd", 21:"st", 22:"nd", 23:"rd", 31:"st"};
              var txt_months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
              var f = {
                // Day
                d: function(){return pad(f.j(), 2)},
                D: function(){return f.l().substr(0,3)},
                j: function(){return jsdate.getDate()},
                l: function(){return txt_weekdays[f.w()]},
                N: function(){return f.w() + 1},
                S: function(){return txt_ordin[f.j()] ? txt_ordin[f.j()] : 'th'},
                w: function(){return jsdate.getDay()},
                z: function(){return (jsdate - new Date(jsdate.getFullYear() + "/1/1")) / 864e5 >> 0},

                // Week
                W: function(){
                  var a = f.z(), b = 364 + f.L() - a;
                  var nd2, nd = (new Date(jsdate.getFullYear() + "/1/1").getDay() || 7) - 1;
                  if(b <= 2 && ((jsdate.getDay() || 7) - 1) <= 2 - b){
                    return 1;
                  } else{
                    if(a <= 2 && nd >= 4 && a >= (6 - nd)){
                      nd2 = new Date(jsdate.getFullYear() - 1 + "/12/31");
                      return this.date("W", Math.round(nd2.getTime()/1000));
                    } else{
                      return (1 + (nd <= 3 ? ((a + nd) / 7) : (a - (7 - nd)) / 7) >> 0);
                    }
                  }
                },

                // Month
                F: function(){return txt_months[f.n()]},
                m: function(){return pad(f.n(), 2)},
                M: function(){return f.F().substr(0,3)},
                n: function(){return jsdate.getMonth() + 1},
                t: function(){
                  var n;
                  if( (n = jsdate.getMonth() + 1) == 2 ){
                    return 28 + f.L();
                  } else{
                    if( n & 1 && n < 8 || !(n & 1) && n > 7 ){
                      return 31;
                    } else{
                      return 30;
                    }
                  }
                },

                // Year
                L: function(){var y = f.Y();return (!(y & 3) && (y % 1e2 || !(y % 4e2))) ? 1 : 0},
                //o not supported yet
                Y: function(){return jsdate.getFullYear()},
                y: function(){return (jsdate.getFullYear() + "").slice(2)},

                // Time
                a: function(){return jsdate.getHours() > 11 ? "pm" : "am"},
                A: function(){return f.a().toUpperCase()},
                B: function(){
                  // peter paul koch:
                  var off = (jsdate.getTimezoneOffset() + 60)*60;
                  var theSeconds = (jsdate.getHours() * 3600) + (jsdate.getMinutes() * 60) + jsdate.getSeconds() + off;
                  var beat = Math.floor(theSeconds/86.4);
                  if (beat > 1000) beat -= 1000;
                  if (beat < 0) beat += 1000;
                  if ((String(beat)).length == 1) beat = "00"+beat;
                  if ((String(beat)).length == 2) beat = "0"+beat;
                  return beat;
                },
                g: function(){return jsdate.getHours() % 12 || 12},
                G: function(){return jsdate.getHours()},
                h: function(){return pad(f.g(), 2)},
                H: function(){return pad(jsdate.getHours(), 2)},
                i: function(){return pad(jsdate.getMinutes(), 2)},
                s: function(){return pad(jsdate.getSeconds(), 2)},
                //u not supported yet

                // Timezone
                //e not supported yet
                //I not supported yet
                O: function(){
                  var t = pad(Math.abs(jsdate.getTimezoneOffset()/60*100), 4);
                  if (jsdate.getTimezoneOffset() > 0) t = "-" + t; else t = "+" + t;
                  return t;
                },
                P: function(){var O = f.O();return (O.substr(0, 3) + ":" + O.substr(3, 2))},
                //T not supported yet
                //Z not supported yet

                // Full Date/Time
                c: function(){return f.Y() + "-" + f.m() + "-" + f.d() + "T" + f.h() + ":" + f.i() + ":" + f.s() + f.P()},
                //r not supported yet
                U: function(){return Math.round(jsdate.getTime()/1000)}
              };

              return format.replace(/[\\]?([a-zA-Z])/g, function(t, s){
                var ret;
                if( t!=s ){
                  // escaped
                  ret = s;
                } else if( f[s] ){
                  // a date function exists
                  ret = f[s]();
                } else{
                  // nothing special
                  ret = s;
                }
                return ret;
              });
            }

        }
    }
</script>

