<template>
  <div class="root-container">
    <div class="more-btns">
      <el-button type="primary" @click="newadd">新增</el-button>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="id" label="序号" align="center"> </el-table-column>
      <el-table-column prop="task_img" label="任务图片" align="center">
        <template slot-scope="{ row }">
          <img style="height: 150px; width: 100px" :src="row.task_img" />
        </template>
      </el-table-column>
      <el-table-column
        prop="task_title"
        label="任务名称"
        align="center"
      ></el-table-column>
      <el-table-column prop="type_title" label="任务类型" align="center">
      </el-table-column>
        <el-table-column prop="mode_title" label="任务方式" align="center">
      </el-table-column>
      <el-table-column
        prop="task_num"
        label="任务次数 "
        align="center"
      ></el-table-column>
      <el-table-column
        prop="task_desc"
        label="任务说明 "
        align="center"
      ></el-table-column>
      <el-table-column
        prop="task_param"
        label="任务额外参数"
        align="center"
      ></el-table-column>
      <el-table-column prop="task_status" label="任务状态 " align="center">
        <template slot-scope="{ row }">
          <div v-show="row.task_status == 0">关闭</div>
          <div v-show="row.task_status == 1">开启</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="task_weight"
        label="权重 "
        align="center"
      ></el-table-column>

      <el-table-column
        prop="more"
        label="操作"
        align="center"
        width="300"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" @click="editInfo(row)"
            >编辑</el-button
          >
          <el-button size="mini" type="danger" @click="del(row.id)"
            >删除</el-button
          >
          <el-button size="mini" type="danger" @click="addgoods(row.id)"
            >添加奖励</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="searchParams.page"
      :page-sizes="[10, 15, 20, 50, 100]"
      :page-size="searchParams.size"
      layout="total,sizes, prev, pager, next"
      :total="searchParams.count"
    >
    </el-pagination>

    <!-- 添加任务 -->
    <el-dialog
      :title="picTitle"
      width="800px"
      top="10vh"
      :visible.sync="dialogVisible"
    >
      <div class="flexBox">
        <div class="flexBoxTitle">任务图片:</div>
        <img
          @click="setnew(1)"
          style="width: 100px; height: 70px"
          :src="addimg"
          alt=""
        />
        <input
          id="uploadImage1"
          type="file"
          ref="fileInput1"
          accept="image/*"
          @change="getFile(1)"
          style="display: none"
        />
      </div>
      <div class="flexBox">
        <div class="flexBoxTitle">任务名称:</div>
        <input v-model="taskName" class="flexBoxInp" type="text" />
      </div>
      <div class="flexBox">
        <div class="flexBoxTitle">任务说明:</div>
        <textarea
          v-model="taskExplain"
          class="flexBoxInp"
          style="height: 100px"
          type="text"
        />
      </div>

      <div class="flexBox">
        <div class="flexBoxTitle">任务类型:</div>
        <el-radio-group v-model="taskType" @change="selectTask">
          <el-radio
            v-for="(item, index) in TaskListType"
            :key="index"
            :label="index"
            >{{ item }}</el-radio
          >
        </el-radio-group>
      </div>

      <div class="flexBox" v-show="showUrl == true">
        <div class="flexBoxTitle">任务额外参数:</div>
        <input v-model="taskParam" class="flexBoxInp" type="text" />
      </div>
      <div class="flexBox">
        <div class="flexBoxTitle">任务方式:</div>
        <el-select v-model="taskWays" placeholder="请选择">
          <el-option
            v-for="(item, index) in TaskListWay"
            :key="index"
            :label="item"
            :value="index"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flexBox">
        <div class="flexBoxTitle">任务次数:</div>
        <input v-model="taskNum" class="flexBoxInp" type="text" />
      </div>
      <div class="flexBox">
        <div class="flexBoxTitle">任务权重:</div>
        <input v-model="weight" class="flexBoxInp" type="text" />
      </div>
      <div class="flexBox">
        <div class="flexBoxTitle">任务状态:</div>
        <el-radio-group v-model="taskStatus">
          <el-radio :label="0">关闭</el-radio>
          <el-radio :label="1">开启</el-radio>
        </el-radio-group>
      </div>
      <div class="flexBox">
        <el-button type="primary" @click="sure">立即创建</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >取消</el-button
        >
      </div>
    </el-dialog>

    <!-- 添加奖励 -->
    <el-dialog
      :title="picTitle"
      width="900px"
      top="10vh"
      :visible.sync="dialogVisible1"
    >
      <el-button type="primary" @click="RewardsNumbox">新增</el-button>
      <div class="flexBox" v-for="(item, index) in RewardsNum" :key="index">
        <div class="flexBoxTitle">任务奖励{{ index + 1 }}:</div>
        <el-select
          @change="taskReward(index)"
          v-model="item.id1"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <input
          v-show="item.id1 == 2 || item.id1 == 1"
          v-model="item.num"
          class="flexBoxInp"
          style="width: 21%; margin-left: 3%"
          type="text"
        />

        <el-select
          v-show="item.id1 > 2"
          @change="taskReward1(index)"
          v-model="item.id2"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options2"
            :key="item.id"
            :label="item.sort_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          v-show="item.id1 == 3"
          @change="taskReward2"
          v-model="item.id3"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options3"
            :key="item.id"
            :label="item.sort_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button
          style="margin-left: 30px"
          type="primary"
          @click="goodsSava(index, item)"
          >保存</el-button
        >
        <el-button type="primary" @click="goodsDel(item)">删除</el-button>
      </div>
      <div class="flexBox">
        <el-button type="primary" @click="closebox">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "",
  data() {
    return {
      loading: false,
      searchParams: {
        page: 1,
        size: 10,
        count: 0,
      }, //分页
      picTitle: "", //弹窗的title
      tableData: [{ num: 1 }], //列表数据
      dialogVisible: false,
      dialogVisible1: false,
      addimg: require("@/assets/images/bg.png"),
      TaskList: [
        { label: "普通任务", value: 1 },
        { label: "每日任务", value: 2 },
      ], //任务类型
      options1: [
        {
          value: "1",
          label: "蜂蜜",
        },
        {
          value: "2",
          label: "蜜罐",
        },
        {
          value: "3",
          label: "家具",
        },
        {
          value: "4",
          label: "贴纸",
        },
      ], //奖品类别list
      options2: [], //家具和贴纸中层
      options3: [], //家具底层
      TaskListWay: [], //任务方式
      TaskListType: [], //任务方式
      showUrl: true, //显示隐藏
      taskName: "", //任务名字
      taskExplain: "", //任务说明
      taskType: "1", //任务类型
      taskParam: "", //任务的额外参数
      taskWays: "", //任务方式
      taskRewards: "", //任务奖励
      taskRewards1: "", //任务奖励
      taskRewards2: "", //任务奖励
      rewardNum: "", //奖励个数
      taskNum: "", //任务次数
      weight: "", //权重
      taskStatus: 0, //任务状态
      addType: "", //0是新增 1是编辑
      addgoodsType: "", //0是新增 1是编辑(奖励)
      infoId: "", //数据id
      RewardsNum: [], //设置奖励数量
    };
  },
  created() {
    const that = this;
    that.getTableList();
    that.getTaskWay();
    that.getTaskType();
  },
  methods: {
    RewardsNumbox() {
      console.log(this.RewardsNum);
      var obj = { id: "", id1: "", num: "", id2: "", id3: "" };
      this.RewardsNum.push(obj);
    },

    //列表数据
    getTableList() {
      let data = {
        page: this.searchParams.page,
        size: this.searchParams.size,
      };
      this.isLoading = true;
      this.$api.journal
        .taskList("List", data, "get")
        .then((result) => {
          console.log(result);
          this.tableData = result.data.list;
          this.searchParams.count = result.data.total;
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //获取任务方式
    getTaskWay() {
      this.isLoading = true;
      this.$api.journal
        .TaskWay("TaskModeList", {}, "get")
        .then((result) => {
          console.log(result);
          this.TaskListWay = result.data.list;
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //获取任务类型
    getTaskType() {
      this.isLoading = true;
      this.$api.journal
        .taskType("TaskTypeList", {}, "get")
        .then((result) => {
          console.log(result);
          this.TaskListType = result.data.list;
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //选择任务类型
    selectTask() {
      console.log(this.taskType);
      if (this.taskType == 1) {
        this.showUrl = true;
      } else {
        this.showUrl = false;
      }
    },
    //上传图片
    setnew: function (e) {
      console.log(this.$refs);
      if (e == 1) {
        this.$refs.fileInput1.click();
      }
    },
    getFile: function (e) {
      var file = "";
      if (e == 1) {
        file = document.getElementById("uploadImage1").files[0];
      }

      console.log(file); //由打印的可以看到，图片    信息就在files[0]里面
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (data) => {
        console.log(data);
      };
      let formData = new FormData(); //通过formdata上传
      formData.append("file", file);
      axios.post("/common/upload/multipart", formData).then((res) => {
        console.log(res.data.data);
        //   预览的图片
        if (e == 1) {
          this.addimg = res.data.data.object_url;
        }
      });
    },
    //任务奖励1
    taskReward(e) {
      if (this.RewardsNum[e].id1 == 1) {
        //蜂蜜
      } else if (this.RewardsNum[e].id1 == 2) {
        //蜜罐
      } else if (this.RewardsNum[e].id1 == 3) {
        //家具
        this.getFurniture(1);
      } else {
        //贴纸
        this.getFurniture(6);
      }
      this.RewardsNum[e].id2 = "";
      this.RewardsNum[e].id3 = "";
      this.RewardsNum[e].num = "";
    },
    //任务奖励2
    taskReward1(e) {
      this.getFurniture2(this.RewardsNum[e].id2);
    },
    //任务奖励3
    taskReward2() {
      console.log(this.taskRewards);
    },
    // //新增
    newadd: function () {
      this.dialogVisible = true;
      this.addType = 0;

      this.taskName = "";
      this.taskExplain = "";
      this.taskType = "1";
      this.taskParam = "";
      this.taskWays = "";
      this.taskNum = "";
      this.weight = "";
      this.addimg = require("@/assets/images/bg.png");
      this.taskStatus = 0;
    },
    // //编辑
    editInfo: function (e) {
      this.dialogVisible = true;
      this.taskName = e.task_title;
      this.taskExplain = e.task_desc;
      this.taskType = e.task_type;
      this.taskParam = e.task_param;
      this.taskWays = e.task_mode;
      this.taskNum = e.task_num;
      this.weight = e.task_weight;
      this.addimg = e.task_img;
      this.taskStatus = Number(e.task_status);
      if (e.task_type == 1) {
        this.showUrl = true;
      } else {
        this.showUrl = false;
      }
      this.addType = 1;
      this.infoId = e.id;
    },
    sure() {
      if (this.addType == 0) {
        var dataInfo = {
          img: this.addimg,
          title: this.taskName, 
          desc: this.taskExplain,
          type: this.taskType,
          task_param: this.taskParam,
          mode: this.taskWays,
          num: this.taskNum,
          weight: this.weight,
          status: this.taskStatus,
        };// eslint-disable-line no-unused-vars
      } else {
        var dataInfo = {
          id: this.infoId,
          img: this.addimg,
          title: this.taskName,
          desc: this.taskExplain,
          type: this.taskType, 
          task_param: this.taskParam,
          mode: this.taskWays,
          num: this.taskNum,
          weight: this.weight,
          status: this.taskStatus,
        };// eslint-disable-line no-unused-vars
      }
      this.isLoading = true;
      this.$api.journal
        .TaskSave("Save", dataInfo, "POST")
        .then((result) => {
          console.log(result);
          this.$message.success(result.message);
          this.getTableList();
          this.dialogVisible = false;
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //分页
    handleSizeChange(val) {
      this.searchParams.size = val;
      this.getTableList();
    },

    handleCurrentChange(val) {
      this.searchParams.page = val;
      this.getTableList();
    },
    //删除
    del(e) {
      this.$confirm("是否要删除这条id：" + e + "的数据!", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            id: e,
          };
          this.isLoading = true;
          this.$api.journal
            .TaskDel("DeleteUserTask", data, "POST")
            .then((result) => {
              console.log(result);
              this.$message.success(result.message);
              this.getTableList();
            })
            .catch((error) => {
              this.$message.error(error);
            })
            .finally(() => {
              this.isLoading = false;
            });
        })
        .catch(() => {});
    },

    //家具1
    getFurniture: function (e) {
      var that = this;
      that.isLoading = true;
      that.$api.journal
        .GetFurnitureSuit("GetFurnitureSuit", { type: e }, "get")
        .then((result) => {
          console.log(result.data);
          this.options2 = result.data.list;
        })
        .catch((error) => {
          that.$message.error(error);
        })
        .finally(() => {
          that.isLoading = false;
        });
    },
    //家具2
    getFurniture2: function (e) {
      var that = this;
      that.isLoading = true;
      that.$api.journal
        .GetFurnitureParts("GetFurnitureParts", { furniture_suit_id: e }, "get")
        .then((result) => {
          console.log(result.data);
          that.options3 = result.data.list;
        })
        .catch((error) => {
          that.$message.error(error);
        })
        .finally(() => {
          that.isLoading = false;
        });
    },
    //添加奖励
    addgoods(e) {
      this.dialogVisible1 = true;
      this.infoId = e;
      this.RewardsNum = [];
      this.isLoading = true;
      this.$api.journal
        .RewardSave("GetTaskReward", { task: this.infoId }, "get")
        .then((result) => {
          console.log(result);
          for (let i = 0; i < result.data.list.length; i++) {
            console.log("pppp--");
            if (result.data.list[i].reward_type < 3) {
              this.RewardsNum.push({
                id: result.data.list[i].id,
                id1: result.data.list[i].reward_type,
                num: result.data.list[i].reward_value,
                id2: "",
                id3: "",
              });
            } else if (result.data.list[i].reward_type == 3) {
              var arr = result.data.list[i].reward_value.split(",");
              this.RewardsNum.push({
                id: result.data.list[i].id,
                id1: result.data.list[i].reward_type,
                num: "",
                id2: arr[0],
                id3: arr[1],
              });
            } else {
              this.RewardsNum.push({
                id: result.data.list[i].id,
                id1: result.data.list[i].reward_type,
                num: "",
                id2: result.data.list[i].reward_value,
                id3: "",
              });
            }
          }

          if (result.data.list.length == 0) {
            this.addgoodsType = 0;
          } else {
            this.addgoodsType = 1;
          }
          console.log(this.RewardsNum);
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //奖励保存
    goodsSava(e, q) {
      console.log(q);
      if (q.id == "") {
        if (this.RewardsNum[e].id1 < 3) {
          var info = {
            task: this.infoId,
            type: this.RewardsNum[e].id1,
            value: this.RewardsNum[e].num, 
          }// eslint-disable-line no-unused-vars
        } else if (this.RewardsNum[e].id1 == 3) {
          var info = {
            task: this.infoId,
            type: this.RewardsNum[e].id1,
            value: this.RewardsNum[e].id2 + "," + this.RewardsNum[e].id3,
          }// eslint-disable-line no-unused-vars
        } else {
          var info = {
            task: this.infoId,
            type: this.RewardsNum[e].id1,
            value: this.RewardsNum[e].id2,
          }// eslint-disable-line no-unused-vars
        }
      } else {
        if (this.RewardsNum[e].id1 < 3) {
          var info = {
            id: q.id,
            task: this.infoId,
            type: this.RewardsNum[e].id1,
            value: this.RewardsNum[e].num,
          }// eslint-disable-line no-unused-vars
        } else if (this.RewardsNum[e].id1 == 3) {
          var info = {
            id: q.id,
            task: this.infoId,
            type: this.RewardsNum[e].id1,
            value: this.RewardsNum[e].id2 + "," + this.RewardsNum[e].id3,
          }// eslint-disable-line no-unused-vars
        } else {
          var info = {
            id: q.id,
            task: this.infoId,
            type: this.RewardsNum[e].id1,
            value: this.RewardsNum[e].id2,
          };// eslint-disable-line no-unused-vars
        }
      }
      this.isLoading = true;
      this.$api.journal
        .RewardSave("Save", info, "POST")
        .then((result) => {
          console.log(result);
          this.$message.success(result.message);
          this.addgoods(this.infoId);
          //   this.dialogVisible1 = false;
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
 
    //删除 任务奖励
    goodsDel(e) {
      this.isLoading = true;
      this.$api.journal
        .RewardSave("DeleteUserTaskReward", { id: e.id }, "POST")
        .then((result) => {
          console.log(result);
          this.$message.success(result.message); 
          this.addgoods(this.infoId);
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    closebox() {
      this.getTableList();
      this.dialogVisible1 = false;
    },
  },
};
</script>
<style>
.flexBox {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.flexBoxTitle {
  margin-right: 20px;
}
.flexBoxInp {
  border: 1px solid gray;
  border-radius: 3px;
  width: 50%;
  height: 30px;
  outline: none;
  padding-left: 10px;
}
</style>
