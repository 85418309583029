<template>
    <div>
        <!--搜索栏-->
        <el-form :inline="true" :model="searchParams" ref="searchParams" class="demo-form-inline">
            <el-row :gutter="10">
                <el-form-item label="" prop="keywords">
                    <el-input v-model="searchParams.keyword" placeholder="任务名称" @change="getList()"></el-input>
                </el-form-item>
                <el-select v-model="searchParams.status" placeholder="执行状态" @change="getList()">
                    <el-option label="全部状态" value=""></el-option>
                    <el-option label="执行成功" value="1"></el-option>
                    <el-option label="执行失败" value="0"></el-option>
                </el-select>
                <el-form-item style="margin-left: 10px">
                    <el-button icon="el-icon-search" type="primary" :loading="isLoading" @click="getList()">查询
                    </el-button>
                    <el-button icon="el-icon-delete" type="warning" :loading="isLoading" @click="clear()">清理
                    </el-button>
                </el-form-item>
            </el-row>
        </el-form>
        <el-table
                :data="dataList"
                stripe
                style="width: 100%">
            <el-table-column
                    prop="id"
                    width="80"
                    label="ID">
            </el-table-column>
            <el-table-column
                    prop="name"
                    label="名称">
            </el-table-column>
            <el-table-column
                    prop="script"
                    label="脚本">
            </el-table-column>
            <el-table-column
                    prop="data"
                    label="参数">
                <template slot-scope="scope">
                    <el-popover
                            placement="right-start"
                            title="数据信息"
                            width="600"
                            trigger="click">
                        <el-row>
                            <json-viewer :value="scope.row.data" :expand-depth=10
                                         expanded></json-viewer>
                        </el-row>
                        <el-button type="text" slot="reference" class="c-blue" icon="el-icon-document">查看</el-button>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column
                    prop="created"
                    label="创建时间">
                <template slot-scope="{row}">
                    {{row.created|HumanizedTime}}
                </template>
            </el-table-column>
            <el-table-column
                    prop="updated"
                    label="更新时间">
                <template slot-scope="{row}">
                    {{row.updated|HumanizedTime}}
                </template>
            </el-table-column>
            <el-table-column
                    prop="finished"
                    label="完成时间">
                <template slot-scope="{row}">
                    {{row.finished|HumanizedTime}}
                </template>
            </el-table-column>
            <el-table-column
                    prop="try_times"
                    label="尝试次数">
            </el-table-column>
            <el-table-column
                    prop="remark"
                    width="300"
                    label="执行结果">
                <template slot-scope="scope">
                    <el-row :gutter="10" type="flex">
                        <el-col :span="20">
                            {{scope.row.remark||'--'}}
                            <el-button v-if="scope.row.status==0" type="success" @click="execute(scope.$index)"
                                       size="small"
                                       icon="el-icon-refresh-right">重试
                            </el-button>
                        </el-col>
                    </el-row>
                </template>
            </el-table-column>
        </el-table>
        <!--翻页-->
        <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="searchParams.page"
                :page-sizes="[10, 15, 20,50,100]"
                :page-size="searchParams.size"
                layout="total,sizes, prev, pager, next"
                :total="searchParams.count">
        </el-pagination>
    </div>
</template>

<script>
    import JsonViewer from 'vue-json-viewer'

    export default {
        name: "Queue",
        components: {
            JsonViewer
        },
        mounted() {
            this.getList()
        },
        data() {
            return {
                isLoading: false,
                searchParams: {
                    keyword: '',
                    page: 1,
                    count: 1,
                    size: 10,
                    status: ''
                },
                dataList: []
            }
        },
        methods: {
            //加载列表
            getList() {
                this.isLoading = true;
                this.$api.system.queue('List', this.searchParams).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.dataList = result.data.list;
                        this.searchParams.count = result.data.total;
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).finally(() => {
                    this.isLoading = false;
                });
            },
            clear() {
                this.$message.confirm({message: '此操作将删除所有已成功完成的队列任务日志,确定清理吗?'}, () => {
                    this.$loading.show('清理中')
                    this.$api.system.queue('Clear').then(result => {
                        if (result.errCode) {
                            this.$message.alert(result.message)
                        } else {
                            this.$message.success('清理完成,' + result.data + '条数据已删除')
                            this.getList()
                        }
                    }).catch(error => {
                        this.$message.error(error)
                    }).finally(() => {
                        this.$loading.close()
                    })
                })
            },
            execute(index) {
                let queue = this.dataList[index]
                this.$message.confirm({message: '确定手动执行此队列任务吗?'}, () => {
                    this.$loading.show('执行中')
                    this.$api.system.queue('Execute', {id: queue.id}, 'POST').then(result => {
                        if (result.errCode) {
                            this.$message.alert(result.message)
                        } else {
                            this.$message.success('执行成功')
                            this.getList()
                        }
                    }).catch(error => {
                        this.$message.error(error)
                    }).finally(() => {
                        this.$loading.close()
                    })
                })
            },
            save() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$api.system.queue('Save', this.form, 'post').then(result => {
                            if (result.errCode) {
                                this.$message.alert(result.message)
                            } else {
                                this.dialogFormVisible = false;
                                this.$message.success('保存成功');
                                this.getList()
                            }
                        }).catch(error => {
                            this.$message.error(error)
                        }).finally(() => {
                            this.isLoading = false
                        })
                    } else {
                        return false;
                    }
                });
            },
            // 点击分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getList();

            },
        }
    }
</script>