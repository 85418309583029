<template>
    <!--基本信息-->
    <div>
        <el-dialog title="计算器" :visible.sync="dialogFormVisible" :close-on-click-modal="false" @close="closeDialog"
                   width="40%">
            <el-form class="follow-form" ref="form" :rules="formRules" :model="order" label-width="110px">
                <el-form-item label="商业险：" prop="commercial_amount" required="">
                    <el-row :gutter="10" type="flex">
                        <el-col :span="7">
                            <el-input type="text" placeholder="商业险金额" v-model="order.commercial_amount"
                                      @change="compute">
                                <template slot="prepend">金额</template>
                            </el-input>
                        </el-col>
                        <el-col :span="7">
                            <el-input type="number" placeholder="返点比例" max="1" step="0.01"
                                      v-model="order.commercial_amount_discount" @change="compute">
                                <template slot="prepend">返点</template>
                            </el-input>
                        </el-col>
                        <el-checkbox v-model="taxesRateStatus.commercial" @change="setTaxesRate">净保费
                        </el-checkbox>
                        <el-col :span="7" v-show="taxesRateStatus.commercial">
                            <el-input type="number" placeholder="净保费率" min="1" max="1.06" step="0.01"
                                      v-model="order.commercial_taxes_rate" @change="compute">
                                <template slot="prepend">净保费率</template>
                            </el-input>
                        </el-col>

                    </el-row>
                </el-form-item>

                <el-form-item label="交强险：" prop="compulsory_amount" required="">
                    <el-row :gutter="10" type="flex">
                        <el-col :span="7">
                            <el-input type="text" placeholder="交强险金额" v-model="order.compulsory_amount"
                                      @change="compute">
                                <template slot="prepend">金额</template>
                            </el-input>
                        </el-col>
                        <el-col :span="7">
                            <el-input type="number" placeholder="返点比例" max="1" step="0.01"
                                      v-model="order.compulsory_amount_discount" @change="compute">
                                <template slot="prepend">返点</template>
                            </el-input>
                        </el-col>
                        <el-checkbox v-model="taxesRateStatus.compulsory" @change="setTaxesRate">净保费
                        </el-checkbox>
                        <el-col :span="7" v-show="taxesRateStatus.compulsory">
                            <el-input type="number" placeholder="净保费率" min="1" max="1.06" step="0.01"
                                      v-model="order.compulsory_taxes_rate" @change="compute">
                                <template slot="prepend">净保费率</template>
                            </el-input>
                        </el-col>

                    </el-row>

                </el-form-item>

                <el-form-item label="意外险：" prop="accident_amount" required="">
                    <el-row :gutter="10" type="flex">
                        <el-col :span="7">
                            <el-input type="text" placeholder="意外险金额" v-model="order.accident_amount"
                                      @change="compute">
                                <template slot="prepend">金额</template>
                            </el-input>
                        </el-col>
                        <el-col :span="7">
                            <el-input type="number" placeholder="返点比例" max="1" step="0.01"
                                      v-model="order.accident_amount_discount" @change="compute">
                                <template slot="prepend">返点</template>
                            </el-input>
                        </el-col>
                        <el-checkbox v-model="taxesRateStatus.accident" @change="setTaxesRate">净保费</el-checkbox>
                        <el-col :span="7" v-show="taxesRateStatus.accident">
                            <el-input type="number" placeholder="净保费率" min="1" max="1.06" step="0.01"
                                      v-model="order.accident_taxes_rate" @change="compute">
                                <template slot="prepend">净保费率</template>
                            </el-input>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="车船税：" prop="taxes_amount" required="">
                    <el-col :span="12">
                        <el-input type="text" placeholder="车船税金额" v-model="order.taxes_amount"
                                  @change="compute"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="累计返现：">
                    <el-col :span="12">
                        <el-input type="text" placeholder="累计返点" v-model="order.total_profit"
                                  disabled="disabled"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="客户返现：" prop="discount_amount" required="">
                    <el-col :span="12">
                        <el-input type="text" placeholder="给客户的返现金额" v-model="order.discount_amount"
                                  @change="compute"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="剩余提成：">
                    <el-col :span="12">
                        <el-input type="text" placeholder="剩余提成" v-model="order.manager_profit"
                                  disabled="disabled"></el-input>
                    </el-col>
                </el-form-item>

                <el-form-item label="累计保费：">
                    <el-col :span="12">
                        <el-input type="text" placeholder="累计保费" v-model="order.total_amount"
                                  disabled="disabled"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="实际保费：">
                    <el-col :span="12">
                        <el-input type="text" placeholder="实际保费" v-model="order.finial_amount"
                                  disabled="disabled"></el-input>
                    </el-col>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="onSubmit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "Calculator",
        props: { //接受父组件传递来的数据
            customer: {
                type: Object
            }
        },
        mounted() {
        },
        data() {
            return {
                dialogFormVisible: true,
                formRules: {
                    commercial_amount: [
                        {required: true, message: '请填写商业险金额', trigger: 'submit'}
                    ],
                    commercial_amount_discount: [
                        {required: true, message: '请填写商业险折扣', trigger: 'submit'}
                    ],
                    compulsory_amount: [
                        {required: true, message: '请填写交强险金额', trigger: 'submit'}
                    ],
                    compulsory_amount_discount: [
                        {required: true, message: '请填写交强险折扣', trigger: 'submit'}
                    ],
                    accident_amount: [
                        {required: true, message: '请填写意外险金额', trigger: 'submit'}
                    ],
                    accident_amount_discount: [
                        {required: true, message: '请填写意外险折扣', trigger: 'submit'}
                    ],
                    taxes_amount: [
                        {required: true, message: '请填写车船税', trigger: 'submit'}
                    ],
                    discount_amount: [
                        {required: true, message: '请填写返现金额', trigger: 'submit'}
                    ],
                },
                order: {
                    commercial_amount: "",
                    commercial_amount_discount: 0,
                    commercial_taxes_rate: 1,
                    compulsory_amount: 0,
                    compulsory_amount_discount: 0,
                    compulsory_taxes_rate: 1,
                    accident_amount: 0,
                    accident_amount_discount: 0,
                    accident_taxes_rate: 1,
                    taxes_amount: 0,
                    discount_amount: 0,

                    total_profit: 0,
                    manager_profit: 0,
                    total_amount: 0,
                    finial_amount: 0
                },
                taxesRateStatus: {
                    commercial: false,
                    compulsory: false,
                    accident: false
                },
                commercialProfit: 0,
                compulsoryProfit: 0,
                accidentProfit: 0

            }
        },
        methods: {
            //设置净保费率
            setTaxesRate() {
                this.taxesRateStatus.commercial ? this.order.commercial_taxes_rate = 1.06 : this.order.commercial_taxes_rate = 1
                this.taxesRateStatus.compulsory ? this.order.compulsory_taxes_rate = 1.06 : this.order.compulsory_taxes_rate = 1
                this.taxesRateStatus.accident ? this.order.accident_taxes_rate = 1.06 : this.order.accident_taxes_rate = 1

                this.compute()
            },
            compute() {
                //商业险
                this.commercialProfit = parseFloat(this.order.commercial_amount) * parseFloat(this.order.commercial_amount_discount) / parseFloat(this.order.commercial_taxes_rate)
                //交强险
                this.compulsoryProfit = parseFloat(this.order.compulsory_amount) * parseFloat(this.order.compulsory_amount_discount) / parseFloat(this.order.compulsory_taxes_rate)
                //意外险
                this.accidentProfit = parseFloat(this.order.accident_amount) * parseFloat(this.order.accident_amount_discount) / parseFloat(this.order.accident_taxes_rate)
                this.order.total_profit = (this.commercialProfit + this.compulsoryProfit + this.accidentProfit).toFixed(2);//累计优惠
                this.order.manager_profit = (this.order.total_profit - parseFloat(this.order.discount_amount)).toFixed(2);//员工提成
                this.order.total_amount = (parseFloat(this.order.commercial_amount) + parseFloat(this.order.compulsory_amount) + parseFloat(this.order.accident_amount) + parseFloat(this.order.taxes_amount)).toFixed(2)
                this.order.finial_amount = (parseFloat(this.order.total_amount) - parseFloat(this.order.discount_amount)).toFixed(2)
            },

            onSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let content = '<p>累计保费:' + this.order.total_amount + '</p><p>商业险优惠:' + this.commercialProfit + '</p><p>交强险优惠:' + this.compulsoryProfit + '</p><p>意外险优惠:' + this.accidentProfit + '</p><p>累计优惠:' + this.order.total_profit + '</p><p>实际保费:' + this.order.finial_amount + '</p>'
                        this.$message.alert({title: '计算结果', content: content})
                    } else {
                        return false;
                    }
                });
            },
            closeDialog() {
                this.dialogFormVisible = false;
                this.$emit('closeTransferCalculator', this.dialogFormVisible)
            }
        }
    }
</script>

<style scoped>

</style>