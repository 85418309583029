<template>
    <div>
		<div class="text-right">
			<el-radio-group v-model="radio" @change="dateChange">
			    <el-radio :label="1">今日</el-radio>
			    <el-radio :label="2">本周</el-radio>
			    <el-radio :label="3">本月</el-radio>
			</el-radio-group>
			<el-date-picker class="ml-30"
				v-model="timedate"
				type="daterange"
				range-separator="至"
				start-placeholder="开始日期"
				end-placeholder="结束日期"
				@change="timeChange"
				>
			</el-date-picker>
		</div>
		
		
		<el-row :gutter="20" class="today-data c-999 fs-14">
			<el-col :span="4" align="middle">
				<div>累计用户数</div>
				<div class="mt-20 fs-20 c-333">{{firstInfo.all_user || 0}}</div>
			</el-col>
			<el-col :span="4" align="middle">
				<div>今日新增用户数</div>
				<div class="mt-20 fs-20 c-333">{{firstInfo.today_user || 0}}</div>
<!--				<div class="mt-20"><span class="c-success mr-5"><i class="el-icon-caret-top"></i>26%</span>同比昨日</div>-->
			</el-col>
			<el-col :span="4" align="middle">
		  		<div>今日活跃用户数</div>
		  		<div class="mt-20 fs-20 c-333">{{firstInfo.activity_user || 0}}</div>
<!--		  		<div class="mt-20"><span class="c-warning mr-5"><i class="el-icon-caret-bottom"></i>10%</span>同比昨日</div>-->
			</el-col>
			<el-col :span="4" align="middle">
		  		<div>累计发布人数</div>
		  		<div class="mt-20 fs-20 c-333">{{firstInfo.all_publish_user || 0}}</div>
<!--		  		<div class="mt-20"><span class="c-success mr-5"><i class="el-icon-caret-top"></i>26%</span>同比昨日</div>-->
			</el-col>
			<el-col :span="4" align="middle">
		  		<div>今日发布人数</div>
		  		<div class="mt-20 fs-20 c-333">{{firstInfo.today_publish_user || 0}}</div>
<!--		  		<div class="mt-20"><span class="c-success mr-5"><i class="el-icon-caret-top"></i>26%</span>同比昨日</div>-->
			</el-col>
			<el-col :span="4" align="middle">
		  		<div>今日转发人数</div>
		  		<div class="mt-20 fs-20 c-333">{{firstInfo.today_share_user || 0}}</div>
<!--		  		<div class="mt-20"><span class="c-success mr-5"><i class="el-icon-caret-top"></i>26%</span>同比昨日</div>-->
			</el-col>
		</el-row>
		
		<el-row :gutter="20" class="today-data c-999 fs-14">
			<el-col :span="4" align="middle">
				<div>累计平台收益</div>
				<div class="mt-20 fs-20 c-333">&yen;{{firstInfo.all_platform_income || 0}}</div>
			</el-col>
			<el-col :span="4" align="middle">
				<div>今日平台收益</div>
				<div class="mt-20 fs-20 c-333">{{firstInfo.today_platform_income || 0}}</div>
<!--				<div class="mt-20"><span class="c-success mr-5"><i class="el-icon-caret-top"></i>26%</span>同比昨日</div>-->
			</el-col>
			<el-col :span="4" align="middle">
		  		<div>今日活动访问数</div>
		  		<div class="mt-20 fs-20 c-333">{{firstInfo.today_visit_number || 0}}</div>
<!--		  		<div class="mt-20"><span class="c-warning mr-5"><i class="el-icon-caret-bottom"></i>10%</span>同比昨日</div>-->
			</el-col>
			<el-col :span="4" align="middle">
		  		<div>累计发布活动数</div>
		  		<div class="mt-20 fs-20 c-333">{{firstInfo.all_publish_number || 0}}</div>
<!--		  		<div class="mt-20"><span class="c-success mr-5"><i class="el-icon-caret-top"></i>26%</span>同比昨日</div>-->
			</el-col>
			<el-col :span="4" align="middle">
		  		<div>今日发布活动数</div>
		  		<div class="mt-20 fs-20 c-333">{{firstInfo.today_publish_number || 0}}</div>
<!--		  		<div class="mt-20"><span class="c-success mr-5"><i class="el-icon-caret-top"></i>26%</span>同比昨日</div>-->
			</el-col>
			<el-col :span="4" align="middle">
		  		<div>今日转发次数</div>
		  		<div class="mt-20 fs-20 c-333">{{firstInfo.today_share_number || 0}}</div>
<!--		  		<div class="mt-20"><span class="c-success mr-5"><i class="el-icon-caret-top"></i>26%</span>同比昨日</div>-->
			</el-col>
		</el-row>

		<!--
		<el-row :gutter="20" class="mt-30">
			<el-col :span="6" :xs="24" :sm="24" :md="12" :lg="6">
				<div>发布审核通过率</div>
				<div class="text-center passRote">
					<el-progress type="circle" :percentage="80" :width="200" :stroke-width="20" stroke-linecap="butt"></el-progress>
				</div>
			</el-col>
			<el-col :span="8" :xs="24" :sm="24" :md="12" :lg="8">
				<div class="typeChart" id="typeChart"></div>
			</el-col>
			<el-col :span="10" :xs="24" :sm="24" :md="24" :lg="10">
				<div>
					<span>转发Top5</span>
					<span class="fs-14 fr c-blue">更多</span>
				</div>
				<el-radio-group v-model="tabPosition" size="small" class="mt-20">
				    <el-radio-button label="commodity">商品</el-radio-button>
				    <el-radio-button label="coupon">发券</el-radio-button>
				    <el-radio-button label="task">任务</el-radio-button>
				</el-radio-group>
				<el-table
				    :data="tableData"
				    style="width: 100%">
					<el-table-column
					      type="index"
						   label="排名"
					      width="50">
					    </el-table-column>
				    <el-table-column
				        prop="title"
				        label="标题">
				    </el-table-column>
				    <el-table-column
				        label="占比">
						<template slot-scope="scope">
							<el-progress :percentage="60"></el-progress>
						</template>	 
				    </el-table-column>
				    <el-table-column
				        prop="share"
				        label="转发数"
						width="80"
						>
				    </el-table-column>
				</el-table>
			</el-col>
		</el-row>
		-->
		
		<!--商品-->
		<div class="mt-50">
			<h3>商品</h3>
			<div class="count-con">
				<div class="item">
					<div class="mt">累计发布数</div>
					<div class="mc">{{commodityInfo.all_commodity_publish || 0}}</div>
				</div>
				<div class="item">
					<div class="mt">今日发布数</div>
					<div class="mc">{{commodityInfo.today_commodity_publish || 0}}</div>
				</div>
				<div class="item">
					<div class="mt">累计订单数</div>
					<div class="mc">{{commodityInfo.all_commodity_order || 0}}</div>
				</div>
				<div class="item">
					<div class="mt">今日订单数</div>
					<div class="mc">{{commodityInfo.today_commodity_order || 0}}</div>
				</div>
				<div class="item">
					<div class="mt">累计销售额</div>
					<div class="mc">{{commodityInfo.all_commodity_sales || 0}}</div>
				</div>
				<div class="item">
					<div class="mt">今日销售额</div>
					<div class="mc">{{commodityInfo.today_commodity_sales || 0}}</div>
				</div>
				<div class="item">
					<div class="mt">商品累计平台收益</div>
					<div class="mc">{{commodityInfo.all_commodity_income || 0}}</div>
				</div>
				<div class="item">
					<div class="mt">商品今日平台收益</div>
					<div class="mc">{{commodityInfo.today_commodity_income || 0}}</div>
				</div>
				<div class="item">
					<div class="mt">累计转发数</div>
					<div class="mc">{{commodityInfo.all_commodity_share || 0}}</div>
				</div>
				<div class="item">
					<div class="mt">今日转发数</div>
					<div class="mc">{{commodityInfo.today_commodity_share || 0}}</div>
				</div>
			</div>
		</div>
		
		<!--发券-->
		<!--
		<div class="mt-50">
			<h3>发券</h3>
			<div class="count-con">
				<div class="item">
					<div class="mt">累计发布数</div>
					<div class="mc">123456</div>
				</div>
				<div class="item">
					<div class="mt">今日发布数</div>
					<div class="mc">123456</div>
				</div>
				<div class="item">
					<div class="mt">累计领取数</div>
					<div class="mc">123456</div>
				</div>
				<div class="item">
					<div class="mt">今日领取数</div>
					<div class="mc">123456</div>
				</div>
				<div class="item">
					<div class="mt">累计核销数</div>
					<div class="mc">123456</div>
				</div>
				<div class="item">
					<div class="mt">今日核销数</div>
					<div class="mc">123456</div>
				</div>
				<div class="item">
					<div class="mt">发券累计平台收益</div>
					<div class="mc">123456</div>
				</div>
				<div class="item">
					<div class="mt">发券今日平台收益</div>
					<div class="mc">123456</div>
				</div>
				<div class="item">
					<div class="mt">累计转发数</div>
					<div class="mc">123456</div>
				</div>
				<div class="item">
					<div class="mt">今日转发数</div>
					<div class="mc">123456</div>
				</div>
			</div>
		</div>
		-->
		<!--推广-->
		<div class="mt-50">
			<h3>推广</h3>
			<div class="count-con">
				<div class="item">
					<div class="mt">推广累计平台收益</div>
					<div class="mc">{{extendInfo.all_extend_income || 0}}</div>
				</div>
				<div class="item">
					<div class="mt">推广今日平台收益</div>
					<div class="mc">{{extendInfo.today_extend_income || 0}}</div>
				</div>
				<div class="item">
					<div class="mt">累计发布数</div>
					<div class="mc">{{extendInfo.all_extend_publish || 0}}</div>
				</div>
				<div class="item">
					<div class="mt">今日发布数</div>
					<div class="mc">{{extendInfo.today_extend_publish || 0}}</div>
				</div>
				<div class="item">
					<div class="mt">累计转发数</div>
					<div class="mc">{{extendInfo.all_extend_share || 0}}</div>
				</div>
				<div class="item">
					<div class="mt">今日转发数</div>
					<div class="mc">{{extendInfo.today_extend_share || 0}}</div>
				</div>
			</div>
		</div>
		
		<!--悬赏-->
		<div class="mt-50">
			<h3>悬赏</h3>
			<div class="count-con">
				<div class="item">
					<div class="mt">悬赏累计平台收益</div>
					<div class="mc">{{rewardInfo.all_reward_income || 0}}</div>
				</div>
				<div class="item">
					<div class="mt">悬赏今日平台收益</div>
					<div class="mc">{{rewardInfo.today_reward_income || 0}}</div>
				</div>
				<div class="item">
					<div class="mt">累计发布数</div>
					<div class="mc">{{rewardInfo.all_reward_publish || 0}}</div>
				</div>
				<div class="item">
					<div class="mt">今日发布数</div>
					<div class="mc">{{rewardInfo.today_reward_publish || 0}}</div>
				</div>
				<div class="item">
					<div class="mt">累计转发数</div>
					<div class="mc">{{rewardInfo.all_reward_share || 0}}</div>
				</div>
				<div class="item">
					<div class="mt">今日转发数</div>
					<div class="mc">{{rewardInfo.today_reward_share || 0}}</div>
				</div>

				<div class="item">
					<div class="mt">累计线索量</div>
					<div class="mc">{{rewardInfo.all_commit_clue || 0}}</div>
				</div>

				<div class="item">
					<div class="mt">今日线索数</div>
					<div class="mc">{{rewardInfo.today_commit_clue || 0}}</div>
				</div>

				<div class="item">
					<div class="mt">累计采纳数</div>
					<div class="mc">{{rewardInfo.all_adopt_clue || 0}}</div>
				</div>

				<div class="item">
					<div class="mt">今日采纳数</div>
					<div class="mc">{{rewardInfo.today_adopt_clue || 0}}</div>
				</div>

<!--				<div class="item">
					<div class="mt">采纳/线索比</div>
					<div class="mc">123456</div>
				</div>-->
			</div>
		</div>
		
		<!--寻人-->
		<!--
		<div class="mt-50">
			<h3>寻人</h3>
			<div class="count-con">
				<div class="item">
					<div class="mt">累计发布数</div>
					<div class="mc">123456</div>
				</div>
				<div class="item">
					<div class="mt">今日发布数</div>
					<div class="mc">123456</div>
				</div>
				<div class="item">
					<div class="mt">累计转发数</div>
					<div class="mc">123456</div>
				</div>
				<div class="item">
					<div class="mt">今日转发数</div>
					<div class="mc">123456</div>
				</div>
				<div class="item">
					<div class="mt">累计线索量</div>
					<div class="mc">123456</div>
				</div>
				<div class="item">
					<div class="mt">累计采纳数</div>
					<div class="mc">123456</div>
				</div>
			</div>
		</div>
		-->
		
    </div>
</template>

<script>
    import echarts from 'echarts'
    import moment from 'moment'

    export default {
        watch: {},
        filters: {},
        data() {
            return{
				 radio: 2,
				 timedate:'',
				 tabPosition:'commodity',
				 tableData: [{
					title: '萨达阿斯蒂芬萨达',
					percent: '55%',
					share: '10W+'
				}, {
					title: '悬赏找服务员',
					percent: '30%',
					share: '888'
				}, {
					title: '撒打发斯蒂芬',
					percent: '10%',
					share: '752'
				}, {
					title: '撒打发斯蒂芬',
					percent: '5%',
					share: '20'
				},
				{
					title: '撒打发斯蒂芬',
					percent: '5%',
					share: '20'
				}],
				firstInfo:{},
				commodityInfo:{},
				extendInfo:{},
				rewardInfo:{}
			}
        },
        mounted() {
			// this.passRateChart();
			//this.typeChart()
			this.statistic(this.radio)
        },
        methods: {
			timeChange(e){
				if(e){
					let d1 = new Date(e[0])
					let d2 = new Date(e[1])
					let date1 = d1.getFullYear() + '-' + (d1.getMonth()+1) + '-' + d1.getDate()
					let date2 = d2.getFullYear() + '-' + (d2.getMonth()+1) + '-' + d2.getDate()
					console.log(date1)
					console.log(date2)
					this.radio = 4;
					this.statistic(this.radio,date1,date2);
				}
			},
			dateChange(){
				this.statistic(this.radio);
			},

			statistic(radio,start,end){
				//前两行需要统计的数据
				this.$api.zx.statistics('First', {type:radio,start:start,end:end}).then(result => {
					if (result.errCode) {
						this.$message.error(result.message);
					} else {
						this.firstInfo = result.data;
						console.log(this.firstInfo);
					}
				}).catch(error => {
					this.$message.error(error);
				}).then(() => {
					this.isLoading = false;
				});

				//商品相关需要统计的数
				this.$api.zx.statistics('Commodity', {type:this.radio,start:start,end:end}).then(result => {
					if (result.errCode) {
						this.$message.error(result.message);
					} else {
						this.commodityInfo = result.data;
						console.log(this.commodityInfo);
					}
				}).catch(error => {
					this.$message.error(error);
				}).then(() => {
					this.isLoading = false;
				});

				//推广相关统计
				this.$api.zx.statistics('Extend', {type:this.radio,start:start,end:end}).then(result => {
					if (result.errCode) {
						this.$message.error(result.message);
					} else {
						this.extendInfo = result.data;
						console.log(this.extendInfo);
					}
				}).catch(error => {
					this.$message.error(error);
				}).then(() => {
					this.isLoading = false;
				});

				//悬赏相关统计
				this.$api.zx.statistics('Reward', {type:this.radio,start:start,end:end}).then(result => {
					if (result.errCode) {
						this.$message.error(result.message);
					} else {
						this.rewardInfo = result.data;
						console.log(this.rewardInfo);
					}
				}).catch(error => {
					this.$message.error(error);
				}).then(() => {
					this.isLoading = false;
				});

			},

			typeChart(){
				let myChart = echarts.init(document.getElementById('typeChart'));
				let option = {
				    title: {
				        text: '发布类型占比',
				        subtext: '',
				        left: 'center',
						top: '20'
				    },
				    tooltip: {
				        trigger: 'item',
				        formatter: '{a} <br/>{b} : {c} ({d}%)'
				    },
				    series: [
				        {
				            name: '访问来源',
				            type: 'pie',
				            radius: '55%',
				            center: ['50%', '50%'],
				            data: [
				                {value: 52, name: '商品'},
				                {value: 3, name: '发券'},
				                {value: 10, name: '推广'},
				                {value: 5, name: '悬赏'},
				                {value: 2, name: '寻人'}
				            ],
				            emphasis: {
				                itemStyle: {
				                    shadowBlur: 10,
				                    shadowOffsetX: 0,
				                    shadowColor: 'rgba(0, 0, 0, 0.5)'
				                }
				            }
				        }
				    ]
				};
				myChart.setOption(option);
			}
        }
    }
</script>
<style scoped lang="scss">
	.ml-30{
		margin-left: 30px;
	}
	.text-right{
		text-align: right;
	}
	.text-center{
		text-align: center;
	}
	.c-success{
		color: #67C23A;
	}
	.c-warning{
		color: #E6A23C;
	}
	.today-data{
		border: #DCDFE6 solid 1px;
		padding: 30px 0;
		margin: 30px 0 !important;
	}
	.typeChart{
		width: 100%;
		height: 400px;
	}
	.passRote{
		margin-top: 70px;
	}
	.count-con{
		display: flex;
		align-items: center;
		margin-top: 20px;
		text-align: center;
		flex-wrap: wrap;
		border: #DCDFE6 solid 1px;
		padding: 10px 0;
	}
	.count-con .item{
		width: 180px;
		margin: 10px 0;
	}
	.count-con .item .mt{
		font-size: 14px;
		color: #909399;
	}
	.count-con .item .mc{
		margin-top: 10px;
		color: #333;
		font-weight: 700;
		font-size: 18px;
	}
	.count-con .blue .mt,.count-con .blue .mc{
		color: #409EFF;
	}
</style>
