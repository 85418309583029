<template>
    <div class="">
        <el-form :model="turntableForm" label-width="100px">

            <!--奖励one-->
            <el-row :gutter="30" class="turntable" v-for="(flag,index) in turntableForm">

                <el-form-item label="转盘奖励">{{flag.id}}
                    <!--奖励类别-->
                    <el-select v-model="flag.turntable_type" placeholder="奖励类别" class="from_interval">
                        <el-option
                                v-for="item in baseSort"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                    <!--家具类别-->
                    <el-select v-model="flag.furniture_sort_id" placeholder="家具套装" class="from_interval" v-if="flag.turntable_type == 3" @change="getFurnitureList(flag,flag.furniture_sort_id,index)">
                        <el-option label="家具类别" value= 0 ></el-option>
                        <el-option
                                v-for="furniture_sort in furnitureSort"
                                :key="furniture_sort.id"
                                :label="furniture_sort.sort_name"
                                :value="furniture_sort.id">
                        </el-option>
                    </el-select>
                    <!--家具部件-->
                    <el-select v-model="flag.furniture_id" placeholder="家具部件" class="from_interval"  v-if="flag.furniture_sort_id != 0 && flag.turntable_type == 3">
                        <el-option label="家具部件" value= 0 ></el-option>
                        <el-option
                                v-for="furniture in flag.furniture_info"
                                :key="furniture.id"
                                :label="furniture.name"
                                :value="furniture.id">
                        </el-option>
                    </el-select>

                    <!--家具蜜罐-->
                    <!--
                    <el-input v-model.number="flag.pot_number" class="text_input from_interval" placeholder="" v-if="flag.furniture_sort_id != 0 && flag.turntable_type == 3">
                        <template slot="prepend">等额蜜罐</template>
                    </el-input>
                    -->
                    <!--贴纸套件-->
                    <el-select v-model="flag.paster_sort_id" placeholder="贴纸套件" class="from_interval" v-if="flag.turntable_type == 4">
                        <el-option label="贴纸套件" value= 0 ></el-option>
                        <el-option
                                v-for="paster in pasterList"
                                :key="paster.id"
                                :label="paster.sort_name"
                                :value="paster.id">
                        </el-option>
                    </el-select>

                    <!--贴纸等额蜂蜜-->
                    <!--
                    <el-input v-model.number="flag.number" class="text_input from_interval" placeholder="" v-if="flag.turntable_type == 4">
                        <template slot="prepend">等额蜂蜜</template>
                    </el-input>
                     -->
                    <!--蜜罐or蜂蜜对应数量-->
                    <el-input v-model.number="flag.number" class="text_input from_interval" placeholder="数额" v-if="flag.turntable_type<3 && flag.turntable_type>0">
                        <template slot="prepend">数量</template>
                    </el-input>
                    <!--中奖概率-->
                    <el-input v-model.number="flag.probability" class="text_input from_interval" placeholder="1~100的整数">
                        <template slot="prepend">概率</template>
                    </el-input>
                </el-form-item>

            </el-row>

            <el-form-item>
                <el-button type="primary" @click="TurntableSave()" :loading="isLoading">保存配置</el-button>
            </el-form-item>

        </el-form>

</div>
</template>

<script>
//import moment from 'moment';

export default {
name: 'Turntable',
mounted() {
    this.getBaseSort();
    this.getFurnitureSort();
    this.getPasterList();
    this.getTurntableList();
},
components: {},
data() {
return {
    isLoading: false,
    turntableForm:{
        one:{
            id : '',
            name : '',
            cover : '',
            turntable_type : '0',
            furniture_sort_id: '0',
            furniture_id: '0',
            paster_sort_id: '0',
            number: '',
            honey_number:'',
            pot_number:'',
            probability:'0',
            furniture_info:[]
        },
        two:{
            id : '',
            name : '',
            cover : '',
            turntable_type : '0',
            furniture_sort_id: '0',
            furniture_id: '0',
            paster_sort_id: '0',
            number: '',
            honey_number:'',
            pot_number:'',
            probability:'0',
            furniture_info:[]
        },
        three:{
            id : '',
            name : '',
            cover : '',
            turntable_type : '0',
            furniture_sort_id: '0',
            furniture_id: '0',
            paster_sort_id: '0',
            number: '',
            honey_number:'',
            pot_number:'',
            probability:'0',
            furniture_info:[]
        },
        four:{
            id : '',
            name : '',
            cover : '',
            turntable_type : '0',
            furniture_sort_id: '0',
            furniture_id: '0',
            paster_sort_id: '0',
            number: '',
            honey_number:'',
            pot_number:'',
            probability:'0',
            furniture_info:[]
        },
        five:{
            id : '',
            name : '',
            cover : '',
            turntable_type : '0',
            furniture_sort_id: '0',
            furniture_id: '0',
            paster_sort_id: '0',
            number: '',
            honey_number:'',
            pot_number:'',
            probability:'0',
            furniture_info:[]
        },
        six:{
            id : '',
            name : '',
            cover : '',
            turntable_type : '0',
            furniture_sort_id: '0',
            furniture_id: '0',
            paster_sort_id: '0',
            number: '',
            honey_number:'',
            pot_number:'',
            probability:'0',
            furniture_info:[]
        },
        seven:{
            id : '',
            name : '',
            cover : '',
            turntable_type : '0',
            furniture_sort_id: '0',
            furniture_id: '0',
            paster_sort_id: '0',
            number: '',
            honey_number:'',
            pot_number:'',
            probability:'0',
            furniture_info:[]
        },
        eight:{
            id : '',
            name : '',
            cover : '',
            turntable_type : '0',
            furniture_sort_id: '0',
            furniture_id: '0',
            paster_sort_id: '0',
            number: '',
            honey_number:'',
            pot_number:'',
            probability:'0',
            furniture_info:[]
        },
    },
    //奖励基础类别
    baseSort:[],
    //家具类别
    furnitureSort:[],
    //可能的贴纸列表
    pasterList:[],
};
},
methods: {
    //获取模板列表
    getTurntableList() {
        this.isLoading = true;
        this.$api.journal.turntable('Detail').then(result => {
            if (result.errCode) {
                this.$message.error(result.message);
            } else {
                this.turntableForm.one = result.data[0];
                this.turntableForm.two = result.data[1];
                this.turntableForm.three = result.data[2];
                this.turntableForm.four = result.data[3];
                this.turntableForm.five = result.data[4];
                this.turntableForm.six = result.data[5];
                this.turntableForm.seven = result.data[6];
                this.turntableForm.eight = result.data[7];
            }
        }).catch(error => {
            this.$message.error(error);
        }).then(() => {
            this.isLoading = false;
        })
    },

    //获取基础类别
    getBaseSort() {
        this.isLoading = true;
        this.$api.journal.turntable('BaseSort').then(result => {
            if (result.errCode) {
                this.$message.error(result.message);
            } else {
                this.baseSort = result.data;
            }
        }).catch(error => {
            this.$message.error(error);
        }).then(() => {
            this.isLoading = false;
        })
    },

    //获取转盘家具类别
    getFurnitureSort() {
        this.isLoading = true;
        this.$api.journal.turntable('FurnitureSort').then(result => {
            if (result.errCode) {
                this.$message.error(result.message);
            } else {
                this.furnitureSort = result.data;
            }
        }).catch(error => {
            this.$message.error(error);
        }).then(() => {
            this.isLoading = false;
        })
    },

    //获取转盘家具
    //如果这里是传对象，估计可以不适用scene参数
    getFurnitureList(obj,sort_id,scene) {
        /*
        console.log(obj);
        return;
        */
        if(sort_id == 0){
            return;
        }
        this.isLoading = true;
        this.$api.journal.turntable('GetFurniture',{ id :sort_id } ).then(result => {
            if (result.errCode) {
                this.$message.error(result.message);
            } else {
                switch (scene) {
                    case 'one':
                        this.turntableForm.one.furniture_id = '0';
                        this.turntableForm.one.furniture_info = result.data;
                        break;
                    case 'two':
                        this.turntableForm.two.furniture_id = '0';
                        this.turntableForm.two.furniture_info = result.data;
                        break;
                    case 'three':
                        this.turntableForm.three.furniture_id = '0';
                        this.turntableForm.three.furniture_info = result.data;
                        break;
                    case 'four':
                        this.turntableForm.four.furniture_id = '0';
                        this.turntableForm.four.furniture_info = result.data;
                        break;
                    case 'five':
                        this.turntableForm.five.furniture_id = '0';
                        this.turntableForm.five.furniture_info = result.data;
                        break;
                    case 'six':
                        this.turntableForm.six.furniture_id = '0';
                        this.turntableForm.six.furniture_info = result.data;
                        break;
                    case 'seven':
                        this.turntableForm.seven.furniture_id = '0';
                        this.turntableForm.seven.furniture_info = result.data;
                        break;
                    case 'eight':
                        this.turntableForm.eight.furniture_id = '0';
                        this.turntableForm.eight.furniture_info = result.data;
                        break;
                }
            }
        }).catch(error => {
            this.$message.error(error);
        }).then(() => {
            this.isLoading = false;
        })
    },

    //获取相关贴纸
    getPasterList() {
        this.isLoading = true;
        this.$api.journal.turntable('PasterSort').then(result => {
            if (result.errCode) {
                this.$message.error(result.message);
            } else {
                this.pasterList = result.data;
            }
        }).catch(error => {
            this.$message.error(error);
        }).then(() => {
            this.isLoading = false;
        })
    },
    //保存配置
    TurntableSave(){
        this.isLoading = true;
        this.$api.journal.turntable('Save',this.turntableForm,'post').then(result => {
            if (result.errCode) {
                this.$message.error(result.message);
            } else {
                this.$message.success('设置成功')
            }
        }).catch(error => {
            this.$message.error(error);
        }).then(() => {
            this.isLoading = false;
        })
    }

},

}
</script>

<style>

</style>
<style scoped="">
    .turntable{
        margin-top: 25px;
    }

    .text_input{
        width: 200px;
    }

    .from_interval{
        margin-left: 30px;
    }

</style>
