<template>
    <div>
        <!--搜索栏-->
        <el-form :inline="true" :model="searchParams" ref="searchForm" label-width="90px" class="demo-form-inline">
            <el-form-item prop="nickname" class="ml-10">
              <el-input v-model="searchParams.nickname" placeholder="用户昵称"></el-input>
            </el-form-item>
            <el-form-item prop="mobile" class="ml-10">
              <el-input v-model="searchParams.mobile" placeholder="用户手机号"></el-input>
            </el-form-item>
            <el-form-item prop="identity_num" class="ml-10">
              <el-input v-model="searchParams.identity_num" placeholder="用户身份号"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button icon="el-icon-search" type="primary" @click="getList()" :loading="isLoading">查询</el-button>
            </el-form-item>
        </el-form>
        <!--表单信息-->
        <el-table
                :data="dataList"
                stripe
                style="width: 100%"
                row-key="id">
            <el-table-column prop="id" label="ID"></el-table-column>
            <el-table-column prop="nick_name" label="举报用户"> </el-table-column>
            <el-table-column prop="be_nick_name" label="被举报用户"> </el-table-column>
            <el-table-column prop="reason" label="举报理由"> </el-table-column>
            <el-table-column
                    prop="created"
                    label="举报时间">
                <template slot-scope="scope">
                    {{scope.row.create_time|time}}
                </template>
            </el-table-column>
        </el-table>
        <!--翻页-->
        <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="searchParams.page"
                :page-sizes="[10, 15, 20,50,100]"
                :page-size="searchParams.size"
                layout="total,sizes, prev, pager, next"
                :total="searchParams.count">
        </el-pagination>

    </div>


</template>

<script>

    export default {
        name: "JournalUserReportRecordList",
        mounted() {
            this.getList();
        },
        data() {
            return {
                isLoading: false,
                //搜索传参
                searchParams: {
                    identity_num : '',
                    mobile: '',
                    nickname: '',
                    page: 1,
                    count: 0,
                    size: 10,
                },
                //列表数据
                dataList: [],
            }
        },
        methods: {
            //获取举报记录列表
            getList() {
                this.isLoading = true;
                this.$api.journal.user_report_record('List', this.$copy(this.searchParams)).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.dataList = result.data.list;
                        this.searchParams.count = result.data.total;//总条数
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                })
            },

            //分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getList();
            },

            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getList();
            },

        }
    }
</script>

