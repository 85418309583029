<template>
  <div>
    <!-- 产品logo -->
    <div class="settopLogo">
      <div class="bannerTitle">产品logo：</div>
      <div>
        <img
          style="height: 100px; width: 150px"
          :src="topimg"
          alt=""
          srcset=""
        />
      </div>
      <div v-if="topimgShow == 1" style="padding-top: 68px; margin-left: 20px">
        <el-button
          @click="setnew(1)"
          class="el-icon-upload2"
          size="small"
          type="primary"
          >上传</el-button
        >
      </div>
      <div v-else style="padding-top: 68px; margin-left: 20px">
        <el-button
          @click="setnew(1)"
          class="el-icon-edit"
          size="small"
          type="primary"
          >修改</el-button
        >
      </div>
      <input
        id="uploadImage1"
        type="file"
        ref="fileInput1"
        accept="image/*"
        @change="getFile(1)"
        style="display: none"
      />
    </div>

    <!-- 背景图 -->
    <div class="settopLogo">
      <div class="bannerTitle">背景图：</div>
      <div>
        <img
          style="height: 100px; width: 150px"
          :src="bgimg"
          alt=""
          srcset=""
        />
      </div>
      <div v-if="bgimgShow == 1" style="padding-top: 68px; margin-left: 20px">
        <el-button
          @click="setnew(2)"
          class="el-icon-upload2"
          size="small"
          type="primary"
          >上传</el-button
        >
      </div>
      <div v-else style="padding-top: 68px; margin-left: 20px">
        <el-button
          @click="setnew(2)"
          class="el-icon-edit"
          size="small"
          type="primary"
          >修改</el-button
        >
      </div>
      <input
        id="uploadImage2"
        type="file"
        ref="fileInput2"
        accept="image/*"
        @change="getFile(2)"
        style="display: none"
      />
    </div>

        <!-- h5背景图 -->
    <div class="settopLogo">
      <div class="bannerTitle">H5背景图：</div>
      <div>
        <img
          style="height: 100px; width: 150px"
          :src="h5Bgimg"
          alt=""
          srcset=""
        />
      </div>
      <div v-if="h5BgimgShow == 1" style="padding-top: 68px; margin-left: 20px">
        <el-button
          @click="setnew(3)"
          class="el-icon-upload2"
          size="small"
          type="primary"
          >上传</el-button
        >
      </div>
      <div v-else style="padding-top: 68px; margin-left: 20px">
        <el-button
          @click="setnew(3)"
          class="el-icon-edit"
          size="small"
          type="primary"
          >修改</el-button
        >
      </div>
      <input
        id="uploadImage3"
        type="file"
        ref="fileInput3"
        accept="image/*"
        @change="getFile(3)"
        style="display: none"
      />
    </div>

    <!-- 产品标识 -->
   <div class="setname">
      <div class="bannerTitle">产品标识：</div>
      <div>
        <input
          class="nameInp"
          v-model="nameSign"
          placeholder="请输入"
          type="text"
        />
      </div>
    </div>
    <!-- app名字 -->
    <!-- app 特点 -->
    <div class="setname">
      <div class="bannerTitle">产品名字：</div>
      <div>
        <input
          class="nameInp"
          v-model="name"
          placeholder="请输入"
          type="text"
        />
      </div>

      <div class="bannerTitle" style="margin-left: 160px">产品特点：</div>
      <div>
        <input
          class="nameInp"
          v-model="peculiarity"
          placeholder="请输入"
          type="text"
        />
      </div>
    </div>

    <!-- app 介绍 -->
    <div class="setname">
      <div class="bannerTitle">产品介绍：</div>
      <div>
        <textarea
          class="nameInp"
          v-model="introduce"
          style="height: 100px; padding-top: 10px"
          placeholder="请输入"
          type="text"
        ></textarea>
      </div>
       <div class="bannerTitle" style="margin-left: 160px">特色详情：</div>
      <div>
        <textarea
          class="nameInp"
          v-model="peculiarityInfo"
          style="height: 100px; padding-top: 10px"
          placeholder="请输入"
          type="text"
        ></textarea>
      </div>
    </div>

    <div style="width: 20%; margin: 40px auto 0px auto">
      <el-button style="width: 100%" type="primary" @click="sure"
        >确定</el-button
      >
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
export default {
  props: {
    value: {
      type: Number,
      id: Number,
    },
    value: Object,
  },
  name: "Index",
  data() {
    return {
      topimg: require("@/assets/images/bg.png"),//产品logo
      bgimg: require("@/assets/images/bg.png"),//背景图
      h5Bgimg:require("@/assets/images/bg.png"),//h5页面背景图
      name: "",//产品名字
      peculiarity: "",//产品特点
      introduce: "",//介绍详情
      peculiarityInfo: "",//特色详情
      nameSign:''//产品标识
    };
  },
  created() {
    console.log(this.value);
    if (this.value.type == 0) {
      this.topimgShow = 1;
      this.bgimgShow = 1;
      this.h5BgimgShow=1
    } else {
      this.topimg = this.value.obj.brief_pic;
      this.bgimg = this.value.obj.detail_pic;
      this.h5Bgimg = this.value.obj.h5_pic;
      this.name = this.value.obj.title_one;
      this.peculiarity = this.value.obj.title_two;
      this.introduce = this.value.obj.content_one;
      this.peculiarityInfo = this.value.obj.content_two;
      this.nameSign=this.value.obj.name;
      this.topimgShow = 2;
      this.bgimgShow = 2;
      this.h5BgimgShow=2;
    }
  },
  mounted() {},
  watch: {},
  methods: {
//触发选图
    setnew: function (e) {
      console.log(this.$refs);
      if (e == 1) {
        this.$refs.fileInput1.click();
      } else if(e == 2){
        this.$refs.fileInput2.click();
      }else{
 this.$refs.fileInput3.click();
      }
    },
//上传图片
    getFile: function (e) {
      var that = this;
      console.log(e);
      var file = "";
      if (e == 1) {
        file = document.getElementById("uploadImage1").files[0];
      } else if(e==2){
        file = document.getElementById("uploadImage2").files[0];
      }else{
        file = document.getElementById("uploadImage3").files[0];
      }

      console.log(file); //由打印的可以看到，图片    信息就在files[0]里面
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (data) => {
        console.log(data);
      };
      let formData = new FormData(); //通过formdata上传
      formData.append("file", file);

      axios
        .post(
          "/common/upload/multipart",
          formData
        )
        .then((res) => {
          console.log(res.data.data);
          //   预览的图片
          if (e == 1) {
            this.topimgShow = 2;
            this.topimg = res.data.data.object_url;
          } else if (e == 2) {
            this.bgimgShow = 2;
            this.bgimg = res.data.data.object_url;
          }else{
            this.h5BgimgShow = 2;
            this.h5Bgimg = res.data.data.object_url;
            console.log( this.h5Bgimg)
          }
        });
    },
// 确定按钮  this.value.type区分新增（0）or编辑（1）
    sure: function () {
      if (this.value.type == 0) {
        this.setoneInfo("");
      } else {
        this.setoneInfo(this.value.obj.id);
      }
    },

//e是id
    setoneInfo: function (e) {
      let data = {
        title_one: this.name,
        title_two: this.peculiarity,
        content_one: this.introduce,
        content_two: this.peculiarityInfo,
        brief_pic: this.topimg,
        detail_pic: this.bgimg,
        h5_pic:this.h5Bgimg,
        id: e,
        name: this.nameSign,
      };
      this.isLoading = true;
      this.$api.website
        .oneSave(data)
        .then((result) => {
          console.log(result);
          this.$message.success('修改成功');
            this.$emit('dialogVisible','false')
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style>
.el-icon-upload2:before {
  float: right;
}
.el-icon-edit:before {
  float: right;
}
.settopLogo {
  display: flex;
  justify-items: center;
}
.bannerTitle { 
     width: 80px;
  color: #606266;
  font-size: 14px;
}
.setname {
  display: flex;
  justify-items: center;
  align-items: center;
  margin-top: 20px;
}
.nameInp {
  height: 30px;
  padding-left: 10px;
  width: 223px;
  outline: none;
}
</style>
