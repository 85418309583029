var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-button',{attrs:{"size":"medium","type":"primary"},on:{"click":function($event){return _vm.newadd()}}},[_vm._v("新增")]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData}},[_c('el-table-column',{attrs:{"prop":"id","label":"序号"}}),_c('el-table-column',{attrs:{"prop":"title_one","label":"产品名字"}}),_c('el-table-column',{attrs:{"prop":"name","label":"产品标识"}}),_c('el-table-column',{attrs:{"label":"项目logo","align":"center","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{staticStyle:{"width":"100%"},attrs:{"src":row.brief_pic}})]}}])}),_c('el-table-column',{attrs:{"label":"背景图","align":"center","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{staticStyle:{"width":"100%"},attrs:{"src":row.detail_pic}})]}}])}),_c('el-table-column',{attrs:{"label":"H5背景图","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{staticStyle:{"width":"100%"},attrs:{"src":row.h5_pic}})]}}])}),_c('el-table-column',{attrs:{"prop":"title_two","label":"产品特点"}}),_c('el-table-column',{attrs:{"prop":"content_one","label":"产品介绍","width":"100"}}),_c('el-table-column',{attrs:{"prop":"content_two","label":"特色详情","width":"100"}}),_c('el-table-column',{attrs:{"prop":"more","label":"操作","align":"center","width":"300","class-name":"small-padding fixed-width"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.editInfo(row)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.del(row.id)}}},[_vm._v("删除")])]}}])})],1),_c('el-dialog',{attrs:{"title":"操作","visible":_vm.dialogVisible,"width":"60%","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[(_vm.flag)?_c('addcontent',{attrs:{"value":_vm.value,"title":"添加"},on:{"dialogVisible":function($event){return _vm.childByValue($event)}}},[_c('div',{attrs:{"slot":"top"},slot:"top"})]):_vm._e()],1),_c('el-pagination',{attrs:{"background":"","current-page":_vm.searchParams.page,"page-sizes":[10, 15, 20, 50, 100],"page-size":_vm.searchParams.size,"layout":"total,sizes, prev, pager, next","total":_vm.searchParams.count},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){return _vm.$set(_vm.searchParams, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.searchParams, "page", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }