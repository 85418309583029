<template>
    <div>
        <!--搜索栏-->
        <el-form :inline="true" :model="searchParams" ref="searchParams" class="demo-form-inline">
            <el-form-item label="节点分组：" prop="group">
                <el-select v-model="searchParams.group" placeholder="节点分组">
                    <el-option label="所有分组" value=""></el-option>
                    <el-option label="父节点" value="0"></el-option>
                    <el-option
                            v-for="item in allGroupList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="" prop="keywords">
                <el-input v-model="searchParams.keyword" placeholder="关键字"></el-input>
            </el-form-item>
            <el-form-item label="节点类型：" prop="is_nav">
                <el-select v-model="searchParams.is_nav" placeholder="节点分组">
                    <el-option label="所有类型" value=""></el-option>
                    <el-option label="导航节点" value="1"></el-option>
                    <el-option label="普通节点" value="0"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" :loading="isLoading" @click="getNodeList()">查询
                </el-button>
                <el-button icon="el-icon-plus" type="primary" @click="add('0')">新增节点</el-button>
            </el-form-item>
        </el-form>
        <el-alert
                title="检测到生产环境节点数据版本大于本地数据,请同步后进行修改/新增操作"
                type="error"
                show-icon
                center
                close-text="立即同步"
                @close="PullAuthNodeVersion" v-if="navVersion.remote>navVersion.local">
        </el-alert>
        <el-table
                :data="nodeList"
                stripe
                style="width: 100%"
                row-key="id"
                default-expand-all>
            <el-table-column
                    prop="name"
                    label="节点名称">
            </el-table-column>
            <el-table-column
                    prop="remark"
                    label="说明">
                <template slot-scope="scope">
                    {{scope.row.remark||'--'}}
                </template>
            </el-table-column>
            <el-table-column
                    prop="name"
                    label="导航">
                <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.is_nav==1" class="el-icon-check"></el-tag>
                    <el-tag type="info" v-if="scope.row.is_nav==0" class="el-icon-close"></el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="alias"
                    label="别名">
            </el-table-column>
            <el-table-column
                    prop="active"
                    label="节点类型">
                <template slot-scope="scope">
                    <div>{{actives[scope.row.active]}}</div>
                </template>
            </el-table-column>
            <el-table-column
                    prop="orderby"
                    label="排序">
                <template slot-scope="scope">
                    <edit-able-content :content="scope.row.orderby" :index="parseInt(scope.row.id)"
                                       @changed="updateOrderby"/>
                </template>
            </el-table-column>
            <el-table-column
                    label="操作">
                <template slot-scope="scope">
                    <el-link v-if="scope.row.parent==0" type="success"  @click="add(scope.row.id)"
                               icon="el-icon-plus">添加
                    </el-link>
                    <el-link type="primary" @click="edit(scope.row)"
                               icon="el-icon-edit-outline" class="ml-10">编辑
                    </el-link>
                    <el-link type="danger" @click="remove(scope.row)"
                               icon="el-icon-delete" class="ml-10">删除
                    </el-link>
                </template>
            </el-table-column>
        </el-table>
        <!--翻页-->
        <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="searchParams.page"
                :page-sizes="[10, 15, 20,50,100]"
                :page-size="searchParams.size"
                layout="total,sizes, prev, pager, next"
                :total="searchParams.count">
        </el-pagination>
        <!--弹窗-->
        <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="50%">
            <el-form :model="form" :rules="formRules" status-icon ref="form" label-width="120px" class="">
                <el-form-item label="归属节点：" prop="parent">
                    <el-select v-model="form.parent" placeholder="归属节点">
                        <el-option label="请选择" value=""></el-option>
                        <el-option label="父节点" value="0"></el-option>
                        <el-option
                                v-for="item in allGroupList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="节点名称：" prop="name">
                    <el-input type="text" placeholder="请输入节点名称" v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="节点类型：" prop="active">
                    <el-radio-group v-model="form.active">
                        <el-radio label="0">主模块</el-radio>
                        <el-radio label="1">后端操作</el-radio>
                        <el-radio label="2">前端页面</el-radio>
                        <el-radio label="3">前后端并用</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="导航菜单：" prop="is_nav">
                    <el-radio-group v-model="form.is_nav">
                        <el-radio label="1">显示</el-radio>
                        <el-radio label="0">隐藏</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="菜单图标：" prop="ico" v-if="form.parent==0 && form.is_nav==1">
                    <el-input type="text" placeholder="为父级导航菜单时填写" v-model="form.ico"></el-input>
                </el-form-item>
                <el-form-item label="后端入口：" prop="action_path" v-if="form.active==1 || form.active==3">
                    <el-input type="text" placeholder="ControllerName/ActionName" v-model="form.action_path"></el-input>
                </el-form-item>
                <el-form-item label="前端入口：" prop="route_path" v-if="form.active==2 || form.active==3">
                    <el-input type="text" placeholder="/path" v-model="form.route_path"></el-input>
                </el-form-item>
                <el-form-item label="前端组件：" prop="route_component" v-if="form.active==2 || form.active==3">
                    <el-input type="text" placeholder="views/path/name.vue" v-model="form.route_component"></el-input>
                </el-form-item>
                <el-form-item label="节点别名：" prop="alias">
                    <el-input type="text" placeholder="请输入节点别名"
                              v-model="form.alias" width="100"></el-input>
                </el-form-item>
                <el-form-item label="节点备注：" prop="remark">
                    <el-input type="text" placeholder="备注信息" v-model="form.remark"></el-input>
                </el-form-item>
                <el-form-item label="节点排序：" prop="orderby">
                    <el-input type="text" placeholder="排序值,越大越靠前" v-model="form.orderby"></el-input>
                </el-form-item>
                <el-form-item label="节点状态：" prop="status">
                    <el-radio-group v-model="form.status">
                        <el-radio label="1">开启</el-radio>
                        <el-radio label="0">关闭</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="save()" :loading="isLoading">保 存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import EditAbleContent from '../../components/EditAbleContent.vue'

    export default {
        name: "AccessNodes",
        components: {
            EditAbleContent
        },
        data() {
            return {
                isLoading: false,
                dialogFormVisible: false,
                dialogGroupFormVisible: false,
                actives: {
                    '0': '主模块',
                    '1': '后端',
                    '2': '前端',
                    '3': '前后端通用',
                },
                form: {},
                groupForm: {},
                dialogGroupTitle: '添加分组',
                dialogTitle: '添加节点',
                formRules: {
                    parent: {required: true, message: '请选择归属节点', trigger: 'blur'},
                    name: {required: true, message: '请输入节点名称', trigger: 'blur'},
                    active: {required: true, message: '请选择节点类型', trigger: 'blur'},
                    is_nav: {required: true, message: '请选择是否导航菜单', trigger: 'blur'},
                    alias: {required: true, message: '请输入节点别名', trigger: 'blur'},
                    status: {required: true, message: '请选择节点状态', trigger: 'blur'},
                },
                groupList: [],
                allGroupList: [],
                searchParams: {
                    keyword: '',
                    is_nav: '',
                    group: this.$route.query.group || '',
                    page: 1,
                    count: 1,
                    size: 10,
                },
                groupSearchParams: {
                    keyword: '',
                    page: 1,
                    count: 1,
                    size: 10,
                },
                nodeList: [],
                navVersion: {}
            }
        },
        methods: {
            selectGroup(group) {
                this.searchParams.group = group;
                this.getNodeList()
            },
            //创建分组
            add(parent) {
                this.dialogTitle = '添加节点';
                this.form = {
                    parent: parent ? parent : this.searchParams.group,
                    status: '1',
                    orderby: 0
                };
                this.dialogFormVisible = true;
            },
            edit(form) {
                this.dialogTitle = '编辑节点';
                this.form = form;
                this.dialogFormVisible = true;
            },
            updateOrderby(value, id) {
                this.isLoading = true;
                this.$api.system.accessNodes({action: 'save_node', id: id, orderby: value}, 'post').then(result => {
                    if (result.errCode) {
                        this.$message.alert(result.message)
                    } else {
                        this.$message.success('修改成功');
                        this.getNodeList()
                    }
                }).catch(error => {
                    this.$message.error(error)
                }).finally(() => {
                    this.isLoading = false
                })
            },
            save() {
                if (this.navVersion.remote > this.navVersion.local) {
                    return this.$message.warning('生产环境数据版本大于本地数据,请同步后重试')
                }
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.isLoading = true;
                        let form = this.form
                        form.action = 'save_node'
                        this.$api.system.accessNodes(form, 'post').then(result => {
                            if (result.errCode) {
                                this.$message.alert(result.message)
                            } else {
                                this.dialogFormVisible = false;
                                this.$message.success('保存成功');
                                this.getNodeList()
                            }
                        }).catch(error => {
                            this.$message.error(error)
                        }).finally(() => {
                            this.isLoading = false
                        })
                    } else {
                        return false;
                    }
                });
            },
            remove(form) {
                if (this.navVersion.remote > this.navVersion.local) {
                    return this.$message.warning('生产环境数据版本大于本地数据,请同步后重试')
                }
                let item = form
                this.$message.confirm({message: '确定要删除节点[' + item.name + ']吗?'}, () => {
                    this.$loading.show('删除中')
                    this.$api.system.accessNodes({action: 'remove_node', id: item.id}, 'post').then(result => {
                        if (result.errCode) {
                            this.$message.alert(result.message)
                        } else {
                            this.getNodeList()
                        }
                    }).catch(error => {
                        this.$message.error(error)
                    }).finally(() => {
                        this.$loading.close()
                    })
                })
            },
            //获取列表
            getNodeList() {
                this.loading = true;
                let params = this.searchParams
                params.action = 'get_node_list'
                this.$api.system.accessNodes(params).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.nodeList = result.data.list;
                        this.searchParams.count = result.data.total;//总条数
                        this.loading = false;
                    }
                }).catch(error => {
                    this.$message.error(error);
                    this.loading = false;
                }).then(() => {

                });
            },
            //点击分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getNodeList();
            },
            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getNodeList();
            },
            //获取分组列表
            getGroupList() {
                this.loading = true;
                let params = this.groupSearchParams
                params.action = 'group_list'
                this.$api.system.accessNodes(params).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.groupList = result.data.list;
                        this.groupSearchParams.count = result.data.total;//总条数
                        this.loading = false;
                    }
                }).catch(error => {
                    this.$message.error(error);
                    this.loading = false;
                });
            },
            addGroup() {
                this.dialogGroupTitle = '添加分组';
                this.groupForm = {};
                this.dialogGroupFormVisible = true;
            },
            editGroup(index) {
                this.dialogGroupTitle = '编辑分组';
                this.groupForm = this.groupList[index];
                this.dialogGroupFormVisible = true;
            },
            saveGroup() {
                this.$refs['gform'].validate((valid) => {
                    if (valid) {
                        this.isLoading = true;
                        let form = this.groupForm
                        form.action = 'group_save'
                        this.$api.system.accessNodes(form, 'post').then(result => {
                            if (result.errCode) {
                                this.$message.alert(result.message)
                            } else {
                                this.dialogGroupFormVisible = false;
                                this.$message.success('保存成功');
                                this.getGroupList()
                            }
                        }).catch(error => {
                            this.$message.error(error)
                        }).finally(() => {
                            this.isLoading = false
                        })
                    } else {
                        return false;
                    }
                });
            },
            //点击分页
            handleGroupSizeChange(val) {
                this.groupSearchParams.size = val;
                this.getGroupList();
            },
            handleGroupCurrentChange(val) {
                this.groupSearchParams.page = val;
                this.getGroupList();
            },
            //获取分组列表
            getAllGroups() {
                this.$loading.show('加载中')
                let params = this.searchParams
                params.action = 'get_all_groups'
                this.$api.system.accessNodes(params).then(result => {
                    this.isLoading = false;
                    if (result.errCode) {
                        this.$message.alert('加载节点分组失败:' + result.message)
                    } else {
                        this.allGroupList = result.data
                        this.getNodeList()
                    }
                }).catch(error => {
                    this.$message.error(error)
                }).finally(() => {
                    this.loadNavVersion()
                    this.$loading.close()
                })
            },
            //获取分组名称
            getGroupName(id) {
                let name = '--'
                this.allGroupList.forEach(function (item) {
                    if (item.id == id) {
                        name = item.name
                    }
                })
                return name
            },
            //获取数据版本
            loadNavVersion() {
                this.$api.system.config('LoadNavVersion', {}).then(result => {
                    this.isLoading = false;
                    if (!result.errCode) {
                        this.navVersion = result.data
                    }
                }).catch(error => {
                    console.log(error)
                }).finally(() => {
                })
            },
            //拉取线上的权限节点
            PullAuthNodeVersion() {
                this.$message.confirm({message: '确定拉取数据到本地环境吗?'}, () => {
                    this.$loading.show('同步中')
                    this.$api.system.config('PullAuthNodeVersion').then(result => {
                        if (result.errCode) {
                            this.$message.alert(result.message)
                        } else {
                            this.$message.success('拉取成功:' + result.data)
                            this.loadNavVersion()
                        }
                    }).catch(error => {
                        this.$message.error(error)
                    }).finally(() => {
                        this.$loading.close()
                    })
                })
            },
        },
        mounted() {
            this.getAllGroups()

        }
    }
</script>