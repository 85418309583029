<template>
    <div class="home">
        <div class="bg-white pd-20">
            <el-row>
                <el-col :span="4">
                    <h3>客户概况</h3>
                </el-col>
                <el-col :span="20" class="txt-right">
                    筛选：
                    <el-radio-group v-model="dateParams.day" class="mr-10"
                                    @change="sumCustomer();sumDeal();sumCompany();sumGiveup();sumWorkers()">
                        <el-radio :label="1">今日</el-radio>
                        <el-radio :label="2">昨日</el-radio>
                        <el-radio :label="7">近7天</el-radio>
                        <el-radio :label="30">近30天</el-radio>
                        <el-radio :label="-1">自定义</el-radio>
                    </el-radio-group>
                    <el-date-picker
                            class="mr-10"
                            v-model="dateParams.dateRange"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd">
                    </el-date-picker>
                    <el-button type="primary" @click="sumCustomer();sumDeal();sumCompany();sumGiveup();sumWorkers()">
                        查询
                    </el-button>
                </el-col>
            </el-row>

            <el-row class="mt-30">
                <el-col :span="6" class="txt-center">
                    <span class="fs-14 c-999">拜访数量</span>
                    <div class="mt-20 mb-20 fs-22 fs-weight-bold">{{customerData.contactToday}}</div>
                    <span class="fs-14 c-999"
                          v-if="dateParams.day==1">昨日新增：{{customerData.contactYesterday}}</span>
                </el-col>
                <el-col :span="6" class="txt-center">
                    <span class="fs-14 c-999">微信通过</span>
                    <div class="mt-20 mb-20 fs-22 fs-weight-bold">{{customerData.wxInviteToday}}</div>
                    <span class="fs-14 c-999"
                          v-if="dateParams.day==1">昨日新增：{{customerData.wxInviteYesterday}}</span>
                </el-col>
                <el-col :span="6" class="txt-center">
                    <span class="fs-14 c-999">成交数量</span>
                    <div class="mt-20 mb-20 fs-22 fs-weight-bold">{{customerData.dealToday}}</div>
                    <span class="fs-14 c-999"
                          v-if="dateParams.day==1">昨日新增：{{customerData.dealYesterday}}</span>
                </el-col>
                <el-col :span="6" class="txt-center">
                    <span class="fs-14 c-999">放弃客户</span>
                    <div class="mt-20 mb-20 fs-22 fs-weight-bold">{{customerData.giveupToday}}</div>
                    <span class="fs-14 c-999"
                          v-if="dateParams.day==1">昨日新增：{{customerData.giveupYesterday}}</span>
                </el-col>
                <!--<el-col :span="4" style="line-height: 25px">-->
                <!--<div>潜在客户<i class="el-icon-top c-green"></i>26.67%</div>-->
                <!--<div>意向客户<i class="el-icon-top c-green"></i>26.67%</div>-->
                <!--<div>签约客户<i class="el-icon-bottom c-red"></i>26.67%</div>-->
                <!--<div>签约金额<i class="el-icon-top c-green"></i>26.67%</div>-->
                <!--</el-col>-->
                <!--<el-col :span="10">-->
                <!--<div id="myFunnel" :style="{width: '100%', height: '200px'}"></div>-->
                <!--</el-col>-->
            </el-row>
        </div>
        <div class="bg-white pd-20 mt-20">
            <el-row class="mt-30">
                <el-col :span="6" class="txt-center">
                    <span class="fs-14 c-999">联系不上</span>
                    <div class="mt-20 mb-20 fs-22 fs-weight-bold">{{giveupData.reason1Today}}</div>
                    <span class="fs-14 c-999"
                          v-if="dateParams.day==1">昨日新增：{{giveupData.reason1Yesterday}}</span>
                </el-col>
                <el-col :span="6" class="txt-center">
                    <span class="fs-14 c-999">拒绝骚扰</span>
                    <div class="mt-20 mb-20 fs-22 fs-weight-bold">{{giveupData.reason2Today}}</div>
                    <span class="fs-14 c-999"
                          v-if="dateParams.day==1">昨日新增：{{giveupData.reason2Yesterday}}</span>
                </el-col>
                <el-col :span="6" class="txt-center">
                    <span class="fs-14 c-999">已续保</span>
                    <div class="mt-20 mb-20 fs-22 fs-weight-bold">{{giveupData.reason3Today}}</div>
                    <span class="fs-14 c-999"
                          v-if="dateParams.day==1">昨日新增：{{giveupData.reason3Yesterday}}</span>
                </el-col>
                <el-col :span="6" class="txt-center">
                    <span class="fs-14 c-999">无意向</span>
                    <div class="mt-20 mb-20 fs-22 fs-weight-bold">{{giveupData.reason4Today}}</div>
                    <span class="fs-14 c-999"
                          v-if="dateParams.day==1">昨日新增：{{giveupData.reason4Yesterday}}</span>
                </el-col>
            </el-row>
        </div>
        <div class="bg-white pd-20 mt-20">
            <el-row>
                <el-col :span="4">
                    <h3>销售概况</h3>
                </el-col>

            </el-row>

            <el-row class="mt-30">
                <el-col :span="12" class="txt-center">
                    <el-row class="moneyBox">
                        <el-col :span="12" class="border-right">
                            <p>签约订单</p>
                            <p>(位)</p>
                            <h3 class="mt-20">{{dealData.dealToday}}</h3>
                            <div class="mt-50" v-if="dateParams.day==1">昨日：{{dealData.dealYesterday}}<i
                                    class="el-icon-top fs-weight-bold c-red" v-if="false"></i></div>
                        </el-col>
                        <el-col :span="12">
                            <p>签约金额</p>
                            <p>(元)</p>
                            <h3 class="mt-20">¥{{dealData.dealAmountToday}}</h3>
                            <div class="mt-50" v-if="dateParams.day==1">昨日：¥{{dealData.dealAmountYesterday}}<i
                                    class="el-icon-top fs-weight-bold c-red" v-if="false"></i></div>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="12">
                    <div id="companyChart" :style="{width: '100%', height: '260px'}"></div>
                </el-col>
            </el-row>
        </div>
        <div class="bg-white pd-20 mt-20">
            <el-row class="mt-30">
                <el-col :span="24">
                    <div id="myChartRank" :style="{width: '100%', height: '300px'}"></div>
                </el-col>
            </el-row>
        </div>
        <div class="bg-white pd-20 mt-20">
            <el-row class="mt-30">
                <el-col :span="24">
                    <div id="myChartLine" :style="{width: '100%', height: '300px'}"></div>
                </el-col>
            </el-row>
        </div>
        <ul v-if="false">
            <li>建档
                <div>
                    <router-link to="/filing/customerSearch">客户建档</router-link>
                </div>
            </li>
            <li>客户
                <div>
                    <router-link to="/customerManage/list">客户列表</router-link>
                </div>
                <div>
                    <router-link to="/customerManage/publiclist">公共客户</router-link>
                </div>
            </li>
            <li>数据
                <div>
                    <router-link to="/data/salesStatistics">销售额统计</router-link>
                </div>
                <div>
                    <router-link to="/data/performanceStatistics">业绩统计</router-link>
                </div>
                <div>
                    <router-link to="/data/orderStatistics">订单统计</router-link>
                </div>
            </li>
            <li>系统
                <div>
                    <router-link to="/salesman/list">用户管理</router-link>
                </div>
                <div>
                    <router-link to="/system/insurance">保险公司</router-link>
                </div>
            </li>


        </ul>
    </div>
</template>

<script>
    import echarts from 'echarts'
    import moment from 'moment'

    export default {
        watch: {},
        filters: {},
        data() {
            return {
                radio: 3,
                date: '',
                data: [],

                //客户统计
                dateParams: {//当为自定义天数时设置
                    start: moment(new Date().getTime() - 3600 * 1000 * 24 * 30).format('YYYY-MM-DD'),
                    end: moment(new Date().getTime()).format('YYYY-MM-DD'),
                    cycle: 'day', //day/week/month
                    day: 1,
                    dateRange: []
                },
                customerData: {
                    giveupToday: 0,
                    giveupYesterday: 0,
                    wxInviteToday: 0,
                    wxInviteYesterday: 0,
                    contactToday: 0,
                    contactYesterday: 0,
                    dealToday: 0,
                    dealYesterday: 0
                },
                customerModulesData: [
                    {
                        "key": "ci_contact_result_-1",
                        "module": "ci_contact_result_-1",
                        "name": "放弃客户 ",
                        "type": "value",
                    },
                    {
                        "key": "ci_customer_wx_invite",
                        "module": "ci_customer_wx_invite",
                        "name": "微信通过",
                        "type": "value",
                    },
                    {
                        "key": "ci_customer_contact",
                        "module": "ci_customer_contact",
                        "name": "拜访数量 ",
                        "type": "value",
                    },
                    {
                        "key": "ci_customer_deal",
                        "module": "ci_customer_deal",
                        "name": "成交数量 ",
                        "type": "value",
                    }
                ],
                dealData: {
                    dealToday: 0,
                    dealYesterday: 0,
                    dealAmountToday: 0,
                    dealAmountYesterday: 0,
                    dealCommercialAmountToday: 0,
                    dealCommercialAmountYesterday: 0
                },
                dealModulesData: [
                    {
                        "key": "ci_customer_deal",
                        "module": "ci_customer_deal",
                        "name": "签约数量 ",
                        "type": "value",
                    },
                    {
                        "key": "ci_deal_amount",
                        "module": "ci_deal_amount",
                        "name": "签约金额",
                        "type": "value",
                    },
                    {
                        "key": "ci_deal_commercial_amount",
                        "module": "ci_deal_commercial_amount",
                        "name": "商业险金额 ",
                        "type": "value",
                    }
                ],
                giveupData: {
                    reason1Today: 0,
                    reason1Yesterday: 0,
                    reason2Today: 0,
                    reason2Yesterday: 0,
                    reason3Today: 0,
                    reason3Yesterday: 0,
                    reason4Today: 0,
                    reason4Yesterday: 0
                },
                giveupModulesData: [
                    {
                        "key": "ci_contact_giveup_1",
                        "module": "ci_contact_giveup_1",
                        "name": "联系不上 ",
                        "type": "value",
                    },
                    {
                        "key": "ci_contact_giveup_2",
                        "module": "ci_contact_giveup_2",
                        "name": "拒绝骚扰",
                        "type": "value",
                    },
                    {
                        "key": "ci_contact_giveup_3",
                        "module": "ci_contact_giveup_3",
                        "name": "已续保 ",
                        "type": "value",
                    },
                    {
                        "key": "ci_contact_giveup_4",
                        "module": "ci_contact_giveup_4",
                        "name": "无意向 ",
                        "type": "value",
                    }
                ],
                //保险公司统计
                //累计数据module配置
                companyChartModulesData: [
                    {
                        "key": "ci_deal_company",
                        "module": "ci_deal_company",
                        "name": "成交数量",
                        "item_id": 1,
                        "type": "value"
                    },
                    {
                        "key": "ci_deal_company",
                        "module": "ci_deal_company",
                        "name": "成交数量",
                        "item_id": 2,
                        "type": "value"
                    },
                    {
                        "key": "ci_deal_company",
                        "module": "ci_deal_company",
                        "name": "成交数量",
                        "item_id": 3,
                        "type": "value"
                    },
                    {
                        "key": "ci_deal_company",
                        "module": "ci_deal_company",
                        "name": "成交数量",
                        "item_id": 4,
                        "type": "value"
                    },
                    {
                        "key": "ci_deal_company",
                        "module": "ci_deal_company",
                        "name": "成交数量",
                        "item_id": 5,
                        "type": "value"
                    },
                    {
                        "key": "ci_deal_company",
                        "module": "ci_deal_company",
                        "name": "成交数量",
                        "item_id": 6,
                        "type": "value"
                    },
                    {
                        "key": "ci_deal_company",
                        "module": "ci_deal_company",
                        "name": "成交数量",
                        "item_id": 7,
                        "type": "value"
                    },
                    {
                        "key": "ci_deal_company",
                        "module": "ci_deal_company",
                        "name": "成交数量",
                        "item_id": 8,
                        "type": "value"
                    },
                    {
                        "key": "ci_deal_company",
                        "module": "ci_deal_company",
                        "name": "成交数量",
                        "item_id": 9,
                        "type": "value"
                    },
                    {
                        "key": "ci_deal_company",
                        "module": "ci_deal_company",
                        "name": "成交数量",
                        "item_id": 10,
                        "type": "value"
                    },
                    {
                        "key": "ci_deal_company",
                        "module": "ci_deal_company",
                        "name": "成交数量",
                        "item_id": 11,
                        "type": "value"
                    },
                    {
                        "key": "ci_deal_company",
                        "module": "ci_deal_company",
                        "name": "成交数量",
                        "item_id": 12,
                        "type": "value"
                    },
                    {
                        "key": "ci_deal_company_amount",
                        "module": "ci_deal_company_amount",
                        "name": "成交金额",
                        "item_id": 1,
                        "type": "value",
                    },
                    {
                        "key": "ci_deal_company_amount",
                        "module": "ci_deal_company_amount",
                        "name": "成交金额",
                        "item_id": 2,
                        "type": "value",
                    },
                    {
                        "key": "ci_deal_company_amount",
                        "module": "ci_deal_company_amount",
                        "name": "成交金额",
                        "item_id": 3,
                        "type": "value"
                    },
                    {
                        "key": "ci_deal_company_amount",
                        "module": "ci_deal_company_amount",
                        "name": "成交金额",
                        "item_id": 4,
                        "type": "value"
                    }, {
                        "key": "ci_deal_company_amount",
                        "module": "ci_deal_company_amount",
                        "name": "成交金额",
                        "item_id": 5,
                        "type": "value"
                    }, {
                        "key": "ci_deal_company_amount",
                        "module": "ci_deal_company_amount",
                        "name": "成交金额",
                        "item_id": 6,
                        "type": "value"
                    }, {
                        "key": "ci_deal_company_amount",
                        "module": "ci_deal_company_amount",
                        "name": "成交金额",
                        "item_id": 7,
                        "type": "value"
                    }, {
                        "key": "ci_deal_company_amount",
                        "module": "ci_deal_company_amount",
                        "name": "成交金额",
                        "item_id": 8,
                        "type": "value"
                    }, {
                        "key": "ci_deal_company_amount",
                        "module": "ci_deal_company_amount",
                        "name": "成交金额",
                        "item_id": 9,
                        "type": "value"
                    }, {
                        "key": "ci_deal_company_amount",
                        "module": "ci_deal_company_amount",
                        "name": "成交金额",
                        "item_id": 10,
                        "type": "value"
                    }, {
                        "key": "ci_deal_company_amount",
                        "module": "ci_deal_company_amount",
                        "name": "成交金额",
                        "item_id": 11,
                        "type": "value"
                    }, {
                        "key": "ci_deal_company_amount",
                        "module": "ci_deal_company_amount",
                        "name": "成交金额",
                        "item_id": 12,
                        "type": "value"
                    },
                ],
                companyChartData: {},
                workerNames: [],
                workerRanksContactData: [],
                workerRanksWxInviteData: [],
                workerContactResult: [],
                workerWxInviteResult: [],
                rankChart: '',
                chartLine: '',
                companyChart: ''
            }
        },
        mounted() {
            //console.log(this.$store.getters.getRoutePath)
            this.rankChart = echarts.init(document.getElementById('myChartRank'))
            this.chartLine = echarts.init(document.getElementById('myChartLine'))
            this.companyChart = echarts.init(document.getElementById('companyChart'))
            //this.drawFunnel();
            //拜访统计
            this.sumCustomer();
            //成交统计
            this.sumDeal()
            //放弃统计
            this.sumGiveup()
            //保险公司营业额统计
            this.sumCompany()
            //员工排行
            this.getWorkers()
        },
        methods: {
            //拜访统计
            sumCustomer() {
                if (this.dateParams.day == -1) {
                    this.dateParams.start = this.dateParams.dateRange[0]
                    this.dateParams.end = this.dateParams.dateRange[1]
                }
                this.$api.statistics.getSumData({
                    modules: this.customerModulesData,
                    day: this.dateParams.day,
                    params: this.dateParams,
                }).then(result => {
                    if (!result.errCode) {
                        this.customerData.giveupToday = result.data[0].result
                        this.customerData.wxInviteToday = result.data[1].result
                        this.customerData.contactToday = result.data[2].result
                        this.customerData.dealToday = result.data[3].result
                    }

                });
                this.$api.statistics.getChartData({
                    modules: this.customerModulesData,
                    day: this.dateParams.day,
                    params: this.dateParams,
                }).then(result => {
                    if (!result.errCode) {
                        this.displayLine(result.data)
                    }

                });
                if (this.dateParams.day == 1) {
                    //昨日
                    this.$api.statistics.getSumData({
                        modules: this.customerModulesData,
                        day: 2,
                    }).then(result => {
                        if (!result.errCode) {
                            this.customerData.giveupYesterday = result.data[0].result
                            this.customerData.wxInviteYesterday = result.data[1].result
                            this.customerData.contactYesterday = result.data[2].result
                            this.customerData.dealYesterday = result.data[3].result
                        }
                    })
                }
            },
            //放弃统计
            sumGiveup() {
                if (this.dateParams.day == -1) {
                    this.dateParams.start = this.dateParams.dateRange[0]
                    this.dateParams.end = this.dateParams.dateRange[1]
                }
                this.$api.statistics.getSumData({
                    modules: this.giveupModulesData,
                    day: this.dateParams.day,
                    params: this.dateParams,
                }).then(result => {
                    if (!result.errCode) {
                        this.giveupData.reason1Today = result.data[0].result
                        this.giveupData.reason2Today = result.data[1].result
                        this.giveupData.reason3Today = result.data[2].result
                        this.giveupData.reason4Today = result.data[3].result
                    }

                });
                if (this.dateParams.day == 1) {
                    //昨日
                    this.$api.statistics.getSumData({
                        modules: this.giveupModulesData,
                        day: 2,
                    }).then(result => {
                        if (!result.errCode) {
                            this.giveupData.reason1Yesterday = result.data[0].result
                            this.giveupData.reason2Yesterday = result.data[1].result
                            this.giveupData.reason3Yesterday = result.data[2].result
                            this.giveupData.reason4Yesterday = result.data[3].result
                        }

                    })
                }

            },
            //成交统计
            sumDeal() {
                if (this.dateParams.day == -1) {
                    this.dateParams.start = this.dateParams.dateRange[0]
                    this.dateParams.end = this.dateParams.dateRange[1]
                }
                this.$api.statistics.getSumData({
                    modules: this.dealModulesData,
                    day: this.dateParams.day,
                    params: this.dateParams,
                }).then(result => {
                    if (!result.errCode) {
                        this.dealData.dealToday = result.data[0].result
                        this.dealData.dealAmountToday = result.data[1].result
                        this.dealData.dealCommercialAmountToday = result.data[2].result
                    }
                });
                if (this.dateParams.day == 1) {
                    //昨日
                    this.$api.statistics.getSumData({
                        modules: this.dealModulesData,
                        day: 2,
                    }).then(result => {
                        if (!result.errCode) {
                            this.dealData.dealYesterday = result.data[0].result
                            this.dealData.dealAmountYesterday = result.data[1].result
                            this.dealData.dealCommercialAmountYesterday = result.data[2].result
                        }
                    })
                }
            },
            //保险公司成交数据
            sumCompany() {
                if (this.dateParams.day == -1) {
                    this.dateParams.start = this.dateParams.dateRange[0]
                    this.dateParams.end = this.dateParams.dateRange[1]
                }
                this.$api.statistics.getSumData({
                    modules: this.companyChartModulesData,
                    day: this.dateParams.day,
                    params: this.dateParams,
                }).then(result => {
                    if (!result.errCode) {
                        this.companyChartData = result.data
                        this.displayCompanySumData()
                    }
                });
            },
            drawFunnel() {
                let myChart = echarts.init(document.getElementById('myFunnel'));
                myChart.setOption({
                    tooltip: {
                        trigger: 'item',
                        formatter: "{a} <br/>{b} : {c}%"
                    },
                    series: [
                        {
                            name: '预期',
                            type: 'funnel',
                            left: '0',
                            width: '60%',
                            label: {
                                formatter: '{b}预期'
                            },
                            labelLine: {
                                show: false
                            },
                            itemStyle: {
                                opacity: 0.7
                            },
                            emphasis: {
                                label: {
                                    position: 'inside',
                                    formatter: '{b}预期: {c}%'
                                }
                            },
                            data: [
                                {value: 60, name: '成交转化率'},
                                {value: 40, name: '意向转化率'},
                                {value: 20, name: '意向成交转化率'},
                            ]
                        },
                        {
                            name: '实际',
                            type: 'funnel',
                            left: '10%',
                            width: '40%',
                            maxSize: '80%',
                            label: {
                                position: 'inside',
                                formatter: '{c}%',
                                color: '#fff'
                            },
                            itemStyle: {
                                opacity: 0.5,
                                borderColor: '#fff',
                                borderWidth: 2
                            },
                            emphasis: {
                                label: {
                                    position: 'inside',
                                    formatter: '{b}实际: {c}%'
                                }
                            },
                            data: [
                                {value: 30, name: '潜在客户'},
                                {value: 10, name: '意向客户'},
                                {value: 5, name: '成交客户'},
                            ]
                        }
                    ]
                })
            },
            displayCompanySumData() {
                // 绘制图表
                this.companyChart.setOption({
                    color: ['#3aa1ff', '#4ecb73', '#2b3a79'],
                    title: {
                        left: 'center',
                        text: '保险公司销售统计'
                    },
                    tooltip: {},
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '2%',
                        containLabel: true
                    },
                    xAxis: {
                        data: ["人保", "太平洋", "平安", "阳光", "安诚", "安心", "人寿", "大家", "天安", "利宝", "锦泰", "太平"]
                    },
                    yAxis: {},
                    series: [{
                        name: '销售金额(万元)',
                        type: 'bar',
                        data: [this.companyChartData[12].result / 10000, this.companyChartData[13].result / 10000, this.companyChartData[14].result / 10000, this.companyChartData[15].result / 10000, this.companyChartData[16].result / 10000, this.companyChartData[17].result / 10000, this.companyChartData[18].result / 10000, this.companyChartData[19].result / 10000, this.companyChartData[20].result / 10000, this.companyChartData[21].result / 10000, this.companyChartData[22].result / 10000, this.companyChartData[23].result / 10000]
                    },
                        {
                            name: '订单数量',
                            type: 'bar',
                            data: [this.companyChartData[0].result, this.companyChartData[1].result, this.companyChartData[2].result, this.companyChartData[3].result, this.companyChartData[4].result, this.companyChartData[5].result, this.companyChartData[6].result, this.companyChartData[7].result, this.companyChartData[8].result, this.companyChartData[9].result, this.companyChartData[10].result, this.companyChartData[11].result]
                        }]
                });
            },
            //员工排行数据
            getWorkers() {
                this.$api.statistics.workers({}).then(result => {
                    if (!result.errCode) {
                        result.data.forEach(item => {
                            this.workerNames.push(item.u_name)
                            this.workerRanksContactData.push({
                                "key": "ci_customer_contact",
                                "module": "ci_customer_contact",
                                "name": item.u_name + "的拜访数",
                                "type": "value",
                                "mch_id": item.u_id
                            })
                            this.workerRanksWxInviteData.push({
                                "key": "ci_customer_wx_invite",
                                "module": "ci_customer_wx_invite",
                                "name": item.u_name + "微信通过",
                                "type": "value",
                                "mch_id": item.u_id
                            })
                        })
                        this.sumWorkers()
                    }
                });
            },
            sumWorkers() {
                if (this.dateParams.day == -1) {
                    this.dateParams.start = this.dateParams.dateRange[0]
                    this.dateParams.end = this.dateParams.dateRange[1]
                }
                this.workerContactResult = []
                this.workerWxInviteResult = []
                this.$api.statistics.getSumData({
                    modules: this.workerRanksContactData,
                    day: this.dateParams.day,
                    params: this.dateParams,
                }).then(result => {
                    if (!result.errCode) {
                        result.data.forEach(item => {
                            this.workerContactResult.push(item.result)
                        })
                        this.$api.statistics.getSumData({
                            modules: this.workerRanksWxInviteData,
                            day: this.dateParams.day,
                            params: this.dateParams,
                        }).then(result => {
                            if (!result.errCode) {
                                result.data.forEach(item => {
                                    this.workerWxInviteResult.push(item.result)
                                })
                                this.displayWorkRanks()
                            }
                        });
                    }
                });

            },
            displayWorkRanks() {
                // 绘制图表
                this.rankChart.setOption({
                    color: ['#3aa1ff', '#4ecb73', '#2b3a79'],
                    title: {text: '业务员排行'},
                    tooltip: {},
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        data: this.workerNames
                    },
                    yAxis: {},
                    series: [
                        {
                            name: '拜访数量',
                            type: 'bar',
                            data: this.workerContactResult
                        },
                        {
                            name: '添加微信',
                            type: 'bar',
                            data: this.workerWxInviteResult
                        }
                    ]
                });
            },
            //走势
            displayLine(data) {
                let series = []
                data.series.forEach(item => {
                    series.push({
                        name: item.name,
                        type: 'line',
                        // stack: '数量',
                        // smooth: true,
                        data: item.data,
                        //symbol:'rect'
                    })
                })
                // 绘制图表
                this.chartLine.setOption({
                    color: ['#3aa1ff', '#4ecb73', '#2b3a79'],
                    title: {
                        text: '数据走势'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: data.types
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: data.axis_datas
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: series
                });
            },
        }
    }
</script>
<style scoped>
    .home {
        margin: -20px;
        background-color: #f5f5f5
    }

    .moneyBox {
        border: rgba(215, 215, 215, 1) solid 1px;
        padding: 50px 10px;
        margin: 0 30px;
    }

    .moneyBox .border-right {
        border-right: rgba(215, 215, 215, 1) solid 1px;
    }
</style>
