<template>
    <div>
      <el-tabs v-model="activeName" @tab-click="changeTab">
        <!--VIP订单-->
        <el-tab-pane label="VIP订单列表" name="first">
          <!--搜索栏-->
          <el-form :inline="true" ref="searchForm" label-width="90px" class="demo-form-inline">
              <el-form-item label="VIP标记号:">
                <el-select v-model="searchParams.vip_mark_id" placeholder="VIP标记号">
                  <el-option label="全部" value= 0 ></el-option>
                  <el-option
                      v-for="(item,index) in vipConfigList"
                      :key="item.mark_id"
                      :label="item.title +'/'+ item.vip_tag + '(' + getPlatform(item.platform) + ')'"
                      :value="item.mark_id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="支付方式:">
                <el-select v-model="searchParams.purchase_type" placeholder="支付方式">
                  <el-option label="全部" value= 0 ></el-option>
                  <el-option
                      v-for="(v,k) in purchaseTypeList"
                      :key="v.type"
                      :label="v.name"
                      :value="v.type">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="支付状态:">
                <el-select v-model="searchParams.purchase_status" placeholder="支付状态">
                  <el-option label="全部" value= -1 ></el-option>
                  <el-option
                      v-for="(v,k) in purchaseStatusList"
                      :key="v.type"
                      :label="v.name"
                      :value="v.type">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="支付时间:" class="ml-10">
                <div class="block">
                  <el-date-picker
                      v-model="searchParams.timeValue"
                      value-format="timestamp"
                      type="datetimerange"
                      align="right"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :default-time="['00:00:00', '23:59:59']">
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item prop="vip_num" class="ml-10">
                <el-input v-model="searchParams.vip_num" placeholder="VIP开通记录号"></el-input>
              </el-form-item>
              <el-form-item prop="transaction_id" class="ml-10">
                <el-input v-model="searchParams.transaction_id" placeholder="交易流水号"></el-input>
              </el-form-item>
              <el-form-item>
                  <el-button icon="el-icon-plus" type="primary" @click="getList()" :loading="isLoading">查询</el-button>
              </el-form-item>
              <el-form-item>
                <el-button icon="el-icon-folder-opened" type="primary" :loading="isLoading" @click="exportExcel">导出Excel</el-button>
              </el-form-item>
          </el-form>
          <div style="font-weight: bold;background-color: rgb(206 214 230);border-radius: 3px;font-family: 'Microsoft YaHei';padding: 0 5px 0;">
            共有<span style="color: red;padding: 0 5px;" id="purchase-user-num">{{purchase_user_num}}</span>位用户购买VIP。
            累积<span style="color: red;padding: 0 5px;" id="vip-order-num">{{vip_order_num}}</span>笔成功订单。
            总收款<span style="color: red;padding: 0 5px;" id="money">{{money.toFixed(2)}}</span>元
          </div>
          <!--表单信息-->
          <el-table :data="dataList" stripe style="width: 100%" row-key="id" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
              <el-table-column prop="id" label="ID" min-width="25%"> </el-table-column>
              <el-table-column prop="mark_id" label="VIP标记ID" min-width="30%"> </el-table-column>
              <el-table-column prop="vip_num" label="订单号" min-width="35%"> </el-table-column>
              <el-table-column prop="transaction_id" label="交易流水号" min-width="50%"> </el-table-column>
              <el-table-column prop="vip_title" label="VIP名称/标签" min-width="25%">
                <template slot-scope="scope">
                    {{scope.row.vip_title}}/{{scope.row.vip_tag}}
                </template>
              </el-table-column>
              <el-table-column prop="vip_price" label="原价/购买价" min-width="25%">
                <template slot-scope="scope">
                  {{scope.row.vip_price}}/{{scope.row.purchase_price}}
                </template>
              </el-table-column>
              <el-table-column prop="purchase_days" label="有效期(天)" min-width="20%">
                <template slot-scope="scope">
                  <a v-if="scope.row.purchase_days == 0">永久</a>
                  <a v-if="scope.row.purchase_days > 0">{{scope.row.purchase_days}}天</a>
                </template>
              </el-table-column>
              <el-table-column prop="purchase_type" label="支付方式" min-width="20%">
                <template slot-scope="scope">
                  <a v-if="scope.row.purchase_type == 1">微信</a>
                  <a v-if="scope.row.purchase_type == 2">支付宝</a>
                  <a v-if="scope.row.purchase_type == 3">IOS内购</a>
                </template>
              </el-table-column>
              <el-table-column prop="purchase_status" label="支付状态" min-width="15%">
                <template slot-scope="scope">
                  <a v-if="scope.row.purchase_status == 0">待支付</a>
                  <a v-if="scope.row.purchase_status == 1">已支付</a>
                </template>
              </el-table-column>
              <el-table-column prop="is_refund" label="是否退款" min-width="18%">
                <template slot-scope="scope">
                  <a v-if="scope.row.is_refund == 0">否</a>
                  <a v-if="scope.row.is_refund == 1">是</a>
                </template>
              </el-table-column>
              <el-table-column prop="create_time" label="创建时间" min-width="35%">
                <template slot-scope="scope">
                  {{scope.row.create_time|time}}
                </template>
              </el-table-column>
          </el-table>
          <!--翻页-->
          <el-pagination
                  background
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="searchParams.page"
                  :page-sizes="[10, 15, 20,50,100]"
                  :page-size="searchParams.size"
                  layout="total,sizes, prev, pager, next"
                  :total="searchParams.count">
          </el-pagination>

          <!--<div id="vipPurchaseRecordStatistics" :style="{width: '1000px', height: '650px'}"></div>-->
          </el-tab-pane>
          <!--活动礼包订单-->
          <el-tab-pane label="活动礼包订单" name="second">
            <!--搜索栏-->
            <el-form :inline="true" ref="searchActivityGiftBagOrderForm" label-width="90px" class="demo-form-inline">
              <el-form-item label="支付方式:">
                <el-select v-model="searchActivityGiftBagOrderParams.purchase_type" placeholder="支付方式">
                  <el-option label="全部" value= 0 ></el-option>
                  <el-option
                      v-for="(v,k) in activityGiftBagOrderPurchaseTypeList"
                      :key="v.type"
                      :label="v.name"
                      :value="v.type">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="支付状态:">
                <el-select v-model="searchActivityGiftBagOrderParams.purchase_status" placeholder="支付状态">
                  <el-option label="全部" value= '-5' ></el-option>
                  <el-option
                      v-for="(v,k) in activityGiftBagOrderPurchaseStatusList"
                      :key="v.type"
                      :label="v.name"
                      :value="v.type">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="支付时间:" class="ml-10">
                <div class="block">
                  <el-date-picker
                      v-model="searchActivityGiftBagOrderParams.timeValue"
                      value-format="timestamp"
                      type="datetimerange"
                      align="right"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :default-time="['00:00:00', '23:59:59']">
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item prop="order_num" class="ml-10">
                <el-input v-model="searchActivityGiftBagOrderParams.gift_bag_num" placeholder="礼包号"></el-input>
              </el-form-item>
              <el-form-item prop="order_num" class="ml-10">
                <el-input v-model="searchActivityGiftBagOrderParams.gift_bag_name" placeholder="礼包名称"></el-input>
              </el-form-item>
              <el-form-item prop="order_num" class="ml-10">
                <el-input v-model="searchActivityGiftBagOrderParams.order_num" placeholder="订单号"></el-input>
              </el-form-item>
              <el-form-item prop="transaction_id" class="ml-10">
                <el-input v-model="searchActivityGiftBagOrderParams.transaction_id" placeholder="交易流水号"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button icon="el-icon-plus" type="primary" @click="getActivityGiftBagOrderList()" :loading="isLoading">查询</el-button>
              </el-form-item>
              <el-form-item>
                <el-button icon="el-icon-folder-opened" type="primary" :loading="isLoading" @click="exportActivityGiftBagOrderExcel">导出Excel</el-button>
              </el-form-item>
            </el-form>
            <div style="font-weight: bold;background-color: rgb(206 214 230);border-radius: 3px;font-family: 'Microsoft YaHei';padding: 0 5px 0;">
              共有<span style="color: red;padding: 0 5px;" id="activity-gift-bag-purchase-user-num">{{activityGiftBagPurchaseUserNum}}</span>位用户购买礼包。
              累积<span style="color: red;padding: 0 5px;" id="activity-gift-bag-order-num">{{activityGiftBagOrderNum}}</span>笔成功订单。
              总收款<span style="color: red;padding: 0 5px;" id="activity-gift-bag-money">{{activityGiftBagMoney.toFixed(2)}}</span>元
            </div>
            <!--表单信息-->
            <el-table :data="activityGiftBagOrderDataList" stripe style="width: 100%" row-key="id" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
              <el-table-column prop="id" label="ID" min-width="25%"> </el-table-column>
              <el-table-column prop="gift_bag_num" label="礼包号" min-width="30%"> </el-table-column>
              <el-table-column prop="order_num" label="订单号" min-width="35%"> </el-table-column>
              <el-table-column prop="transaction_id" label="交易流水号" min-width="50%"> </el-table-column>
              <el-table-column prop="gift_bag_name" label="礼包名称" min-width="25%"></el-table-column>
              <el-table-column prop="purchase_price" label="购买价" min-width="25%"></el-table-column>
              <el-table-column prop="purchase_type" label="支付方式" min-width="20%">
                <template slot-scope="scope">
                  <a v-if="scope.row.purchase_type == 1">微信</a>
                  <a v-if="scope.row.purchase_type == 2">支付宝</a>
                  <a v-if="scope.row.purchase_type == 3">IOS内购</a>
                </template>
              </el-table-column>
              <el-table-column prop="purchase_status" label="支付状态" min-width="15%">
                <template slot-scope="scope">
                  <a v-if="scope.row.purchase_status == -1">已退款</a>
                  <a v-if="scope.row.purchase_status == 0">待支付</a>
                  <a v-if="scope.row.purchase_status == 1">已支付</a>
                </template>
              </el-table-column>
              <el-table-column prop="create_time" label="更新时间" min-width="35%">
                <template slot-scope="scope">
                  {{scope.row.update_time|time}}
                </template>
              </el-table-column>
              <el-table-column prop="create_time" label="创建时间" min-width="35%">
                <template slot-scope="scope">
                  {{scope.row.create_time|time}}
                </template>
              </el-table-column>
            </el-table>
            <!--翻页-->
            <el-pagination
                background
                @size-change="handleActivityGiftBagOrderSizeChange"
                @current-change="handleActivityGiftBagOrderCurrentChange"
                :current-page.sync="searchActivityGiftBagOrderParams.page"
                :page-sizes="[10, 15, 20,50,100]"
                :page-size="searchActivityGiftBagOrderParams.size"
                layout="total,sizes, prev, pager, next"
                :total="searchActivityGiftBagOrderParams.count">
            </el-pagination>
          </el-tab-pane>

      </el-tabs>
    </div>


</template>

<script>

    export default {
        name: "UserVipPurchaseRecordList",
        components: {},
        mounted() {
            this.getVipConfigList();
            this.getPurchaseTypeList();
            this.getPurchaseStatusList();
            this.getList();
            this.getActivityGiftBagOrderPurchaseStatusList();
            this.getActivityGiftBagOrderPurchaseTypeList();
            this.getActivityGiftBagOrderList();
        },
        data() {
            return {
                isLoading: false,
                activeName: 'first',
                //搜索传参
                searchParams: {
                    vip_mark_id: '0',
                    purchase_type: '0',
                    purchase_status: '-1',
                    timeValue: [],
                    vip_num: '',
                    transaction_id: '',
                    page: 1,
                    count: 0,
                    size: 10,
                },
                purchase_user_num:0,
                vip_order_num: 0,
                money: 0.00,
                //数据列表
                dataList: [],
                //VIP配置列表
                vipConfigList: [],
                //支付方式
                purchaseTypeList: [],
                //支付状态
                purchaseStatusList: [],
                //excel导出数据
                excelData: [],
              /****************礼包订单信息*********************/
                searchActivityGiftBagOrderParams: {
                  gift_bag_num: '',
                  gift_bag_name:'',
                  purchase_type: '0',
                  purchase_status: '-5',
                  timeValue: [],
                  order_num: '',
                  transaction_id: '',
                  page: 1,
                  count: 0,
                  size: 10,
                },
                //数据列表
                activityGiftBagOrderDataList: [],
                //支付方式
                activityGiftBagOrderPurchaseTypeList: [],
                //支付状态
                activityGiftBagOrderPurchaseStatusList: [],
                activityGiftBagPurchaseUserNum:0,
                activityGiftBagOrderNum:0,
                activityGiftBagMoney: 0.00,
            }
        },
        methods: {
            //切换栏目时触发
            changeTab(tab, event) {

            },
            //获取已有的VIP配置
            getVipConfigList() {
                this.isLoading = true;
                this.$api.journal.vip_config('List').then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.vipConfigList = result.data.list;
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                })
            },
            //获取支付方式
            getPurchaseTypeList() {
              this.isLoading = true;
              this.$api.journal.vip_purchase_record('PurchaseTypeList').then(result => {
                if (result.errCode) {
                  this.$message.error(result.message);
                } else {
                  this.purchaseTypeList = result.data;
                }
              }).catch(error => {
                this.$message.error(error);
              }).then(() => {
                this.isLoading = false;
              })
            },
            //获取支付状态
            getPurchaseStatusList() {
              this.isLoading = true;
              this.$api.journal.vip_purchase_record('PurchaseStatusList').then(result => {
                if (result.errCode) {
                  this.$message.error(result.message);
                } else {
                  this.purchaseStatusList = result.data;
                }
              }).catch(error => {
                this.$message.error(error);
              }).then(() => {
                this.isLoading = false;
              })
            },
            //获取购买记录
            getList() {
              this.isLoading = true;
              let params = this.$copy(this.searchParams);
              params.startTime = (this.searchParams.timeValue && this.searchParams.timeValue[0]) ? (this.searchParams.timeValue[0] / 1000) : '';
              params.endTime = (this.searchParams.timeValue && this.searchParams.timeValue[1]) ? (this.searchParams.timeValue[1] / 1000) : '';
              delete params.timeValue;
              this.$api.journal.vip_purchase_record('List', params).then(result => {
                if (result.errCode) {
                  this.$message.error(result.message);
                } else {
                  this.dataList = result.data.list;
                  this.purchase_user_num = result.data.purchase_user_num;
                  this.money = result.data.money;
                  this.vip_order_num = result.data.vip_order_num;
                  this.searchParams.count = result.data.total;//总条数
                }
              }).catch(error => {
                this.$message.error(error);
              }).then(() => {
                this.isLoading = false;
              })
            },
            exportExcel() {
              this.isLoading = true;
              this.$confirm('此操作将导出excel文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                let params = this.$copy(this.searchParams);
                params.startTime = this.searchParams.timeValue[0] ? (this.searchParams.timeValue[0] / 1000): '';
                params.endTime = this.searchParams.timeValue[1] ? (this.searchParams.timeValue[1] / 1000) : '';
                delete params.timeValue;
                this.$api.journal.vip_purchase_record('ExportRecord', params).then(result => {
                  if (result.errCode) {
                    this.$message.error(result.message);
                  }else{
                    require.ensure([], () => {
                      const { export_json_to_excel } = require('../../excel/Export2Excel.js')
                      const tHeader = ['VIP标记号','VIP购买订单号', '交易流水号', 'VIP名称/标签','原价','购买价','有效期','支付方式','支付状态','是否退款', '购买时间']; // 导出的表头名
                      const filterVal = ['mark_id','vip_num','transaction_id', 'vip_title','vip_price','purchase_price','purchase_days','purchase_type','purchase_status','is_refund','create_time']; // 导出的表头字段名
                      const list = result.data.list
                      const data = this.formatJson(filterVal, list)
                      export_json_to_excel
                      export_json_to_excel(tHeader, data, '用户VIP购买记录')
                    })
                  }
                }).catch(error => {
                  this.$message.error(error);
                }).then(() => {
                  this.isLoading = false;
                })
              }).catch(() => {
                this.isLoading = false;
              });
            },
            //分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getVipConfigList();
                this.getPurchaseTypeList();
                this.getPurchaseStatusList();
                this.getList();
            },

            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getVipConfigList();
                this.getPurchaseTypeList();
                this.getPurchaseStatusList();
                this.getList();
            },
          /**********************礼包订单*************************/
            //获取支付方式
            getActivityGiftBagOrderPurchaseTypeList() {
              this.isLoading = true;
              this.$api.journal.activity_gift_bag_purchase_record('PurchaseTypeList').then(result => {
                if (result.errCode) {
                  this.$message.error(result.message);
                } else {
                  this.activityGiftBagOrderPurchaseTypeList = result.data;
                }
              }).catch(error => {
                this.$message.error(error);
              }).then(() => {
                this.isLoading = false;
              })
            },
            //获取支付状态
            getActivityGiftBagOrderPurchaseStatusList() {
              this.isLoading = true;
              this.$api.journal.activity_gift_bag_purchase_record('PurchaseStatusList').then(result => {
                if (result.errCode) {
                  this.$message.error(result.message);
                } else {
                  this.activityGiftBagOrderPurchaseStatusList = result.data;
                }
              }).catch(error => {
                this.$message.error(error);
              }).then(() => {
                this.isLoading = false;
              })
            },
            getActivityGiftBagOrderList(){
              this.isLoading = true;
              let params = this.$copy(this.searchActivityGiftBagOrderParams);
              params.startTime = (this.searchActivityGiftBagOrderParams.timeValue && this.searchActivityGiftBagOrderParams.timeValue[0]) ? (this.searchActivityGiftBagOrderParams.timeValue[0] / 1000) : '';
              params.endTime = (this.searchActivityGiftBagOrderParams.timeValue && this.searchActivityGiftBagOrderParams.timeValue[1]) ? (this.searchActivityGiftBagOrderParams.timeValue[1] / 1000) : '';
              delete params.timeValue;
              this.$api.journal.activity_gift_bag_purchase_record('List', params).then(result => {
                if (result.errCode) {
                  this.$message.error(result.message);
                } else {
                  this.activityGiftBagOrderDataList = result.data.list;
                  this.activityGiftBagPurchaseUserNum = result.data.purchase_user_num;
                  this.activityGiftBagOrderNum = result.data.purchase_order_num;
                  this.activityGiftBagMoney = result.data.order_money;
                  this.searchActivityGiftBagOrderParams.count = result.data.total;//总条数
                }
              }).catch(error => {
                this.$message.error(error);
              }).then(() => {
                this.isLoading = false;
              })
            },
            exportActivityGiftBagOrderExcel() {
              this.isLoading = true;
              this.$confirm('此操作将导出excel文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                let params = this.$copy(this.searchActivityGiftBagOrderParams);
                params.startTime = this.searchActivityGiftBagOrderParams.timeValue[0] ? (this.searchActivityGiftBagOrderParams.timeValue[0] / 1000): '';
                params.endTime = this.searchActivityGiftBagOrderParams.timeValue[1] ? (this.searchActivityGiftBagOrderParams.timeValue[1] / 1000) : '';
                delete params.timeValue;
                this.$api.journal.activity_gift_bag_purchase_record('ExportRecord', params).then(result => {
                  if (result.errCode) {
                    this.$message.error(result.message);
                  }else{
                    require.ensure([], () => {
                      const { export_json_to_excel } = require('../../excel/Export2Excel.js')
                      const tHeader = ['订单号','交易流水号','礼包号','礼包名称','原价','购买价','支付方式','支付状态', '购买时间']; // 导出的表头名
                      const filterVal = ['order_num','transaction_id','gift_bag_num', 'gift_bag_name','gift_bag_price','purchase_price','purchase_type','purchase_status','create_time']; // 导出的表头字段名
                      const list = result.data.list
                      const data = this.formatJson(filterVal, list)
                      export_json_to_excel(tHeader, data, '用户购买礼包记录')
                    })
                  }
                }).catch(error => {
                  this.$message.error(error);
                }).then(() => {
                  this.isLoading = false;
                })
              }).catch(() => {
                this.isLoading = false;
              });
            },
            //分页
            handleActivityGiftBagOrderSizeChange(val) {
              this.searchActivityGiftBagOrderParams.size = val;
              //this.getActivityGiftBagOrderPurchaseTypeList();
              //this.getActivityGiftBagOrderPurchaseStatusList();
              this.getActivityGiftBagOrderList();
            },

            handleActivityGiftBagOrderCurrentChange(val) {
              this.searchActivityGiftBagOrderParams.page = val;
              //this.getActivityGiftBagOrderPurchaseTypeList();
              //this.getActivityGiftBagOrderPurchaseStatusList();
              this.getActivityGiftBagOrderList();
            },
          /**********************公共方法*************************/
            getPlatform(type) {
              if(1 == type){
                return '安卓';
              }
              return 'IOS';
            },
            formatJson(filterVal, jsonData) {
              return jsonData.map(v => filterVal.map(j => v[j]))
            },
        }
    }
</script>

