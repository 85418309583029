<template>
    <div>
        <!--搜索栏-->
        <el-form :inline="true" ref="searchForm" label-width="90px" class="demo-form-inline">
          <el-form-item prop="mobile" class="ml-10">
            <el-input v-model="searchParams.mobile" placeholder="用户手机号"></el-input>
          </el-form-item>
          <el-form-item prop="identity_num" class="ml-10">
            <el-input v-model="searchParams.identity_num" placeholder="用户身份号"></el-input>
          </el-form-item>
          <el-form-item prop="nick_name" class="ml-10">
            <el-input v-model="searchParams.nick_name" placeholder="用户昵称"></el-input>
          </el-form-item>
          <el-form-item prop="user_vip" class="ml-10" label="VIP用户:">
            <el-select v-model="searchParams.user_vip" placeholder="VIP用户">
              <el-option label="所有" value= -1 ></el-option>
              <el-option label="不是" value= 0 ></el-option>
              <el-option label="是" value= 1 ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="userList()" :loading="isLoading">查询</el-button>
          </el-form-item>
        </el-form>
        <!--表单信息-->
        <el-table :data="dataList" stripe style="width: 100%" row-key="id" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
            <el-table-column prop="id" label="ID" min-width="25%"> </el-table-column>
            <el-table-column prop="identity_num" label="身份号" min-width="35%"> </el-table-column>
            <el-table-column prop="nick_name" label="昵称" min-width="45%"></el-table-column>
            <el-table-column prop="avatar" label="头像" min-width="20%">
              <template slot-scope="scope"> <image-preview :file="scope.row.avatar"/> </template>
            </el-table-column>
            <el-table-column prop="user_sign" label="签名" min-width="45%">
              <template slot-scope="scope">
                {{ scope.row.user_sign && scope.row.user_sign.length > 10? scope.row.user_sign.slice(0, 10) + '...' : scope.row.user_sign}}
              </template>
            </el-table-column>
            <el-table-column prop="mobile" label="手机号" min-width="38%">
              <template slot-scope="scope">
                <a v-if="scope.row.mobile">{{scope.row.mobile}}</a>
                <a v-if="!scope.row.mobile">未绑定手机</a>
              </template>
            </el-table-column>
            <el-table-column prop="is_vip" label="VIP有效期" min-width="45%">
              <template slot-scope="scope">
                <a v-if="scope.row.is_vip == 0">否</a>
                <a v-if="scope.row.is_vip > 0 && scope.row.vip_time == 0">永久</a>
                <a v-if="scope.row.is_vip > 0 && scope.row.vip_time > 0">{{scope.row.vip_time|time}}</a>
              </template>
            </el-table-column>
            <el-table-column prop="pot" label="蜜罐" min-width="25%"></el-table-column>
            <el-table-column prop="honey" label="蜂蜜" min-width="25%"></el-table-column>
<!--            <el-table-column prop="email" label="邮箱" min-width="35%"></el-table-column>-->
            <el-table-column prop="gender" label="性别" min-width="20%">
              <template slot-scope="scope">
                <a v-if="scope.row.gender">{{scope.row.gender}}</a>
                <a v-if="!scope.row.gender">---</a>
              </template>
            </el-table-column>
            <el-table-column prop="province" label="国家/省份/城市" min-width="25%">
              <template slot-scope="scope">
                <a v-if="scope.row.country && scope.row.province && scope.row.city">{{scope.row.country}}/{{scope.row.province}}/{{scope.row.city}}</a>
                <a v-if="!scope.row.country && !scope.row.province && !scope.row.city">---</a>
              </template>
            </el-table-column>
            <el-table-column prop="create_time" label="最近编辑手账" min-width="55%">
              <template slot-scope="scope">{{scope.row.last_record_journal|time}}</template>
            </el-table-column>
            <el-table-column prop="create_time" label="注册时间" min-width="55%">
              <template slot-scope="scope">{{scope.row.create_time|time}}</template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="showDialog(scope.row.id)">编辑</el-button>
                <el-button size="mini" type="primary" @click="showDisableRecordDialog(scope.row.id)">封禁记录</el-button>
                <el-button size="mini" type="warning" @click="showDisableDialog(scope.row.id)">封禁</el-button>
              </template>
            </el-table-column>
        </el-table>
        <!--翻页-->
        <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="searchParams.page"
                :page-sizes="[10, 15, 20,50,100]"
                :page-size="searchParams.size"
                layout="total,sizes, prev, pager, next"
                :total="searchParams.count">
        </el-pagination>
        <!--弹窗-->
        <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="50%">
            <el-form :model="userInfo.form" label-width="80px">
              <el-input v-model="userInfo.form.id" type="hidden" clearable></el-input>
              <el-form-item label="手机号" required="">
                <el-col :span="8">
                  <el-input v-model="userInfo.form.mobile"></el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="昵称" required="">
                <el-col :span="8">
                  <el-input v-model="userInfo.form.nick_name"></el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="头像" required="">
                <el-upload
                    action="/common/upload/multipart/"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemovePic"
                    :on-success="handlePictureSuccessPic"
                    :limit="1" :file-list="imageList" >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog  append-to-body :visible.sync="dialogVisibleMessage">
                  <img width="100%" :src="dialogImageUrlMessage" alt="">
                </el-dialog>
              </el-form-item>
              <el-form-item label="蜂蜜" required="">
                <el-col :span="8">
                  <el-input v-model="userInfo.form.honey"></el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="蜜罐" required="">
                <el-col :span="8">
                  <el-input v-model="userInfo.form.pot"></el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="签名" required="">
                <el-col :span="8">
                  <el-input v-model="userInfo.form.user_sign"></el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="VIP" required="">
                <el-radio-group v-model="userInfo.form.is_vip" @change="setSelect()">
                  <el-radio :label=0>不是</el-radio>
                  <el-radio :label=1>是</el-radio>
                </el-radio-group>
                <a style="margin-left: 15px;" v-if="tempInfo.is_vip > 0 && tempInfo.vip_time === 0">&nbsp;VIP有效期：永久</a>
                <a style="margin-left: 15px;" v-if="tempInfo.is_vip > 0 && tempInfo.vip_time > 0">&nbsp;VIP有效期：{{userInfo.form.vip_time|time}}</a>
              </el-form-item>
              <el-form-item v-show="selectStatus" label="VIP有效期">
                <el-select v-model="vip_id" placeholder="请选择VIP">
                  <el-option label="请选择" value=0 ></el-option>
                  <el-option v-for="item in vipDataList" :key="item.id" :label="setTitle(item.title,item.vip_tag,item.vip_valid)" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="邮箱">
                <el-col :span="8">
                  <el-input v-model="userInfo.form.email"></el-input>
                </el-col>
              </el-form-item>
                <!--输入框-->
                <el-form-item>
                    <el-button type="primary"  @click="submitUser()">保存</el-button>
                    <el-button  @click="cancelSubmitUser()">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <!--封禁用户弹窗-->
        <el-dialog :title="disableDialog" :visible.sync="disableDialogVisible" :close-on-click-modal="false" width="50%">
          <el-form :model="reasonForm">
            <el-form-item label="封禁理由：">
              <el-select v-model="reasonForm.reason_id" placeholder="请选择">
                <el-option
                    v-for="item in disableReasonList"
                    :key="item.id"
                    :label="item.report_content"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="封禁时间：">
              <el-input v-model="reasonForm.day" style="width: 50%" placeholder="请输入内容,永久为-1否则为具体天数"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary"  @click="disableUser()">保存</el-button>
              <el-button  @click="cancelDisableUserDialog()">取消</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
        <!--封禁记录弹窗-->
        <el-dialog :title="disableRecord" :visible.sync="disableRecordDialog" :close-on-click-modal="false" width="50%">
          <el-table :data="disableRecordList">
            <el-table-column prop="id" label="ID" min-width="25%"> </el-table-column>
            <el-table-column prop="reason" label="理由" min-width="35%"> </el-table-column>
            <el-table-column prop="disable_time" label="有效时间" min-width="45%">
              <template slot-scope="scope">
                <a v-if="scope.row.disable_time == -1">永久</a>
                <a v-if="scope.row.disable_time > 0">{{scope.row.disable_time|time}}</a>
              </template>
            </el-table-column>
            <el-table-column prop="create_time" label="封禁时间" min-width="45%">
              <template slot-scope="scope"><a>{{scope.row.create_time|time}}</a></template>
            </el-table-column>
          </el-table>
        </el-dialog>
    </div>


</template>

<script>
    import ImagePreview from '../../components/ImagePreview'

    export default {
        name: "UserList",
        components: {
          ImagePreview
        },
        mounted() {
            this.userList();
        },
        data() {
            return {
              isLoading: false,
              //搜索传参
              searchParams: {
                  mobile: '',
                  identity_num: '',
                  nick_name: '',
                  user_vip: '-1',
                  page: 1,
                  count: 0,
                  size: 10,
              },
              //弹窗表单
              dialogTitle:'创建VIP配置记录',
              disableDialog: '封禁用户',
              disableRecord: '封禁记录',
              dialogFormVisible:false,
              disableDialogVisible:false,
              disableRecordDialog: false,
              userInfo:{
                  form:{
                      id : 0,
                      nick_name : '',
                      avatar: '',
                      user_sign: '',
                      mobile : '',
                      is_vip : 0,
                      vip_time : 0,
                      email : '',
                      pot: 0,
                      honey : 0,
                  }
              },
              tempInfo:{
                is_vip: 0,
                vip_time: 0
              },
              //选择的VIPid
              vip_id: '0',
              //版本列表
              dataList: [], //数据列表
              imageList: [],
              //vip配置信息
              vipDataList: [],
              //封禁理由列表
              disableReasonList : [],
              //封禁记录列表
              disableRecordList: [],
              reasonForm:{
                user_id:'',
                reason_id: '',
                day: '1',
              },
              //封禁理由

              //图片上传相关
              dialogImageUrlMessage:'',
              dialogVisibleMessage: false, //放大镜属性
              selectStatus: false,
            }
        },
        methods: {
          //获取用户列表
          userList() {
            this.isLoading = true;
            this.$api.journal.user('List', this.$copy(this.searchParams)).then(result => {
                if (result.errCode) {
                    this.$message.error(result.message);
                } else {
                    this.dataList = result.data.list;
                    this.searchParams.count = result.data.total;//总条数
                }
            }).catch(error => {
                this.$message.error(error);
            }).then(() => {
                this.isLoading = false;
            })
          },
          //取消提交
          cancelSubmitUser(){
              this.dialogFormVisible = false;
          },
          //编辑
          showDialog(id){
              this.$message.confirm({message: "修改数据时请仔细确认修改是否正确"}, () =>{
                  this.$api.journal.user('Details', {id:id}).then(result => {
                    if(result.errCode){
                      this.$message.error(result.message);
                    }else {
                      this.dialogTitle = '编辑用户信息';
                      this.dialogFormVisible = true;
                      this.userInfo.form = result.data;
                      this.tempInfo.is_vip = result.data.is_vip;
                      this.tempInfo.vip_time = result.data.vip_time;
                      this.selectStatus = result.data.is_vip > 0;
                      this.dialogImageUrlMessage = this.userInfo.form.avatar;
                      let obj = new Object();
                      obj.url = this.dialogImageUrlMessage;
                      this.imageList = [];
                      this.imageList.push(obj);
                      this.getVipList();
                      this.vip_id = '0';
                    }
                  }).catch(error => {
                    this.dialogFormVisible = false;
                    this.$message.error(error);
                  }).then(() => {});
              });
          },
          submitUser(){
            this.$message.confirm({message: "是否确定修改用户信息"}, () =>{
                this.isLoading = true;
                let data = this.$copy(this.userInfo.form);
                data.vip_id = this.vip_id;
                this.$api.journal.user("SaveUser",data,"post").then(result => {
                  if(result.errCode){
                    this.$message.error(result.message);
                  }else{
                    this.dialogFormVisible = false;
                    this.userList();
                  }
                }).catch(error => {
                  this.$message.error(error);
                }).then(() =>{
                  this.isLoading = false;
                });
            });

          },
          getVipList(){
            this.isLoading = true;
            this.$api.journal.vip_config('List').then(result => {
              if(result.errCode){
                this.$message.error(result.message);
              }else {
                this.vipDataList = result.data.list;
                this.$forceUpdate()
              }
            }).catch(error => {
              this.$message.error(error);
            }).then(() => {
              this.isLoading = false;
            });
          },
          getDisableReasonList(){
            this.isLoading = true;
            this.$api.journal.user('GetReportConfigList').then(result => {
              if(result.errCode){
                this.$message.error(result.message);
              }else {
                this.disableReasonList = result.data.list;
                this.$forceUpdate()
              }
            }).catch(error => {
              this.$message.error(error);
            }).then(() => {
              this.isLoading = false;
            });
          },
          showDisableDialog(id){
            this.disableDialogVisible = true;
            if(this.disableReasonList.length < 1){
              this.getDisableReasonList();
            }
            this.reasonForm.user_id = id;
          },
          disableUser(){
            this.$message.confirm({message: "是否确定封禁用户"}, ()=>{
              this.$api.journal.user_report_record("DisableUser",this.$copy(this.reasonForm), "post").then(result=>{
                if(result.errCode){
                  this.$message.error(result.message);
                }else {
                  this.disableDialogVisible = false;
                }
              }).catch(error => {
                this.$message.error(error);
              }).then(() =>{
                this.isLoading = false;
              });
            });
          },
          cancelDisableUserDialog(){
            this.disableDialogVisible = false;
          },
          showDisableRecordDialog(id){
            this.$api.journal.user_disable_record("getListByUserId", {user_id:id}).then(result=>{
              this.disableRecordList = result.data.list;
            })
            this.disableRecordDialog = true;
          },
          //头像图片处理的具体方式
          handleRemovePic() {
            this.userInfo.form.avatar = '';
          },
          //放大镜
          handlePictureCardPreview(file) {
            this.dialogImageUrlMessage = file.url;
            this.dialogVisibleMessage = true;
          },
          //上传成功
          handlePictureSuccessPic(result){
            if(result.errCode){
              this.$message.error(result.message);
            }
            //成功的情况进行图片存储
            this.userInfo.form.avatar = result.data.object_url;
          },
          //分页
          handleSizeChange(val) {
              this.searchParams.size = val;
              this.userList();
          },

          handleCurrentChange(val) {
              this.searchParams.page = val;
              this.userList();
          },
          setTitle(title,tag,valid){
            let str = title + '('+tag+')';
            if(valid == 0){
              str = str +'/'+ '永久'
            }else {
              str = str +'/'+ valid +'天'
            }
            return str;
          },
          setSelect(){
            if(this.userInfo.form.is_vip){
              this.vip_id = '0';
              this.selectStatus = true;
            }else {
              this.selectStatus = false;
            }
          }
        }
    }
</script>

