<template>
    <div>
        <!--搜索栏-->
        <el-form :inline="true" :model="searchParams" ref="searchParams" class="demo-form-inline">
            <el-form-item label="" prop="keywords">
                <el-input v-model="searchParams.keyword" placeholder="商户名称/ID"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" :loading="isLoading" @click="getList()">查询
                </el-button>
                <el-button icon="el-icon-plus" type="primary" @click="add()">添加插件</el-button>
            </el-form-item>
        </el-form>
        <el-table
                :data="dataList"
                stripe
                style="width: 100%">
            <el-table-column
                    prop="id"
                    label="ID">
            </el-table-column>
            <el-table-column
                    prop="mch_id"
                    label="绑定商户">
                <template slot-scope="{row}">
                    {{ row.mch_id || '--' }}
                </template>
            </el-table-column>
            <el-table-column
                    prop="name"
                    label="插件名称">
            </el-table-column>
            <el-table-column
                    prop="type"
                    label="插件类型">
            </el-table-column>
            <el-table-column
                    prop="script"
                    label="脚本名称">
                <template slot-scope="{row}">
                    {{ row.script || '--' }}
                </template>
            </el-table-column>
            <el-table-column
                    prop="keyword"
                    label="关键字">
                <template slot-scope="{row}">
                    {{ row.keyword || '--' }}
                </template>
            </el-table-column>
            <el-table-column
                    prop="trigger_count"
                    label="触发次数">
                <template slot-scope="{row}">
                    {{row.trigger_count}}
                </template>
            </el-table-column>

            <el-table-column
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="text" class="c-blue" @click="edit(scope.$index)"
                               icon="el-icon-edit-outline">编辑
                    </el-button>
                    <el-button type="text" class="c-red" @click="remove(scope.$index)"
                               icon="el-icon-delete">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--翻页-->
        <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="searchParams.page"
                :page-sizes="[10, 15, 20,50,100]"
                :page-size="searchParams.size"
                layout="total,sizes, prev, pager, next"
                :total="searchParams.count">
        </el-pagination>
        <!--表单弹框-->
        <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="40%">
            <el-form :model="form" :rules="formRules" status-icon ref="form" label-width="120px" class="">
                <el-form-item label="插件名称：" prop="name">
                    <el-col :span="15">
                        <el-input type="text" placeholder="插件名称" v-model="form.name"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="插件图标：" prop="icon">
                    <image-uploader v-model="form.icon" dt="url"/>
                </el-form-item>
                <el-form-item label="绑定商户：" prop="mch_id">
                    <el-col :span="15">
                        <el-input type="text" placeholder="不绑定填写0" v-model="form.mch_id"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="插件简介：" prop="describe">
                    <el-col :span="20">
                        <el-input type="textarea" :rows="3" placeholder="插件简介" v-model="form.describe"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="插件类型：" prop="type">
                    <el-radio-group v-model="form.type">
                        <el-radio label="1">脚本</el-radio>
                        <el-radio label="2">链接</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="脚本名称：" prop="script" v-if="form.type==1">
                    <template slot="label">
                        <el-popover
                                placement="top-start"
                                title="说明"
                                width="200"
                                trigger="hover"
                                content="填写位于 App\Wx\Plugin 目录下的插件脚本名称">
                            <span slot="reference">脚本名称：</span>
                        </el-popover>
                    </template>
                    <el-col :span="15">
                        <el-input type="text" placeholder="位于 App/Wx/Plugin/ 下的脚本名称" v-model="form.script"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="封面图片：" prop="image" v-if="form.type==2">
                    <image-uploader v-model="form.image" dt="url"/>
                </el-form-item>
                <el-form-item label="跳转链接：" prop="url" v-if="form.type==2">
                    <el-input type="text" placeholder="跳转的链接地址" v-model="form.url"></el-input>
                </el-form-item>
                <el-form-item label="触发关键字：">
                    <el-col :span="15">
                        <el-input type="text" placeholder="触发插件的关键字,可以留空" v-model="form.keyword"></el-input>
                    </el-col>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="save()" :loading="isLoading">保 存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import ImageUploader from '../../components/ImageUploader.vue'
    import ImagePreview from '../../components/ImagePreview.vue'

    export default {
        name: "WxPluginManage",
        components: {
            ImagePreview, ImageUploader
        },
        mounted() {
            this.getList()
        },
        data() {
            return {
                isLoading: false,
                dialogFormVisible: false,
                form: {},
                dialogTitle: '添加插件',
                formRules: {
                    name: {required: true, message: '请输入插件名称', trigger: 'blur'},
                    type: {required: true, message: '请选择插件类型', trigger: 'blur'},
                },
                groupList: [],
                searchParams: {
                    keyword: '',
                    page: 1,
                    count: 1,
                    size: 10,
                },
                dataList: []
            }
        },
        methods: {
            onIconChanged(file) {
                this.form.icon = file
            },
            onImageChanged(file) {
                this.form.image = file
            },
            //创建分组
            add() {
                this.dialogTitle = '添加插件';
                this.form = {
                    type: '1',
                    mch_id: 0
                };
                this.dialogFormVisible = true;
            },
            edit(index) {
                this.dialogTitle = '修改插件';
                this.form = this.dataList[index];
                this.dialogFormVisible = true;
            },
            remove(index) {
                let item = this.dataList[index]
                this.$message.confirm({message: '确定要删除吗?'}, () => {
                    this.$loading.show('删除中')
                    this.$api.wx.pluginManage('Delete', {id: item.id}, 'post').then(result => {
                        if (result.errCode) {
                            this.$message.alert(result.message)
                        } else {
                            this.getList()
                        }
                    }).catch(error => {
                        this.$message.error(error)
                    }).finally(() => {
                        this.$loading.close()
                    })
                })
            },
            save() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.isLoading = true;
                        this.$api.wx.pluginManage('Save', this.$copy(this.form), 'post').then(result => {
                            if (result.errCode) {
                                this.$message.alert(result.message)
                            } else {
                                this.dialogFormVisible = false;
                                this.$message.success('保存成功');
                                this.getList()
                            }
                        }).catch(error => {
                            this.$message.error(error)
                        }).finally(() => {
                            this.isLoading = false
                        })
                    } else {
                        return false;
                    }
                });
            },
            //获取员工列表
            getList() {
                this.isLoading = true
                this.$api.wx.pluginManage('list', this.searchParams).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message)
                    } else {
                        this.dataList = result.data.list
                        this.searchParams.count = result.data.total
                    }
                }).catch(error => {
                    this.$message.error(error)
                }).finally(() => {
                    this.isLoading = false
                });
            },
            //获取分组列表
            getGroups() {
                this.isLoading = true
                this.$api.system.getGroups().then(result => {
                    this.isLoading = false;
                    if (result.errCode) {
                        this.$message.alert('加载权限分组失败:' + result.message)
                    } else {
                        this.groupList = result.data
                    }
                }).catch(error => {
                    this.$message.error(error)
                }).finally(() => {
                    this.isLoading = false
                })
            },
            getGroupName(id) {
                let name = ''
                this.groupList.forEach(function (item) {
                    if (item.uc_id == id) {
                        name = item.uc_name
                    }
                })
                return name
            },
            // 点击分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getList();

            },
        }

    }
</script>