<template>
    <div>
        <!--搜索栏-->
        <el-form :inline="true" :model="searchParams" ref="searchForm" label-width="90px" class="demo-form-inline">
            <el-form-item prop="identity_num" class="ml-10">
              <el-input v-model="searchParams.identity_num" placeholder="用户身份号"></el-input>
            </el-form-item>
            <el-form-item prop="nick_name" class="ml-10">
              <el-input v-model="searchParams.nick_name" placeholder="用户昵称"></el-input>
            </el-form-item>
            <el-form-item prop="keyword" class="ml-10">
                <el-input v-model="searchParams.keyword" placeholder="评论/回复关键字"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" @click="getList()" :loading="isLoading">查询</el-button>
            </el-form-item>
        </el-form>
        <!--表单信息-->
        <el-table
                :data="dataList"
                stripe
                style="width: 100%"
                row-key="id">
            <el-table-column prop="id" label="ID"></el-table-column>
            <el-table-column prop="nick_name" label="回复/评论用户"> </el-table-column>
            <el-table-column prop="be_nick_name" label="被回复/评论用户"> </el-table-column>
            <el-table-column prop="comment_text" label="回复/评论内容"> </el-table-column>
            <el-table-column prop="is_read" label="查阅状态">
                <template slot-scope="scope">
                    <a v-if="scope.row.is_read == 0">未读</a>
                    <a v-if="scope.row.is_read == 1">已读</a>
                </template>
            </el-table-column>
            <el-table-column prop="comment_status" label="回复/评论状态">
              <template slot-scope="scope">
                <a v-if="scope.row.comment_status == 0">禁用</a>
                <a v-if="scope.row.comment_status == 1">正常</a>
              </template>
            </el-table-column>

            <el-table-column
                    prop="created"
                    label="评论/回复时间">
                <template slot-scope="scope">
                    {{scope.row.create_time|time}}
                </template>
            </el-table-column>

            <el-table-column
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="text" class="c-blue" @click="setStatus(scope.row.id,scope.row.comment_status)" icon="el-icon-help">
                        <a v-if="scope.row.comment_status == 0">开启</a>
                        <a v-if="scope.row.comment_status == 1">禁用</a>
                    </el-button>
<!--                    <el-button type="text" class="c-blue" @click="deleteAdvertConfig(scope.row.id)" icon="el-icon-edit-outline">删除</el-button>-->
                </template>
            </el-table-column>
        </el-table>
        <!--翻页-->
        <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="searchParams.page"
                :page-sizes="[10, 15, 20,50,100]"
                :page-size="searchParams.size"
                layout="total,sizes, prev, pager, next"
                :total="searchParams.count">
        </el-pagination>

    </div>


</template>

<script>

    export default {
        name: "JournalUserTextCommentList",
        mounted() {
            this.getList();
        },
        data() {
            return {
                isLoading: false,
                //搜索传参
                searchParams: {
                    identity_num : '',
                    nick_name: '',
                    keyword: '',
                    page: 1,
                    count: 0,
                    size: 10,
                },
                //列表数据
                dataList: [],
            }
        },
        methods: {
            //获取评论/回复列表
            getList() {
                this.isLoading = true;
                this.$api.journal.user_text_comment('List', this.$copy(this.searchParams)).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.dataList = result.data.list;
                        this.searchParams.count = result.data.total;//总条数
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                })
            },
            //快捷设置状态
            setStatus(id,status){
              let msg = '确定要禁该条数据吗?如果被禁用,则该内容为不可见。';
              if(status == 0){
                msg = "是否修改评论或回复状态为正常";
              }
              this.$message.confirm({message: msg}, ()=>{
                this.$loading.show('修改状态中');
                this.$api.journal.user_text_comment('SetStatus', {id:id},'post').then(result => {
                  if (result.errCode) {
                    this.$message.error(result.message);
                  } else {
                    this.$message.success('修改成功');
                    this.getList();
                  }
                }).catch(error => {
                  this.$message.error(error);
                }).then(() => {
                  this.isLoading = false;
                  this.$loading.close();
                });
              });
            },

            //分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getList();
            },

            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getList();
            },

        }
    }
</script>

