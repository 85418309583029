<template>
    <!--基本信息-->
    <div>
        <el-dialog title="编辑客户信息" :visible.sync="dialogFormVisible" :close-on-click-modal="false" @close="closeDialog" width="30%">
            <el-form class="follow-form" ref="customer" :model="customer" label-width="110px">
                <el-form-item label="客户姓名">
                    <el-input v-model="customer.id_name" placeholder="请输入微信昵称"></el-input>
                </el-form-item>
                <el-form-item label="联系电话">
                    <el-input v-model="customer.mobile" placeholder="请输入微信昵称"></el-input>
                </el-form-item>
                <el-form-item label="微信昵称">
                    <el-input v-model="customer.wx_nickname" placeholder="请输入微信昵称"></el-input>
                </el-form-item>
                <el-form-item label="微信号">
                    <el-input  v-model="customer.wx_number" placeholder="请输入微信号"></el-input>
                </el-form-item>
                <el-form-item label="QQ号">
                    <el-input v-model="customer.qq_number" placeholder="请输入QQ号"></el-input>
                </el-form-item>
                <el-form-item label="地址">
                    <el-input v-model="customer.address" placeholder="请输入地址"></el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer txt-center">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="onSubmit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "FormBase",
        props: { //接受父组件传递来的数据
            customer: {
                type: Object
            }
        },
        mounted() {
        },
        data(){
            return{
                dialogFormVisible:true,
            }
        },
        methods:{
            onSubmit(){
                this.$loading.show('保存中');
                this.customer.action = 'edit_baseinfo';
                this.$api.ciCustomer.save(this.customer).then(result => {
                    if (result.errCode) {
                        this.$message.alert(result.message)
                    } else {
                        this.dialogFormVisible = false;
                        this.$emit('submitBaseInfo', this.dialogFormVisible)
                    }
                }).catch(error => {
                    this.$message.error(error)
                }).finally(() => {
                    this.$loading.close()
                })
                //this.closeDialog();
            },
            closeDialog(){
                this.dialogFormVisible = false;
                this.$emit('closeTransferBase',this.dialogFormVisible)
            }
        }
    }
</script>

<style scoped>

</style>