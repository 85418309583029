<template>
    <el-tabs v-model="activeName" type="border-card">
        <el-tab-pane label="错误日志" name="first">
            <div class="block">
                <el-form :inline="true" :model="searchParams" ref="searchParams" class="demo-form-inline">
                    <el-form-item label="" prop="keywords">
                        <el-input v-model="searchParams.keyword" placeholder="文件名称"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button icon="el-icon-refresh" type="primary" :loading="isLoading" @click="getErrLogs">刷新
                        </el-button>
                        <el-button icon="el-icon-delete" type="warning" @click="clear()" v-if="errorLogs.length">
                            清空({{errorLogs.length}})
                        </el-button>
                    </el-form-item>
                </el-form>
                <el-alert
                        title="暂无数据"
                        type="info"
                        v-if="!errorLogs.length"
                        :closable="false">
                </el-alert>
                <el-timeline v-if="errorLogs.length">
                    <el-timeline-item placement="top" v-for="(item,index) in searchErrors" :timestamp="item.time"
                                      :key="index">
                        <el-card>
                            <h4>IP:{{item.ip||'--'}} File:{{item.file||'--'}}:{{item.line||'--'}}</h4>
                            <p style="margin-top: 5px;word-break: break-all;">{{item.error}}</p>
                        </el-card>
                    </el-timeline-item>
                </el-timeline>
            </div>
        </el-tab-pane>
        <el-tab-pane label="访问日志" name="second">
            <div class="block">
                <el-form :inline="true" :model="searchParams" ref="searchParams" class="demo-form-inline">
                    <el-form-item label="" prop="keywords">
                        <el-input v-model="searchParams.keyword" placeholder="文件名称"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button icon="el-icon-refresh" type="primary" :loading="isLoading" @click="getAccessLogs">刷新
                        </el-button>
                        <el-button icon="el-icon-delete" type="warning" @click="clearAccess()" v-if="accessLogs.length">
                            清空({{accessLogs.length}})
                        </el-button>
                    </el-form-item>
                </el-form>
                <el-alert
                        title="暂无数据"
                        type="info"
                        v-if="!accessLogs.length"
                        :closable="false">
                </el-alert>
                <el-timeline v-if="accessLogs.length">
                    <el-timeline-item placement="top" v-for="(item,index) in searchAccessLogs" :timestamp="item.time"
                                      :key="index">
                        <el-card>
                            <h4>IP:{{item.ip||'--'}} File:{{item.file||'--'}}:{{item.line||'--'}}</h4>
                            <p style="margin-top: 5px;word-break: break-all;">{{item.message}}</p>
                        </el-card>
                    </el-timeline-item>
                </el-timeline>
            </div>
        </el-tab-pane>
    </el-tabs>

</template>

<script>
    export default {
        name: "Logs",
        data() {
            return {
                isLoading: false,
                activeName: 'first',
                searchParams: {
                    keyword: ''
                },
                errorLogs: [],
                accessLogs: []
            }
        },
        mounted() {
            this.getErrLogs()
            this.getAccessLogs()
        },
        computed: {
            searchErrors() {
                let keyword = this.searchParams.keyword.toLowerCase()
                if (!keyword) {
                    return this.errorLogs
                } else {
                    return this.errorLogs.filter(item => {
                        //以name来搜索
                        let str = item.file.toLowerCase()
                        return str.indexOf(keyword) !== -1
                    })
                }

            },
            searchAccessLogs() {
                let keyword = this.searchParams.keyword.toLowerCase()
                if (!keyword) {
                    return this.accessLogs
                } else {
                    return this.accessLogs.filter(item => {
                        //以name来搜索
                        let str = item.file.toLowerCase()
                        return str.indexOf(keyword) !== -1
                    })
                }

            }
        },
        methods: {
            clear() {
                this.$message.confirm({message: '确定要清空错误日志吗'}, () => {
                    this.isLoading = true
                    this.$api.system.config('ClearErrorLogs').then(result => {
                        if (result.errCode) {
                            this.$message.alert(result.message)
                        } else {
                            this.$message.success('日志清理完成');
                            this.getErrLogs()
                        }
                    }).catch(error => {
                        this.$message.error(error)
                    }).finally(() => {
                        this.isLoading = false
                    })
                })
            },
            clearAccess() {
                this.$message.confirm({message: '确定要清空访问日志吗'}, () => {
                    this.isLoading = true
                    this.$api.system.config('ClearAccessLogs').then(result => {
                        if (result.errCode) {
                            this.$message.alert(result.message)
                        } else {
                            this.$message.success('日志清理完成');
                            this.getAccessLogs()
                        }
                    }).catch(error => {
                        this.$message.error(error)
                    }).finally(() => {
                        this.isLoading = false
                    })
                })
            },
            getErrLogs() {
                this.$loading.show('数据加载中')
                this.$api.system.config('ErrorLogs', {}).then(result => {
                    if (!result.errCode) {
                        this.errorLogs = result.data
                    }
                }).catch(error => {
                    this.$message.error(error)
                }).finally(() => {
                    this.$loading.close()
                })
            },
            getAccessLogs() {
                this.$loading.show('数据加载中')
                this.$api.system.config('AccessLogs', {}).then(result => {
                    if (!result.errCode) {
                        this.accessLogs = result.data
                    }
                }).catch(error => {
                    this.$message.error(error)
                }).finally(() => {
                    this.$loading.close()
                })
            }
        }
    }
</script>

<style scoped>

</style>