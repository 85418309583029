<template>
    <div :id="echarts" class="echarts-container"></div>
</template>

<script>
    import echarts from 'echarts'

    export default {
        name: "ChartBars",
        props: { //接受父组件传递来的数据
            id: {
                type: String
            },
            items: {
                type: Object,
                legend: {},
                tooltip: {},
                dataset: {
                    source: [
                        ['product', '2015', '2016', '2017'],
                        ['Matcha Latte', 43.3, 85.8, 93.7],
                        ['Milk Tea', 83.1, 73.4, 55.1],
                        ['Cheese Cocoa', 86.4, 65.2, 82.5],
                        ['Walnut Brownie', 72.4, 53.9, 39.1]
                    ]
                },
                xAxis: {type: 'category'},
                yAxis: {},
                series: [
                    {type: 'bar'},
                    {type: 'bar'},
                    {type: 'bar'}
                ]
            }
        },
        data() {
            return {
                chart: ''
            }
        },
        watch: {
            items: {
                immediate: true,
                deep: true,
                handler(newVal) {

                    console.log(newVal);
                    console.log(this.chart);
                    if (this.chart) {
                        console.log('111111111');
                        this.chart.hideLoading();
                        if (newVal) {
                            let option = {
                                legend: {
                                    data: newVal.legend.data
                                },
                                dataset: {
                                    source: newVal.dataset.source
                                },
                                series: newVal.series

                            }
                            this.chart.setOption(option);
                        }
                    }
                }
            }
        },
        computed: {
            echarts() {
                return 'echarts_bar_' + Math.random() * 100000
            }
        },
        mounted() {
            const echarts = require('echarts');
            const option = {
                color: ['#3aa1ff', '#4ecb73', '#face1e', '#2b3a79', '#8a4be1'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {type: 'category'},
                yAxis: {},
                series: [
                    {type: 'bar'},
                    {type: 'bar'},
                    {type: 'bar'}
                ],
                dataset: {
                    source: this.items.dataset.source
                },
            };
            this.chart = echarts.init(document.getElementById(this.echarts));
            this.chart.showLoading();
            this.chart.setOption(option);

        }
    }
</script>

<style scoped>
    .echarts-container {
        width: auto;
    }
</style>