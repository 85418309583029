<template>
    <!--写跟进-->
    <div>
        <el-dialog title="写跟进" :visible.sync="dialogFormVisible" :close-on-click-modal="false" @close="closeDialog">
            <el-form class="follow-form" ref="form" :rules="formRules" :model="form" label-width="110px">
                <!--跟进信息-->
                <h4><i class="el-icon-collection-tag mr-5"></i>跟进信息</h4>
                <el-row class="mt-20">
                    <el-col :span="12">
                        <el-form-item label="跟进方式" required="">
                            <el-select v-model="form.contact_type" placeholder="请选择跟进方式">
                                <el-option label="电话" value="电话"></el-option>
                                <el-option label="微信" value="微信"></el-option>
                                <el-option label="QQ" value="QQ"></el-option>
                                <el-option label="面访" value="面谈"></el-option>
                                <el-option label="其它" value="其它"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="跟进状态" required="">
                            <el-select v-model="form.contact_step" placeholder="请选择跟进状态">
                                <el-option label="已建档" value="1"></el-option>
                                <el-option label="联系中" value="2"></el-option>
                                <el-option label="签约中" value="3"></el-option>
                                <el-option label="出单中" value="4"></el-option>
                                <el-option label="完成" value="5"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="跟进时间" required="">
                            <el-date-picker
                                    v-model="form.lastest_contact_date"
                                    type="datetime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    placeholder="选择日期时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="跟进结果" prop="quotation_status" required="">
                            <el-radio-group v-model="form.quotation_status">
                                <el-radio label="1">保持联系</el-radio>
                                <el-radio label="2">完成交易</el-radio>
                                <el-radio label="-1">放弃客户</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.quotation_status==1">
                        <el-form-item label="下次跟进" required="">
                            <el-date-picker
                                    v-model="form.next_contact_date"
                                    type="datetime"
                                    value-format="yyyy-MM-dd  HH:mm:ss"
                                    placeholder="选择日期时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="添加微信" prop="wx_invited" required="">
                            <el-radio-group v-model="form.wx_invited">
                                <el-radio label="0">未通过</el-radio>
                                <el-radio label="1">已通过</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.quotation_status==-1">
                        <el-form-item label="放弃理由" prop="quotation_status" required="">
                            <el-select v-model="form.giveup_reason" placeholder="放弃原因">
                                <el-option label="多次联系不上" value="多次联系不上"></el-option>
                                <el-option label="拒绝骚扰" value="拒绝骚扰"></el-option>
                                <el-option label="已续保" value="已续保"></el-option>
                                <el-option label="无意向" value="无意向"></el-option>
                                <el-option label="其它原因" value="其它原因"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="备注说明" prop="remark">
                            <el-input type="textarea" v-model="form.remark" placeholder="勤跟进，多签单..."></el-input>
                        </el-form-item>

                    </el-col>
                </el-row>
            </el-form>

            <div slot="footer" class="dialog-footer txt-center">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="onSubmit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        name: "Follow",
        props: { //接受父组件传递来的数据
            id: {
                type: String
            },
            step: {
                type: String
            },
            wx_invited: {
                type: String
            }
        },
        mounted() {
        },
        data() {
            return {
                dialogFormVisible: true,
                form: {
                    id: this.id,
                    contact_type: "电话",
                    contact_step: this.step,
                    lastest_contact_date: moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss'),
                    quotation_status: "1",
                    next_contact_date: '',
                    giveup_reason: '',
                    wx_invited: this.wx_invited,
                },
                formRules: {
                    contact_step: [
                        {required: true, message: '请选择跟进状态', trigger: 'submit'}
                    ],
                },
            }
        },
        methods: {
            onSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$loading.show('保存中');
                        this.$api.ciCustomer.contactRecord(this.form).then(result => {
                            if (result.errCode) {
                                this.$message.alert(result.message)
                            } else {
                                this.dialogFormVisible = false;
                                this.$emit('submitFollow', this.dialogFormVisible)
                            }
                        }).catch(error => {
                            this.$message.error(error)
                        }).finally(() => {
                            this.$loading.close()
                        })
                    } else {
                        return false;
                    }
                });
            },
            closeDialog() {
                this.dialogFormVisible = false;
                this.$emit('closeTransferFollow', this.dialogFormVisible)
            }
        }
    }
</script>

<style scoped>
    .follow-form {
        padding-right: 20px;
    }
</style>