<template>
    <div>
        <!--搜索栏-->
        <el-form :inline="true" :model="searchParams" ref="searchForm" label-width="90px" class="demo-form-inline">
            <el-form-item prop="keyword" class="ml-10">
                <el-input v-model="searchParams.keyword" placeholder="关键字"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" @click="getList()" :loading="isLoading">查询</el-button>
                <el-button icon="el-icon-plus" type="primary" @click="add()">添加文章</el-button>
            </el-form-item>
        </el-form>
        <el-table
                :data="dataList"
                stripe
                style="width: 100%"
                row-key="id">
            <el-table-column
                    prop="id"
                    label="ID">
            </el-table-column>
            <el-table-column
                    prop="title"
                    label="文章标题">
            </el-table-column>
            <el-table-column
                    prop="cover"
                    label="封面">
                <template slot-scope="scope">
                    <image-preview :file="scope.row.cover"/>
                </template>
            </el-table-column>
            <el-table-column
                    prop="author"
                    label="发布人">
                <template slot-scope="scope">
                    {{scope.row.author||'--'}}
                </template>
            </el-table-column>
            <el-table-column
                    prop="created"
                    label="创建时间">
                <template slot-scope="scope">
                    {{scope.row.created|time}}
                </template>
            </el-table-column>
            <el-table-column
                    prop="visit"
                    label="点击数量">
            </el-table-column>
            <el-table-column
                    prop="orderby"
                    label="排序">
                <template slot-scope="scope">
                    <edit-able-content :content="scope.row.orderby" :index="parseInt(scope.row.id)"
                                       @changed="updateOrderby"/>
                </template>
            </el-table-column>
            <el-table-column
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="text" class="c-blue" @click="edit(scope.row.id)"
                               icon="el-icon-edit-outline">编辑
                    </el-button>
                    <el-button type="text" class="c-blue" @click="remove(scope.$index)" icon="el-icon-delete">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--翻页-->
        <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="searchParams.page"
                :page-sizes="[10, 15, 20,50,100]"
                :page-size="searchParams.size"
                layout="total,sizes, prev, pager, next"
                :total="searchParams.count">
        </el-pagination>
    </div>
</template>

<script>
    import EditAbleContent from '../../components/EditAbleContent'
    import ImagePreview from '../../components/ImagePreview'

    export default {
        name: "Article",
        components: {
            EditAbleContent, ImagePreview
        },
        mounted() {
            this.getList()
        },
        data() {
            return {
                isLoading: false,
                searchParams: {
                    keyword: '',
                    catalog: this.$route.query.catalog || '',
                    page: 1,
                    count: 0,
                    size: 10,
                },
                dataList: []
            }
        },
        methods: {
            //创建分组
            add() {
                this.$router.push({path: '/article/save', query: {}})
            },
            edit(id) {
                this.$router.push({path: '/article/save', query: {id: id}})
            },
            updateOrderby(value, id) {
                this.isLoading = true;
                this.$api.article.article('Save', {id: id, orderby: value}, 'post').then(result => {
                    if (result.errCode) {
                        this.$message.alert(result.message)
                    } else {
                        this.$message.success('修改成功');
                        this.getList()
                    }
                }).catch(error => {
                    this.$message.error(error)
                }).finally(() => {
                    this.isLoading = false
                })
            },
            remove(form) {
                let item = form
                if (item.children.length) {
                    return this.$message.error('栏目下存在子栏目,无法删除')
                }
                this.$message.confirm({message: '确定要删除栏目[' + item.name + ']吗?'}, () => {
                    this.$loading.show('删除中')
                    this.$api.article.excute('RemoveCatalog', {id: item.id}, 'post').then(result => {
                        if (result.errCode) {
                            this.$message.alert(result.message)
                        } else {
                            this.getList()
                        }
                    }).catch(error => {
                        this.$message.error(error)
                    }).finally(() => {
                        this.$loading.close()
                    })
                })
            },
            //获取列表
            getList() {
                this.isLoading = true;
                this.$api.article.article('List', this.$copy(this.searchParams)).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.dataList = result.data.list;
                        this.searchParams.count = result.data.total;//总条数
                    }
                }).catch(error => {
                    this.$message.error(error);

                }).then(() => {
                    this.isLoading = false;
                });
            },
            // 点击分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getList();
            }
        }
    }
</script>