<template>
  <!-- 施工打卡  -->
  <div class="root-container">
    <div class="more-btns">
      <el-button type="primary" @click="newadd">新增</el-button>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="id" label="ID" align="center"> </el-table-column>
      <el-table-column
        prop="award_name"
        label="昵称"
        align="center"
      ></el-table-column>
      <el-table-column prop="award_type" label="类型" align="center">
        <template slot-scope="{ row }">
          <div v-if="row.award_type == 1">实体奖品</div>
          <div v-if="row.award_type == 2">VIP</div>
          <div v-if="row.award_type == 3">蜂蜜</div>
          <div v-if="row.award_type == 4">蜜罐</div>
        </template>
      </el-table-column>
      <el-table-column prop="award_pic" label="图片" align="center">
        <template slot-scope="{ row }">
          <img :src="row.award_pic" />
        </template>
      </el-table-column>
      <el-table-column
        prop="award_num"
        label="数量"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="create_time"
        label="创建时间"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="update_time"
        label="修改时间"
        align="center"
      ></el-table-column>
      <el-table-column prop="the_odds" label="概率" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.the_odds }}%</div>
        </template>
      </el-table-column>

      <el-table-column
        prop="more"
        label="操作"
        align="center"
        width="300"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" @click="editInfo(row)"
            >编辑</el-button
          >
          <el-button size="mini" type="danger" @click="setStatus(row.id)"
            >开启</el-button
          >
          <el-button size="mini" type="danger" @click="del(row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="searchParams.page"
      :page-sizes="[10, 15, 20, 50, 100]"
      :page-size="searchParams.size"
      layout="total,sizes, prev, pager, next"
      :total="searchParams.count"
    >
    </el-pagination>

    <!-- 添加盲盒查看详情 -->
    <el-dialog
      :title="picTitle"
      width="800px"
      top="10vh"
      :visible.sync="dialogVisible"
    >
      <div class="flexBox">
        <div class="flexBoxTitle">奖品名称:</div>
        <input v-model="name" class="flexBoxInp" type="text" />
      </div>

      <div class="flexBox">
        <div class="flexBoxTitle">奖品类别:</div>
        <el-radio-group v-model="blindBoxType">
          <el-radio :label="1">实体奖品</el-radio>
          <el-radio :label="2">VIP</el-radio>
          <el-radio :label="3">蜂蜜</el-radio>
          <el-radio :label="4">蜜罐</el-radio>
        </el-radio-group>
      </div>

      <div class="flexBox">
        <div class="bannerTitle">奖品图片：</div>

        <el-upload
          action="/common/upload/multipart/"
          list-type="picture-card"
          :on-preview="handleActivityGiftBagPictureCardPreview"
          :on-remove="handleRemoveActivityGiftBagPic"
          :on-success="handleActivityGiftBagPictureSuccess"
          :limit="1"
          :file-list="activity_gift_bag_pic"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog
          :visible.sync="activityGiftBagPicVisible"
          append-to-body
          width="40%"
        >
          <img width="100%" :src="activityGiftBagPicUrl" alt="" />
        </el-dialog>
      </div>

      <div class="flexBox">
        <div class="flexBoxTitle">奖品数量:</div>
        <input v-model="goodsNum" class="flexBoxInp" type="text" />
      </div>

      <div class="flexBox">
        <div class="flexBoxTitle">中奖概率:</div>
        <input v-model="probability" class="flexBoxInp" type="text" />
      </div>

      <div class="flexBox">
        <div class="flexBoxTitle">奖品状态:</div>
        <el-radio-group v-model="blindBoxStatus">
          <el-radio :label="0">关闭</el-radio>
          <el-radio :label="1">开启</el-radio>
        </el-radio-group>
      </div>

      <div class="flexBox">
        <el-button type="primary" @click="sure()">确定</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >取消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "",
  data() {
    return {
      loading: false,
      searchParams: {
        page: 1,
        size: 10,
        count: 0,
      }, //分页
      picTitle: "", //弹窗的title
      tableData: [{ num: "刘可月" }], //列表数据
      dialogVisible: false,
      dialogVisible1: false,

      addimgShow: "", //
      setBlindBox: 0, //0是新增 1是编辑

      name: "", //名称
      probability: "", //概率
      blindBoxType: 0, //选择奖品类型
      goodsNum: "", //奖励个数（蜜罐和蜂蜜）
      addimg: "", //添加图片
      blindBoxStatus: 0, //盲盒奖品状态

      infoId: "", //单条垃圾id

      //礼包图是否开启大图展示
      activityGiftBagPicVisible: false,
      activity_gift_bag_pic: [],
      activityGiftBagPicUrl: "",
    };
  },
  created() {
    const self = this;
    self.getTableList();
  },
  methods: {
    //移除图片
    handleRemoveActivityGiftBagPic(file) {
      this.addimg = "";
    },
    //图片放大观察
    handleActivityGiftBagPictureCardPreview(file) {
      this.activityGiftBagPicVisible = true;
      this.addimg = file.url;
    },
    handleActivityGiftBagPictureSuccess(result) {
      if (result.errCode) {
        this.$message.error(result.message);
      }
      this.addimg = result.data.object_url;
    },

    //快捷设置状态
    setStatus(e) {
      this.isLoading = true;
      let data = {
        name: "",
        page: this.searchParams.page,
        size: this.searchParams.size,
      };
      this.$api.journal
        .blindBoxList("List", data, "GET")
        .then((result) => {
          console.log(result);
          this.$message.success(result.message);
          this.getTableList();
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    timeStamp(date) {
      let s = new Date(date * 1000);
      let y = s.getFullYear();
      let m =
        s.getMonth() + 1 < 10 ? "0" + (s.getMonth() + 1) : s.getMonth() + 1;
      let dd = s.getDate() < 10 ? "0" + s.getDate() : s.getDate();
      let hh = s.getHours() < 10 ? "0" + s.getHours() : s.getHours();
      let mm = s.getMinutes() < 10 ? "0" + s.getMinutes() : s.getMinutes();
      let ss = s.getSeconds() < 10 ? "0" + s.getSeconds() : s.getSeconds();
      let enddate = y + "-" + m + "-" + dd + " " + hh + ":" + mm + ":" + ss;
      // console.log(enddate);
      return enddate;
    },

    //列表数据
    getTableList() {
      this.isLoading = true;
      let data = {
        name: "",
        page: this.searchParams.page,
        size: this.searchParams.size,
      };
      this.$api.journal
        .blindBoxList("List", data, "GET")
        .then((result) => {
          console.log(result);
          //   this.$message.success(result.message);
          for (let i = 0; i < result.data.list.length; i++) {
            result.data.list[i].create_time = this.timeStamp(
              result.data.list[i].create_time
            );
            result.data.list[i].update_time = this.timeStamp(
              result.data.list[i].update_time
            );
          }
          this.tableData = result.data.list;
          this.searchParams.count = result.data.total;
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //新增
    newadd: function () {
      this.dialogVisible = true;
      this.setBlindBox = 0;

      this.name = "";
      this.addimg = "";
      this.goodsNum = "";
      this.blindBoxType = 1;
      this.probability = "";
      this.blindBoxStatus = 1;
    },

    //编辑
    editInfo: function (e) {
      this.activity_gift_bag_pic=[]
      this.name = e.award_name;
      this.addimg = e.award_pic;
      this.goodsNum = e.award_num;
      this.blindBoxType = Number(e.award_type);
      this.probability = e.the_odds + "%";
      this.blindBoxStatus = Number(e.award_status);

this.activity_gift_bag_pic.push({url:e.award_pic})

      this.dialogVisible = true;
      this.infoId = e.id;
      this.setBlindBox = 1;
    },
    //创建盲盒
    sure() {
      if (this.setBlindBox == 0) {
        var data = {
          name: this.name,
          pic: this.addimg,
          num: this.goodsNum,
          type: this.blindBoxType,
          the_odds: this.probability,
          status: this.blindBoxStatus,
        };
      } else {
        var data = {
          id: this.infoId,
          name: this.name,
          pic: this.addimg,
          num: this.goodsNum,
          type: this.blindBoxType,
          the_odds: this.probability,
          status: this.blindBoxStatus,
        };
      }
      this.isLoading = true;
      this.$api.journal
        .blindBoxList("Save", data, "post")
        .then((result) => {
          console.log(result);
          if (result.errCode == 0) {
            this.$message.success(result.message);
            this.dialogVisible = false;
            this.getTableList();
          } else {
            this.$message.error(result.message);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //删除
    del(e) {
      this.$confirm("是否要删除这条id：" + e + "的数据!", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            id: e,
          };
          this.isLoading = true;
          this.$api.journal
            .blindBoxList("DeleteBlindBoxLuckDraw", data, "POST")
            .then((result) => {
              console.log(result);
              this.$message.success(result.message);
              this.getTableList();
            })
            .catch((error) => {
              this.$message.error(error);
            })
            .finally(() => {
              this.isLoading = false;
            });
        })
        .catch(() => {});
    },
    //删除
    setStatus(e) {
      this.$confirm("是否要开启这条id：" + e + "的数据!", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            id: e,
          };
          this.isLoading = true;
          this.$api.journal
            .blindBoxList("SetStatus", data, "POST")
            .then((result) => {
              console.log(result);
              this.$message.success(result.message);
              this.getTableList();
            })
            .catch((error) => {
              this.$message.error(error);
            })
            .finally(() => {
              this.isLoading = false;
            });
        })
        .catch(() => {});
    },

    //分页
    handleSizeChange(val) {
      this.searchParams.size = val;
      this.getTableList();
    },

    handleCurrentChange(val) {
      this.searchParams.page = val;
      this.getTableList();
    },
  },
};
</script>
<style>
.flexBox {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.flexBoxTitle {
  margin-right: 20px;
}
.flexBoxInp {
  border: 1px solid gray;
  border-radius: 3px;
  width: 50%;
  height: 30px;
  outline: none;
  padding-left: 10px;
}
</style>
 