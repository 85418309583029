<template>
    <div class="txt-center fs-12 c-ccc">

    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

</style>