<template>
    <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="所有任务" name="task"></el-tab-pane>
        <el-tab-pane label="商品管理" name="commodity"></el-tab-pane>
        <el-tab-pane label="悬赏任务" name="posts"></el-tab-pane>
        <el-tab-pane label="优惠券" name="coupon"></el-tab-pane>
    </el-tabs>
</template>

<script>
    export default {
        name: "TaskNav",
        props: {
            active: {
                type: String
            },
        },
        data() {
            return {
                activeName: this.active,
                pages: {
                    task: 'zx/task',
                    commodity: 'zx/commodity',
                    posts: 'zx/posts',
                    coupon: 'zx/coupon'
                }
            };
        },
        methods: {
            handleClick(tab) {
                if (tab.name === this.active) {
                    return
                }
                switch (tab.name) {
                    case 'commodity':
                        this.$router.push({path: '/zx/commodity'})
                        break;
                    case 'posts':
                        this.$router.push({path: '/zx/posts'})
                        break;
                    case 'coupon':
                        this.$router.push({path: '/zx/coupon'})
                        break;
                    default:
                        this.$router.push({path: '/zx/task'})
                        break;
                }
            }
        }
    }
</script>

<style scoped>

</style>