<template>
  <div>
    <el-button size="medium" type="primary" @click="newadd()">新增</el-button>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="id" label="序号"> </el-table-column>
      <el-table-column prop="title_one" label="产品名字"> </el-table-column>
      <el-table-column prop="name" label="产品标识"> </el-table-column>
      <el-table-column label="项目logo" align="center" width="200">
        <template slot-scope="{ row }">
          <img style="width: 100%" :src="row.brief_pic" />
        </template>
      </el-table-column>
      <el-table-column label="背景图" align="center" width="200">
        <template slot-scope="{ row }">
          <img style="width: 100%" :src="row.detail_pic" />
        </template>
      </el-table-column>
      <el-table-column label="H5背景图" align="center" width="100">
        <template slot-scope="{ row }">
          <img style="width: 100%" :src="row.h5_pic" />
        </template>
      </el-table-column>
      <el-table-column prop="title_two" label="产品特点"> </el-table-column>
      <el-table-column prop="content_one" label="产品介绍" width="100">
      </el-table-column>
      <el-table-column prop="content_two" label="特色详情" width="100">
      </el-table-column>
      <el-table-column
        prop="more"
        label="操作"
        align="center"
        width="300"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" @click="editInfo(row)"
            >编辑</el-button
          >
          <el-button size="mini" type="danger" @click="del(row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 支付弹出框 -->
    <el-dialog
      title="操作"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
    >
      <addcontent @dialogVisible="childByValue($event)" v-if="flag" :value="value" title="添加">
        <div slot="top"></div>
      </addcontent>
    </el-dialog>

    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="searchParams.page"
      :page-sizes="[10, 15, 20, 50, 100]"
      :page-size="searchParams.size"
      layout="total,sizes, prev, pager, next"
      :total="searchParams.count"
    >
    </el-pagination>

    
  </div>
</template>

<script>
import addcontent from "./addcontent";
import axios from "axios";
export default {
  // name: "Index",
  components: {
    addcontent,
  },
  data() {
    return {
      dialogVisible: false,//是否显示弹出框 
      tableData: [],//列表数据
      value: {},//父传子
      flag: true,//刷新子组件
      searchParams: {
        page: 1,
        size: 10,
        count: 0,
      },//分页
    };
  },
  created() {
    this.getlist();
  },
  mounted() {},
  watch: {},
  methods: {
    //获取列表数据
    getlist: function () {
      this.isLoading = true;
      this.$api.website
        .oneInfo("List", { page: this.searchParams.page, size:  this.searchParams.size }, "get")
        .then((result) => {
          console.log(result);
          this.tableData = result.data.list;
          this.searchParams.count=result.data.total;
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //关闭弹出框
    handleClose(done) {
      done();
    },
    //编辑
    editInfo: function (e) {
      console.log(e);
      this.value = {
        type: 1,
        obj: e,
      };
      this.flag = false;
      this.$nextTick(() => {
        this.flag = true;
      });
      this.dialogVisible = true;
    },
    //新增
    newadd: function (e) {
      this.value = {
        type: 0,
        id: 0,
      };
      this.flag = false;
      this.$nextTick(() => {
        this.flag = true;
      });
      this.dialogVisible = true;
    },
    //删除
    del: function (e) {
      this.isLoading = true;
      this.$api.website
        .oneDle("Delete", { id: e }, "get")
        .then((result) => {
          console.log(result);
            this.$message.success('删除成功');
          this.getlist();
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //分页
    handleSizeChange(val) {
      this.searchParams.size = val;
      this.getlist();
    },

    handleCurrentChange(val) {
      this.searchParams.page = val;
      this.getlist();
    },

       childByValue: function (childValue) {
    // childValue就是子组件传过来的值
    console.log(childValue)
          this.$nextTick(() => {
        this.flag = true;
      });
    this. dialogVisible = false
    this.getlist();

   }
  },
};
</script>

<style>
.el-icon-upload2:before {
  float: right;
}
.el-icon-edit:before {
  float: right;
}
.settopLogo {
  display: flex;
  justify-items: center;
}



</style>