<template>
    <div>
        <!--搜索栏-->
        <el-form :inline="true" :model="searchParams" ref="searchParams" class="demo-form-inline">
            <el-row :gutter="10" type="flex">
                <el-form-item label="" prop="keywords">
                    <el-input v-model="searchParams.keyword" placeholder="关键字"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button icon="el-icon-search" type="primary" :loading="isLoading" @click="getList()">查询
                    </el-button>
                    <el-button icon="el-icon-plus" type="primary" @click="add()" :loading="isLoading">添加任务</el-button>
                </el-form-item>
                <el-col :span="5" v-if="connectStatus.status===0">
                    <el-button type="success" @click="connect()"
                               icon="el-icon-connection" :loading="isLoading">连接
                    </el-button>
                </el-col>
                <el-col :span="5" v-if="connectStatus.status===1 && runStatus.isRunning!==1">
                    <el-button type="success" @click="start()"
                               icon="el-icon-video-play" :loading="isLoading">启动
                    </el-button>
                </el-col>
                <el-col :span="10" v-if="connectStatus.status===1 && runStatus.isRunning===1">
                    <h5 style="font-size: 10px; padding-left: 10px">
                        运行状态：
                        <i class="el-icon-finished"></i>
                        {{loopTasks.length+patrolTasks.length}} &nbsp;
                        <i class="el-icon-time"></i>
                        {{runStatus.runTime|secondToHour}} &nbsp;
                        <i class="el-icon-coin"></i>
                        {{runStatus.memoryUsed}} &nbsp;
                        <el-button type="warning" @click="stop()"
                                   icon="el-icon-video-pause" :loading="isLoading">暂停
                        </el-button>
                    </h5>
                </el-col>
            </el-row>
        </el-form>
        <el-table
                :data="dataList"
                stripe
                style="width: 100%">
            <el-table-column
                    prop="name"
                    label="任务名称">
            </el-table-column>
            <el-table-column
                    prop="execution_interval"
                    label="执行时间">
                <template slot-scope="scope">
                    <el-tag type="success" v-if="scope.row.execution_type==1">每{{scope.row.execution_interval}}秒循环
                    </el-tag>
                    <el-tag type="success" v-if="scope.row.execution_type==2">{{scope.row.execution_time}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="controller"
                    label="脚本">
                <template slot-scope="{row}">
                    {{row.controller}}->{{row.action}}
                </template>
            </el-table-column>
            <el-table-column
                    prop="status"
                    label="状态">
                <template slot-scope="{row}">
                    <el-tag type="success" v-if="row.status==1">开启</el-tag>
                    <el-tag type="info" v-if="row.status==0">关闭</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="lastest_execute"
                    label="最近执行">
                <template slot-scope="scope">
                    {{previousExcuteTime(scope.$index)}}
                </template>
            </el-table-column>
            <el-table-column
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="text" class="c-blue" @click="getLogs(scope.$index)"
                               icon="el-icon-tickets">日志
                    </el-button>
                    <el-button type="text" class="c-blue" @click="edit(scope.$index)"
                               icon="el-icon-edit-outline">编辑
                    </el-button>
                    <!--<el-button type="text" class="c-red" @click="remove(scope.$index)"-->
                    <!--icon="el-icon-delete">删除-->
                    <!--</el-button>-->
                </template>
            </el-table-column>
        </el-table>
        <!--日志-->
        <el-drawer
                size="45%"
                title="运行日志"
                :visible.sync="drawer"
                direction="rtl">
            <div slot="title" style="padding-left: 10px">
                <el-date-picker
                        v-model="logParams.day"
                        type="date"
                        format="yyyyMMdd"
                        value-format="yyyyMMdd"
                        @change="getLogs()"
                        class="fl"
                        :editable="false"
                        :clearable="false"
                        placeholder="选择日期">
                </el-date-picker>
            </div>
            <div slot="">
                <el-timeline style="margin-left: 30px">
                    <el-timeline-item
                            placement="top" style="padding-right: 20px;padding-left: 20px"
                            v-for="(item, index) in excuteLogs"
                            :key="index"
                            :timestamp="item.timestamp">
                        执行结果:{{item.result.message}} <span
                            v-if="item.result.errCode">错误信息:{{item.result.errCode}}</span> 返回数据:{{item.result.data}}
                    </el-timeline-item>

                    <!--<el-timeline-item v-for="(item,key) in excuteLogs" :key="key" :timestamp="item.timestamp"-->
                    <!--placement="top" style="padding-right: 20px">-->
                    <!--<el-card>-->
                    <!--<json-viewer :value="item.result" :expand-depth=10-->
                    <!--expanded></json-viewer>-->
                    <!--</el-card>-->
                    <!--</el-timeline-item>-->
                </el-timeline>
                <!--翻页-->
                <el-pagination
                        background
                        @size-change="handleLogsSizeChange"
                        @current-change="handleLogsCurrentChange"
                        :current-page.sync="logParams.page"
                        :page-sizes="[10, 15, 20,50,100]"
                        :page-size="logParams.size"
                        layout="total,sizes, prev, pager, next"
                        :total="logParams.count">
                </el-pagination>
            </div>
        </el-drawer>
        <!--翻页-->
        <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="searchParams.page"
                :page-sizes="[10, 15, 20,50,100]"
                :page-size="searchParams.size"
                layout="total,sizes, prev, pager, next"
                :total="searchParams.count">
        </el-pagination>
        <!--表单弹框-->
        <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="50%">
            <el-form :model="form" :rules="formRules" status-icon ref="form" label-width="120px" class="">
                <el-form-item label="任务名称：" prop="name">
                    <el-col :span="10">
                        <el-input type="text" placeholder="任务名称" v-model="form.name"></el-input>
                    </el-col>
                </el-form-item>

                <el-form-item label="执行方式" prop="execution_type">
                    <el-col :span="10">
                        <el-radio-group v-model="form.execution_type">
                            <el-radio label="1">循环执行</el-radio>
                            <el-radio label="2">定时执行</el-radio>
                        </el-radio-group>
                    </el-col>
                </el-form-item>
                <el-form-item label="间隔时间：" prop="execution_interval" v-if="form.execution_type==1">
                    <el-col :span="10">
                        <el-input type="text" placeholder="单位:秒" v-model="form.execution_interval"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="执行时间：" prop="execution_time" v-if="form.execution_type==2">
                    <el-col :span="20">
                        <el-input type="textarea" :rows="3" placeholder="10:00,12:30"
                                  v-model="form.execution_time"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="controller：" prop="controller">
                    <el-col :span="10">
                        <el-input type="text" placeholder="位于cli模块下的控制器名称" v-model="form.controller"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="action：" prop="action">
                    <el-col :span="10">
                        <el-input type="text" placeholder="action名称,无需加action前缀" v-model="form.action"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="任务状态" prop="status">
                    <el-radio-group v-model="form.status">
                        <el-radio label="1">开启</el-radio>
                        <el-radio label="0">关闭</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="save()" :loading="isLoading">保 存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import SocketIO from "socket.io-client"
    import moment from 'moment'
    import JsonViewer from 'vue-json-viewer'

    export default {
        name: "TaskManager",
        components: {
            JsonViewer
        },
        mounted() {
            this.getList()
            this.connect()
        },
        destroyed() {
            if (this.socket) {
                this.socket.disconnect()
            }
        },
        data() {
            return {
                socket: null,
                drawer: false,
                isLoading: false,
                dialogFormVisible: false,
                dialogTitle: '添加任务',
                formRules: {
                    name: {required: true, message: '请填写任务名称', trigger: 'blur'},
                    execution_type: {required: true, message: '请选择执行方式', trigger: 'blur'},
                },
                form: {},
                searchParams: {
                    keyword: '',
                    page: 1,
                    count: 1,
                    size: 10,
                },
                logParams: {
                    task_id: '',
                    page: 1,
                    count: 1,
                    size: 12,
                    day: moment().format('YYYYMMDD')
                },
                dataList: [],
                excuteLogs: [],
                loopTasks: [],
                patrolTasks: [],
                connectStatus: {
                    status: 0
                },
                runStatus: {
                    isRunning: 0
                }
            }
        },
        methods: {
            stop() {
                if (this.connectStatus.status === 0) {
                    return this.$message.error('未连接到服务器');
                }
                this.isLoading = true
                this.socket.emit("stop", {status: 0});
            },
            start() {
                if (this.connectStatus.status === 0) {
                    return this.$message.error('未连接到服务器');
                }
                this.isLoading = true
                this.socket.emit("start", {status: 1});
            },
            connect() {
                if (this.connectStatus.status) {
                    return
                }
                this.$api.system.task('Socket').then(result => {
                    if (result.errCode) {
                        this.$message.error('获取任务监控服务器地址失败:' + result.message);
                    } else {
                        this.socket = SocketIO(result.data + '?uid=' + new Date().getTime(), {
                            reconnection: false, //是否自动重连
                            reconnectionDelay: 2000, //重连间隔
                            reconnectionDelayMax: 2000 // 重连超时等待时长 每次重连 reconnectionDelay 增加一次
                        })
                        this.socket.on("connect", () => {
                            this.$message.success('任务管理服务器连接成功')
                            this.$set(this.connectStatus, 'status', 1)
                        })
                        //连接 服务器 错误
                        this.socket.on("connect_error", () => {
                            this.$set(this.connectStatus, 'status', 0)
                            //this.$message.warning("连接任务管理服务器失败");
                        });
                        // 连接服务器超时
                        this.socket.on("connect_timeout", () => {
                            this.$set(this.connectStatus, 'status', 0)
                            //this.$message.warning("连接任务管理服务器超时");
                        });
                        this.socket.on('disconnect', () => {
                            //this.$message.warning('与任务管理服务器断开连接')
                            this.$set(this.connectStatus, 'status', 0)
                        });
                        //数据推送
                        this.socket.on('push', result => {
                            this.runStatus = result.data
                            this.loopTasks = result.data.loopTasks;
                            this.patrolTasks = result.data.patrolTasks;
                        });
                        this.socket.on('current log', log => {
                            this.loopLogs.unshift({
                                time: new Date().getTime(),
                                log: log
                            });
                            if (this.loopLogs.length > 200) {
                                this.loopLogs.splice(this.loopLogs.length - 1, 1);
                            }
                        });
                        this.socket.on('order successed', result => {
                            this.isLoading = false
                            this.loopTasks = result.loopTasks;
                            this.patrolTasks = result.patrolTasks;
                        });
                        //日志
                        this.socket.on('logs', result => {
                            $.hideLoading();
                            if (result.errCode) {
                                this.$message.error('日志获取失败:' + result.message);
                                return;
                            }
                            this.patrolLogs = result.data.patrol;
                            this.loopLogs = result.data.loop;
                        });
                    }
                }).catch(error => {
                    this.$message.error('获取任务监控服务器地址失败:' + error);
                });
            },
            previousExcuteTime(index) {
                let task = this.dataList[index]
                if (this.connectStatus.status !== 1) {
                    return task.lastest_execute != 0 ? moment(parseInt(task.lastest_execute) * 1000).format('MM-DD HH:mm:ss') : '--'
                }
                let time = '--'
                if (task.execution_type == 1) {
                    this.loopTasks.forEach(item => {
                        if (item.id == task.id) {
                            if (item.isRunning == true) {
                                time = '运行中'
                            } else {
                                time = item.previous_execution_date
                            }
                        }
                    })
                } else {
                    this.patrolTasks.forEach(item => {
                        if (item.id == task.id) {
                            if (item.isRunning == true) {
                                time = '运行中'
                            } else {
                                time = item.previous_execution_date
                            }
                        }
                    })
                }
                if (time == 0) {
                    return '--'
                }
                return moment(time).format('MM-DD HH:mm:ss') === 'Invalid date' ? time : moment(time).format('MM-DD HH:mm:ss')
            },
            getLogs(index) {
                if (index !== undefined) {
                    let task = this.dataList[index]
                    this.logParams.task_id = task.id
                }
                this.drawer = true
                this.isLoading = true;
                this.$api.system.task('Logs', this.logParams).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.drawer = true
                        this.excuteLogs = result.data.list;
                        this.logParams.count = result.data.total;
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).finally(() => {
                    this.isLoading = false;
                });
            },
            //加载列表
            getList() {
                this.isLoading = true;
                this.$api.system.task('List', this.searchParams).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.dataList = result.data.list;
                        this.searchParams.count = result.data.total;
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).finally(() => {
                    this.isLoading = false;
                });
            },
            //创建分组
            add() {
                this.dialogTitle = '添加任务';
                this.form = {
                    status: '1',
                    execution_type: '1',
                };
                this.dialogFormVisible = true;
            },
            edit(index) {
                this.dialogTitle = '编辑任务';
                this.form = this.dataList[index];
                this.dialogFormVisible = true;
            },
            save() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$api.system.task('Save', this.form, 'post').then(result => {
                            if (result.errCode) {
                                this.$message.alert(result.message)
                            } else {
                                this.dialogFormVisible = false;
                                this.$message.success('保存成功');
                                this.getList()
                            }
                        }).catch(error => {
                            this.$message.error(error)
                        }).finally(() => {
                            this.isLoading = false
                        })
                    } else {
                        return false;
                    }
                });
            },
            remove(index) {
                let item = this.dataList[index]
                this.$message.confirm({message: '确定要删除吗?'}, () => {
                    this.$loading.show('删除中')
                    this.$api.system.task('Delete', {id: item.id}, 'post').then(result => {
                        if (result.errCode) {
                            this.$message.alert(result.message)
                        } else {
                            this.getList()
                        }
                    }).catch(error => {
                        this.$message.error(error)
                    }).finally(() => {
                        this.$loading.close()
                    })
                })
            },
            // 点击分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getList();

            },
            handleLogsSizeChange(val) {
                this.logParams.size = val;
                this.getLogs();
            },
            handleLogsCurrentChange(val) {
                this.logParams.page = val;
                this.getLogs();

            },
        }
    }
</script>
<style>

</style>