<template>
    <div :id="echarts" class="echarts-container"></div>
</template>

<script>
    import echarts from 'echarts'
    export default {
        name: "ChartLine",
        props:{ //接受父组件传递来的数据
            items:{
                type:Object,
                text:'折线图堆叠',
                legend:{
                    data:['选项一','选项二']
                },
                xAxis:{
                    data:['2018-9','2018-10','2018-12','2019-1']
                },
                series:[
                    {
                        name:'选项一',
                        type:'line',
                        data:[120, 132, 101, 134],
                        symbol:'rect'
                    },
                    {
                        name:'选项二',
                        type:'line',
                        data:[33, 78, 58, 45],
                        symbol:'rect'
                    },
                ]
            },
        },
        data(){
            return{
                chart:null
            }
        },
        watch: {
            items: {
                immediate: true,
                deep: true,
                handler(newVal, oldVal) {
                    if (this.chart) {
                        if (newVal) {

                            this.chart.setOption(newVal);
                            this.chart.hideLoading();
                        } else {
                            this.chart.setOption(oldVal);
                        }
                    }
                }
            }
        },
        computed:{
            echarts(){
                return 'echarts' + Math.random()*100000
            }
        },
        mounted() {
            const echarts = require('echarts');
            const option = {
                color:['#1890FF','#31C290','#FACC14','#223273','#7143E0'],
                title: {
                    text: this.items.text
                },
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: 'rgba(255,255,255,.8)',
                    borderWidth: 1,
                    borderColor: '#ccc',
                    padding: 10,
                    textStyle: {
                        color: '#000',
                        fontSize:'12',
                        lineHeight:80
                    },
                },
                legend: {
                    bottom:5,
                    data:this.items.legend.data
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '15%',
                    containLabel: true
                },
//                toolbox: {
//                    feature: {
//                        saveAsImage: {}
//                    }
//                },
                xAxis: {
                    type: 'category',
                    // boundaryGap: false,
                    data: this.items.xAxis.data
                },
                yAxis: {
                    type: 'value'
                },
                series: this.items.series
            };
            this.chart = echarts.init(document.getElementById(this.echarts));
            this.chart.showLoading();
            this.chart.setOption(option);
        }
    }
</script>

<style scoped>
    .echarts-container{width: auto;}
</style>