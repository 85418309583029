<template>
    <el-row :gutter="5" type="flex">
        <el-col :span="15" :key="key" v-for="(arrItem,key) in selectList">
            <el-select v-model="parents[key]" filterable placeholder="请选择" value-key="id" @change="selected" @focus="position=key">
                <el-option label="请选择" value="0" v-if="key==0"></el-option>
                <el-option v-for="item in arrItem" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
        </el-col>
    </el-row>
</template>

<script>
    export default {
        name: "CatalogSelector",
        props: {
            list: {
                type: Array
            },
            parent: {
                type: String
            },
            limit: {
                type: Number,
                default: 0
            }
        },
        mounted() {
            this.init()
        },
        data() {
            return {
                all: [],
                position: 0,
                selectList: [],
                id: '',
                parents: [],
                limitPosition: 0,
                name: ''
            }
        },
        methods: {
            init() {
                this.id = ''
                this.all = []
                this.parents = []
                this.selectList = []
                this.limitPosition = this.limit || 0
                this.listTrans(this.list)
                if (this.parent != '0') {
                    this.position = 1
                    this.getParent(this.parent)
                } else {
                    this.position = 0
                    this.parents = ["0"]
                    this.id = "0"
                }
                this.selectList.unshift(this.$copy(this.list))
                this.$emit('changed', {id: this.id, parents: this.parents, position: this.position, name: this.name})
            },

            selected(item) {
                let children = this.getChildren(item)
                if (children.length && (!this.limitPosition || this.position < this.limitPosition)) {
                    this.id = ''
                    this.selectList.splice(this.position + 1, this.selectList.length, children);
                    this.parents.splice(this.position + 1, this.parents.length);
                } else {
                    this.id = item
                    this.name = this.getName(item)
                    this.selectList.splice(this.position + 1, this.selectList.length);
                    this.parents.splice(this.position + 1, this.parents.length);
                }
                this.$emit('changed', {id: this.id, parents: this.parents, position: this.position, name: this.name})
            },
            getParent(id) {
                this.all.forEach(item => {
                    if (item.id == id) {
                        if (item.children.length && item.id !== this.parent) {
                            this.position++
                            this.selectList.unshift(item.children);
                        } else {
                            this.id = item.id
                        }
                        this.parents.unshift(item.id)
                        if (item.parent) {
                            this.getParent(item.parent)
                        }
                    }
                })
            },
            getChildren(id) {
                let children = []
                this.all.forEach(item => {
                    if (item.id === id && item.children.length && item.id !== this.parent) {
                        children = item.children
                    }
                })
                return children
            },
            getName(id) {
                let name = ''
                this.all.forEach(item => {
                    if (item.id == id) {
                        name = item.name
                    }
                })
                return name
            },
            listTrans(list) {
                list.forEach(item => {
                    this.all.push(item)
                    if (item.children.length) {
                        this.listTrans(item.children)
                    }
                })
            }
        },
        watch: {
            list: {
                handler() {
                    this.init()
                }
            },
            parent: {
                handler() {
                    this.init()
                }
            },
        },
    }
</script>

<style scoped>

</style>