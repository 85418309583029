<template>
    <el-popover
            v-if="fileUrl"
            placement="right-start"
            :title="fileName"
            width="500"
            trigger="hover">
        <a :href="fileUrl" target="_blank"><img :src="fileUrl" width="500"></a>
        <img slot="reference" :src="fileUrl" width="30" height="30">
    </el-popover>
    <i v-else slot="default" :class="{'el-icon-picture-outline':!isLoading,'el-icon-loading':isLoading}"></i>
</template>

<script>
    import http from '../cloud/http'

    export default {
        name: "ImagePreview",
        props: {
            file: {
                type: String
            }
        },
        mounted() {
            this.loadFile()
        },
        data() {
            return {
                isLoading: false,
                fileUrl: '',
                fileName: ''
            }
        },
        watch: {
            file: {
                handler() {
                    this.loadFile()
                }
            }
        },
        methods: {
            isIntNum(val) {
                let regPos = /^\d+(\.\d+)?$/; //非负浮点数
                let regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
                if (regPos.test(val) || regNeg.test(val)) {
                    return true;
                } else {
                    return false;
                }
            },
            loadFile() {
                if (!this.file) {
                    return
                }
                this.isLoading = true;
                if (this.isIntNum(this.file)) {
                    http({
                        url: '/common/file/get/' + this.file + '/',
                        method: 'get',
                        params: {id: this.file}
                    }).then(result => {
                        this.fileName = result.data.original_name;
                        this.fileUrl = result.data.object_url
                    }).catch(error => {
                        console.log('文件加载失败:' + error)
                    }).finally(() => {
                        this.isLoading = false
                    })
                } else {
                    this.fileName = '';
                    this.fileUrl = this.file
                }
            },
        }
    }
</script>

<style scoped>

</style>