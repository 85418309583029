<template>
    <div>
        <!--搜索栏-->
        <el-form :inline="true" :model="searchParams" ref="searchForm" label-width="90px" class="demo-form-inline">
            <el-form-item prop="keyword" class="ml-10">
                <el-input v-model="searchParams.keyword" placeholder="关键字"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" @click="getListCover()" :loading="isLoading">查询</el-button>
                <el-button icon="el-icon-plus" type="primary" @click="createVersion()" :loading="isLoading">上传版本</el-button>
            </el-form-item>
        </el-form>
        <!--表单信息-->
        <el-table
                :data="dataList"
                stripe
                style="width: 100%"
                row-key="id">
            <el-table-column
                    prop="id"
                    label="ID"
            >
            </el-table-column>

            <el-table-column
                    prop="title"
                    label="版本名称">
            </el-table-column>

            <el-table-column
                    prop="version_code"
                    label="版本号">
            </el-table-column>

            <el-table-column
                    prop="constraint_desc"
                    label="是否强制更新">
            </el-table-column>

            <el-table-column
                    prop="record_time_desc"
                    label="记录时间">
            </el-table-column>

            <el-table-column
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="text" class="c-blue" @click="editVersionInfo(scope.$index)" icon="el-icon-edit-outline">编辑
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--翻页-->
        <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="searchParams.page"
                :page-sizes="[10, 15, 20,50,100]"
                :page-size="searchParams.size"
                layout="total,sizes, prev, pager, next"
                :total="searchParams.count">
        </el-pagination>
        <!--弹窗-->
        <el-dialog :title="dialogTitleCover" :visible.sync="dialogCoverFormVisible" :close-on-click-modal="false" width="50%">
            <el-form :model="Cover.form" label-width="80px">
                <!--输入框-->
                <el-form-item label="title" required="">
                    <el-col :span="8">
                        <el-input v-model="Cover.form.title" clearable></el-input>
                    </el-col>
                </el-form-item>

                <el-form-item label="版本号" required="">
                    <el-col :span="8">
                        <el-input v-model.number="Cover.form.version_code" clearable></el-input>
                    </el-col>
                </el-form-item>

                <el-form-item label="版本说明" required="">
                    <el-col :span="15">
                        <el-input type="textarea"  :rows="10" v-model="Cover.form.message"></el-input>
                    </el-col>
                </el-form-item>

                <!--单选框 传入的值为label-->
                <el-form-item label="强制更新" required="">
                    <el-radio-group v-model="Cover.form.is_constraint">
                        <el-radio :label=0>否</el-radio>
                        <el-radio :label=1>是</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="资源包" required="">
                    <el-upload
                            style="text-align:left; display: inline-block;"
                            action="/common/upload/multipart/"
                            :on-preview="handlePreviewFile"
                            :on-remove="handleRemoveFile"
                            :on-success="handleSuccessFile"
                            multiple
                            :limit="1"
                            :on-exceed="handleExceedFile"
                            :file-list="VersionPatchList"
                    >
                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip"></div>
                    </el-upload>
                </el-form-item>

                <el-form-item label="资源路径" prop="forbid">
                    <el-input v-model="Cover.form.path" :disabled="true"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary"  @click="CreateSubmitVersion()">立即创建</el-button>
                    <el-button  @click="CancelSubmitVersion()">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>


</template>

<script>

    export default {
        name: "VersionList",
        components: {},
        mounted() {
            this.getListVersion();
        },
        data() {
            return {
                isLoading: false,
                //搜索传参
                searchParams: {
                    keyword: '',
                    sort_id: '0',
                    page: 1,
                    count: 0,
                    size: 10,
                },
                //弹窗表单
                dialogTitleCover:'创建版本',
                dialogCoverFormVisible:false,
                Cover:{
                    form:{
                        id : 0,
                        title : '',
                        version_code : 0,
                        path : '',
                        is_constraint : 0,
                    }
                },
                //版本列表
                dataList: [],
                //版本list
                VersionPatchList:[]
            }
        },
        methods: {
            //获取版本列表
            getListVersion() {
                this.isLoading = true;
                this.$api.journal.version('List', this.$copy(this.searchParams)).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.dataList = result.data.list;
                        this.searchParams.count = result.data.total;//总条数
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                })
            },

            //创建封面（打开弹窗）
            createVersion() {
                this.dialogCoverFormVisible = true;
                this.Cover.form = {
                    id : 0,
                    title : '',
                    version_code : 0,
                    path : '',
                    is_constraint : 0,
                };
            },
            //提交创建封面
            CreateSubmitVersion(){
                this.$api.journal.version('Save', this.Cover.form,'post').then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.getListVersion();
                        this.dialogCoverFormVisible = false;
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                });
            },
            //取消提交
            CancelSubmitVersion(){
                this.dialogCoverFormVisible = false;
            },
            //编辑
            editVersionInfo(index){
                this.dialogTitleCover = '编辑版本';
                this.dialogCoverFormVisible = true;
                //对应copy的使用
                this.Cover.form = this.$copy(this.dataList[index]);
                this.VersionPatchList = [];
            },

            //分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getListCover();
            },

            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getListCover();
            },

            //文件上传相关
            handlePreviewFile(file){
                console.log(file);
            },
            handleRemoveFile(file){
                console.log(file);
                this.Cover.form.path = '';
            },
            handleExceedFile(){
                this.$message.error('当前只支持上传一个文件包');
            },
            handleSuccessFile(result){
                if(result.errCode){
                    this.$message.error(result.message);
                }
                this.Cover.form.path = result.data.object_url;
            },
        }
    }
</script>

