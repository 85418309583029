<template>
    <el-row>
        <span v-show="!isEditing" @mouseover="showEditBtn" @mouseleave="hideEditBtn">
            <span v-if="!isLoading">{{content}}</span>
            <i v-if="isLoading" class="el-icon-loading"></i>
            <el-button v-show="isShow" type="text" class="c-blue" @click="startEdit" icon="el-icon-edit-outline">修改</el-button>
        </span>
        <el-col :span="15" v-show="isEditing">
            <el-input type="text" v-focus="isEditing" :placeholder="content" v-model="newValue" @blur="finishEdit"></el-input>
        </el-col>
        <!--        <el-dialog-->
        <!--                title="数据更新"-->
        <!--                :close-on-click-modal="false"-->
        <!--                :visible.sync="isEditing"-->
        <!--                width="30%"-->
        <!--                center>-->
        <!--            <el-input v-if="isEditing" type="text" placeholder="输入内容" v-model="newValue"></el-input>-->
        <!--            <span slot="footer" class="dialog-footer">-->
        <!--                <el-button @click="cancelEdit">取 消</el-button>-->
        <!--                <el-button type="primary" @click="finishEdit">保存</el-button>-->
        <!--            </span>-->
        <!--        </el-dialog>-->
    </el-row>
</template>

<script>
    export default {
        name: "EditAbleContent",
        props: {
            content: {},
            index: {
                type: Number,
                default: -1
            }
        },
        watch: {
            content: {
                handler(val) {
                    this.isLoading = false
                    //this.newValue = val
                }
            }
        },
        directives: {
            focus: {
                update: (el, {value}) => {
                    if (value) {
                        setTimeout(() => {
                            el.children[0].focus()
                        }, 200)
                    }
                },
                inserted: (el, {value}) => {
                    el.children[0].style.height = '30px'
                    //console.log(el, value)
                    // if (value) {
                    //     el.focus();
                    // }
                }
            }
        },
        mounted() {
            //this.newValue = this.$copy(this.content)
        },
        data() {
            return {
                isShow: false,
                isEditing: false,
                newValue: '',
                isLoading: false
            }
        },
        methods: {
            startEdit() {
                this.isEditing = true
                this.newValue = ''
            },
            cancelEdit() {
                this.isEditing = false
                this.isShow = false
            },
            finishEdit() {
                this.isEditing = false
                this.isShow = false
                if (this.newValue == this.content || !this.newValue) {
                    return
                }
                this.isLoading = true
                this.$emit('changed', this.newValue, this.index)
            },
            showEditBtn() {
                this.isShow = true
            },
            hideEditBtn() {
                this.isShow = false
            }
        }
    }
</script>

<style scoped>

</style>