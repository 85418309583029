<template>
    <div>
        <el-col :span="20">
            <el-tag type="info" v-if="value==0" @click="verify">未审核</el-tag>
            <el-tag type="success" v-if="value==1" @click="verify">通过</el-tag>
            <el-tag type="warning" v-if="value==-1" @click="verify">未通过</el-tag>
        </el-col>

        <!--审核-->
        <el-dialog title="审核" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="50%">
            <el-form :model="task" :rules="formRules" status-icon ref="form" label-width="120px" class="" >

                <el-form-item label="名称：">
                    <el-col :span="8">
                        <el-input type="text" :rows="5"
                                  v-model="host.name"></el-input>
                    </el-col>
                </el-form-item>

                <!-- :on-remove = "removeOnePic" -->
                <el-form-item label="图片信息:">
                    <el-upload
                            action="/common/upload/multipart/"
                            list-type="picture-card"
                            :file-list="host.images"
                            :on-success = "upOnePic"
                            :on-remove="(file, fileList) => {removeOnePic(file, fileList)}"
                    >
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>


                <el-form-item label="描述：" prop="describe">
                    <el-col :span="10">
                        <el-input type="textarea" :rows="5"
                                  v-model="host.describe"></el-input>
                    </el-col>
                </el-form-item>

                <!--
                <el-form-item label="富文本信息：" >
                    <a @click="showContent">编辑富文本</a>
                </el-form-item>
                <el-form-item label="扩展信息：" prop="content" v-if="editInfo">
                    <el-col :span="24" >
                        <Editor :content="submit.content"  @changed="onContentChanged"/>
                    </el-col>
                </el-form-item>
                -->
                <el-form-item label="创建时间：">
                    <el-col :span="20">
                        <el-row>{{this.host.created}}</el-row>
                    </el-col>
                </el-form-item>

                <el-form-item label="过期时间：">
                    <el-col :span="20">
                        <el-row>{{this.host.expired}}</el-row>
                    </el-col>
                </el-form-item>


                <!-- 悬赏类任务需要展示的信息 -->
                <div v-if="this.host.type == 1">
                    <el-form-item label="奖励金额：">
                        <el-col :span="20">
                            <el-row>{{this.host.reward}}</el-row>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="佣金支付时间：">
                        <el-col :span="20">
                            <el-row>{{this.host.reward_paid_date}}</el-row>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="单笔奖励：">
                        <el-col :span="20">
                            <el-row>{{this.host.reward_amount_pre_user}}</el-row>
                        </el-col>
                    </el-form-item>
                </div>

                <!-- 商品类任务需要展示的信息 -->
                <div v-if="this.host.type == 2">

                    <el-form-item label="单价：">
                        <el-col :span="20">
                            <el-row>{{this.host.price}}</el-row>
                        </el-col>
                    </el-form-item>

                    <el-form-item label="单位：">
                        <el-col :span="5">
                                <el-input type="text"
                                          v-model="host.unit"></el-input>
<!--                            <el-row>{{this.host.unit}}</el-row>-->
                        </el-col>
                    </el-form-item>

                    <el-form-item label="佣金比例：">
                        <el-col :span="20">
                            <el-row>{{this.host.commission_ratio}}</el-row>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="库存：">
                        <el-col :span="20">
                            <el-row>{{this.host.stock}}</el-row>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="邮寄方式：">
                        <el-col :span="20">
                            <el-row>{{this.host.deliver_mode}}</el-row>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="邮费：">
                        <el-col :span="20">
                            <el-row>{{this.host.deliver_fee}}</el-row>
                        </el-col>
                    </el-form-item>
                </div>

                <!-- 卡券类任务需要展示的信息 -->
                <div v-if="this.host.type == 3">
                    <el-form-item label="商家名称：">
                        <el-col :span="15">
                            <el-input type="text"
                                      v-model="host.shop_name"></el-input>
<!--                            <el-row>{{this.host.shop_name}}</el-row>-->
                        </el-col>
                    </el-form-item>
                    <el-form-item label="商家地址：">
                        <el-col :span="15">
                            <el-input type="text"
                                      v-model="host.shop_address"></el-input>
<!--                            <el-row>{{this.host.shop_address}}</el-row>-->
                        </el-col>
                    </el-form-item>
                    <el-form-item label="商家联系方式：">
                        <el-col :span="15">
                            <el-input type="text"
                                      v-model="host.shop_contact_number"></el-input>
<!--                            <el-row>{{this.host.shop_contact_number}}</el-row>-->
                        </el-col>
                    </el-form-item>

                    <el-form-item label="具体位置：">
                        <el-col :span="15">
                            <el-input type="text"
                                      v-model="host.shop_address_concrete"></el-input>
                        </el-col>
                    </el-form-item>

                    <el-form-item label="同步微信卡包：">
                        <el-col :span="20">
                            <el-row>{{this.host.is_put_in_wxcard}}</el-row>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="发放佣金：">
                        <el-col :span="20">
                            <el-row>{{this.host.commission}}</el-row>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="单个佣金金额：">
                        <el-col :span="20">
                            <el-row>{{this.host.commission_pre_cost}}</el-row>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="支付时间：">
                        <el-col :span="20">
                            <el-row>{{this.host.commission_paid_date}}</el-row>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="是否需要购买：">
                        <el-col :span="20">
                            <el-row>{{this.host.is_need_pay}}</el-row>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="卡券价格：">
                        <el-col :span="20">
                            <el-row>{{this.host.price}}</el-row>
                        </el-col>
                    </el-form-item>
                </div>


                <el-form-item label="审核状态：">
                    <el-col :span="20">
                        <el-row>{{this.host.verify_status}}</el-row>
                    </el-col>
                </el-form-item>


                <el-form-item label="审核时间：">
                    <el-col :span="20">
                        <el-row>{{this.host.verify_date}}</el-row>
                    </el-col>
                </el-form-item>

                <el-form-item label="设为推荐任务" prop="is_recommend">
                    <el-col :span="20">
                        <el-radio-group v-model="host.is_recommend">
                            <el-radio label="1">是</el-radio>
                            <el-radio label="0">否</el-radio>
                        </el-radio-group>
                    </el-col>
                </el-form-item>

                <el-form-item label="推荐值：">
                    <el-col :span="8">
                        <el-input type="number" :rows="5"
                                  v-model="host.recommend_value"></el-input>
                    </el-col>
                </el-form-item>


                <el-form-item label="审核状态" prop="status">
                    <el-col :span="20">
                        <el-radio-group v-model="task.status">
                            <el-radio label="0">未审核</el-radio>
                            <el-radio label="1">通过</el-radio>
                            <el-radio label="-1">不通过</el-radio>
                        </el-radio-group>
                    </el-col>
                </el-form-item>

                <el-form-item label="拒绝理由：" prop="remark" v-if="task.status==-1">
                    <el-col :span="20">
                        <el-input type="textarea" :rows="3" placeholder="拒绝理由" v-model="task.remark"></el-input>
                    </el-col>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="upItemInfo()" :loading="isLoading">内容更新</el-button>
                <el-button type="primary" @click="verifySubmit()" :loading="isLoading">审核更新</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import ImagePreview from '../../../components/ImagePreview.vue'
    import Editor from '../../../components/editor/Editor'

    export default {
        name: "TaskStatus",
        components: {
            ImagePreview, Editor
        },
        props: {
            id: {
                type: String
            },
            value: {}
        },
        watch: {
            id(v) {
                this.task.task_id = v
            },
            value(v) {
                this.task.status = v
            }
        },
        data() {
            return {
                isLoading: false,
                dialogFormVisible: false,
                task: {
                    task_id: this.id,
                    status: this.value,
                    remark: null,
                    name:this.name
                },
                host:{
                    content:''
                },
                submit:{
                    'name' : '',
                    'describe' : '',
                    'content' : '',
                    'images' : '',
                },
                formRules: {
                    status: {required: true, message: '请选择审核状态', trigger: 'blur'},
                },
                dialogImageUrl: '',
                dialogVisible: false,
                disabled: false,
                editInfo: false
            }
        },
        mounted() {
            // 查询对应载体的信息
            // this.task.task_id = this.id
            // this.task.status = this.status
        },
        methods: {
            verify() {
                this.editInfo = false;
                this.dialogFormVisible = true;
                this.$api.zx.task('HostDetail', {task_id:this.task.task_id}, 'get').then(result => {
                    if (result.errCode) {
                        this.dialogFormVisible = false;
                        this.$message.alert(result.message)
                    } else {
                        this.host = result.data;
                    }
                }).catch(error => {
                    this.$message.error(error)
                }).finally(() => {
                    this.isLoading = false
                })
            },
            verifySubmit() {
                this.$message.confirm({message: '确定更改审核状态码?'}, () => {
                    this.isLoading = true
                    this.$api.zx.task('verify', this.$copy(this.task), 'post').then(result => {
                        if (result.errCode) {
                            this.$message.alert(result.message)
                        } else {
                            this.$emit('input', this.task.status)
                            this.dialogFormVisible = false
                        }
                    }).catch(error => {
                        this.$message.error(error)
                    }).finally(() => {
                        this.isLoading = false
                    })
                })
            },

            onContentChanged(content) {
                this.submit.content = content
            },
            //编辑载体信息
            upItemInfo(){
                this.isLoading = true
                this.submit.item_id = this.host.item_id;
                this.submit.type = this.host.type;
                this.submit.name = this.host.name;
                this.submit.describe = this.host.describe;
                //this.submit.content = this.host.content;
                this.submit.images = this.host.images;
                this.submit.is_recommend = this.host.is_recommend;
                this.submit.recommend_value = this.host.recommend_value;
                switch (this.host.type) {
                    case 1:

                        break;
                    case 2:
                        this.submit.unit = this.host.unit;
                    break;
                    case 3:
                        this.submit.shop_name = this.host.shop_name;
                        this.submit.shop_address = this.host.shop_address;
                        this.submit.shop_address_concrete = this.host.shop_address_concrete;
                        this.submit.shop_contact_number = this.host.shop_contact_number;
                        break;
                }
                this.$api.zx.task('HostEdit', this.submit, 'post').then(result => {
                    if (result.errCode) {
                        this.$message.alert(result.message)
                    } else {
                        this.dialogFormVisible = false
                    }
                }).catch(error => {
                    this.$message.error(error)
                }).finally(() => {
                    this.isLoading = false
                })

            },
            //增加图片 resources为上传图片的信息
            upOnePic(resources){
                let addInfo = {}
                addInfo.name = resources.data.file;
                addInfo.url = resources.data.object_url;
                this.host.images.push(addInfo);
                console.log(this.host.images);
            },

            //移除后，返回的第二个参数，就是当前图片列表
            removeOnePic(file,fileList){
                console.log(file);
                console.log(fileList);
                console.log(this.host.images);
                this.host.images = fileList;
                console.log(this.host.images);
            },

            showContent(){
                this.editInfo = false;
                this.submit.content = '';
                let _this = this;
                // 2秒延迟，保证先加载组件，再更新扩展内容
                setTimeout(function(){
                    _this.editInfo = true;
                }, 1000)
                setTimeout(function(){
                    _this.submit.content = _this.host.content;
                }, 1000)

            },
            cancelContent(){
                this.editInfo = false;
            },

        }
    }
</script>
<style scoped>

</style>