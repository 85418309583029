<template>
  <div>
    <div class="settopLogo">
      <div class="bannerTitle">顶部logo：</div>
      <div>
        <img
          style="height: 100px; width: 150px"
          :src="topimg"
          alt=""
          srcset=""
        />
      </div>
      <div v-if="topimgShow == 1" style="padding-top: 68px; margin-left: 20px">
        <el-button
          @click="setnew(1)"
          class="el-icon-upload2"
          size="small"
          type="primary"
          >上传</el-button
        >
      </div>
      <div v-else style="padding-top: 68px; margin-left: 20px">
        <el-button
          @click="setnew(1)"
          class="el-icon-edit"
          size="small"
          type="primary"
          >修改</el-button
        >
      </div>
      <input
        id="uploadImage1"
        type="file"
        ref="fileInput1"
        accept="image/*"
        @change="getFile(1)"
        style="display: none"
      />
    </div>

    <div class="settopLogo">
      <div class="bannerTitle">背景图：</div>
      <div>
        <img
          style="height: 100px; width: 150px"
          :src="bgimg"
          alt=""
          srcset=""
        />
      </div>
      <div v-if="bgimgShow == 1" style="padding-top: 68px; margin-left: 20px">
        <el-button
          @click="setnew(2)"
          class="el-icon-upload2"
          size="small"
          type="primary"
          >上传</el-button
        >
      </div>
      <div v-else style="padding-top: 68px; margin-left: 20px">
        <el-button
          @click="setnew(2)"
          class="el-icon-edit"
          size="small"
          type="primary"
          >修改</el-button
        >
      </div>
      <input
        id="uploadImage2"
        type="file"
        ref="fileInput2"
        accept="image/*"
        @change="getFile(2)"
        style="display: none"
      />
    </div>

    <div class="settopLogo">
      <div class="bannerTitle">背景图logo：</div>
      <div>
        <img
          style="height: 100px; width: 150px"
          :src="bglogo"
          alt=""
          srcset=""
        />
      </div>
      <div v-if="bglogoShow == 1" style="padding-top: 68px; margin-left: 20px">
        <el-button
          @click="setnew(3)"
          class="el-icon-upload2"
          size="small"
          type="primary"
          >上传</el-button
        >
      </div>
      <div v-else style="padding-top: 68px; margin-left: 20px">
        <el-button
          @click="setnew(3)"
          class="el-icon-edit"
          size="small"
          type="primary"
          >修改</el-button
        >
      </div>
      <input
        id="uploadImage3"
        type="file"
        ref="fileInput3"
        accept="image/*"
        @change="getFile(3)"
        style="display: none"
      />
    </div>

    <div style="width: 20%; margin: 40px auto 0px auto">
      <el-button style="width: 100%" type="primary" @click="sure"
        >修改</el-button
      >
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      // list:[{name:'顶部logo：'},{name:'背景图：'},{name:'背景图logo：'}],
      topimg: require("@/assets/images/bg.png"),
      bgimg: require("@/assets/images/bg.png"),
      bglogo: require("@/assets/images/bg.png"),
      topimgShow: 1,
      bgimgShow: 1,
      bglogoShow: 1,
      id: "",
    };
  },
  created() {
    this.isLoading = true;
    this.$api.website
      .bannerList("List", { page: 1, size: 1 }, "get")
      .then((result) => {
        console.log(result);
        this.id = result.data.list[0].id;
        if (result.data.list[0].top_log != "") {
          this.topimgShow = 2;
          this.topimg = result.data.list[0].top_log;
        }

        console.log(result);
        if (result.data.list[0].banner != "") {
          this.bgimgShow = 2;
          this.bgimg = result.data.list[0].banner;
        }

        console.log(result);
        if (result.data.list[0].middle_log != "") {
          this.bglogoShow = 2;
          this.bglogo = result.data.list[0].middle_log;
        }
      })
      .catch((error) => {
        this.$message.error(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  mounted() {},
  watch: {},
  methods: {
    setnew: function (e) {
      console.log(this.$refs);
      if (e == 1) {
        this.$refs.fileInput1.click();
      } else if (e == 2) {
        this.$refs.fileInput2.click();
      } else {
        this.$refs.fileInput3.click();
      }
    },
    getFile: function (e) {
      var that = this;
      console.log(e);
      var file = "";
      if (e == 1) {
        file = document.getElementById("uploadImage1").files[0];
      } else if (e == 2) {
        file = document.getElementById("uploadImage2").files[0];
      } else {
        file = document.getElementById("uploadImage3").files[0];
      }

      console.log(file); //由打印的可以看到，图片    信息就在files[0]里面
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (data) => {
        console.log(data);
      };
      let formData = new FormData(); //通过formdata上传
      formData.append("file", file);
      axios.post("/common/upload/multipart", formData).then((res) => {
        console.log(res.data.data);
        //   预览的图片
        if (e == 1) {
          this.topimgShow = 2;
          this.topimg = res.data.data.object_url;
        } else if (e == 2) {
          this.bgimgShow = 2;
          this.bgimg = res.data.data.object_url;
        } else {
          this.bglogoShow = 2;
          this.bglogo = res.data.data.object_url;
        }

        console.log(this.topimg);
      });
    },
    sure: function () {
      this.isLoading = true;
      this.$api.website
        .bannerSave({
          top_log: this.topimg,
          middle_log: this.bglogo,
          banner: this.bgimg,
          name: "1",
          id: this.id,
        })
        .then((result) => {
          console.log(result);
          this.isLoading = false;
          // if (result.errCode) {
          //     return this.$message.error(result.message)
          // }
          this.$message.success("修改成功");
          // User.logout(() => {
          //     this.$router.push('/login')
          // })
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style>
.el-icon-upload2:before {
  float: right;
}
.el-icon-edit:before {
  float: right;
}
.settopLogo {
  display: flex;
  justify-items: center;
}
.bannerTitle {
  width: 100px;
}
</style>