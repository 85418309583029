<template>
    <el-tabs v-model="activeName" @tab-click="changeTab">
        <!--元素信息-->
        <el-tab-pane label="元素信息" name="first">
                <!--搜索栏-->
                <el-form :inline="true" :model="searchParams" ref="searchForm" label-width="90px" class="demo-form-inline">
                    <el-form-item prop="keyword" class="ml-10">
                        <el-input v-model="searchParams.keyword" placeholder="关键字"></el-input>
                    </el-form-item>

                    <el-form-item prop="sort_id" class="ml-10">
                        <el-select v-model="searchParams.element_type" placeholder="元素类别" @change="getSortList($event)">
                            <el-option label="全部" value= 0 ></el-option>
                            <el-option
                                    v-for="(item,index) in AllElementType"
                                    :key="index"
                                    :label="item"
                                    :value="index">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item prop="sort_id" class="ml-10" v-if="searchParams.element_type != '0'">
                        <el-select v-model="searchParams.sort_id" placeholder="子类信息">
                            <el-option label="全部" value= 0 ></el-option>
                            <el-option
                                    v-for="(item,index) in sortList"
                                    :key="item.id"
                                    :label="item.sort_name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-button icon="el-icon-search" type="primary" @click="getListElement()" :loading="isLoading">查询</el-button>
                        <el-button icon="el-icon-plus" type="primary" @click="createElement('Element')" :loading="isLoading">创建元素</el-button>
                    </el-form-item>

                </el-form>
                <!--表单信息-->
                <el-table
                        :data="elementDataList"
                        stripe
                        style="width: 100%"
                        row-key="id">
                    <el-table-column
                            prop="id"
                            label="ID"
                    >
                    </el-table-column>

                    <el-table-column
                            prop="name"
                            label="元素名称">
                    </el-table-column>

                    <el-table-column
                            prop="element_type_desc"
                            label="元素归属">
                    </el-table-column>

                    <el-table-column
                            prop="sort_name"
                            label="元素类别">
                    </el-table-column>

                    <el-table-column
                            prop="cover"
                            label="元素图片">
                        <template slot-scope="scope">
                            <image-preview :file="scope.row.cover"/>
                        </template>
                    </el-table-column>

                    <el-table-column
                            prop="weight"
                            label="排序权重">
                    </el-table-column>

                    <el-table-column
                            prop="forbidden_desc"
                            label="是否禁用">
                    </el-table-column>

                    <!--
                    <el-table-column
                            prop="using_time_desc"
                            label="启用时间">
                        <template slot-scope="scope">
                            {{scope.row.using_time_desc}}
                        </template>
                    </el-table-column>
                    -->

                    <el-table-column
                            prop="created"
                            label="创建时间">
                        <template slot-scope="scope">
                            {{scope.row.create_time|time}}
                        </template>
                    </el-table-column>

                    <el-table-column
                            label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" class="c-blue" @click="confirmElementRemove(scope.row.id,'Element')" icon="el-icon-help">
                                <a v-if="scope.row.is_forbidden == 0">禁用</a>
                                <a v-if="scope.row.is_forbidden == 1">启用</a>
                            </el-button>
                            <el-button type="text" class="c-blue" @click="editElementInfo(scope.$index)" icon="el-icon-edit-outline">编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!--翻页-->
                <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="searchParams.page"
                        :page-sizes="[10, 15, 20,50,100]"
                        :page-size="searchParams.size"
                        layout="total,sizes, prev, pager, next"
                        :total="searchParams.count">
                </el-pagination>

                <!--弹窗,通过el-dialog来进行限制-->
                <el-dialog :title="dialogTitleElement" :visible.sync="dialogElementFormVisible" :close-on-click-modal="false" width="50%">
                    <el-form :model="Element.form" label-width="80px">
                        <!--输入框-->
                        <el-form-item label="元素名称" required="">
                            <el-col :span="8">
                                <el-input v-model="Element.form.name" clearable></el-input>
                            </el-col>
                        </el-form-item>

                        <el-form-item label="元素归属" prop="element_type" required="">
                            <el-select v-model="Element.form.element_type" placeholder="请选择元素归属类别" @change="getUsingSortList($event,'select')" :disabled=isEdit>
                                <el-option label="选择归属" value= '0' ></el-option>
                                <el-option
                                        v-for="(item,index) in AllElementType"
                                        :key="index"
                                        :label="item"
                                        :value="index">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="元素类别" prop="sort_id" required="" v-if="Element.form.element_type != '0'">
                            <el-select v-model="Element.form.sort_id" placeholder="请选择家具类别" :disabled=isEdit>
                                <el-option label="选择类别" value= 0 ></el-option>
                                <el-option
                                        v-for="(item,index) in AllCanSortList"
                                        :key="item.id"
                                        :label="item.sort_name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <!--单选框 传入的值为label-->
                        <!--
                        <el-form-item label="使用权限" required="">
                            <el-radio-group v-model="Element.form.authority">
                                <el-radio
                                        v-for="(item,index) in AllAuthorityList"
                                        :label="index"
                                        :value="index">
                                    {{item}}
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item label="蜜罐价格" required="" v-if="Element.form.authority == 5">
                            <el-col :span="8">
                                <el-input v-model.number="Element.form.pot"></el-input>
                            </el-col>
                        </el-form-item>

                        <el-form-item label="蜂蜜价格" required="" v-if="Element.form.authority == 4">
                            <el-col :span="8">
                                <el-input v-model.number="Element.form.honey"></el-input>
                            </el-col>
                        </el-form-item>
                        -->
                        <el-form-item label="元素图片" required="">
                            <el-upload
                                    action="/common/upload/multipart/"
                                    list-type="picture-card"
                                    :on-preview="handlePictureCardPreview"
                                    :on-remove="handleRemoveElement"
                                    :on-success="handlePictureSuccessElement"
                                    :limit="1"
                                    :file-list="ElementPicList"
                            >
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisibleElement">
                                <img width="100%" :src="dialogImageUrlElement" alt="">
                            </el-dialog>
                        </el-form-item>

                        <el-form-item label="排序权重" required="">
                            <el-col :span="8">
                                <el-input v-model.number="Element.form.weight"></el-input>
                            </el-col>
                        </el-form-item>

                        <el-form-item label="字体资源"  v-if="Element.form.element_type == 4">
                            <el-upload
                                    style="text-align:left; display: inline-block;"
                                    action="/common/upload/multipart/"
                                    :on-preview="handlePreviewFileStyle"
                                    :on-remove="handleRemoveFileStyle"
                                    :on-success="handleSuccessFileStyle"
                                    multiple
                                    :limit="1"
                                    :on-exceed="handleExceedFileStyle"
                                    :file-list="FontStylePathList"
                            >
                                <el-button size="small" type="primary">点击上传</el-button>
                                <div slot="tip" class="el-upload__tip"></div>
                            </el-upload>
                        </el-form-item>


                        <!--时间插件-->
                        <!--
                        <el-form-item label="启用时间" >
                            <el-col :span="11">
                                <el-form-item prop="date1">
                                    <el-date-picker type="date" placeholder="选择日期" v-model="Element.form.date_info" style="width: 100%;"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col class="line" :span="2">-</el-col>
                            <el-col :span="11">
                                <el-form-item prop="date2">
                                    <el-time-picker placeholder="选择时间" v-model="Element.form.time_info" style="width: 100%;"></el-time-picker>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        -->
                        <el-form-item>
                            <el-button type="primary"  @click="CreateSubmitElement('Element')">立即创建</el-button>
                            <el-button  @click="CancelSubmitElement('Element')">取消</el-button>
                        </el-form-item>
                    </el-form>
                </el-dialog>

        </el-tab-pane>
        <!--元素类别-->
        <el-tab-pane label="元素类别" name="second">

            <!--搜索栏-->
            <el-form :inline="true" :model="searchSortParams" label-width="90px" class="demo-form-inline">

                <el-form-item prop="keyword" class="ml-10">
                    <el-input v-model="searchSortParams.keyword" placeholder="关键字"></el-input>
                </el-form-item>

                <el-form-item prop="sort_id" class="ml-10">
                    <el-select v-model="searchSortParams.type" placeholder="元素类别">
                        <el-option label="全部" value= 0 ></el-option>
                        <el-option
                                v-for="(item,index) in AllElementType"
                                :key="index"
                                :label="item"
                                :value="index">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button icon="el-icon-search" type="primary" @click="getAllElementSort()" :loading="isLoading">查询</el-button>
                    <el-button icon="el-icon-plus" type="primary" @click="createElement('sort')" :loading="isLoading">创建类别</el-button>
                </el-form-item>
            </el-form>

            <!--表格信息-->
            <el-table
                    :data="AllSortList"
                    stripe
                    style="width: 100%"
                    row-key="id">
                <el-table-column
                        prop="id"
                        label="ID"
                >
                </el-table-column>

                <el-table-column
                        prop="sort_name"
                        label="类别名称">
                </el-table-column>
                <el-table-column
                    prop="create_author"
                    label="作者">
                </el-table-column>

                <el-table-column
                        prop="sort_type_desc"
                        label="类别归属">
                </el-table-column>

                <el-table-column
                        prop="cover"
                        label="类别图片">
                    <template slot-scope="scope">
                        <image-preview :file="scope.row.cover"/>
                    </template>
                </el-table-column>

                <el-table-column
                        prop="weight"
                        label="排序权重">
                </el-table-column>

                <el-table-column
                        prop="authority_desc"
                        label="使用权限">
                </el-table-column>
                <el-table-column prop="is_register_free" label="是否赠送">
                  <template slot-scope="scope">
                    <a v-if="scope.row.is_register_free == 0">否</a>
                    <a v-if="scope.row.is_register_free == 1">是</a>
                  </template>
                </el-table-column>

                <el-table-column
                        prop="forbidden_desc"
                        label="是否禁用">
                </el-table-column>

                <el-table-column
                        prop="created"
                        label="创建时间">
                    <template slot-scope="scope">
                        {{scope.row.create_time|time}}
                    </template>
                </el-table-column>

                <el-table-column
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" class="c-blue" @click="confirmElementRemove(scope.row.id)" icon="el-icon-help">
                            <a v-if="scope.row.is_forbidden == 0">禁用</a>
                            <a v-if="scope.row.is_forbidden == 1">启用</a>
                        </el-button>

                        <el-button type="text" class="c-blue" @click="editSortInfo(scope.$index)" icon="el-icon-edit-outline">编辑
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!--弹窗,通过el-dialog来进行限制-->
            <el-dialog :title="dialogTitleSort" :visible.sync="dialogSortFormVisible" :close-on-click-modal="false" width="50%">
                <el-form :model="AllSort.form" label-width="80px">
                    <!--输入框-->
                    <el-form-item label="名称" required="">
                        <el-col :span="8">
                            <el-input v-model="AllSort.form.sort_name" clearable ></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="作者" required="">
                      <el-col :span="8">
                        <el-input v-model="AllSort.form.create_author" clearable ></el-input>
                      </el-col>
                    </el-form-item>

                    <el-form-item label="类别归属" prop="sort_type" required="">
                        <el-select v-model="AllSort.form.sort_type" placeholder="请选择类别">
                            <el-option label="选择类别" value= 0 ></el-option>
                            <el-option
                                    v-for="(item,index) in AllElementType"
                                    :key="index"
                                    :label="item"
                                    :value="index">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <!--单选框 传入的值为label-->
                    <el-form-item label="使用权限" required="">
                        <el-radio-group v-model="AllSort.form.authority">
                            <el-radio
                                    v-for="(item,index) in AllAuthorityList"
                                    :label="index"
                                    :value="index">
                                {{item}}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="蜜罐价格" required="" v-if="AllSort.form.authority == 5">
                        <el-col :span="8">
                            <el-input v-model.number="AllSort.form.pot"></el-input>
                        </el-col>
                    </el-form-item>

                    <el-form-item label="蜂蜜价格" required="" v-if="AllSort.form.authority == 4">
                        <el-col :span="8">
                            <el-input v-model.number="AllSort.form.honey"></el-input>
                        </el-col>
                    </el-form-item>

                    <el-form-item label="商城商品" required="">
                        <el-radio v-model="AllSort.form.is_shopping" label="0">否</el-radio>
                        <el-radio v-model="AllSort.form.is_shopping" label="1">是</el-radio>
                    </el-form-item>
                    <el-form-item label="注册赠送" required="">
                      <el-radio v-model="AllSort.form.is_register_free" label="0" > 否 </el-radio>
                      <el-radio v-model="AllSort.form.is_register_free" label="1" > 是 </el-radio>
                    </el-form-item>

                    <el-form-item label="支持变色" required="" v-if="AllSort.form.sort_type == 6">
                        <el-radio v-model="AllSort.form.fill_color" label="0">否</el-radio>
                        <el-radio v-model="AllSort.form.fill_color" label="1">是</el-radio>
                    </el-form-item>

                    <el-form-item label="类别图片" required="">
                        <el-upload
                                action="/common/upload/multipart/"
                                list-type="picture-card"
                                :on-preview="handleSortPictureCardPreview"
                                :on-remove="handleRemoveSortPic"
                                :on-success="handleSortPictureSuccess"
                                :limit="1"
                                :file-list = "SortPicList"
                        >
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="dialogSortVisible">
                            <img width="100%" :src="dialogSortImageUrl" alt="">
                        </el-dialog>
                    </el-form-item>


                    <el-form-item label="列表图：" v-if="AllSort.form.sort_type == 6">
                        <image-uploader v-model="AllSort.form.shopping_list_cover" dt="url"/>
                    </el-form-item>

                    <el-form-item label="详情图：" v-if="AllSort.form.sort_type == 6">
                        <image-uploader v-model="AllSort.form.shopping_cover" dt="url"/>
                    </el-form-item>




                    <el-form-item label="排序权重" required="">
                        <el-col :span="8">
                            <el-input v-model.number="AllSort.form.weight"></el-input>
                        </el-col>
                    </el-form-item>


                    <!--启用时间-->
                    <el-form-item label="启用时间" >
                        <el-col :span="11">
                            <el-form-item prop="date1">
                                <el-date-picker type="date" placeholder="选择日期" v-model="AllSort.form.date_info" style="width: 100%;"></el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col class="line" :span="2">-</el-col>
                        <el-col :span="11">
                            <el-form-item prop="date2">
                                <el-time-picker placeholder="选择时间" v-model="AllSort.form.time_info" style="width: 100%;"></el-time-picker>
                            </el-form-item>
                        </el-col>
                    </el-form-item>

                    <el-form-item label="元素图包"  v-if="AllSort.form.id == 0">
<!--                        <el-upload-->
<!--                                style="text-align:left; display: inline-block;"-->
<!--                                action="/common/upload/multipart/"-->
<!--                                :on-preview="handlePreviewFile"-->
<!--                                :on-remove="handleRemoveFile"-->
<!--                                :on-success="handleSuccessFile"-->
<!--                                multiple-->
<!--                                :limit="1"-->
<!--                                :on-exceed="handleExceedFile"-->
<!--                                :file-list="elementPatchList"-->
<!--                        >-->
                          <el-upload
                              style="text-align:left; display: inline-block;"
                              action="/common/upload/multipart_compressed_package/"
                              :on-preview="handlePreviewFile"
                              :on-remove="handleRemoveFile"
                              :on-success="handleSuccessFile"
                              multiple
                              :limit="1"
                              :on-exceed="handleExceedFile"
                              :file-list="elementPatchList"
                          >
                            <el-button size="small" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip"></div>
                        </el-upload>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary"  @click="CreateSubmitElement('sort')">立即创建</el-button>
                        <el-button  @click="CancelSubmitElement('sort')">取消</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>

        </el-tab-pane>
    </el-tabs>

</template>

<script>

    import ImagePreview from '../../components/ImagePreview'
    import ImageUploader from '../../components/ImageUploader'

    export default {
        name: "Element",
        components: {
            ImagePreview, ImageUploader
        },
        mounted() {
            this.getListElement();
            this.getAllElementSort();
            this.getAllAuthorityList();
            this.getAllElementType();
        },
        data() {
            return {
                isLoading: false,
                //搜索传参
                searchParams: {
                    keyword: '',
                    sort_id: '0',
                    page: 1,
                    count: 0,
                    size: 10,
                    element_type: '0'
                },
                searchSortParams: {
                    keyword: '',
                    type: '0',
                    scene: 1,
                    element_flag:1
                },
                //弹窗表单
                dialogTitleElement:'创建元素',
                dialogElementFormVisible:false,
                Element:{
                    form:{
                        id : 0,
                        name : '',
                        sort_id : '0',
                        cover : '',
                        authority : '0',
                        weight : '0',
                        is_forbidden : '0',
                        element_type: '0',
                        honey:0,
                        pot:0,
                        file_path:''
                    }
                },
                //家具基础列表
                elementDataList: [],
                activeName: 'first',
                //图片上传相关
                dialogImageUrlElement:'',
                dialogVisibleElement: false,
                ElementPicList:[],
                //类别相关
                //类别基础列表
                sortList:[],
                dialogTitleSort:'创建类别',
                dialogSortFormVisible:false,
                AllSort:{
                    form:{
                        id : 0,
                        sort_name : '',
                        create_author: '',
                        cover : '',
                        sort_type : '0',
                        authority : '1',
                        weight : 0,
                        is_forbidden : 0,
                        honey:0,
                        pot:0,
                        is_shopping:'0',
                        resource:'',
                        date_info:'',
                        time_info:'',
                        fill_color:'0',
                        is_register_free: '0',
                        shopping_cover:'',
                        shopping_list_cover:'',
                    }
                },
                //获取元素的所有分类(列表展示)
                AllSortList:[],
                //创建家具时，筛选出可用分类（筛选使用）
                AllCanSortList:[],
                //展示大图时对应的图片地址
                dialogSortImageUrl:'',
                //是否开启大图展示
                dialogSortVisible: false,
                SortPicList:[],
                //获取所有权限信息
                AllAuthorityList:[],
                //所有元素大类
                AllElementType:[],
                //元素图片资源压缩包
                elementPatchList:[],
                //字体资源包
                FontStylePathList:[],
                isEdit:false,
            }
        },
        methods: {
            //切换栏目时触发
            changeTab(tab, event) {

            },
            //获取元素列表
            getListElement() {
                this.isLoading = true;
                    this.$api.journal.element('List', this.$copy(this.searchParams)).then(result => {
                        if (result.errCode) {
                            this.$message.error(result.message);
                        } else {
                            this.elementDataList = result.data.list;
                            this.searchParams.count = result.data.total;//总条数
                        }
                    }).catch(error => {
                        this.$message.error(error);
                    }).then(() => {
                        this.isLoading = false;
                    })
            },
            //禁用元素or类别
            confirmElementRemove(id,type){
                if(!id){
                    this.$message.error('未查询到相关数据');
                    return;
                }
                if(type == 'Element'){
                    this.$confirm('是否更改使用状态?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$api.journal.element('SetChange', {id:id}).then(result => {
                            if (result.errCode) {
                                this.$message.error(result.message);
                            } else {
                                this.getListElement();
                                this.$message({
                                    type: 'success',
                                    message: '操作成功!'
                                });
                            }
                        }).catch(error => {
                            this.$message.error(error);
                        }).then(() => {
                            this.isLoading = false;
                        });
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '取消'
                        });
                    });
                }else{
                    this.$confirm('是否更改使用状态?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$api.journal.all_sort('SetChange', {id:id}).then(result => {
                            if (result.errCode) {
                                this.$message.error(result.message);
                            } else {
                                this.getAllElementSort();
                                this.$message({
                                    type: 'success',
                                    message: '操作成功!'
                                });
                            }
                        }).catch(error => {
                            this.$message.error(error);
                        }).then(() => {
                            this.isLoading = false;
                        });
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '取消'
                        });
                    });
                }
            },
            //创建元素or类别
            createElement(type) {
                if(type == 'Element') {
                    this.dialogElementFormVisible = true;
                    this.ElementPicList = [];
                    this.isEdit = false;
                    this.FontStylePathList = [];
                    this.Element.form = {
                        id : 0,
                        name : '',
                        sort_id : '',
                        cover : '',
                        authority : '0',
                        weight : '0',
                        is_forbidden : '0',
                        element_type : '0',
                    };
                }else{
                    this.dialogSortFormVisible = true;
                    this.SortPicList = [];
                    this.elementPatchList = [];
                    this.AllSort.form = {
                        id : 0,
                        sort_name : '',
                        create_author: '',
                        cover : '',
                        sort_type : '0',
                        authority : '1',
                        weight : 0,
                        is_forbidden : 0,
                        honey:0,
                        pot:0,
                        is_shopping:'0',
                        date_info:'',
                        time_info:'',
                        fill_color:'0',
                        is_register_free: '0',
                        shopping_cover:'',
                        shopping_list_cover:'',
                    };
                }
            },
            //提交
            CreateSubmitElement(type){
                //家具相关
                if(type == 'Element'){
                    this.$api.journal.element('Save', this.Element.form,'post').then(result => {
                        if (result.errCode) {
                            this.$message.error(result.message);
                        } else {
                            this.getListElement();
                            this.dialogElementFormVisible = false;
                        }
                    }).catch(error => {
                        this.$message.error(error);
                    }).then(() => {
                        this.isLoading = false;
                    });
                }else{
                    //类别相关
                    this.$api.journal.all_sort('Save', this.AllSort.form,'post').then(result => {
                        if (result.errCode) {
                            this.$message.error(result.message);
                        } else {
                            this.getAllElementSort();
                            this.dialogSortFormVisible = false;
                        }
                    }).catch(error => {
                        this.$message.error(error);
                    }).then(() => {
                        this.isLoading = false;
                    });
                }
            },
            //取消提交
            CancelSubmitElement(type){
                if(type == 'Element'){
                    this.dialogElementFormVisible = false;
                }else{
                    this.dialogSortFormVisible = false;
                }
            },
            //编辑元素(通过列表直接带入数据信息，无需请求接口的方式)
            editElementInfo(index){
                this.isEdit = true;
                this.dialogTitleElement = '编辑元素';
                this.dialogElementFormVisible = true;
                this.ElementPicList = [];
                this.FontStylePathList = [];
                //对应copy的使用
                this.Element.form = this.$copy(this.elementDataList[index]);
                //二级联动框初始化
                this.getUsingSortList(this.Element.form.element_type,'init');
                //设置放大图片信息
                this.dialogImageUrlElement = this.Element.form.cover;
                let obj = new Object();
                obj.url = this.dialogImageUrlElement;
                this.ElementPicList.push(obj);
            },
            //家具图片处理的具体方式
            handleRemoveElement() {
                this.Element.form.cover = '';
            },
            //放大镜
            handlePictureCardPreview(file) {
                this.dialogImageUrlElement = file.url;
                this.dialogVisibleElement = true;
            },
            //上传成功
            handlePictureSuccessElement(result){
                if(result.errCode){
                    this.$message.error(result.message);
                }
                //成功的情况进行图片存储
                this.Element.form.cover = result.data.object_url;
            },


            //类别上传图片涉及的绑定事件
            //移除图片
            handleRemoveSortPic(file) {
                console.log(file);
                this.AllSort.form.cover = '';
            },
            //图片成功上传
            handleSortPictureSuccess(result){
                if(result.errCode){
                    this.$message.error(result.message);
                }
                console.log(result);
                //成功的情况进行图片存储
                this.AllSort.form.cover = result.data.object_url;
            },
            //图片放大观察
            handleSortPictureCardPreview(file) {
                this.dialogSortVisible = true;
                this.dialogSortImageUrl = file.url;
            },
            //获取所有元素类别
            getAllElementSort(){
                this.$api.journal.all_sort('SortInfo', this.$copy(this.searchSortParams)).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.AllSortList = result.data;
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                });
            },
            //编辑类别(通过列表直接带入数据信息，无需请求接口的方式)
            editSortInfo(index){
                this.dialogTitleSort = '编辑类别';
                this.dialogSortFormVisible = true;
                this.SortPicList = [];
                this.AllSort.form = this.$copy(this.AllSortList[index]);
                //设置放大图片信息
                this.dialogSortImageUrl = this.AllSort.form.cover;
                let obj = new Object();
                obj.url = this.dialogSortImageUrl;
                this.SortPicList.push(obj);
            },

            //获取所有权限类别
            getAllAuthorityList(){
                this.$api.journal.all_sort('AuthorityInfo',{flag:1}).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.AllAuthorityList = result.data;
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                });
            },
            //获取所有元素大类别
            getAllElementType(){
                this.$api.journal.element('ElementType').then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.AllElementType = result.data;
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                });
            },

            //分页相关
            // 点击分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getListElement();
            },

            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getListElement();
            },

            //联动获取元素大类下的子类
            getSortList(index){
                if(index != 0){
                    this.$api.journal.all_sort('SortInfo', {type:index}).then(result => {
                        if (result.errCode) {
                            this.$message.error(result.message);
                        } else {
                            //错误error
                            this.sortList = result.data;
                        }
                    }).catch(error => {
                        this.$message.error(error);
                    }).then(() => {
                        this.isLoading = false;
                    });
                }
                this.searchParams.sort_id = '0';
            },
            //联动获取未禁用的子类
            getUsingSortList(index,type){
                let that = this;
                if(index != 0){
                    this.$api.journal.all_sort('SortInfo', {type:index}).then(result => {
                        if (result.errCode) {
                            this.$message.error(result.message);
                        } else {
                            that.AllCanSortList = [];
                            result.data.forEach(function(value){
                                if(that.isEdit){
                                    //编辑时，所有的都要写入
                                    that.AllCanSortList.push(value);
                                }else{
                                    //新增时，只有未禁止的
                                    if(value.is_forbidden == 0){
                                        that.AllCanSortList.push(value);
                                    }
                                }
                            });
                            //每次选择后，都对二级联动菜单进行初始化
                            if(type == 'select'){
                                that.Element.form.sort_id = '0';
                            }
                        }
                    }).catch(error => {
                        this.$message.error(error);
                    }).then(() => {
                        this.isLoading = false;
                    });
                }
            },
            //文件上传相关
            handlePreviewFile(file){
                console.log(file);
            },
            handleRemoveFile(file){
                console.log(file);
                this.AllSort.form.resource = '';
            },
            handleExceedFile(){
                this.$message.error('当前只支持上传一个文件包');
            },
            handleSuccessFile(result){
                if(result.errCode){
                    this.$message.error(result.message);
                }
                this.AllSort.form.resource = result.data.object_url;
            },

            //字体资源上传相关
            handlePreviewFileStyle(file){
                console.log(file);
            },
            handleRemoveFileStyle(file){
                console.log(file);
                this.Element.form.file_path = '';
            },
            handleExceedFileStyle(){
                this.$message.error('当前只支持上传一个文件包');
            },
            handleSuccessFileStyle(result){
                if(result.errCode){
                    this.$message.error(result.message);
                }
                this.Element.form.file_path = result.data.object_url;
            },
        }
    }
</script>

