<template>
    <div>
        <!--搜索栏-->
        <el-form :inline="true" :model="searchParams" ref="searchParams" class="demo-form-inline">
            <el-form-item label="" prop="keywords">
                <el-input v-model="searchParams.keyword" placeholder="关键字"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" :loading="isLoading" @click="getList()">查询
                </el-button>
                <el-button icon="el-icon-plus" type="primary" @click="add()">添加文件</el-button>
            </el-form-item>
        </el-form>
        <el-table
                :data="dataList"
                stripe
                style="width: 100%">
            <el-table-column type="expand">
                <template slot-scope="props">
                    <el-form label-position="left" inline class="demo-table-expand">
                        <el-form-item label="日志">
                            <span>{{props.row.pull_log}}</span>
                        </el-form-item>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column
                    prop="id"
                    label="ID"
                    width="50">
            </el-table-column>
            <el-table-column
                    prop="file"
                    label="文件列表">
                <template slot-scope="{row}">
                    <div v-html="row.file"></div>
                </template>
            </el-table-column>
            <el-table-column
                    prop="u_name"
                    label="提交人"
                    width="100">
            </el-table-column>
            <el-table-column
                    prop="description"
                    label="说明">
            </el-table-column>
            <el-table-column
                    prop="created"
                    label="创建"
                    width="200">
                <template slot-scope="{row}">
                    {{row.created|time('MM-DD HH:mm')}}
                </template>
            </el-table-column>
            <el-table-column
                    prop="commited"
                    label="发布">
                <template slot-scope="{row}">
                    {{row.commited|time('MM-DD HH:mm')}}
                </template>
            </el-table-column>
            <el-table-column
                    prop="finished"
                    label="完成">
                <template slot-scope="{row}">
                    {{row.finished|time('MM-DD HH:mm')}}
                </template>
            </el-table-column>
            <el-table-column
                    prop="created"
                    label="完成服务器">
                <template slot-scope="{row}">
                    <span @click="showLogs(row.pull_log)">{{row.updated_servers}}</span>
                </template>
            </el-table-column>

            <el-table-column
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="text" class="c-green" @click="commit(scope.$index)"
                               icon="el-icon-upload">发布
                    </el-button>

                    <el-button type="text" class="c-blue" @click="edit(scope.$index)"
                               icon="el-icon-edit-outline">编辑
                    </el-button>
                    <el-button type="text" class="c-red" @click="remove(scope.$index)"
                               icon="el-icon-delete">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--日志-->
        <el-drawer
                size="40%"
                title="更新日志"
                :visible.sync="drawer"
                direction="rtl">
            <el-timeline>
                <el-timeline-item v-for="(item,key) in pullLogs" :key="key" :timestamp="item.time" placement="top">
                    <el-card>
                        <h4>{{item.server}}:{{item.ip}}</h4>
                        <p v-for="(c,k) in item.content" :key="k" class="mt-10" v-html="c"></p>
                    </el-card>
                </el-timeline-item>
            </el-timeline>
        </el-drawer>
        <!--翻页-->
        <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="searchParams.page"
                :page-sizes="[10, 15, 20,50,100]"
                :page-size="searchParams.size"
                layout="total,sizes, prev, pager, next"
                :total="searchParams.count">
        </el-pagination>
        <!--表单弹框-->
        <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="50%">
            <el-form :model="form" :rules="formRules" status-icon ref="form" label-width="120px" class="">
                <el-form-item label="文件列表：" prop="files">
                    <el-col :span="20">
                        <el-input type="textarea" :rows="10"
                                  :placeholder="filesPlaceHolder"
                                  v-model="form.files"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="版本说明：" prop="description">
                    <el-col :span="20">
                        <el-input type="textarea" :rows="3" placeholder="更新功能介绍" v-model="form.description"></el-input>
                    </el-col>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="save()" :loading="isLoading">保 存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "Updater",
        mounted() {
            this.getList()
        },
        data() {
            return {
                filesPlaceHolder: "src/view/index/default/index/index.html\nsrc/lib/Common/Model/Updater.php",
                drawer: false,
                isLoading: false,
                dialogFormVisible: false,
                dialogTitle: '添加文件',
                formRules: {
                    files: {required: true, message: '请填写文件列表', trigger: 'blur'},
                    description: {required: true, message: '请填写文件说明', trigger: 'blur'},
                },
                form: {},
                searchParams: {
                    keyword: '',
                    page: 1,
                    count: 1,
                    size: 10,
                },
                dataList: [],
                pullLogs: []
            }
        },
        methods: {
            showLogs(log) {
                if (!log.length) {
                    return
                }
                this.drawer = true
                this.pullLogs = log
            },
            //加载列表
            getList() {
                this.isLoading = true;
                this.$api.system.updater('GetList', this.searchParams).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.dataList = result.data.list;
                        this.searchParams.count = result.data.total;
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).finally(() => {
                    this.isLoading = false;
                });
            },
            //创建分组
            add() {
                this.dialogTitle = '添加文件';
                this.form = {
                    project: this.searchParams.project,
                    module: this.searchParams.module,
                    request_params: [],
                    response_params: [{key: 'errCode', type: 'String', description: '错误码,未发生错误时返回0'}, {
                        key: 'message',
                        type: 'String',
                        description: '提示信息'
                    }, {key: 'data', type: 'mixed', description: '业务数据,没有数据时返回空值'}],
                    hidden: '0'
                };
                this.dialogFormVisible = true;
            },
            edit(index) {
                this.dialogTitle = '编辑文件';
                this.form = this.dataList[index];
                this.dialogFormVisible = true;
            },
            save() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$api.system.updater('Save', this.form, 'post').then(result => {
                            if (result.errCode) {
                                this.$message.alert(result.message)
                            } else {
                                this.dialogFormVisible = false;
                                this.$message.success('保存成功');
                                this.getList()
                            }
                        }).catch(error => {
                            this.$message.error(error)
                        }).finally(() => {
                            this.isLoading = false
                        })
                    } else {
                        return false;
                    }
                });
            },
            remove(index) {
                let item = this.dataList[index]
                this.$message.confirm({message: '确定要删除吗?'}, () => {
                    this.$loading.show('删除中')
                    this.$api.system.updater('Delete', {id: item.id}, 'post').then(result => {
                        if (result.errCode) {
                            this.$message.alert(result.message)
                        } else {
                            this.getList()
                        }
                    }).catch(error => {
                        this.$message.error(error)
                    }).finally(() => {
                        this.$loading.close()
                    })
                })
            },
            commit(index) {
                let item = this.dataList[index]
                this.$message.confirm({message: '确定要发布吗?'}, () => {
                    this.$loading.show('发布中')
                    this.$api.system.updater('Commit', {id: item.id}, 'post').then(result => {
                        if (result.errCode) {
                            this.$message.alert(result.message)
                        } else {
                            this.getList()
                        }
                    }).catch(error => {
                        this.$message.error(error)
                    }).finally(() => {
                        this.$loading.close()
                    })
                })
            },
            // 点击分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getList();

            },
        }
    }
</script>