<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="changeTab">
      <!--VIP订单-->
      <el-tab-pane label="盲盒机订单" name="first">
        <div class="filter-container">
          <el-select
            v-model="purchase_type"
            style="margin-left: 30px"
            placeholder="请选择支付方式"
            clearable
          >
            <el-option
              v-for="item in payWay"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            />
          </el-select>

          <el-select
            v-model="purchase_status"
            style="margin-left: 30px"
            placeholder="请选择支付状态"
            clearable
          >
            <el-option
              v-for="item in payStatus"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            />
          </el-select>

          <div class="caption">支付时间</div>

          <el-date-picker
            v-model="registerTimes"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss" 
            format="yyyy-MM-dd HH:mm:ss"
          />

          <el-input
            v-model="transaction_id"
            placeholder="请输入流水号"
            style="width: 200px; margin-left: 20px"
          />

          <el-input
            v-model="order_num"
            placeholder="请输入订单号"
            style="width: 200px; margin-left: 20px"
          />

          <el-button
            style="margin-bottom: 0px; margin-left: 10px"
            class="filter-item"
            type="primary"
            icon="el-icon-search"
            @click="handleFilterOrder"
            >搜索</el-button
          >
        </div>

        <!--表单信息-->
        <el-table
          :data="orderList"
          stripe
          style="width: 100%"
          row-key="id"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column prop="user_id" label="用户ID" min-width="25%">
          </el-table-column>
          <el-table-column prop="order_num" label="订单号" min-width="30%">
          </el-table-column>
          <el-table-column
            prop="transaction_id"
            label="交易流水号"
             
          >
          </el-table-column>
          <el-table-column prop="purchase_price" label="价格" min-width="50%">
          </el-table-column>
          <el-table-column prop="purchase_type" label="支付方式" align="center">
            <template slot-scope="{ row }">
              <div v-if="row.purchase_type == 1">微信</div>
              <div v-if="row.purchase_type == 2">支付宝</div>
              <div v-if="row.purchase_type == 3">ios内购</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="purchase_status"
            label="订单状态"
            align="center"
          >
            <template slot-scope="{ row }">
              <div v-if="row.purchase_status == -1">已退款</div>
              <div v-if="row.purchase_status == 0">待支付</div>
              <div v-if="row.purchase_status == 1">已支付</div>
            </template>
          </el-table-column>
          <el-table-column prop="update_time" label="更新时间" min-width="50%">
          </el-table-column>
        </el-table>
        <!--翻页-->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="searchParams.page"
          :page-sizes="[10, 15, 20, 50, 100]"
          :page-size="searchParams.size"
          layout="total,sizes, prev, pager, next"
          :total="searchParams.count"
        >
        </el-pagination>
      </el-tab-pane>

      <!--活动礼包订单-->
      <el-tab-pane label="盲盒中奖信息" name="second">
        <div class="filter-container">
          <el-select
            v-model="type"
            style="margin-left: 30px"
            placeholder="请选择奖品类型"
            clearable
          >
            <el-option
              v-for="item in goodsTypeList"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            />
          </el-select>

          <el-select
            v-model="status"
            style="margin-left: 30px"
            placeholder="请选择奖品状态"
            clearable
          >
            <el-option
              v-for="item in goodsStatusList"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            />
          </el-select>

          <el-input
            v-model="userid"
            placeholder="请输入用户id"
            style="width: 200px; margin-left: 20px"
          />

          <el-input
            v-model="phone"
            placeholder="请输入收货手机号"
            style="width: 200px; margin-left: 20px"
          />

          <el-button
            style="margin-bottom: 0px; margin-left: 10px"
            class="filter-item"
            type="primary"
            icon="el-icon-search"
            @click="handleFilter"
            >搜索</el-button
          >
        </div>
        <!--表单信息-->
        <el-table
          :data="orderInfo"
          stripe
          style="width: 100%"
          row-key="id"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column prop="user_id" label="用户ID" >
          </el-table-column>
          <el-table-column
            prop="award_name"
            label="中奖奖品名称"
            min-width="30%"
          >
          </el-table-column>
          <el-table-column
            prop="award_num"
            label="中奖奖品数量"
             
          >
          </el-table-column>

          <el-table-column prop="award_type" label="奖品类型" align="center" min-width="25%">
            <template slot-scope="{ row }">
              <div v-if="row.award_type == 1">实体物品</div>
              <div v-if="row.award_type == 2">VIP</div>
              <div v-if="row.award_type == 3">蜂蜜</div>
              <div v-if="row.award_type == 4">蜜罐</div>
            </template>
          </el-table-column>
          <el-table-column prop="user_name" label="收货人姓名" >
          </el-table-column>
          <el-table-column prop="mobile" label="收货手机号" >
          </el-table-column>
          <el-table-column
            prop="shipping_address"
            label="收货地址"
          >
          </el-table-column>
          <el-table-column prop="courier_num" label="物流单号"  >
          </el-table-column>
          <el-table-column
            prop="more"
            label="操作"
            align="center"
            width="300"
            class-name="small-padding fixed-width"
          >
            <template slot-scope="{ row }">
              <el-button v-if="row.award_type==1" size="mini" type="primary" @click="setaddress(row)">发货</el-button>
              <div v-else>已领取</div>
            </template>
          </el-table-column>
        </el-table>
        <!--翻页-->
        <el-pagination
          background
          @size-change="handleActivityGiftBagOrderSizeChange"
          @current-change="handleActivityGiftBagOrderCurrentChange"
          :current-page.sync="searchParams1.page"
          :page-sizes="[10, 15, 20, 50, 100]"
          :page-size="searchParams1.size"
          layout="total,sizes, prev, pager, next"
          :total="searchParams1.count"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>

        <!-- 发放设置 -->
    <el-dialog
      v-if="dialogVisible"
      width="900px"
      top="10vh"
      :visible.sync="dialogVisible">
      <div style="  display: flex;align-items: center;;">
          <div>物流单号：</div>
          <div>          
              <el-input
                v-model="courier_num"
                placeholder="请输入物流单号"
                style="width: 200px; margin-left: 20px"/>
            </div>
      </div>
            <el-button
            style="margin-top: 20px;width:100px"
            class="filter-item"
            type="primary"
            @click="sure">确定</el-button>
            <el-button
            style="margin-left: 30px;width:100px"
            class="filter-item"
            type="primary"
            @click="dialogVisible=false">取消</el-button>

    </el-dialog>


  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      activeName: "first",
      orderList: [],
      orderInfo: [],
      searchParams: {
        page: 1,
        count: 0,
        size: 10,
      },
      searchParams1: {
        page: 1,
        count: 0,
        size: 10,
      },
      selectValue: "", //支付方式
      order_num: "", //订单号
      transaction_id: "", //流水号
      purchase_type: "", //支付方式
      purchase_status: "", //支付状态
      payWay: [], //支付方式列表
      payStatus: [], //支付状态列表
      registerTimes:[],//时间

      type: "", //奖品类型
      status: "", //奖品状态
      userid: "", //用户id
      phone: "", //电话
      goodsTypeList: [], //中奖物品类型
      goodsStatusList: [], //中奖物品状态
      dialogVisible:false,//发放弹出框
      courier_num:'',//物流单号
      goodsinfo:'',//单条数据
    };
  },
  created() {
    const self = this;
    self.getList();
    self.getorderTypeList();
    self.getorderStatusList();
    self.getInfoList();
    self.getGoodTypeList();
    self.getGoodTStatusList();
  }, 

  methods: {
      //发放物品
      setaddress(e){
          console.log(e)
          this.goodsinfo=e
          this.dialogVisible=true
      },
    //获取盲盒机订单
    getList() {
      this.isLoading = true;
      if(this.registerTimes==null){
          this.registerTimes=[]
      }
      if(this.registerTimes.length==0 ){
        
      var data = {
        order_num: this.order_num,
        transaction_id: this.transaction_id,
        purchase_type: this.purchase_type,
        purchase_status: this.purchase_status,
        page: this.searchParams.page,
        size: this.searchParams.size,
      };
      }else{
      var data = {
        order_num: this.order_num,
        transaction_id: this.transaction_id,
        purchase_type: this.purchase_type,
        purchase_status: this.purchase_status,
        startTime: (new Date(this.registerTimes[0])).getTime()/1000,
        endTime:  (new Date(this.registerTimes[1])).getTime()/1000,
        page: this.searchParams.page,
        size: this.searchParams.size,
      };
      }

      this.$api.journal
        .blindBoxOrder("List", data, "get")
        .then((result) => {
          console.log(result);
          this.orderList = result.data.list;
          this.searchParams.count=result.data.total
          
          for(let i=0;i<result.data.list.length;i++){
            result.data.list[i].update_time=this.timeStamp(result.data.list[i].update_time)
          }
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    //获取盲盒机订单的支付方式
    getorderTypeList() {
      this.isLoading = true;

      this.$api.journal
        .blindBoxOrder("PurchaseTypeList", {}, "get")
        .then((result) => {
          console.log(result);
          this.payWay = result.data;
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    //获取盲盒机订单的支付状态
    getorderStatusList() {
      this.isLoading = true;

      this.$api.journal
        .blindBoxOrder("PurchaseStatusList", {}, "get")
        .then((result) => {
          console.log(result);
          this.payStatus = result.data;
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    //盲盒中奖信息
    getInfoList() {
      this.isLoading = true;
      let data = {
        mobile: this.phone,
        user_num_id: this.userid,
        type: this.type,
        status: this.status,
        page: this.searchParams1.page,
        size: this.searchParams1.size,
      };
      this.$api.journal
        .blindBoxOrderinfo("List", data, "get")
        .then((result) => {
          console.log(result);
          this.orderInfo = result.data.list;
          this.searchParams1.count=result.data.total
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    //盲盒中奖奖品类型
    getGoodTypeList() {
      this.isLoading = true;
      this.$api.journal
        .blindBoxOrderinfo("AwardTypeList", {}, "get")
        .then((result) => {
          console.log(result);
          this.goodsTypeList = result.data;
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //盲盒中奖奖品状态
    getGoodTStatusList() {
      this.isLoading = true;
      this.$api.journal
        .blindBoxOrderinfo("AwardStatusList", {}, "get")
        .then((result) => {
          console.log(result);
          this.goodsStatusList = result.data;
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //确定发货物流
    sure(){
      let data = {
    id:this.goodsinfo.id,
	courier_num:this.courier_num,
      };
        this.$api.journal
        .blindBoxOrderinfo("DeliverGoods", data, "post")
        .then((result) => {
          console.log(result);
          if(result.errCode==0){
                    this.$message({
                                    type: 'success',
                                    message: '操作成功!'
                                })
          }
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //搜索订单
    handleFilterOrder(){
        this.getList()
    },
    //搜索信息
    handleFilter(){
        this.getInfoList()
    },
    //修改为时间戳
    timeStamp  (date) {

    let s = new Date(date * 1000);
    let y = s.getFullYear();
    let m =
        s.getMonth() + 1 < 10 ? "0" + (s.getMonth() + 1) : s.getMonth() + 1;
    let dd = s.getDate() < 10 ? "0" + s.getDate() : s.getDate();
    let hh = s.getHours() < 10 ? "0" + s.getHours() : s.getHours();
    let mm = s.getMinutes() < 10 ? "0" + s.getMinutes() : s.getMinutes();
    let ss = s.getSeconds() < 10 ? "0" + s.getSeconds() : s.getSeconds();
    let enddate = y + "-" + m + "-" + dd + " " + hh + ":" + mm + ":" + ss;
    // console.log(enddate);
    return enddate;
},

    //切换栏目时触发
    changeTab(tab, event) {
      console.log(tab);
      console.log(event);
    },

    handleSizeChange(val) {
      this.searchParams.size = val;
       this.getList()
    },

    handleCurrentChange(val) {
      this.searchParams.page = val;
       this.getList()
    },

    //分页
    handleActivityGiftBagOrderSizeChange(val) {
      this.searchParams1.size = val;
      this.getInfoList()
    },

    handleActivityGiftBagOrderCurrentChange(val) {
      this.searchParams1.page = val;
      this.getInfoList()
    },
  },
};
</script>
<style>
.filter-container {
  display: flex;
  justify-items: left;
  align-items: center;
}

.caption {
  font-size: 16px;
  color: #909399;
  font-weight: 500;
  margin-left: 30px;
}

.el-date-editor {
  margin-left: 20px;
  
}
</style>


