<template>
    <div>
        <!--搜索栏/操作栏-->
        <el-form :inline="true" :model="searchParams" ref="searchForm" label-width="90px" class="demo-form-inline">
            <el-form-item prop="title" class="ml-10">
              <el-input v-model="searchParams.title" placeholder="消息标题"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" type="primary" @click="getListMessage()" :loading="isLoading">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-plus" type="primary" @click="addMessage()" :loading="isLoading">添加消息</el-button>
            </el-form-item>
        </el-form>
        <!--表单信息-->
        <el-table :data="dataList" stripe style="width: 100%" row-key="id">
            <el-table-column prop="id" label="ID" ><template slot-scope="scope"> {{scope.row.id}} </template> </el-table-column>
          <el-table-column prop="title" label="标题">
            <template slot-scope="scope"> {{scope.row.title}} </template>
          </el-table-column>
            <el-table-column prop="pic" label="封面图片">
                <template slot-scope="scope"> <image-preview :file="scope.row.pic"/> </template>
            </el-table-column>

            <el-table-column prop="add_time" label="添加时间">
                <template slot-scope="scope"> {{scope.row.add_time|time}} </template>
            </el-table-column>

            <el-table-column prop="update_time" label="更新时间">
                <template slot-scope="scope"> {{scope.row.update_time|time}} </template>
            </el-table-column>

            <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="text" class="c-blue" @click="editMessage(scope.row.id)" icon="el-icon-edit-outline">编辑</el-button>
                  <el-button type="text" class="c-blue" @click="deleteMessage(scope.row.id)" icon="el-icon-edit-outline">删除</el-button>
                </template>
            </el-table-column>

        </el-table>
        <!--翻页-->
        <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="searchParams.page"
                :page-sizes="[10, 15, 20,50,100]"
                :page-size="searchParams.size"
                layout="total,sizes, prev, pager, next"
                :total="searchParams.count">
        </el-pagination>

    </div>


</template>
<script>

    import ImagePreview from '../../components/ImagePreview'

    export default {
        name: "MessageList",
        components: {
            ImagePreview
        },
        mounted() {
            this.getListMessage();

        },
        data() {
            return {
                isLoading: false,
                //分页传参
                searchParams: {
                  title: '',
                  page: 1,
                  count: 0,
                  size: 10,
                },
                dataList:[],
                message:{
                  form:{
                    id : 0,
                    title : '',
                    pic : '',
                    msg_content : ''
                  }
                },
                picUrl:'',
            }
        },
        methods: {
            //创建分组
            addMessage() {
              this.$router.push({path: '/message/save', query: {}})
            },
            editMessage(id) {
              this.$router.push({path: '/message/save', query: {id: id}})
            },
            //获取消息列表
            getListMessage() {
                this.isLoading = true;
                this.$api.journal.message('List', this.$copy(this.searchParams)).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.dataList = result.data.list;
                        this.searchParams.count = result.data.total;//总条数
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                })
            },
            //删除
            deleteMessage(id) {
              this.$message.confirm({message: '确定要删除该条数据吗?'}, () => {
                this.$loading.show('删除中')
                this.$api.journal.message('DeleteMessage', {id: id}, 'post').then(result => {
                  if (result.errCode) {
                    this.$message.alert(result.message)
                  } else {
                    this.$message.success('删除成功');
                    this.getListMessage()
                  }
                }).catch(error => {
                  this.$message.error(error)
                }).finally(() => {
                  this.$loading.close()
                })
              })
            },

            //分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getListMessage();
            },

            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getListMessage();
            },

        }
    }
</script>

