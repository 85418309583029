<template>
    <div>
        <!--搜索栏-->
        <el-form :inline="true" ref="searchForm" label-width="90px" class="demo-form-inline">
            <el-form-item label="售卖平台：">
              <el-select v-model="searchParams.platform_type" placeholder="平台类型">
                <el-option label="全部" value= 0 ></el-option>
                <el-option
                    v-for="(item,index) in platformList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" @click="getListVipConfig()" :loading="isLoading">查询</el-button>
                <el-button icon="el-icon-plus" type="primary" @click="createVipConfig()" :loading="isLoading">增加VIP配置</el-button>
            </el-form-item>
        </el-form>
        <!--表单信息-->
        <el-table :data="dataList" stripe style="width: 100%" row-key="id" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
            <el-table-column prop="id" label="ID" min-width="25%"> </el-table-column>
            <el-table-column prop="mark_id" label="标记ID" min-width="45%"> </el-table-column>
            <el-table-column prop="title" label="VIP名称/标签" min-width="45%">
              <template slot-scope="scope">
                  {{scope.row.title}}/{{scope.row.vip_tag}}
              </template>
            </el-table-column>
            <el-table-column prop="platform" label="平台" min-width="25%">
              <template slot-scope="scope">
                <a v-if="scope.row.platform == 1">安卓</a>
                <a v-if="scope.row.platform == 2">IOS</a>
              </template>
            </el-table-column>
            <el-table-column prop="vip_valid" label="有效期" min-width="25%">
              <template slot-scope="scope">
                <a v-if="scope.row.vip_valid == 0">永久</a>
                <a v-if="scope.row.vip_valid > 0">{{scope.row.vip_valid}}天</a>
              </template>
            </el-table-column>
            <el-table-column prop="is_discount" label="折扣状态" min-width="30%">
              <template slot-scope="scope">
                <a v-if="scope.row.is_discount == 0">关闭折扣</a>
                <a v-if="scope.row.is_discount == 1">开启折扣</a>
              </template>
            </el-table-column>
            <el-table-column prop="price" label="售价" min-width="30%"> </el-table-column>
            <el-table-column prop="final_price" label="最终售价" min-width="30%"></el-table-column>
            <el-table-column prop="vip_status" label="状态" min-width="25%">
              <template slot-scope="scope">
                <a v-if="scope.row.vip_status == 0">关闭</a>
                <a v-if="scope.row.vip_status == 1">开启</a>
              </template>
            </el-table-column>
            <el-table-column prop="renewal" label="自动续费" min-width="25%">
              <template slot-scope="scope">
                <a v-if="scope.row.renewal == 0">否</a>
                <a v-if="scope.row.renewal == 1">是</a>
              </template>
            </el-table-column>

            <el-table-column prop="vip_sort" label="排序" min-width="20%"></el-table-column>
            <el-table-column prop="create_time" label="添加时间" min-width="50%">
              <template slot-scope="scope">
                {{scope.row.create_time|time}}
              </template>
            </el-table-column>
            <el-table-column prop="update_time" label="更新时间" min-width="50%">
              <template slot-scope="scope">
                {{scope.row.update_time|time}}
              </template>
            </el-table-column>

            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" type="warning"  @click="setVipConfigStatus(scope.row.id)">
                      <a v-if="scope.row.vip_status == 0">开启购买</a>
                      <a v-if="scope.row.vip_status == 1">关闭购买</a>
                    </el-button>
                    <el-button size="mini" type="primary" @click="editVipConfigInfo(scope.$index)">
                      编辑
                    </el-button>
                    <el-button size="mini" type="danger"  @click="deleteVipConfig(scope.$index,scope.row.id)">
                      删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--翻页-->
        <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="searchParams.page"
                :page-sizes="[10, 15, 20,50,100]"
                :page-size="searchParams.size"
                layout="total,sizes, prev, pager, next"
                :total="searchParams.count">
        </el-pagination>
        <!--弹窗-->
        <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="50%">
            <el-form :model="VipConfig.form" label-width="80px">
                <!--输入框-->
                <el-form-item label="平台" required="">
                  <el-radio-group v-model="VipConfig.form.platform" ref="platform">
                    <el-radio :label=1>安卓</el-radio>
                    <el-radio :label=2>IOS</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="VIP名称" required="">
                    <el-col :span="8">
                        <el-input v-model="VipConfig.form.title" clearable></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="VIP标签" required="">
                  <el-col :span="8">
                    <el-input v-model="VipConfig.form.vip_tag" clearable></el-input>
                  </el-col>
                </el-form-item>

                <el-form-item label="有效期" property="" required="">
                    <el-col :span="8">
                        <el-input v-model.number="VipConfig.form.vip_valid" clearable></el-input>
                        <span style="color: red;">有效期单位为天，最小为0。0为永久VIP</span>
                    </el-col>
                </el-form-item>
                <!--单选框 传入的值为label-->
                <el-form-item label="开启折扣" required="">
                  <el-radio-group v-model="VipConfig.form.is_discount" ref="vip_is_discount">
                    <el-radio :label=0>不开启</el-radio>
                    <el-radio :label=1>开启</el-radio>
                  </el-radio-group>
                </el-form-item>

                <el-form-item label="售价" required="">
                  <el-col :span="8">
                    <el-input v-model="VipConfig.form.price" ref="vip_price" clearable ></el-input>
                  </el-col>
                </el-form-item>

                <el-form-item label="最终售价" required="">
                  <el-col :span="8">
                    <el-input v-model="VipConfig.form.final_price" ref="vip_final_price" clearable></el-input>
                  </el-col>
                </el-form-item>

                <!--单选框 传入的值为label-->
                <el-form-item label="售卖状态" required="">
                  <el-radio-group v-model="VipConfig.form.vip_status">
                    <el-radio :label=0>不开启售卖</el-radio>
                    <el-radio :label=1>开启售卖</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="自动续费" required="">
                  <el-radio-group v-model="VipConfig.form.renewal">
                    <el-radio :label=0>否</el-radio>
                    <el-radio :label=1>是</el-radio>
                  </el-radio-group>
                </el-form-item>

                <el-form-item label="VIP说明" required="">
                    <el-col :span="15">
                        <el-input type="textarea"  :rows="10" v-model="VipConfig.form.vip_desc"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="排序" required="">
                  <el-col :span="8">
                    <el-input v-model.number="VipConfig.form.vip_sort" clearable></el-input>
                  </el-col>
                </el-form-item>


                <el-form-item>
                    <el-button type="primary"  @click="CreateSubmitVipConfig()">保存</el-button>
                    <el-button  @click="CancelSubmitVipConfig()">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>


</template>

<script>

    export default {
        name: "VipConfigList",
        components: {},
        mounted() {
            this.getListVipConfig();
        },
        data() {
            return {
                isLoading: false,
                //搜索传参
                searchParams: {
                    platform_type: '0',
                    page: 1,
                    count: 0,
                    size: 10,
                },
                //弹窗表单
                dialogTitle:'创建VIP配置记录',
                dialogFormVisible:false,
                VipConfig:{
                    form:{
                        id : 0,
                        title : '',
                        platform: 1,
                        vip_tag: '',
                        vip_valid : 0,
                        is_discount : 0,
                        price : 0,
                        final_price : 0,
                        vip_status : 0,
                        renewal:0,
                        vip_sort : 9999,
                        vip_desc:''
                    }
                },
                //VIP列表
                dataList: [],
                //平台列表
                platformList:[
                  {value:'1',label:'安卓'},
                  {value:'2',label:'IOS'}
                ]
            }
        },
        methods: {
          //获取VIP列表
          getListVipConfig() {
                this.isLoading = true;
                this.$api.journal.vip_config('List',this.$copy(this.searchParams)).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.dataList = result.data.list;
                        this.searchParams.count = result.data.total;//总条数
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                })
            },

            //创建封面（打开弹窗）
            createVipConfig() {
                this.dialogFormVisible = true;
                this.VipConfig.form = {
                    id : 0,
                    title : '',
                    platform: 1,
                    vip_tag: '',
                    vip_valid : 0,
                    is_discount : 0,
                    price : 0,
                    final_price : 0,
                    vip_status : 0,
                    renewal:0,
                    vip_sort : 9999,
                    vip_desc:''
                };
            },
            //提交创建VIP配置
            CreateSubmitVipConfig(){
                this.$api.journal.vip_config('Save', this.VipConfig.form,'post').then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.getListVipConfig();
                        this.dialogFormVisible = false;
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                });
            },
            //取消提交
            CancelSubmitVipConfig(){
                this.dialogFormVisible = false;
            },
            //编辑
            editVipConfigInfo(index){
                this.dialogTitle = '编辑VIP配置记录';
                this.dialogFormVisible = true;
                //对应copy的使用
                this.VipConfig.form = this.$copy(this.dataList[index]);
                this.VipConfig.form.discount_rate = this.VipConfig.form.discount_rate * 10;
            },
            //设置配置
            deleteVipConfig(id) {
              this.$api.journal.vip_config('DeleteVipConfig', {id:id},'post').then(result => {
                if (result.errCode) {
                  this.$message.error(result.message);
                } else {
                  this.getListVipConfig();
                  this.dialogFormVisible = false;
                }
              }).catch(error => {
                this.$message.error(error);
              }).then(() => {
                this.isLoading = false;
              });
            },
            //设置状态
            setVipConfigStatus(id) {
              this.$api.journal.vip_config('SetStatus', {id:id},'post').then(result => {
                if (result.errCode) {
                  this.$message.error(result.message);
                } else {
                  this.getListVipConfig();
                  this.dialogFormVisible = false;
                }
              }).catch(error => {
                this.$message.error(error);
              }).then(() => {
                this.isLoading = false;
              });
            },

            upNumber(e) {
              // 输入框中只允许输入数字
              //e.target.value = e.target.value.replace(/[^\d]/g, '')
              // 输入框中只允许输入小数点和数字，小数点后只能跟2位
              e.target.value = (e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null
            },

            //分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getListVipConfig();
            },

            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getListVipConfig();
            },
        }
    }
</script>

