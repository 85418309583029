<template>
  <div>
    <div class="searchbox">
      <el-button size="medium" type="primary" @click="newadd()">新增</el-button>
      <el-input
        style="width: 200px; margin-left: 40px"
        v-model="id"
        placeholder="请输入中奖人ID"
        prefix-icon="el-icon-search"
      ></el-input>
      <el-button
        style="margin-left: 20px"
        size="medium"
        type="primary"
        icon="el-icon-search"
        @click="searchOne()"
        >查询</el-button
      >
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="id" label="序号"> </el-table-column>
      <el-table-column prop="commit_user_id" label="中奖者Id">
      </el-table-column>
      <el-table-column prop="name" label="奖品名字"> </el-table-column>
       <el-table-column prop="activity_name" label="活动名字"> </el-table-column>
      <el-table-column prop="quantity" label="奖品数量"> </el-table-column>
      <el-table-column label="奖品图片" align="center" width="200">
        <template slot-scope="{ row }">
          <img style="width: 100%" :src="row.banner" />
        </template>
      </el-table-column>
      <el-table-column prop="space" label="热度基数"> </el-table-column>
      <el-table-column
        prop="price"
        label="奖品价值"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="draw_people"
        label="参与人数"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="consume_koi"
        label="参与价格"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="video_add_probability"
        label="视频获取幸运值"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="init_video_times"
        label="视频次数"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="friend_add_probability"
        label="好友助力获取幸运值"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="init_friends_times"
        label="助力次数"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="share_add_probability"
        label="分享空间获取幸运值"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="init_share_times"
        label="分享次数"
        width="100"
      ></el-table-column>
      <el-table-column label="抽奖活动是否开奖" width="100">
        <template slot-scope="{ row }">
          <div v-show="row.is_open == 1">已开奖</div>
          <div v-show="row.is_open == 0">未开奖</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="commit_user_name"
        label="中奖人名称"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="more"
        label="操作"
        align="center"
        width="300"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="{ row }">
          <div style="display: inline-block;">
            <el-button size="mini" type="primary" @click="editInfo(row)"
              >编辑</el-button
            >
            <el-button size="mini" type="danger" @click="delShow(row.id)"
              >删除</el-button
            >
          </div>
          <div style="display: inline-block;"  v-if="row.true_name != ''">
            <el-button
              v-show="row.is_exchange == 0"
              size="mini"
              type="success"
              @click="goShow(row)"
              >去兑奖</el-button
            >
            <el-button
              v-show="row.is_exchange == 1"
              size="mini"
              type="info"
              disabled
              >已兑奖</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 活动配置弹窗  -->
    <el-dialog
      title="操作"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
    >
      <activityConfig
        @dialogVisible="childByValue($event)"
        v-if="flag"
        :value="value"
        title="添加"
      >
        <div slot="top"></div>
      </activityConfig>
    </el-dialog>

    <el-dialog
      title="提示"
      :visible.sync="delshow"
      width="30%"
      :before-close="handleClose"
    >
      <span>您确定要删除这一条信息吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delshow = false">取 消</el-button>
        <el-button type="primary" @click="del">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="提示"
      :visible.sync="goshow"
      width="30%"
      :before-close="handleClose"
    >
      <span>你确定要为昵称：{{ oneName }}，ID：{{ oneId }}，姓名：{{true_name}}，手机号：{{phone}}，qq：{{qq}}兑换奖品吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="goshow = false">取 消</el-button>
        <el-button type="primary" @click="getPrizes">确 定</el-button>
      </span>
    </el-dialog>

    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="searchParams.page"
      :page-sizes="[10, 15, 20, 50, 100]"
      :page-size="searchParams.size"
      layout="total,sizes, prev, pager, next"
      :total="searchParams.count"
    >
    </el-pagination>
  </div>
</template>

<script>
import activityConfig from "./activityConfig";
export default {
  // name: "Index",
  components: {
    activityConfig,
  },
  data() {
    return {
      dialogVisible: false, //是否显示弹出框 111
      tableData: [], //列表数据
      value: {}, //父传子
      flag: true, //刷新子组件
      searchParams: {
        page: 1,
        size: 10,
        count: 0,
      }, //分页
      id: "", //查询id
      delshow: false, //查询
      goshow: false, //查询
      activityId: "", //活动id
      oneId: "", //当前兑换者id
      oneName: "", //当前兑换者昵称
      true_name: "", //当前兑换者真是姓名
      phone: "", //当前兑换者电话
      qq: "", //当前兑换者qq
    };
  },
  created() {
    this.getlist(this.id);
  },
  mounted() {},
  watch: {},
  methods: {
    //获取列表数据
    getlist: function (e) {
      this.isLoading = true;
      this.$api.luckDraw
        .activityList(
          "List",
          { page: this.searchParams.page, size: this.searchParams.size, id: e },
          "get"
        )
        .then((result) => {
          console.log(result);
          this.tableData = result.data.list;
          this.tableData.forEach((item) => {
            if (item.true_name == undefined) {
              item.true_name = "";
            } 
          });
          this.searchParams.count = result.data.total;
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    goShow(e) {
      this.goshow = true;
      this.activityId = e.id;
      this.oneId = e.commit_user_id;
      this.oneName = e.commit_user_name;
      this.true_name=e.true_name;
      this.phone=e.phone;
      this.qq=e.qq;
      
    },
    //去兑奖
    getPrizes() {
      this.isLoading = true;
      this.$api.luckDraw
        .activityList("ConfirmReward", { id: this.activityId }, "get")
        .then(() => {
          this.getlist(this.id);
          this.goshow = false;
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //查询
    searchOne: function () {
      this.getlist(this.id);
    },
    //关闭弹出框
    handleClose(done) {
      done();
    },
    //编辑
    editInfo: function (e) {
      console.log(e);
      this.value = {
        type: 1,
        obj: e,
      };
      this.flag = false;
      this.$nextTick(() => {
        this.flag = true;
      });
      this.dialogVisible = true;
    },
    //新增
    newadd: function () {
      this.value = {
        type: 0,
        id: 0,
      };
      this.flag = false;
      this.$nextTick(() => {
        this.flag = true;
      });
      this.dialogVisible = true;
    },
    //删除对话框
    delShow: function (e) {
      this.delshow = true;
      this.activityId = e;
    },
    //删除
    del: function () {
      this.isLoading = true;
      this.$api.luckDraw
        .activityDle("Delete", { id: this.activityId }, "get")
        .then(() => {
         
          this.$message.success("删除成功");
          this.getlist(this.id);
          this.delshow = false;
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //分页
    handleSizeChange(val) {
      this.searchParams.size = val;
      this.getlist(this.id);
    },

    handleCurrentChange(val) {
      this.searchParams.page = val;
      this.getlist(this.id);
    },

    childByValue: function (childValue) {
      // childValue就是子组件传过来的值
      console.log(childValue);
      this.$nextTick(() => {
        this.flag = true;
      });
      this.dialogVisible = false;
      this.getlist(this.id);
    },
  },
};
</script>

<style>
.el-icon-upload2:before {
  float: right;
}
.el-icon-edit:before {
  float: right;
}
.settopLogo {
  display: flex;
  justify-items: center;
}
.searchbox {
  display: flex;
  align-items: center;

}
</style>
