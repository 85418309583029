<template>
    <div>
        <el-row class="cm-box-title">
            <el-col :span="12" class="fs-14"><i class="el-icon-tickets mr-5"></i>修改头像</el-col>
        </el-row>
        <el-row class="txt-center upload-box">
            <el-col :span="12">
                上传头像
                <el-upload
                        class="avatar-uploader"
                        action="/common/upload/multipart/"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    <div class="tips mb-20">选择一张个人正面照片作为头像</div>
                    <el-button type="primary" class="mt-20">{{imageUrl?'重新选择':'选择头像'}}</el-button>
                </el-upload>
            </el-col>
            <el-col :span="12">
                头像预览
                <div class="avator-show">
                    <el-avatar :size="140" :src="userInfo.avator_url" v-if="!imageUrl"></el-avatar>
                    <el-avatar :size="140" :src="imageUrl" v-if="imageUrl"></el-avatar>
                </div>
                <el-button type="primary" class="mt-20" v-bind:loading="isLoading" v-on:click="submit()">提交头像</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "ResetAvator",
        data() {
            return {
                defaultUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
                imageUrl: '',
                userInfo: {},
                isLoading: false
            };
        },
        mounted() {
            this.$user.checkLogin(info => {
                this.userInfo = info
            })
        },
        methods: {
            submit() {
                this.isLoading = true
                this.$api.user.save(this.userInfo).then(result => {
                    if (result.errCode) {
                        return this.$message.error(result.message)
                    }
                    this.$message.success('头像更新成功');
                    /*
                    User.checkLogin(() => {
                        this.$router.go(-1)
                    })
                    */
                }).catch(error => {
                    this.$message.error(error)
                }).finally(() => {
                    this.isLoading = false
                })
            },
            handleAvatarSuccess(result) {
                if (result.errCode) {
                    return this.$message.error(result.message)
                }
                this.userInfo.avator = result.data.file
                this.imageUrl = result.data.object_url// URL.createObjectURL(file.raw);
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            }
        }
    }
</script>

<style scoped>
    .upload-box {
        margin-top: 80px;
        padding-bottom: 50px;
    }

    .avatar-uploader {
        margin-top: 50px;
        text-align: center;
    }

    .avatar-uploader.el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;

    }

    .avatar-uploader.el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 80px;
        color: #8c939d;
        width: 200px;
        height: 200px;
        line-height: 200px;
        text-align: center;
        border: #ddd dashed 1px;
        background-color: #f5f5f5;
    }

    .avatar-uploader .tips {
        margin-top: 10px;
        color: #999;
        font-size: 14px;
    }

    .avatar {
        width: 200px;
        height: 200px;
        display: block;
    }

    .avator-show {
        width: 200px;
        height: 200px;
        margin: 50px auto 20px;
        padding-top: 30px;
    }
</style>