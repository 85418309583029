<template>
    <el-tabs v-model="activeName" @tab-click="changeTab">
        <el-tab-pane label="接口管理" name="first">
            <!--搜索栏-->
            <el-form :inline="true" :model="searchParams" ref="searchParams" class="demo-form-inline">
                <el-form-item label="所属项目：">
                    <el-select v-model="selectedProject" placeholder="所属项目" @change="changeProject(1)">
                        <el-option label="选择项目" value=""></el-option>
                        <el-option
                                v-for="(item,index) in projectList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="" prop="keywords">
                    <el-input v-model="searchParams.keyword" placeholder="关键字"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button icon="el-icon-search" type="primary" :loading="isLoading" @click="getList()">查询
                    </el-button>
                    <el-button icon="el-icon-plus" type="primary" @click="add()">添加接口</el-button>
                    <el-button type="primary" v-if="modules.length" @click="goDebug()">调试</el-button>
                </el-form-item>
            </el-form>
            <el-radio-group v-model="searchParams.module" size="small" v-if="modules.length" @change="getList()">
                <el-radio-button v-for="(item,index) in modules" :key="index" :label="item.id">{{item.name}}
                </el-radio-button>
            </el-radio-group>
            <el-table
                    :data="dataList"
                    stripe
                    style="width: 100%">
                <el-table-column
                        prop="title"
                        label="接口名称">
                </el-table-column>
                <el-table-column
                        prop="bind_merchant"
                        label="绑定商户">
                </el-table-column>
                <el-table-column
                        prop="request_type"
                        label="METHOD">
                    <template slot-scope="{row}">
                        {{ row.request_type==1?'GET':'POST' }}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="url"
                        label="请求地址">
                </el-table-column>
                <el-table-column
                        prop="user"
                        label="创建人">
                </el-table-column>
                <el-table-column
                        prop="updated"
                        label="更新时间">
                    <template slot-scope="{row}">
                        {{row.updated|time}}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="orderby"
                        label="排序">
                    <template slot-scope="scope">
                        <edit-able-content :content="scope.row.orderby" :index="parseInt(scope.row.id)"
                                           @changed="updateOrderby"/>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="request_count"
                        label="请求次数">
                </el-table-column>
                <el-table-column
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" class="c-blue" @click="edit(scope.$index)"
                                   icon="el-icon-edit-outline">编辑
                        </el-button>
                        <el-button type="text" class="c-blue" @click="remove(scope.$index)"
                                   icon="el-icon-delete">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--翻页-->
            <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="searchParams.page"
                    :page-sizes="[10, 15, 20,50,100]"
                    :page-size="searchParams.size"
                    layout="total,sizes, prev, pager, next"
                    :total="searchParams.count">
            </el-pagination>
            <!--表单弹框-->
            <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="80%">
                <el-form :model="form" :rules="formRules" status-icon ref="form" label-width="120px" class="">
                    <el-form-item label="接口项目：" prop="project">
                        <el-select v-model="form.project" placeholder="所属项目" @change="changeProject(2)">
                            <el-option label="选择项目" value=""></el-option>
                            <el-option
                                    v-for="(item,index) in projectList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="接口模块：" prop="module">
                        <el-select v-model="form.module" placeholder="所属项目">
                            <el-option label="选择模块" value=""></el-option>
                            <el-option
                                    v-for="(item,index) in selectedModules"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="接口名称：" prop="title">
                        <el-col :span="6">
                            <el-input type="text" placeholder="接口名称" v-model="form.title"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="绑定商户：" prop="bind_merchant">
                        <el-col :span="6">
                            <el-input type="text" placeholder="商户ID,不绑定填0" v-model="form.bind_merchant"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="接口地址：" prop="url">
                        <el-col :span="6">
                            <el-input type="text" placeholder="接口的请求路径" v-model="form.url"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="请求方式：" prop="request_type">
                        <el-radio-group v-model="form.request_type">
                            <el-radio label="1">GET</el-radio>
                            <el-radio label="2">POST</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="请求参数：">
                        <el-button v-if="form.request_params.length==0" @click="addRequestParams">添加</el-button>
                        <template v-for="(item,index) in form.request_params">
                            <el-row type="flex" :gutter="5" class="mt-10"
                                    :key="index">
                                <el-col :span="5">
                                    <div>
                                        <el-input type="text" placeholder="参数key" v-model="item.key" required="">
                                            <template slot="append" v-if="item.type=='Object'"><span
                                                    style="cursor: pointer"
                                                    @click="addRequestParamsChildren(index)">+</span>
                                            </template>
                                        </el-input>
                                    </div>
                                </el-col>
                                <el-col :span="3">
                                    <el-select v-model="item.type" placeholder="类型" required="">
                                        <el-option label="类型" value=""></el-option>
                                        <el-option
                                                v-for="(item,index) in paramsTypes"
                                                :key="index"
                                                :label="item"
                                                :value="item">
                                        </el-option>
                                    </el-select>
                                </el-col>
                                <el-col :span="6">
                                    <el-input type="text" placeholder="参数说明=>调试缺省值"
                                              v-model="item.description"></el-input>
                                </el-col>
                                <el-col :span="3">
                                    <el-select v-model="item.required" placeholder="是否必填">
                                        <el-option label="必填" value="1"></el-option>
                                        <el-option label="非必填" value="0"></el-option>
                                    </el-select>
                                </el-col>
                                <el-col :span="6">
                                    <el-button v-if="index!=0" @click="moveUp(index)">↑</el-button>
                                    <el-button v-if="index!=form.request_params.length-1" @click="moveDown(index)">↓
                                    </el-button>

                                    <el-button @click="removeRequestParams(index)">-</el-button>
                                    <el-button v-if="index==form.request_params.length-1" @click="addRequestParams">+
                                    </el-button>
                                </el-col>
                            </el-row>
                            <el-row :gutter="10" class="mt-10" v-for="(children,cIndex) in item.children"
                                    :key="index+'_'+cIndex">
                                <el-col :span="5" :offset="1">
                                    <el-input type="text" placeholder="字段名称" v-model="children.key"
                                              required=""></el-input>
                                </el-col>
                                <el-col :span="3">
                                    <el-select v-model="children.type" placeholder="类型" required="">
                                        <el-option label="类型" value=""></el-option>
                                        <el-option
                                                v-for="(item,index) in paramsTypes"
                                                :key="index"
                                                :label="item"
                                                :value="item">
                                        </el-option>
                                    </el-select>
                                </el-col>
                                <el-col :span="6">
                                    <el-input type="text" placeholder="参数说明=>调试缺省值"
                                              v-model="children.description"></el-input>
                                </el-col>
                                <el-col :span="3">
                                    <el-select v-model="children.required" placeholder="是否必填">
                                        <el-option label="必填" value="1"></el-option>
                                        <el-option label="非必填" value="0"></el-option>
                                    </el-select>
                                </el-col>
                                <el-col :span="6">
                                    <el-button @click="removeRequestParamsChildren(index,cIndex)">-</el-button>
                                </el-col>
                            </el-row>
                        </template>
                    </el-form-item>
                    <el-form-item label="响应字段：">
                        <template v-for="(item,index) in form.response_params">
                            <el-row type="flex" :gutter="10" class="mt-10" :key="index">
                                <el-col :span="5">
                                    <el-input type="text" placeholder="字段名称" v-model="item.key" required="">
                                        <template slot="append"><span style="cursor: pointer"
                                                                      @click="addResponseParamsChildren(index)">+</span>
                                        </template>
                                    </el-input>
                                </el-col>
                                <el-col :span="3">
                                    <el-select v-model="item.type" placeholder="类型" required="">
                                        <el-option label="类型" value=""></el-option>
                                        <el-option
                                                v-for="(item,index) in paramsTypes"
                                                :key="index"
                                                :label="item"
                                                :value="item">
                                        </el-option>
                                    </el-select>
                                </el-col>
                                <el-col :span="6">
                                    <el-input type="text" placeholder="字段说明" v-model="item.description"></el-input>
                                </el-col>
                                <el-col :span="6">
                                    <el-button v-if="index!=0" @click="removeResponseParams(index)">-</el-button>
                                    <el-button v-if="index==form.response_params.length-1" @click="addResponseParams">+
                                    </el-button>
                                </el-col>
                            </el-row>
                            <template v-for="(children,cIndex) in item.children">
                                <el-row :gutter="10" class="mt-10" :key="index+'_'+cIndex">
                                    <el-col :span="5" :offset="1">
                                        <el-input type="text" placeholder="字段名称" v-model="children.key" required="">
                                            <template slot="append"><span style="cursor: pointer"
                                                                          @click="addResponseParamsChildren(index,cIndex)">+</span>
                                            </template>
                                        </el-input>
                                    </el-col>
                                    <el-col :span="3">
                                        <el-select v-model="children.type" placeholder="类型" required="">
                                            <el-option label="类型" value=""></el-option>
                                            <el-option
                                                    v-for="(item,index) in paramsTypes"
                                                    :key="index"
                                                    :label="item"
                                                    :value="item">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-input type="text" placeholder="字段说明"
                                                  v-model="children.description"></el-input>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-button @click="removeResponseParamsChildren(index,cIndex)">-</el-button>
                                    </el-col>
                                </el-row>

                                <el-row :gutter="10" class="mt-10" v-for="(children2,cIndex2) in children.children"
                                        :key="index+'_'+cIndex+'_'+cIndex2">
                                    <el-col :span="5" :offset="2">
                                        <el-input type="text" placeholder="字段名称" v-model="children2.key"
                                                  required=""></el-input>
                                    </el-col>
                                    <el-col :span="3">
                                        <el-select v-model="children2.type" placeholder="类型" required="">
                                            <el-option label="类型" value=""></el-option>
                                            <el-option
                                                    v-for="(item,index) in paramsTypes"
                                                    :key="index"
                                                    :label="item"
                                                    :value="item">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-input type="text" placeholder="字段说明"
                                                  v-model="children2.description"></el-input>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-button @click="removeResponseParamsChildren(index,cIndex,cIndex2)">-
                                        </el-button>
                                    </el-col>
                                </el-row>
                            </template>
                        </template>
                    </el-form-item>
                    <el-form-item label="接口状态：" required="">
                        <el-radio-group v-model="form.hidden">
                            <el-radio label="0">启用</el-radio>
                            <el-radio label="1">禁用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="接口说明：">
                        <el-input placeholder="其它说明信息" v-model="form.tips" type="textarea" :rows="4"></el-input>
                    </el-form-item>
                    <el-form-item label="接口排序：">
                        <el-col :span="4">
                            <el-input type="text" placeholder="值越大越靠前" v-model="form.orderby"></el-input>
                        </el-col>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="save()" :loading="isLoading">保 存</el-button>
                </div>
            </el-dialog>
        </el-tab-pane>
        <el-tab-pane label="项目管理" name="second">
            <!--搜索栏-->
            <el-row :gutter="10" class="mt-10">
                <el-button icon="el-icon-plus" type="primary" @click="addProject()" class="fr mr-20">添加项目</el-button>
            </el-row>
            <el-table
                    :data="projectList"
                    stripe
                    style="width: 100%"
                    row-key="id"
                    default-expand-all>
                <el-table-column
                        prop="name"
                        label="项目名称">
                </el-table-column>
                <el-table-column
                        prop="bind_merchant"
                        label="绑定商户">
                </el-table-column>
                <el-table-column
                        prop="debug_url"
                        label="调试URL">
                    <template slot-scope="{row}">
                        {{row.debug_url||'--'}}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="orderby"
                        label="排序">
                    <template slot-scope="scope">
                        <edit-able-content :content="scope.row.orderby" :index="parseInt(scope.row.id)"
                                           @changed="updateProjectOrderby"/>
                    </template>
                </el-table-column>
                <el-table-column
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" class="c-blue" @click="addModule(scope.row)"
                                   icon="el-icon-plus" v-if="scope.row.parent==0">添加模块
                        </el-button>
                        <el-button type="text" class="c-blue" @click="editProject(scope.row)"
                                   icon="el-icon-edit-outline">编辑
                        </el-button>
                        <el-button type="text" class="c-blue" @click="removeProject(scope.row)"
                                   icon="el-icon-edit-outline" v-if="scope.row.parent!=0 || !scope.row.children.length">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--表单弹框-->
            <el-dialog :title="projectDialogTitle" :visible.sync="dialogProjectFormVisible"
                       :close-on-click-modal="false" width="60%">
                <el-form :model="project" :rules="projectFormRules" status-icon ref="projectForm" label-width="120px"
                         class="">
                    <el-form-item label="项目名称：" prop="name" required="">
                        <el-col :span="10">
                            <el-input type="text" placeholder="项目/模块名称" v-model="project.name"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="绑定商户：" prop="bind_merchant">
                        <el-col :span="10">
                            <el-input type="text" placeholder="商户ID" v-model="project.bind_merchant"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="项目类型：" v-if="project.parent==0" prop="project_type" required="">
                        <el-radio-group v-model="project.project_type">
                            <el-radio label="1">站内应用</el-radio>
                            <el-radio label="2">站外应用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="测试环境网关：" v-if="project.parent==0 && project.project_type==2">
                        <el-col :span="10">
                            <el-input type="text" placeholder="测试环境网关" v-model="project.gateway_dev"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="正式环境网关：" v-if="project.parent==0 && project.project_type==2">
                        <el-col :span="10">
                            <el-input type="text" placeholder="正式环境网关" v-model="project.gateway_production"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="调试APPID：" v-if="project.parent==0 && project.project_type==2">
                        <el-col :span="10">
                            <el-input type="text" placeholder="系统分配的appid" v-model="project.debug_appid"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="调试APPKEY：" v-if="project.parent==0 && project.project_type==2">
                        <el-col :span="10">
                            <el-input type="text" placeholder="32位密钥" v-model="project.debug_appkey"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="调试token：" v-if="project.parent==0 && project.project_type==2">
                        <el-col :span="10">
                            <el-input type="text" placeholder="32位用户身份令牌" v-model="project.debug_token"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="调试参数：" v-if="project.parent==0">
                        <el-button v-if="project.debug_setting.length==0" @click="addProjectParams">添加</el-button>
                        <el-row type="flex" :gutter="10" class="mt-10" v-for="(item,index) in project.debug_setting"
                                :key="index">
                            <el-col :span="8">
                                <el-input type="text" placeholder="参数key" v-model="item.key"></el-input>
                            </el-col>
                            <el-col :span="8">
                                <el-input type="text" placeholder="参数value" v-model="item.value"></el-input>
                            </el-col>
                            <el-button @click="removeProjectParams(index)">移除</el-button>
                            <el-button v-if="index==project.debug_setting.length-1" @click="addProjectParams">添加
                            </el-button>
                        </el-row>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogProjectFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveProject()" :loading="isLoading">保 存</el-button>
                </div>
            </el-dialog>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
    import EditAbleContent from '../../components/EditAbleContent.vue'

    export default {
        name: "ApiManage",
        components: {
            EditAbleContent
        },
        mounted() {
            this.getList()
            this.loadProjects()
        },
        data() {
            return {
                activeName: 'first',
                isLoading: false,
                dialogFormVisible: false,
                dialogProjectFormVisible: false,
                dialogTitle: '添加商户',
                projectDialogTitle: '添加项目',
                projectFormRules: {
                    name: {required: true, message: '请输入名称', trigger: 'submit'},
                    project_type: {required: true, message: '请选择项目类型', trigger: 'submit'},
                },
                formRules: {
                    project: {required: true, message: '请选择所属项目', trigger: 'submit'},
                    module: {required: true, message: '请选择所属模块', trigger: 'submit'},
                    title: {required: true, message: '请输入名称', trigger: 'blur'},
                    url: {required: true, message: '请输入接口地址', trigger: 'blur'},
                    request_type: {required: true, message: '请选择请求方式', trigger: 'submit'},
                    hidden: {required: true, message: '请选择接口状态', trigger: 'submit'},
                },
                paramsTypes: ['String', 'Int', 'Float', 'DateTime', 'Timestamp', 'Object', 'Array', 'Boole', 'Image', 'ImageUrl', 'ImageArray', 'ImageUrlArray', 'File', 'FileArray', 'Mixed'],
                form: {
                    project: '',
                    module: '',
                    request_params: [],
                    response_params: []
                },
                project: {},
                searchParams: {
                    keyword: '',
                    page: 1,
                    count: 1,
                    size: 10,
                    project: '',
                    module: ''
                },
                projectList: [],
                dataList: [],
                selectedProject: '',
                selectedProjectName: '',
                modules: [],
                selectedModules: []
            }
        },
        methods: {

            changeTab(tab, event) {

            },
            goDebug() {
                const {href} = this.$router.resolve({
                    path: '/api',
                    query: {
                        project: this.selectedProjectName
                    }
                })
                window.open(href, '_blank')
                //this.$router.push({path: '/api', query: {project: this.selectedProject}})
            },
            moveUp(index) {
                let _this = this.$copy(this.form.request_params[index])
                let upper = this.$copy(this.form.request_params[index - 1])
                let params = this.$copy(this.form.request_params)
                params[index] = upper
                params[index - 1] = _this
                this.$set(this.form, 'request_params', params)
            },
            moveDown(index) {
                let _this = this.$copy(this.form.request_params[index])
                let lower = this.$copy(this.form.request_params[index + 1])
                let params = this.$copy(this.form.request_params)
                params[index] = lower
                params[index + 1] = _this
                this.$set(this.form, 'request_params', params)
            },
            changeProject(scene) {
                let selectedProject = (scene === 1) ? this.selectedProject : this.form.project
                if (selectedProject !== '') {
                    let project = this.loadModules(selectedProject)
                    this.form.project = project.id
                    this.selectedModules = this.$copy(project.children)
                    console.log(scene)
                    if (scene === 1) {
                        this.searchParams.project = this.$copy(project.id)
                        this.selectedProjectName = this.$copy(project.name)
                        this.modules = this.$copy(project.children)
                        this.getList()
                    }
                    console.log(this.modules)
                } else {
                    this.searchParams.project = ''
                    this.form.project = ''
                    this.modules = []
                    this.selectedModules = []
                }
                this.searchParams.module = ''
                this.form.module = ''
            },
            loadModules(id) {
                let project = {}
                this.projectList.forEach(item => {
                    if (id == item.id) {
                        project = item
                    }
                })
                //this.modules = project.children
                return project
            },
            //加载项目列表
            loadProjects() {
                this.$api.system.apiManage('GetProjects').then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.projectList = result.data;
                    }
                }).catch(error => {
                    this.$message.error(error);
                });
            },
            addProject() {
                this.project = {
                    parent: 0,
                    bind_merchant:'0',
                    debug_setting: [{key: '', value: ''}]
                }
                this.dialogProjectFormVisible = true;
            },
            addModule(project) {
                this.projectDialogTitle = '添加模块';
                this.project = {
                    parent: project.id
                }
                this.dialogProjectFormVisible = true;
            },
            editProject(project) {
                this.projectDialogTitle = '编辑项目';
                this.project = project
                this.dialogProjectFormVisible = true;
            },
            removeProject(project) {
                this.$message.confirm({message: '确定要删除[' + project.name + ']吗?'}, () => {
                    this.$loading.show('删除中')
                    this.$api.system.apiManage('RemoveProject', {id: project.id}, 'post').then(result => {
                        if (result.errCode) {
                            this.$message.alert(result.message)
                        } else {
                            this.loadProjects()
                        }
                    }).catch(error => {
                        this.$message.error(error)
                    }).finally(() => {
                        this.$loading.close()
                    })
                })
            },
            saveProject() {
                this.$refs['projectForm'].validate((valid) => {
                    if (valid) {
                        this.isLoading = true;
                        this.$api.system.apiManage('SaveProject', this.project, 'post').then(result => {
                            if (result.errCode) {
                                this.$message.alert(result.message)
                            } else {
                                this.dialogProjectFormVisible = false;
                                this.$message.success('保存成功');
                                this.loadProjects()
                            }
                        }).catch(error => {
                            this.$message.error(error)
                        }).finally(() => {
                            this.isLoading = false
                        })
                    } else {
                        return false;
                    }
                });
            },
            updateProjectOrderby(value, id) {
                this.$api.system.apiManage('SaveProject', {id: id, orderby: value}, 'post').then(result => {
                    if (result.errCode) {
                        this.$message.alert(result.message)
                    } else {
                        this.loadProjects()
                    }
                }).catch(error => {
                    this.$message.error(error)
                }).finally(() => {
                })
            },
            addProjectParams() {
                this.project.debug_setting.push({key: '', value: ''})
            },
            removeProjectParams(index) {
                this.project.debug_setting.splice(index, 1)
            },
            //加载接口列表
            getList() {
                this.isLoading = true;
                this.$api.system.apiManage('GetApiList', this.searchParams).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.dataList = result.data.list;
                        this.searchParams.count = result.data.total;
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).finally(() => {
                    this.isLoading = false;
                });
            },
            //创建分组
            add() {
                this.dialogTitle = '添加接口';
                this.form = {
                    project: this.searchParams.project,
                    module: this.searchParams.module,
                    request_params: [],
                    response_params: [{key: 'errCode', type: 'String', description: '错误码,未发生错误时返回0'}, {
                        key: 'message',
                        type: 'String',
                        description: '提示信息'
                    }, {key: 'data', type: 'mixed', description: '业务数据,没有数据时返回空值'}],
                    hidden: '0',
                    bind_merchant: '0',
                };
                this.dialogFormVisible = true;
            },
            addRequestParams() {
                this.form.request_params.push({key: '', type: '', description: '', required: '1', children: []})
            },
            removeRequestParams(index) {
                this.form.request_params.splice(index, 1)
            },
            addRequestParamsChildren(index) {
                let children = this.form.request_params[index].children || []
                children.push({
                    key: '',
                    type: 'String',
                    description: '',
                    required: '1',
                })
                this.$set(this.form.request_params[index], 'children', children)
            },
            removeRequestParamsChildren(parentIndex, cIndex) {
                this.form.request_params[parentIndex].children.splice(cIndex, 1)
            },
            addResponseParams() {
                this.form.response_params.push({key: '', type: '', description: '', is_children: false, children: []})
            },
            removeResponseParams(index) {
                this.form.response_params.splice(index, 1)
            },
            addResponseParamsChildren(index, cIndex) {
                if (cIndex !== undefined) {
                    let children = this.form.response_params[index].children[cIndex].children || []
                    children.push({
                        key: '',
                        type: 'String',
                        description: '',
                        is_children: true,
                        children: []
                    })
                    this.$set(this.form.response_params[index].children[cIndex], 'children', children)
                } else {
                    let children = this.form.response_params[index].children || []
                    children.push({
                        key: '',
                        type: 'String',
                        description: '',
                        is_children: true,
                        children: []
                    })
                    this.$set(this.form.response_params[index], 'children', children)
                }
            },
            removeResponseParamsChildren(parentIndex, cIndex, cIndex2) {
                if (cIndex2 !== undefined) {
                    this.form.response_params[parentIndex].children[cIndex].children.splice(cIndex2, 1)
                } else {
                    this.form.response_params[parentIndex].children.splice(cIndex, 1)
                }

            },
            edit(index) {
                this.dialogTitle = '编辑接口';
                this.form = this.dataList[index];
                let project = this.loadModules(this.form.project)
                this.selectedModules = project.children
                this.dialogFormVisible = true;
            },
            updateOrderby(value, id) {
                this.$api.system.apiManage('SaveApi', {id: id, orderby: value}, 'post').then(result => {
                    if (result.errCode) {
                        this.$message.alert(result.message)
                    } else {
                        this.getList()
                    }
                }).catch(error => {
                    this.$message.error(error)
                }).finally(() => {
                })
            },
            save() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.isLoading = true
                        this.$api.system.apiManage('SaveApi', this.form, 'post').then(result => {
                            if (result.errCode) {
                                this.$message.alert(result.message)
                            } else {
                                this.dialogFormVisible = false;
                                this.$message.success('保存成功');
                                this.getList()
                            }
                        }).catch(error => {
                            this.$message.error(error)
                        }).finally(() => {
                            this.isLoading = false
                        })
                    } else {
                        return false;
                    }
                });
            },
            remove(index) {
                let item = this.dataList[index]
                this.$message.confirm({message: '确定要删除接口[' + item.title + ']吗?'}, () => {
                    this.$loading.show('删除中')
                    this.$api.system.apiManage('ApiRemove', {id: item.id}, 'post').then(result => {
                        if (result.errCode) {
                            this.$message.alert(result.message)
                        } else {
                            this.getList()
                        }
                    }).catch(error => {
                        this.$message.error(error)
                    }).finally(() => {
                        this.$loading.close()
                    })
                })
            },
            // 点击分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getList();

            },
        }
    }
</script>