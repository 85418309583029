<template>
  <div>
    <!-- 产品logo -->

    <div class="weekbox">
      <el-select
        v-if="this.value.id == 0"
        @change="select5"
        v-model="value5"
        placeholder="请选择"
      >
        <el-option
          v-for="item in options5"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <div v-else style="margin-right: 20px">
        星期{{ this.value.obj.sign_day }}
      </div>
      <div style="">
        <el-select @change="select1" v-model="value1" placeholder="请选择">
          <el-option
            v-for="item in options1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 家具 -->
      <div class="week">
        <div v-show="FurnitureShow1 == true" class="selectBox">
          <!-- 家具  -->
          <el-select @change="select2" v-model="value2" placeholder="请选择">
            <el-option
              v-for="item in options2"
              :key="item.id"
              :label="item.sort_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 家具 -->
        <div v-show="FurnitureShow2 == true" class="selectBox">
          <el-select v-model="value3" @change="select3" placeholder="请选择">
            <el-option
              v-for="item in options3"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <!-- 贴纸 -->
      <div class="week">
        <div v-show="StickersShow == true" class="selectBox">
          <!-- 家具 贴纸 -->
          <el-select
            v-model="value4"
            @change="select4"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in options4"
              :key="item.id"
              :label="item.sort_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <!-- 蜂蜜 -->
      <div v-show="HoneyShow == true" class="selectBox">
        <el-input
          @blur="setHoney"
          v-model="Honey"
          placeholder="请输入蜂蜜数量"
        ></el-input>
      </div>
      <!-- 蜜罐 -->
      <div v-show="HoneypotyShow == true" class="selectBox">
        <el-input
          @blur="setHoneypot"
          v-model="Honeypot"
          placeholder="请输入蜜罐数量"
        ></el-input>
      </div>
      <div style="margin-left: 80px">
        <el-button type="primary" @click="sure">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      obj: Object,
      id: Number,
    },
    //   value: Object,
  },
  name: "Index",
  data() {
    return {
      timeValue: "",
      options1: [
        {
          value: "1",
          label: "蜂蜜",
        },
        {
          value: "2",
          label: "蜜罐",
        },
        {
          value: "3",
          label: "家具",
        },
        {
          value: "4",
          label: "贴纸",
        },
      ],//奖品类别list
      options2: [],//家具套list
      options3: [],//家具部件list
      options4: [],//贴纸list
      options5: [
        {
          value: "1",
          label: "星期一",
        },
        {
          value: "2",
          label: "星期二",
        },
        {
          value: "3",
          label: "星期三",
        },
        {
          value: "4",
          label: "星期四",
        },
        {
          value: "5",
          label: "星期五",
        },
        {
          value: "6",
          label: "星期六",
        },
      ],//星期的list
      value1: "",//选择奖品类别id
      value2: "",//选择的家具套装id
      value3: "",//选择的家具部件id
      value4: "",//选择的贴纸id
      value5: "",//选择周几天
      Honey: "",//蜂蜜数量
      Honeypot: "",//蜜罐数量
      searchParams: {
        page: 1,
        size: 10,
        count: 0,
      }, //分页
      FurnitureShow1: false,//家具套隐藏
      FurnitureShow2: false,//家具部件隐藏
      StickersShow: false,//贴纸隐藏
      HoneyShow: false,//蜂蜜隐藏
      HoneypotyShow: false,//蜜罐隐藏
      reward_info: "",//选中的奖品信息
      sign_day: "",//编辑时 选中的周几id
      reward_name:''//选择的奖品名字
    };
  },
  created() {
    console.log(this.value);

    this.value1 = this.value.obj.reward_type;
    if (this.value.id != 0) {
         this.sign_day=this.value.obj.sign_day
      if (this.value.obj.reward_type == 1) {
        //蜂蜜
        this.FurnitureShow1 = false;
        this.FurnitureShow2 = false;
        this.StickersShow = false;
        this.HoneyShow = true;
        this.HoneypotyShow = false;
        this.Honey = this.value.obj.reward_info;
        this.reward_name='蜂蜜'
      } else if (this.value.obj.reward_type == 2) {
        //蜜罐
        this.FurnitureShow1 = false;
        this.FurnitureShow2 = false;
        this.StickersShow = false;
        this.HoneyShow = false;
        this.HoneypotyShow = true;
        this.Honeypot = this.value.obj.reward_info;
        this.reward_name='蜜罐'
      } else if (this.value.obj.reward_type == 3) {
        //家具
        this.FurnitureShow1 = true; 
        this.FurnitureShow2 = true; 
        this.StickersShow = false; 
        this.HoneyShow = false;
        this.HoneypotyShow = false;
        var arr = this.value.obj.reward_info.split(",");
        this.value2 = arr[0];
        this.value3 = arr[1];
        this.getFurniture(1);
        this.getFurniture2(this.value2);
        
      } else {
        //贴纸 
        this.FurnitureShow1 = false;  
        this.FurnitureShow2 = false;
        this.StickersShow = true;
        this.HoneyShow = false;
        this.HoneypotyShow = false;
        this.getFurniture(6);
        this.value4 = this.value.obj.reward_info;
      }
    }else{
        this.value.obj.weekid=this.value.obj.id
        this.value.obj.id=""
    }

  },
  mounted() {},
  watch: {},
  methods: {
    setHoney: function () {
      console.log(this.Honey);
      this.reward_info = this.Honey;
    },
    setHoneypot: function () {
      console.log(this.Honeypot);
      this.reward_info = this.Honeypot;
    },
    //家具1
    getFurniture: function (e) {
      var that = this;
      that.isLoading = true;
      that.$api.journal
        .GetFurnitureSuit("GetFurnitureSuit", { type: e }, "get")
        .then((result) => {
          console.log(result.data);
          if (e == 1) {
            that.options2 = result.data.list;
            console.log(that.options2);
          } else {
            that.options4 = result.data.list;
            console.log(that.options4);
          }
          //   that.searchParams.count = result.data.total;
        })
        .catch((error) => {
          that.$message.error(error);
        })
        .finally(() => {
          that.isLoading = false;
        });
    },
    //家具2
    getFurniture2: function (e) {
      var that = this;
      that.isLoading = true;
      that.$api.journal
        .GetFurnitureParts("GetFurnitureParts", { furniture_suit_id: e }, "get")
        .then((result) => {
          console.log(result.data);
          that.options3 = result.data.list;
          console.log(that.options3);
          //   that.searchParams.count = result.data.total;
        })
        .catch((error) => {
          that.$message.error(error);
        })
        .finally(() => {
          that.isLoading = false;
        });
    },

    // 确定按钮  this.value.type区分新增（0）or编辑（1）
    sure: function () {
      var that = this;
      that.isLoading = true;
      that.$api.journal
        .Save(
          "Save",
          {
            id: this.value.obj.id,
            sign_info_id: this.value.obj.weekid ,
            sign_day: this.sign_day,
            reward_type: this.value1,
            reward_info: this.reward_info,
            reward_name:this.reward_name 
          },
          "POST"
        )
        .then((result) => {
          console.log(result);
                if(result.errCode==0){
             this.$emit("dialogVisible", "false");
          }else{    
              this.$message.error(result.message); 
          }

        })
        .catch((error) => {
          that.$message.error(error);
        })
        .finally(() => {
          that.isLoading = false;
        });

    },
    select1: function (e) {
      console.log(e + "111");
      if (e == 1) {
        //蜂蜜
        this.FurnitureShow1 = false;
        this.FurnitureShow2 = false;
        this.StickersShow = false;
        this.HoneyShow = true;
        this.HoneypotyShow = false;
        this.reward_name='蜂蜜'
      } else if (e == 2) {
        //蜜罐
        this.FurnitureShow1 = false;
        this.FurnitureShow2 = false;
        this.StickersShow = false;
        this.HoneyShow = false;
        this.HoneypotyShow = true;
         this.reward_name='蜜罐'
      } else if (e == 3) {
        //家具
        this.FurnitureShow1 = true;
        this.FurnitureShow2 = false;
        this.StickersShow = false;
        this.HoneyShow = false;
        this.HoneypotyShow = false;
        this.getFurniture(1);
      } else {
        //贴纸
        this.FurnitureShow1 = false;
        this.FurnitureShow2 = false;
        this.StickersShow = true;
        this.HoneyShow = false;
        this.HoneypotyShow = false;
        this.getFurniture(6);
      }
    },
    //家具套装选择
    select2: function () {
      console.log(this.StickersShow);
      this.getFurniture2(this.value2);
      this.FurnitureShow2 = true;
    },
    //家具部件选择
    select3: function () {
      this.reward_info = this.value2 + "," + this.value3;

      this.options3.forEach(item => {
                console.log(item.id);
      if(this.value3==item.id){
  this.reward_name=item.name
      }
      } )
    },
    //贴纸选择
    select4: function () {
      this.reward_info = this.value4; 
            this.options4.forEach(item => {
                console.log(item);
      if(this.value4==item.id){
  this.reward_name=item.sort_name 
      } 
      } )

      console.log(this.reward_name);
    },
    //选择日期
    select5: function () {
      this.sign_day = this.value5;
    },

  },
};
</script>

<style>
.weekbox {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-top: 20px;
}
.selectBox {
  margin-left: 20px; 
}
</style>





