<template>
  <div class="root-container">
    <div class="more-btns">
      <el-button type="primary" @click="newadd">新增</el-button>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="id" label="序号" align="center">
      </el-table-column>
      <el-table-column prop="rubbish_title" label="名称" align="center"></el-table-column>
      <el-table-column prop="num" label="垃圾类型" align="center">
        <template slot-scope="{ row }">
          <div v-if="row.rubbish_type==0">普通垃圾</div>
          <div v-if="row.rubbish_type==1">大垃圾</div>
        </template>
      </el-table-column>
      <el-table-column prop="num" label="状态" align="center">
        <template slot-scope="{ row }">
          <div v-if="row.rubbish_status==0">关闭</div>
          <div v-if="row.rubbish_status==1">开启</div>
        </template>
      </el-table-column>

      <el-table-column
        prop="more"
        label="操作"
        align="center"
        width="300"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" @click="editInfo(row)"
            >编辑</el-button
          >
          <el-button size="mini" type="danger" @click="del(row.id)"
            >删除</el-button
          >
          <el-button size="mini" type="danger" @click="addgoods(row.id)"
            >奖励</el-button
          >
          <el-button size="mini" type="danger" @click="setStatus(row.id)"
            >修改状态</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="searchParams.page"
      :page-sizes="[10, 15, 20, 50, 100]"
      :page-size="searchParams.size"
      layout="total,sizes, prev, pager, next"
      :total="searchParams.count"
    >
    </el-pagination>

    <!-- 垃圾查看详情 -->
    <el-dialog
      :title="picTitle"
      width="800px"
      top="10vh"
      :visible.sync="dialogVisible"
    >
      <div class="flexBox">
        <div class="flexBoxTitle">名称:</div>
        <input v-model="name" class="flexBoxInp" type="text" />
      </div>

      <div class="flexBox">
        <div class="flexBoxTitle">垃圾类型:</div>
        <el-radio-group v-model="rubbishType">
          <el-radio :label="0">普通垃圾</el-radio>
          <el-radio :label="1">大垃圾</el-radio>
        </el-radio-group>
      </div>

      <div class="flexBox">
        <div class="flexBoxTitle">垃圾状态:</div>
        <el-radio-group v-model="rubbishStatus">
          <el-radio :label="0">关闭</el-radio>
          <el-radio :label="1">开启</el-radio>
        </el-radio-group>
      </div>

      <div class="flexBox">
        <el-button type="primary" @click="sure()">立即创建</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >取消</el-button
        >
      </div>
    </el-dialog>

    
    <!-- 添加奖励 -->
    <el-dialog
      :title="picTitle"
      width="900px"
      top="10vh"
      :visible.sync="dialogVisible1"
    >
      <el-button type="primary" @click="RewardsNumbox">新增</el-button>
      <div class="flexBox" v-for="(item, index) in RewardsNum" :key="index">
        <div class="flexBoxTitle">任务奖励{{ index + 1 }}:</div>
        <el-select
          @change="taskReward(index)"
          v-model="item.id1"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <input
          v-show="item.id1 == 2 || item.id1 == 1"
          v-model="item.num"
          class="flexBoxInp"
          style="width: 21%; margin-left: 3%"
          type="text"
        />

        <el-select
          v-show="item.id1 > 2"
          @change="taskReward1(index)"
          v-model="item.id2"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options2"
            :key="item.id"
            :label="item.sort_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          v-show="item.id1 == 3"
          @change="taskReward2"
          v-model="item.id3"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options3"
            :key="item.id"
            :label="item.sort_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button
          style="margin-left: 30px"
          type="primary"
          @click="goodsSava(index, item)"
          >保存</el-button
        >
        <el-button type="primary" @click="goodsDel(item)">删除</el-button>
      </div>
      <div class="flexBox">
        <el-button type="primary" @click="closebox">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      loading: false,
      searchParams: {
        page: 1,
        size: 10,
        count: 0,
      }, //分页
      picTitle: "", //弹窗的title
      tableData: [{ num: "刘可月" }], //列表数据
      dialogVisible: false,
      dialogVisible1: false,
      options1: [
        {
          value: "1",
          label: "蜂蜜",
        },
        {
          value: "2",
          label: "蜜罐",
        },
        {
          value: "3",
          label: "家具",
        },
        {
          value: "4",
          label: "贴纸",
        },
      ], //奖品类别list
      options2: [], //家具和贴纸中层
      options3: [], //家具底层
      taskType: "", //选择的奖励类型
      taskType1: "", // 贴纸或者家具
      taskType2: "", //家具
      rubbishType: 0, //选择类型
      rubbishStatus: 0, //垃圾状态
      setRubbish: 0,//0是新增 1是编辑
      goodsNum: "", //奖励个数（蜜罐和蜂蜜）
      name: "", //名称
      RewardsNum: [], //设置奖励数量
      infoId:'',//单条垃圾id

    };
  },
  created() { 
    const self = this;
    self.getTableList();
  },
  methods: {
    RewardsNumbox() { 
      console.log(this.RewardsNum);
      var obj = { id: "", id1: "", num: "", id2: "", id3: "" };
      this.RewardsNum.push(obj);
    },
    //快捷设置状态
    setStatus(e){
      this.isLoading = true;
      this.$api.journal
        .rubbishList("SetStatus", {id:e}, "post")
        .then((result) => {
          console.log(result);
          this.$message.success(result.message);
          this.getTableList()
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //列表数据
    getTableList() {
      let data = {
        page: this.searchParams.page,
        size: this.searchParams.size,
      };
      this.isLoading = true;
      this.$api.journal
        .rubbishList("List", data, "get")
        .then((result) => {
          console.log(result);
          this.tableData = result.data.list;
          this.searchParams.count = result.data.total;
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //新增
    newadd: function () {
      this.dialogVisible = true;
      this.setRubbish=0
    },

     //编辑
    editInfo: function (e) {
      this.dialogVisible = true;
        this.name=e.rubbish_title
        this.rubbishStatus=Number(e.rubbish_status)
        this.rubbishType=Number(e.rubbish_type)
        this.infoId=e.id
        this.setRubbish=1
    }, 
    //创建垃圾
    sure(){
        if(this.setRubbish==0){
                       var data = {
        title: this.name,
        type: this.rubbishType,
        status:this.rubbishStatus
      }
        }else{
           var data = {
               id:this.infoId,
        title: this.name,
        type: this.rubbishType,
        status:this.rubbishStatus
      }
        }
      this.isLoading = true;
      this.$api.journal
        .rubbishList("Save", data, "post")
        .then((result) => {
          console.log(result);
          this.$message.success(result.message);
          this.dialogVisible=false
          this.getTableList()
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
        //删除
    del(e) {
      this.$confirm("是否要删除这条id：" + e + "的数据!", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            id: e,
          };
          this.isLoading = true;
          this.$api.journal
            .rubbishList("DeleteRubbish", data, "POST")
            .then((result) => {
              console.log(result);
              this.$message.success(result.message);
              this.getTableList();
            })
            .catch((error) => {
              this.$message.error(error);
            })
            .finally(() => {
              this.isLoading = false;
            });
        })
        .catch(() => {});
    },

    //任务奖励1
    taskReward(e) {
      if (this.RewardsNum[e].id1 == 1) {
        //蜂蜜
      } else if (this.RewardsNum[e].id1 == 2) {
        //蜜罐
      } else if (this.RewardsNum[e].id1 == 3) {
        //家具
        this.getFurniture(1);
      } else {
        //贴纸
        this.getFurniture(6);
      }
      this.RewardsNum[e].id2 = "";
      this.RewardsNum[e].id3 = "";
      this.RewardsNum[e].num = "";
    },
    //任务奖励2
    taskReward1(e) {
      this.getFurniture2(this.RewardsNum[e].id2);
    },
    //任务奖励3
    taskReward2() {
      console.log(this.taskRewards);
    },

    //家具1
    getFurniture: function (e) {
      var that = this;
      that.isLoading = true;
      that.$api.journal
        .GetFurnitureSuit("GetFurnitureSuit", { type: e }, "get")
        .then((result) => {
          console.log(result.data);
          this.options2 = result.data.list;
        })
        .catch((error) => {
          that.$message.error(error);
        })
        .finally(() => {
          that.isLoading = false;
        });
    },
    //家具2
    getFurniture2: function (e) {
      var that = this;
      that.isLoading = true;
      that.$api.journal
        .GetFurnitureParts("GetFurnitureParts", { furniture_suit_id: e }, "get")
        .then((result) => {
          console.log(result.data);
          that.options3 = result.data.list;
        })
        .catch((error) => {
          that.$message.error(error);
        })
        .finally(() => {
          that.isLoading = false;
        });
    },


    addgoods(e) {
      this.dialogVisible1 = true; 
      this.infoId = e;
      this.RewardsNum = [];
      this.isLoading = true;
      this.$api.journal
        .rubbishReward("GetRubbishReward", { rubbish: this.infoId }, "get")
        .then((result) => {
          console.log(result);
          for (let i = 0; i < result.data.list.length; i++) {
            if (result.data.list[i].reward_type < 3) {
              this.RewardsNum.push({
                id: result.data.list[i].id,
                id1: result.data.list[i].reward_type,
                num: result.data.list[i].reward_value,
                id2: "",
                id3: "",
              });
            } else if (result.data.list[i].reward_type == 3) {
              var arr = result.data.list[i].reward_value.split(",");
              this.RewardsNum.push({
                id: result.data.list[i].id,
                id1: result.data.list[i].reward_type,
                num: "",
                id2: arr[0],
                id3: arr[1],
              });
            } else {
              this.RewardsNum.push({
                id: result.data.list[i].id,
                id1: result.data.list[i].reward_type,
                num: "",
                id2: result.data.list[i].reward_value,
                id3: "",
              });
            }
          }

          if (result.data.list.length == 0) {
            this.addgoodsType = 0;
          } else {
            this.addgoodsType = 1;
          }
          console.log(this.RewardsNum);
        })
        .catch((error) => {
          this.$message.error(error); 
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    //奖励保存
    goodsSava(e, q) {
      console.log(q);
      if (q.id == "") {
        if (this.RewardsNum[e].id1 < 3) {
          var infos = {
            rubbish: this.infoId,
            type: this.RewardsNum[e].id1,
            value: this.RewardsNum[e].num,
          };// eslint-disable-line no-unused-vars
        } else if (this.RewardsNum[e].id1 == 3) {
          var infos = {
            rubbish: this.infoId,
            type: this.RewardsNum[e].id1,
            value: this.RewardsNum[e].id2 + "," + this.RewardsNum[e].id3,
          };// eslint-disable-line no-unused-vars
        } else {
          var infos = {
            rubbish: this.infoId,
            type: this.RewardsNum[e].id1,
            value: this.RewardsNum[e].id2,
          }; // eslint-disable-line no-unused-vars
        }
      } else {
        if (this.RewardsNum[e].id1 < 3) {
          var infos = {
            id: q.id,
            rubbish: this.infoId,
            type: this.RewardsNum[e].id1,
            value: this.RewardsNum[e].num,
          };// eslint-disable-line no-unused-vars
        } else if (this.RewardsNum[e].id1 == 3) {
          var infos = {
            id: q.id,
            rubbish: this.infoId,
            type: this.RewardsNum[e].id1,
            value: this.RewardsNum[e].id2 + "," + this.RewardsNum[e].id3,
          };// eslint-disable-line no-unused-vars
        } else {
          var infos = {
            id: q.id,
            rubbish: this.infoId,
            type: this.RewardsNum[e].id1,
            value: this.RewardsNum[e].id2,
          };// eslint-disable-line no-unused-vars
        }
      }
      this.isLoading = true;
      this.$api.journal
        .rubbishReward("Save", infos, "POST")
        .then((result) => {
          console.log(result);
          this.$message.success(result.message);
          this.addgoods(this.infoId);
          //   this.dialogVisible1 = false;
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    //删除 任务奖励
    goodsDel(e) {
      this.isLoading = true;
      this.$api.journal
        .RewardSave("DeleteUserTaskReward", { id: e.id }, "POST")
        .then((result) => {
          console.log(result);
          this.$message.success(result.message);
          this.addgoods(this.infoId);
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    closebox() {
      this.getTableList();
      this.dialogVisible1 = false;
    },
    //分页
    handleSizeChange(val) {
      this.searchParams.size = val;
      this.getTableList();
    },

    handleCurrentChange(val) {
      this.searchParams.page = val;
      this.getTableList();
    },
    //删除垃圾
    goodsDel(e) {
      this.isLoading = true;
      this.$api.journal
        .rubbishReward("DeleteRubbishReward", { id: e.id }, "POST")
        .then((result) => {
          console.log(result);
          this.$message.success(result.message); 
          this.addgoods(this.infoId);
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style>
.flexBox {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.flexBoxTitle {
  margin-right: 20px;
}
.flexBoxInp {
  border: 1px solid gray;
  border-radius: 3px;
  width: 50%;
  height: 30px;
  outline: none;
  padding-left: 10px;
}
</style>
 