<template>
    <div>
        <!--搜索栏-->
        <el-form :inline="true" :model="searchParams" ref="searchParams" class="demo-form-inline">
            <el-form-item label="" prop="keywords">
                <el-input v-model="searchParams.keyword" placeholder="用户名或者电话号码"></el-input>
            </el-form-item>

            <el-form-item>
                <el-select v-model="searchParams.status" placeholder="状态">
                    <el-option label="正常用户" value="1"></el-option>
                    <el-option label="锁定用户" value="0"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select v-model="searchParams.group" placeholder="用户组">
                    <el-option label="全部" value="0"></el-option>
                    <el-option
                            v-for="item in groupList"
                            :key="item.uc_id"
                            :label="item.uc_name"
                            :value="item.uc_id">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button icon="el-icon-search" type="primary" :loading="isLoading" @click="getUserList()">查询
                </el-button>
                <el-button icon="el-icon-plus" type="primary" @click="addUser()">添加用户</el-button>
            </el-form-item>
        </el-form>

        <el-table
                :data="userList"
                stripe
                style="width: 100%">
            <el-table-column
                    prop="u_id"
                    label="用户ID"
                    width="180">
            </el-table-column>
            <el-table-column
                    prop="u_name"
                    label="用户名"
                    width="180">
            </el-table-column>

            <!--
            <el-table-column
                    prop="avator"
                    label="用户头像">
                <template slot-scope="scope">
                    <ImagePreview :file="scope.row.avator"/>
                </template>
            </el-table-column>
            -->

            <el-table-column
                    prop="u_chek"
                    label="权限组"
                    type="index"
                    width="180">
                <template slot-scope="scope">
                    {{getGroupName(scope.row.u_chek) }}
                </template>
            </el-table-column>
            <el-table-column
                    prop="status"
                    label="状态">
                <template slot-scope="scope">
                    <div v-if="scope.row.status=='1'">
                        <span>正常</span>
                    </div>
                    <div v-if="scope.row.status=='0'">
                        <span>锁定</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                    prop="last_login"
                    label="最近登录">
                <template slot-scope="{row}">
                    {{ row.last_login || '--' }}
                </template>
            </el-table-column>
            <el-table-column
                    prop="u_other"
                    label="备注">
                <template slot-scope="{row}">
                    {{ row.u_other || '--' }}
                </template>
            </el-table-column>

            <el-table-column
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="text" class="c-blue" @click="login(scope.$index,2)"
                               icon="el-icon-sort">登录
                    </el-button>
                    <el-button type="text" class="c-blue" @click="editUser(scope.$index)"
                               icon="el-icon-edit-outline">编辑
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--翻页-->
        <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="searchParams.page"
                :page-sizes="[10, 15, 20,50,100]"
                :page-size="searchParams.size"
                layout="total,sizes, prev, pager, next"
                :total="searchParams.count">
        </el-pagination>
        <!--表单弹框-->
        <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="30%">
            <el-form :model="form" :rules="formRules" status-icon ref="form" label-width="120px" class="">
                <el-form-item label="权限组：" prop="u_chek">
                    <el-select v-model="form.u_chek" placeholder="用户组">
                        <el-option label="全部" value="0"></el-option>
                        <el-option
                                v-for="item in groupList"
                                :key="item.uc_id"
                                :label="item.uc_name"
                                :value="item.uc_id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用户名称：" prop="u_name">
                    <el-input type="text" v-bind:readonly="form.u_id?true:false" placeholder="请输入用户名"
                              v-model="form.u_name" width="100"></el-input>
                </el-form-item>
                <el-form-item label="用户密码：" prop="u_password">
                    <el-input type="password" :placeholder="pwdTips" v-model="form.u_password"></el-input>
                </el-form-item>
                <!--
                <el-form-item label="用户头像：" prop="avator">
                    <image-uploader v-model="form.avator" dt="id"/>
                </el-form-item>
                -->
                <el-form-item label="手机号码：" prop="mobile">
                    <el-input type="text" placeholder="请输入用户名" v-model="form.mobile"></el-input>
                </el-form-item>
                <el-form-item label="用户状态" prop="status" required="">
                    <el-radio-group v-model="form.status">
                        <el-radio label="1">正常</el-radio>
                        <el-radio label="0">锁定</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="备注：" prop="u_other">
                    <el-input type="text" placeholder="请输入备注" v-model="form.u_other"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveUser()" :loading="isLoading">保 存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import ImageUploader from '../../components/ImageUploader.vue'
    import ImagePreview from '../../components/ImagePreview.vue'

    export default {
        name: "Users",
        components: {
            ImagePreview, ImageUploader
        },
        data() {
            return {
                isLoading: false,
                dialogFormVisible: false,
                form: {},
                dialogTitle: '添加用户',
                pwdTips: '请输入用户密码',
                formRules: {
                    u_name: {required: true, message: '请输入用户名称', trigger: 'blur'},
                },
                groupList: [],
                searchParams: {
                    keyword: '',
                    group: '',
                    status: '1',
                    page: 1,
                    export: '',
                    count: 1,
                    size: 10,
                },
                userList: [],
                statusText: {
                    '1': '正常',
                    '0': '锁定'
                }
            }
        },
        methods: {
            onAvatarChanged(file) {
                this.form.avator = file
            },
            //创建分组
            addUser() {
                this.dialogTitle = '添加用户';
                this.pwdTips = '请输入用户密码';
                this.form = {
                    u_id: '',
                    u_name: '',
                    u_password: '',
                    status: '1',
                    u_chek: '',
                    u_other: '',
                    mobile: '',
                    avator: ''
                };
                this.dialogFormVisible = true;
            },
            editUser(index) {
                this.dialogTitle = '编辑用户';
                this.pwdTips = '不修改密码请留空';
                this.form = this.userList[index];
                this.dialogFormVisible = true;
            },
            login(index) {
                let user = this.userList[index]
                this.$message.confirm({message: '确定要切换登录吗'}, () => {
                    this.$loading.show('登陆中')
                    this.$api.user.exchangeLogin({id: user.u_id}).then(result => {
                        if (result.errCode) {
                            this.$message.alert(result.message)
                        } else {
                            this.$message.success('登陆成功');
                            this.$user.reset(() => {
                                this.$eventHub.$emit("user_changed")
                            })
                        }
                    }).catch(error => {
                        this.$message.error(error)
                    }).finally(() => {
                        this.$loading.close()
                    })
                })
            },
            saveUser() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.isLoading = true;
                        this.$api.system.saveUser(this.form).then(result => {
                            if (result.errCode) {
                                this.$message.alert(result.message)
                            } else {
                                this.dialogFormVisible = false;
                                this.$message.success('保存成功');
                                this.getUserList()
                            }
                        }).catch(error => {
                            this.$message.error(error)
                        }).finally(() => {
                            this.isLoading = false
                        })
                    } else {
                        return false;
                    }
                });
            },
            //获取员工列表
            getUserList() {
                this.loading = true;
                this.$api.system.getUserList(this.searchParams).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.userList = result.data.list;
                        this.searchParams.count = result.data.total;//总条数
                        this.loading = false;
                    }
                }).catch(error => {
                    this.$message.error(error);
                    this.loading = false;
                });
            },
            //获取分组列表
            getGroups() {
                //this.$loading.show('加载中')
                this.isLoading = true
                this.$api.system.getGroups().then(result => {
                    this.isLoading = false;
                    if (result.errCode) {
                        this.$message.alert('加载角色列表失败:' + result.message)
                    } else {
                        this.groupList = result.data
                        this.getUserList()
                    }
                }).catch(error => {
                    this.isLoading = false
                    this.$message.error(error)
                }).finally(() => {
                    //this.isLoading = true
                    //this.$loading.close()
                })
            },
            getGroupName(id) {
                let name = ''
                this.groupList.forEach(function (item) {
                    if (item.uc_id == id) {
                        name = item.uc_name
                    }
                })
                return name
            },
            // 点击分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getUserList();
            },
            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getUserList();

            },
        },
        mounted() {
            this.getGroups()
        }
    }
</script>