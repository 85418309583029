<template>
    <div :id="echarts" class="echarts-container"></div>
</template>

<script>
    import echarts from 'echarts'

    export default {
        name: "ChartBar",
        props: { //接受父组件传递来的数据
            id: {
                type: String
            },
            items: {
                type: Object,
                xAxis: ["Mon", "Tue", 'Wed', 'Thu'],
                series: [
                    {
                        name: '直接访问',
                        type: 'bar',
                        barWidth: '60%',
                        data: [15, 45, 12, 54]
                    }
                ]
            }
        },
        data() {
            return {
                chart: ''
            }
        },
        watch: {
            items: {
                immediate: true,
                deep: true,
                handler(newVal, oldVal) {
                    if (this.chart) {
                        this.chart.hideLoading();
                        if (newVal) {
                            //let option = this.chart.getOption()
                            // this.chart.clear()
                            // option.xAxis[0]._data = newVal.xAxis
                            // option.series[0]._data = newVal.series
                            let option = {
                                xAxis: [{data: newVal.xAxis}],
                                series: [newVal.series],
                            }
                            this.chart.setOption(option);
                        }
                    }
                }
            }
        },
        computed: {
            echarts() {
                return 'echarts_bar_' + Math.random() * 100000
            }
        },
        mounted() {
            const echarts = require('echarts');
            const option = {
                color: ['#3AA1FF'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.items.xAxis,
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: this.items.series.name,
                        type: 'bar',
                        barWidth: this.items.series.barWidth,
                        data: this.items.series.data
                    }
                ]
            };
            this.chart = echarts.init(document.getElementById(this.echarts));
            this.chart.showLoading();
            console.log(option)
            this.chart.setOption(option);
//            const chartObj = echarts.init(document.getElementById(this.echarts));
//            chartObj.setOption(option);
        }
    }
</script>

<style scoped>
    .echarts-container {
        width: auto;
    }
</style>