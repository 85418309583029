<template>
    <div>
        <!--搜索栏-->
        <el-form :inline="true" :model="searchParams" ref="searchParams" class="demo-form-inline">
            <el-form-item label="" prop="keywords">
                <el-input v-model="searchParams.keyword" placeholder="商户名称/ID"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button icon="el-icon-search" type="primary" :loading="isLoading" @click="getList()">查询
                </el-button>
                <el-button icon="el-icon-plus" type="primary" @click="add()">添加商户</el-button>
            </el-form-item>
        </el-form>
        <el-table
                :data="dataList"
                stripe
                style="width: 100%">
            <el-table-column
                    prop="mch_id"
                    label="商户ID">
            </el-table-column>
            <el-table-column
                    prop="mch_name"
                    label="商户名称">
            </el-table-column>
            <el-table-column
                    prop="domain"
                    label="商户域名">
            </el-table-column>
            <el-table-column
                    prop="mch_phone_number"
                    label="联系电话">
            </el-table-column>
            <el-table-column
                    prop="admin"
                    label="管理员">
                <template slot-scope="{row}">
                    {{ row.admin.u_name || '--' }}
                </template>
            </el-table-column>

            <el-table-column
                    prop="u_chek"
                    label="权限组"
                    type="index"
                    width="180">
                <template slot-scope="scope">
                    {{getGroupName(scope.row.admin.u_chek) }}
                </template>
            </el-table-column>

            <el-table-column
                    prop="last_login"
                    label="创建时间">
                <template slot-scope="{row}">
                    {{row.create_date|time}}
                </template>
            </el-table-column>

            <el-table-column
                    label="操作">
                <template slot-scope="scope">
                    <!--<el-button type="text" class="c-blue" @click="login(scope.$index,2)"-->
                    <!--icon="el-icon-sort">切换-->
                    <!--</el-button>-->
                    <el-button type="text" class="c-blue" @click="edit(scope.$index)"
                               icon="el-icon-edit-outline">编辑
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--翻页-->
        <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="searchParams.page"
                :page-sizes="[10, 15, 20,50,100]"
                :page-size="searchParams.size"
                layout="total,sizes, prev, pager, next"
                :total="searchParams.count">
        </el-pagination>
        <!--表单弹框-->
        <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="40%">
            <el-form :model="form" :rules="formRules" status-icon ref="form" label-width="120px" class="">
                <el-form-item label="商户名称：" prop="mch_name">
                    <el-input type="text" placeholder="商户的名称" v-model="form.mch_name"></el-input>
                </el-form-item>
                <el-form-item label="联系电话：" prop="mch_phone_number">
                    <el-input type="text" placeholder="商家的联系电话" v-model="form.mch_phone_number"></el-input>
                </el-form-item>
                <el-form-item label="商户地址：" prop="mch_address">
                    <el-input type="text" placeholder="商家的详细地址" v-model="form.mch_address"></el-input>
                </el-form-item>
                <el-form-item label="地图坐标：" prop="mch_location">
                    <map-picker v-model="form.mch_location"></map-picker>
                </el-form-item>
                <el-form-item label="二级域名：" prop="domain">
                    <el-input type="text" placeholder="商家的二级域名" v-model="form.domain"></el-input>
                </el-form-item>
                <el-form-item label="接口密钥：" prop="appkey">
                    <el-input type="text" placeholder="32位字符串签名用密钥" v-model="form.appkey"></el-input>
                </el-form-item>
                <el-form-item label="权限组：" prop="u_chek">
                    <el-select v-model="form.u_chek" placeholder="用户组">
                        <el-option label="选择用户组" value=""></el-option>
                        <el-option
                                v-for="item in groupList"
                                :key="item.uc_id"
                                :label="item.uc_name"
                                :value="item.uc_id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <div v-if="!form.mch_id">
                    <el-form-item label="用户名称：" prop="username" required="">
                        <el-input type="text" placeholder="管理员用户名"
                                  v-model="form.username" width="100"></el-input>
                    </el-form-item>
                    <el-form-item label="用户密码：" prop="password" required="">
                        <el-input type="password" placeholder="管理员密码" v-model="form.password"></el-input>
                    </el-form-item>
                </div>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="save()" :loading="isLoading">保 存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import MapPicker from '../../components/MapPicker'

    export default {
        name: "Merchant",
        components: {
            MapPicker
        },
        data() {
            return {
                isLoading: false,
                dialogFormVisible: false,
                form: {
                    mch_location: ''
                },
                dialogTitle: '添加商户',
                formRules: {
                    mch_name: {required: true, message: '请输入商户名称', trigger: 'change'},
                    u_chek: {required: true, message: '请选择权限组', trigger: 'change'},
                },
                groupList: [],
                searchParams: {
                    keyword: '',
                    page: 1,
                    count: 1,
                    size: 10,
                },
                dataList: []
            }
        },
        methods: {
            //创建分组
            add() {
                this.dialogTitle = '添加商户';
                this.form = {
                    mch_location: ''
                };
                this.dialogFormVisible = true;
            },
            edit(index) {
                this.dialogTitle = '编辑商户';
                this.form = this.dataList[index];
                //this.form.u_chek = this.form.admin.u_chek
                this.$set(this.form, 'u_chek', this.form.admin.u_chek)
                this.dialogFormVisible = true;
            },
            save() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.isLoading = true;
                        let params = this.form
                        params.action = 'save'
                        this.$api.system.merchant(params, 'post').then(result => {
                            if (result.errCode) {
                                this.$message.alert(result.message)
                            } else {
                                this.dialogFormVisible = false;
                                this.$message.success('保存成功');
                                this.getList()
                            }
                        }).catch(error => {
                            this.$message.error(error)
                        }).finally(() => {
                            this.isLoading = false
                        })
                    } else {
                        return false;
                    }
                });
            },
            //获取员工列表
            getList() {
                this.loading = true;
                let params = this.searchParams
                params.action = 'get_list'
                this.$api.system.merchant(params).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.dataList = result.data.list;
                        this.searchParams.count = result.data.total;//总条数
                        this.loading = false;
                    }
                }).catch(error => {
                    this.$message.error(error);
                    this.loading = false;
                });
            },
            //获取分组列表
            getGroups() {
                this.isLoading = true
                this.$api.system.getGroups().then(result => {
                    this.isLoading = false;
                    if (result.errCode) {
                        this.$message.alert('加载权限分组失败:' + result.message)
                    } else {
                        this.groupList = result.data
                    }
                }).catch(error => {
                    this.$message.error(error)
                }).finally(() => {
                    this.isLoading = false
                })
            },
            getGroupName(id) {
                let name = ''
                this.groupList.forEach(function (item) {
                    if (item.uc_id == id) {
                        name = item.uc_name
                    }
                })
                return name
            },
            // 点击分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getList();

            },
        },
        mounted() {
            this.getList()
            this.getGroups()
        }
    }
</script>