<template>
    <el-tabs v-model="activeName" @tab-click="changeTab">
        <!--文案信息-->
        <el-tab-pane label="互动文案" name="first">
                <!--搜索栏-->
                <el-form :inline="true" :model="searchParams" ref="searchForm" label-width="90px" class="demo-form-inline">
                    <el-form-item prop="keyword" class="ml-10">
                        <el-input v-model="searchParams.keyword" placeholder="关键字"></el-input>
                    </el-form-item>
                    <el-form-item prop="sort_id" class="ml-10">
                        <el-select v-model="searchParams.sort_id" placeholder="心情类别">
                            <el-option label="全部" value= 0 ></el-option>
                            <el-option
                                    v-for="(item,index) in AllSortList"
                                    :key="item.id"
                                    :label="item.sort_name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button icon="el-icon-search" type="primary" @click="getListPetText()" :loading="isLoading">查询</el-button>
                        <el-button icon="el-icon-plus" type="primary" @click="createPetText('PetText')" :loading="isLoading">创建文案</el-button>
                    </el-form-item>
                </el-form>
                <!--表单信息-->
                <el-table
                        :data="dataPetTextList"
                        stripe
                        style="width: 100%"
                        row-key="id">
                    <el-table-column
                            prop="id"
                            label="ID"
                    >
                    </el-table-column>

                    <el-table-column
                            prop="name"
                            label="文案名称">
                    </el-table-column>

                    <el-table-column
                            prop="sort_name"
                            label="归属心情">
                    </el-table-column>

                    <el-table-column
                            prop="text"
                            label="文案信息">
                    </el-table-column>

                    <el-table-column
                            prop="skip_url"
                            label="跳转链接">
                    </el-table-column>

                    <el-table-column
                            prop="weight"
                            label="排序权重">
                    </el-table-column>

                    <el-table-column
                            prop="authority_desc"
                            label="使用权限">
                    </el-table-column>

                    <el-table-column
                            prop="forbidden_desc"
                            label="是否禁用">
                    </el-table-column>

                    <el-table-column
                            prop="created"
                            label="创建时间">
                        <template slot-scope="scope">
                            {{scope.row.create_time|time}}
                        </template>
                    </el-table-column>

                    <el-table-column
                            label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" class="c-blue" @click="confirmRemove(scope.row.id,'PetText')" icon="el-icon-help">
                                <a v-if="scope.row.is_forbidden == 0">禁用</a>
                                <a v-if="scope.row.is_forbidden == 1">启用</a>
                            </el-button>
                            <el-button type="text" class="c-blue" @click="editPetTextInfo(scope.$index)" icon="el-icon-edit-outline">编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!--翻页-->
                <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="searchParams.page"
                        :page-sizes="[10, 15, 20,50,100]"
                        :page-size="searchParams.size"
                        layout="total,sizes, prev, pager, next"
                        :total="searchParams.count">
                </el-pagination>

                <!--弹窗,通过el-dialog来进行限制-->
                <el-dialog :title="dialogTitlePetText" :visible.sync="dialogPetTextFormVisible" :close-on-click-modal="false" width="50%">
                    <el-form :model="PetText.form" label-width="80px">
                        <!--输入框-->
                        <el-form-item label="文案名称" required="">
                            <el-col :span="8">
                                <el-input v-model="PetText.form.name" clearable></el-input>
                            </el-col>
                        </el-form-item>

                        <el-form-item label="文案信息" required="">
                            <el-col :span="8">
                                <el-input type="textarea" v-model="PetText.form.text"></el-input>
                            </el-col>
                        </el-form-item>

                        <el-form-item label="跳转链接">
                            <el-col :span="8">
                                <el-input v-model="PetText.form.skip_url" clearable></el-input>
                            </el-col>
                        </el-form-item>

                        <!--select下拉框-->
                        <el-form-item label="心情类别" prop="sort_id" required="">
                            <el-select v-model="PetText.form.sort_id" placeholder="请选择心情类别">
                                <el-option label="选择类别" value= 0 ></el-option>
                                <el-option
                                        v-for="(item,index) in AllCanSortList"
                                        :key="item.id"
                                        :label="item.sort_name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <!--单选框 传入的值为label-->
                        <el-form-item label="使用权限" required="">
                            <el-radio-group v-model="PetText.form.authority">
                                <el-radio
                                        v-for="(item,index) in AllAuthorityList"
                                        :label="index"
                                        :value="index">
                                    {{item}}
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item label="排序权重" required="">
                            <el-col :span="8">
                                <el-input v-model.number="PetText.form.weight"></el-input>
                            </el-col>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary"  @click="CreateSubmitPetText('PetText')">立即创建</el-button>
                            <el-button  @click="CancelSubmitPetText('PetText')">取消</el-button>
                        </el-form-item>
                    </el-form>
                </el-dialog>

        </el-tab-pane>
        <!--心情类别-->
        <el-tab-pane label="心情类别" name="second">
            <el-button icon="el-icon-plus" type="primary" @click="createPetText('sort')" :loading="isLoading" style="margin-left:85%">创建类别</el-button>
            <!--表单信息-->
            <el-table
                    :data="AllSortList"
                    stripe
                    style="width: 100%"
                    row-key="id">
                <el-table-column
                        prop="id"
                        label="ID"
                >
                </el-table-column>
                <el-table-column
                        prop="sort_name"
                        label="类别名称">
                </el-table-column>

                <el-table-column
                        prop="weight"
                        label="排序权重">
                </el-table-column>

                <el-table-column
                        prop="authority_desc"
                        label="使用权限">
                </el-table-column>

                <el-table-column
                        prop="forbidden_desc"
                        label="是否禁用">
                </el-table-column>

                <el-table-column
                        prop="created"
                        label="创建时间">
                    <template slot-scope="scope">
                        {{scope.row.create_time|time}}
                    </template>
                </el-table-column>

                <el-table-column
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" class="c-blue" @click="confirmRemove(scope.row.id)" icon="el-icon-help">
                            <a v-if="scope.row.is_forbidden == 0">禁用</a>
                            <a v-if="scope.row.is_forbidden == 1">启用</a>
                        </el-button>

                        <el-button type="text" class="c-blue" @click="editSortInfo(scope.$index)" icon="el-icon-edit-outline">编辑
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-dialog :title="dialogTitleSort" :visible.sync="dialogSortFormVisible" :close-on-click-modal="false" width="50%">
                <el-form :model="AllSort.form" label-width="80px">
                    <!--输入框-->
                    <el-form-item label="名称" required="">
                        <el-col :span="8">
                            <el-input v-model="AllSort.form.sort_name" clearable ></el-input>
                        </el-col>
                    </el-form-item>

                    <!--单选框 传入的值为label-->
                    <el-form-item label="使用权限" required="">
                        <el-radio-group v-model="AllSort.form.authority">
                            <el-radio
                                    v-for="(item,index) in AllAuthorityList"
                                    :label="index"
                                    :value="index">
                                {{item}}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="排序权重" required="">
                        <el-col :span="8">
                            <el-input v-model.number="AllSort.form.weight"></el-input>
                        </el-col>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary"  @click="CreateSubmitPetText('sort')">立即创建</el-button>
                        <el-button  @click="CancelSubmitPetText('sort')">取消</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>

        </el-tab-pane>
    </el-tabs>

</template>

<script>

    export default {
        name: "PetText",
        components: {},
        mounted() {
            this.getListPetText();
            this.getAllPetTextSort();
            this.getAllAuthorityList();
        },
        data() {
            return {
                isLoading: false,
                //搜索传参
                searchParams: {
                    keyword: '',
                    sort_id: '0',
                    page: 1,
                    count: 0,
                    size: 10,
                },
                //弹窗表单
                dialogTitlePetText:'创建文案',
                dialogPetTextFormVisible:false,
                PetText:{
                    form:{
                        id : 0,
                        name : '',
                        sort_id : '',
                        authority : '1',
                        weight : '0',
                        is_forbidden : '0',
                        skip_url : '',
                        text : ''
                    }
                },
                //家具基础列表
                dataPetTextList: [],
                activeName: 'first',
                //类别相关
                //类别基础列表
                sortList:[],
                dialogTitleSort:'创建类别',
                dialogSortFormVisible:false,
                AllSort:{
                    form:{
                        id : 0,
                        sort_name : '',
                        sort_type : 7,
                        authority : '1',
                        weight : 0,
                        is_forbidden : 0,
                    }
                },
                //获取家具的所有分类(列表展示)
                AllSortList:[],
                //创建家具时，筛选出可用分类（筛选使用）
                AllCanSortList:[],
                //展示大图时对应的图片地址
                dialogSortImageUrl:'',
                //是否开启大图展示
                dialogSortVisible: false,
                //获取所有权限信息
                AllAuthorityList:[],
            }
        },
        methods: {
            //切换栏目时触发
            changeTab() {

            },
            //获取文案列表
            getListPetText() {
                this.isLoading = true;
                    this.$api.journal.pet_text('List', this.$copy(this.searchParams)).then(result => {
                        if (result.errCode) {
                            this.$message.error(result.message);
                        } else {
                            this.dataPetTextList = result.data.list;
                            this.searchParams.count = result.data.total;
                        }
                    }).catch(error => {
                        this.$message.error(error);
                    }).then(() => {
                        this.isLoading = false;
                    })
            },
            //禁用/启用
            confirmRemove(id,type){
                if(!id){
                    this.$message.error('未查询到反馈信息');
                    return;
                }
                if(type == 'PetText'){
                    this.$confirm('是否更改使用状态?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        //点击确认回调的函数
                        this.$api.journal.pet_text('SetChange', {id:id}).then(result => {
                            if (result.errCode) {
                                this.$message.error(result.message);
                            } else {
                                this.getListPetText();
                                this.$message({
                                    type: 'success',
                                    message: '操作成功!'
                                });
                            }
                        }).catch(error => {
                            this.$message.error(error);
                        }).then(() => {
                            this.isLoading = false;
                        });
                    }).catch(() => {
                        //点击取消回调的函数
                        this.$message({
                            type: 'info',
                            message: '取消'
                        });
                    });
                }else{
                    this.$confirm('是否更改使用状态?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        //点击确认回调的函数
                        this.$api.journal.all_sort('SetChange', {id:id}).then(result => {
                            if (result.errCode) {
                                this.$message.error(result.message);
                            } else {
                                this.getAllPetTextSort();
                                this.$message({
                                    type: 'success',
                                    message: '操作成功!'
                                });
                            }
                        }).catch(error => {
                            this.$message.error(error);
                        }).then(() => {
                            this.isLoading = false;
                        });
                    }).catch(() => {
                        //点击取消回调的函数
                        this.$message({
                            type: 'info',
                            message: '取消'
                        });
                    });
                }
            },
            //创建文案or类别（打开弹窗）
            createPetText(type) {
                if(type == 'PetText') {
                    this.dialogPetTextFormVisible = true;
                    this.PetText.form = {
                        id : 0,
                        name : '',
                        sort_id : '',
                        cover : '',
                        authority : '1',
                        weight : '0',
                        is_forbidden : '0',
                    };
                }else{
                    this.dialogSortFormVisible = true;
                    this.AllSort.form = {
                        id : 0,
                        sort_name : '',
                        cover : '',
                        sort_type : 7,
                        authority : '1',
                        weight : 0,
                        is_forbidden : 0,
                    };
                }
            },
            //提交文案信息
            CreateSubmitPetText(type){
                //文案相关
                if(type == 'PetText'){
                    this.$api.journal.pet_text('Save', this.PetText.form,'post').then(result => {
                        if (result.errCode) {
                            this.$message.error(result.message);
                        } else {
                            this.getListPetText();
                            this.dialogPetTextFormVisible = false;
                            this.$message.success('添加成功');
                        }
                    }).catch(error => {
                        this.$message.error(error);
                    }).then(() => {
                        this.isLoading = false;
                    });
                }else{
                    //类别相关
                    this.$api.journal.all_sort('Save', this.AllSort.form,'post').then(result => {
                        if (result.errCode) {
                            this.$message.error(result.message);
                        } else {
                            this.getAllPetTextSort();
                            this.dialogSortFormVisible = false;
                            this.$message.success('添加成功');
                        }
                    }).catch(error => {
                        this.$message.error(error);
                    }).then(() => {
                        this.isLoading = false;
                    });
                }
            },
            //取消提交
            CancelSubmitPetText(type){
                if(type == 'PetText'){
                    this.dialogPetTextFormVisible = false;
                }else{
                    this.dialogSortFormVisible = false;
                }
            },
            //编辑文案
            editPetTextInfo(index){
                this.dialogTitlePetText = '编辑文案';
                this.dialogPetTextFormVisible = true;
                //对应copy的使用
                this.PetText.form = this.$copy(this.dataPetTextList[index]);
            },

            //获取所有文案心情
            getAllPetTextSort(){
                let that = this;
                this.$api.journal.all_sort('SortInfo',{'type':7,'scene':1}).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.AllSortList = result.data;
                        that.AllCanSortList = [];
                        this.AllSortList.forEach(function(value){
                            if(value.is_forbidden == 0){
                                that.AllCanSortList.push(value);
                            }
                        });
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                });
            },

            //编辑类别
            editSortInfo(index){
                this.dialogTitleSort = '编辑类别';
                this.dialogSortFormVisible = true;
                this.AllSort.form = this.$copy(this.AllSortList[index]);
            },

            //获取所有权限类别
            getAllAuthorityList(){
                this.$api.journal.all_sort('AuthorityInfo').then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.AllAuthorityList = result.data;
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                });
            },

            //分页相关
            // 点击分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getListPetText();
            },

            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getListPetText();
            },

            //获取所有部件


            //获取所有类别

        }
    }
</script>

