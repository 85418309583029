<template>
    <div :id="echarts" class="echarts-container"></div>
</template>

<script>
    import echarts from 'echarts'
    import '../../node_modules/echarts/map/js/china.js'
    export default {
        name: "ChartBar",
        props: { //接受父组件传递来的数据
            items: {
                mydata: [
                    {name: '北京', value: '100'}, {name: '天津', value: '100'},
                    {name: '上海', value: '100'}, {name: '重庆', value: '100'},
                    {name: '河北', value: '100'}, {name: '河南', value: '100'},
                    {name: '云南', value: '100'}, {name: '辽宁', value: '100'},
                    {name: '黑龙江', value: '100'}, {name: '湖南', value: '100'},
                    {name: '安徽', value: '100'}, {name: '山东', value: '100'},
                    {name: '新疆', value: '100'}, {name: '江苏', value: '100'},
                    {name: '浙江', value: '100'}, {name: '江西', value: '100'},
                    {name: '湖北', value: '100'}, {name: '广西', value: '100'},
                    {name: '甘肃', value: '100'}, {name: '山西', value: '100'},
                    {name: '内蒙古', value: '100'}, {name: '陕西', value: '100'},
                    {name: '吉林', value: '100'}, {name: '福建', value: '100'},
                    {name: '贵州', value: '100'}, {name: '广东', value: '100'},
                    {name: '青海', value: '100'}, {name: '西藏', value: '100'},
                    {name: '四川', value: '100'}, {name: '宁夏', value: '100'},
                    {name: '海南', value: '100'}, {name: '台湾', value: '100'},
                    {name: '香港', value: '100'}, {name: '澳门', value: '100'}
                ]
            }
        },
        computed: {
            echarts(){
                return 'echarts' + Math.random() * 100000
            }
        },
        mounted() {
            const echarts = require('echarts');

            const option = {
                tooltip: {
                    trigger: 'item',
                    formatter: function(params) {
                        var res = '';
                        res+=params.name + ' : ' + params.value;
                        return res;
                    }
                },
                visualMap: {
                    min: 1,
                    max: 1007,
                    realtime: false,
                    calculable: true,
                    inRange: {
                        color: ['#629fe0', '#adcdef']
                    },
                },

                series: [{
                    color: '#adcdef',
                    type: 'map',
                    map: 'china',
                    roam: false,
                    data: this.items.mydata,  //数据
                    itemStyle: {
                        normal: {
                            areaStyle: {
                                color: '#adcdef',//默认的地图板块颜色
                            },
                            borderWidth:1,
                            borderColor:'#fff',
                        },
                        emphasis: {
                            label: {
                                show: false,//选中状态是否显示省份名称
                            },
                            areaStyle: {
                                color: '#feb41c',//选中状态的地图板块颜色
                            },
                        },
                    },


                }]
            };
            const chartObj = echarts.init(document.getElementById(this.echarts));
            chartObj.setOption(option);
        },
    }
</script>

<style scoped>
    .echarts-container {
        width: auto;
    }
</style>