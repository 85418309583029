<template>
    <div class="">
        <el-form :model="formData" :rules="rules" ref="form" label-width="200px">
            <div><i class="el-icon-collection-tag mr-5"></i>权限节点</div>
            <el-form-item label="当前版本:">
                <el-row :gutter="10">
                    <el-col :span="5">
                        <span>{{navVersion.local|time}}</span>
                    </el-col>
                    <el-col :span="3" v-if="navVersion.local < navVersion.remote">
                        <el-button type="success" @click="PullAuthNodeVersion()" size="small" :loading="isLoading">
                            拉取
                        </el-button>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="线上版本:">
                <el-row :gutter="10">
                    <el-col :span="5">
                        <span>{{navVersion.remote|time}}</span>
                    </el-col>
                    <el-col :span="3" v-if="navVersion.local >navVersion.remote">
                        <el-button type="success" @click="PushAuthNodeVersion()" size="small" :loading="isLoading">
                            推送
                        </el-button>
                    </el-col>
                </el-row>
            </el-form-item>
    <div class="mt-20"><i class="el-icon-collection-tag mr-5"></i>服务器</div>
    <el-form-item label="版本发布:">
        <el-row :gutter="10">
            <el-col :span="5">
                <el-input type="text" placeholder="版本发布的服务器IP地址" v-model="formData.commit_server"></el-input>
            </el-col>
        </el-row>
    </el-form-item>
<el-form-item v-for="(item,index) in formData.production_servers" :key="index"
              :label="'生产环境#'+(index+1)+':'">
    <el-row :gutter="10" type="flex">
        <el-col :span="3">
            <el-input type="text" placeholder="服务器名称,示范:slb-1" v-model="item.name">
                <template slot="prepend">名称</template>
            </el-input>
        </el-col>
        <el-col :span="5">
            <el-input type="text" placeholder="服务器IP地址" v-model="item.ip">
                <template slot="prepend">IP</template>
            </el-input>
        </el-col>
        <el-col :span="5">
            <el-input type="text" placeholder="服务器绑定域名" v-model="item.host">
                <template slot="prepend">域名</template>
            </el-input>
        </el-col>
        <el-col :span="2">
            <span>版本:{{item.version||'--'}}</span>
        </el-col>
        <el-col :span="2" v-if="index==0">
            <el-button type="success" @click="addProductionServer()">新增</el-button>
        </el-col>
        <el-col :span="2" v-if="index!=0">
            <el-button type="info" @click="removeProductionServer(index)">移除</el-button>
        </el-col>
    </el-row>
    <el-row :gutter="10" type="flex">
        <el-col :span="3" v-if="item.status.up_time=='loading'">
            状态:<i class="el-icon-loading"/>
        </el-col>
        <template v-if="item.status.up_time && item.status.up_time!='loading'">
            <el-col :span="24">
                <span>启动时间:{{item.status.up_time|secondToHour}}前</span>
                <span class="ml-20">缓存刷新:{{item.status.last_restart|secondToHour}}前</span>
                <span class="ml-20">占用内存:{{parseFloat(item.status.memory.used_memory/1024/1024).toFixed(1)}}MB</span>
                <span class="ml-20">空闲内存:{{parseFloat(item.status.memory.free_memory/1024/1024).toFixed(1)}}MB</span>
            </el-col>
        </template>
    </el-row>
</el-form-item>
<el-form-item>
        <el-button type="danger" @click="flushCache()" :loading="isLoading">清理数据缓存</el-button>
<!--    <el-button type="warning" @click="refreshServersCache()" :loading="isLoading">刷新OPCache</el-button>-->
    <el-button type="primary" @click="submit()" :loading="isLoading">保存配置</el-button>
</el-form-item>
</el-form>
</div>
</template>

<script>
//import moment from 'moment';

export default {
name: 'Config',
mounted() {
this.loadConfig()
},
components: {},
data() {
return {
    isLoading: false,
    groupList: [],
    groupSetting: [],
    formData: {
        authnode_version: '',
        commit_server: '',
        production_servers: []
    },
    rules: {
        name: [{required: true, message: '请选择投保状态', trigger: 'change'}],
    },
    navVersion: {
        remote: '',
        local: ''
    },
    serverStatusTimer: null,
    isPageActivation: false
};
},
methods: {
addProductionServer() {
    window.clearTimeout(this.serverStatusTimer)
    this.formData.production_servers.push({
        name: '',
        ip: '',
        host: '',
        version: '',
        status: {up_time: 0, last_restart: 0, memory: {used_memory: 0, free_memory: 0}}
    })
},
removeProductionServer(index) {
    window.clearTimeout(this.serverStatusTimer)
    this.formData.production_servers.splice(index, 1)
},
//拉取线上的权限节点
PullAuthNodeVersion() {
    this.$message.confirm({message: '确定拉取数据到本地环境吗?'}, () => {
        this.isLoading = true
        this.$api.system.config('PullAuthNodeVersion').then(result => {
            this.isLoading = false;
            if (result.errCode) {
                this.$message.alert(result.message)
            } else {
                this.$message.success('拉取成功:' + result.data)
                this.loadConfig()
            }
        }).catch(error => {
            this.$message.error(error)
        }).finally(() => {
            this.isLoading = false
        })
    })

},
//同步权限节点
PushAuthNodeVersion() {
    this.$message.confirm({message: '确定推送数据到生产环境吗?'}, () => {
        this.isLoading = true
        this.$api.system.config('PushAuthNodeVersion', {}, 'post').then(result => {
            this.isLoading = false;
            if (result.errCode) {
                this.$error(result.message)
            } else {
                this.$message.success('同步成功:' + result.data)
                this.loadConfig()
            }
        }).catch(error => {
            this.$message.error(error)
        }).finally(() => {
            this.isLoading = false
        })
    })
},
//保存配置
submit() {
    this.$refs['form'].validate((valid) => {
        if (valid) {
            this.isLoading = true
            this.$api.system.config('SaveConfig', this.formData, 'post').then(result => {
                this.isLoading = false;
                if (result.errCode) {
                    this.$error(result.message)
                } else {
                    this.$message.success('保存成功')
                    this.loadConfig()
                }
            }).catch(error => {
                this.$message.error(error)
            }).finally(() => {
                this.isLoading = false
            })
        } else {
            return false;
        }
    });
},
//获取数据版本
loadNavVersion() {
    this.isLoading = true;
    this.$api.system.config('LoadNavVersion', {}).then(result => {
        if (result.errCode) {
            this.$error('加载数据版本失败:' + result.message)
        } else {
            this.navVersion = result.data
        }
    }).catch(error => {
        this.$message.alert(error)
    }).finally(() => {
        this.isLoading = false;
    })
},
//清理数据缓存
flushCache() {
    this.isLoading = true;
    this.$api.system.config('FlushDataCache', {}).then(result => {
        if (result.errCode) {
            this.$message.alert(result.message);
        } else {
            this.$message.success('缓存清理成功')
        }
    }).catch(error => {
        this.$message.error(error)
    }).finally(() => {
        this.isLoading = false
    })
},
//刷新opcache
refreshServersCache() {
    this.isLoading = true
    this.$api.system.config('RefreshServersCache', {}).then(result => {
        if (result.errCode) {
            this.$message.alert(result.message);
        } else {
            this.$message.success(result.data + '台服务器缓存刷新成功')
            this.getServersStatus()
        }
    }).catch(error => {
        this.$message.error(error)
    }).finally(() => {
        this.isLoading = false
    })
},
//获取服务器状态
getServersStatus() {
    if (!this.isPageActivation) {
        return
    }
    clearTimeout(this.serverStatusTimer)
    if (!this.formData.production_servers[0].ip) {
        return
    }
    this.$api.system.config('ServersStatus', {}).then(result => {
        if (!result.errCode && this.isPageActivation) {
            this.$set(this.formData, 'production_servers', result.data)
        }
    }).catch(error => {
        this.$message.error(error)
    }).finally(() => {
        if (this.isPageActivation) {
            this.serverStatusTimer = window.setTimeout(() => {
                this.getServersStatus()
            }, 10000)
        }
    })
},
//获取配置
loadConfig() {
    this.isLoading = true
    this.$api.system.config('LoadConfig', {}).then(result => {
        this.isLoading = false;
        if (result.errCode) {
            this.$error(result.message)
        } else {
            this.formData = result.data || {}
            this.isPageActivation = true
            if (this.formData.production_servers[0].ip) {
                this.getServersStatus()
            }
        }
    }).catch(error => {
        this.$message.error(error)
    }).finally(() => {
        this.isLoading = false
        this.loadNavVersion()
    })
},
},
beforeDestroy() {
window.clearTimeout(this.serverStatusTimer)
this.serverStatusTimer = null
this.isPageActivation = false
},
destroyed() {
window.clearTimeout(this.serverStatusTimer)
this.serverStatusTimer = null
this.isPageActivation = false
}
}
</script>
<style>
.insurePicture .el-upload-list--picture-card {
position: absolute;
left: 0;
}
</style>
<style scoped="">
.row-table {
border: #ccc solid 1px;
border-bottom: none;
font-size: 14px;
}

.row-table:last-child {
border-bottom: #ccc solid 1px;
}

.col-title, .col-content {
padding: 10px;
}

.col-title {
background-color: #f5f5f5;
border-right: #ccc solid 1px;
}

.col-content {
border-right: #ccc solid 1px;
}

.col-content:last-child {
border-right: none;
}

.insurePicture {

}

.insurePicture .item {
position: relative;
display: inline-block;
margin-right: 10px;
margin-bottom: 10px;
text-align: center;
}

</style>
