<template>
    <div>
        <!--<js src="http://api.map.baidu.com/api?v=2.0&services=false&ak=5202126d38c010ca90e79bd0f5d25b14"/>-->
        <el-input type="text" placeholder="点击选择" @focus="mapDialogVisible=true" v-model="location" readonly="readonly">
            <i slot="prefix" class="el-input__icon el-icon-location-outline"/>
        </el-input>
        <el-dialog
                :modal="false"
                title="选择地图坐标"
                :visible.sync="mapDialogVisible"
                style="padding: 0px 0px"
                custom-class="mappicker"
                :show-close="false"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                center
                @opened="onOpened"
                width="60%">
            <template slot="title">
                <el-row :gutter="10">
                    <el-col :span="24" class="txt-center">
                        <span>{{choosedLocation.addr}} lng:{{markerPosition.lng||'--'}}, lat:{{markerPosition.lat||'--'}}</span>
                    </el-col>
                    <!--<el-col :span="6">-->
                    <!--<el-input type="text" placeholder="地名关键字" v-model="keyword">-->
                    <!--<template slot="append">-->
                    <!--<el-button type="text" @click="search">&nbsp;搜索</el-button>-->
                    <!--</template>-->
                    <!--</el-input>-->
                    <!--</el-col>-->
                </el-row>
            </template>
            <baidu-map class="bm-view" ak="5202126d38c010ca90e79bd0f5d25b14"
                       :map-click="false"
                       :scroll-wheel-zoom="true"
                       :center="center"
                       :zoom="zoom"
                       @ready="onBaiduMapReady"
                       @moving="syncCenterAndZoom"
                       @moveend="syncCenterAndZoom"
                       @zoomend="syncCenterAndZoom"
                       @click="getClickInfo">
                <bm-view style="width: 100%; height: 100%;"/>
                <bm-marker v-if="markerPosition.lat" :position="markerPosition" :dragging="true"
                           animation="BMAP_ANIMATION_BOUNCE">
                </bm-marker>
                <bm-control :offset="{left: '10px', top: '10px'}" anchor="BMAP_ANCHOR_TOP_RIGHT">
                    <bm-auto-complete v-model="keyword" :sug-style="{zIndex: 999999}">
                        <el-input v-model="keyword" type="text" placeholder="请输入地名关键字" clearable>
                            <i slot="prefix" class="el-input__icon el-icon-search"/>
                        </el-input>
                    </bm-auto-complete>
                </bm-control>
                <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" show-address-bar auto-location/>
                <bm-local-search :keyword="keyword" :auto-viewport="true" :panel="false"/>
            </baidu-map>
            <!--<div id="mapmarker">-->
            <!--<div id="search_area" style="position:absolute; top:50px; right:20px; z-index:9999">-->

            <!--</div>-->
            <!--<div id="mapmarkersearch_result" style="display:none"></div>-->
            <!--<div id="mapmarkercontainer" style="height:600px;width:900px;"></div>-->
            <!--</div>-->
            <span slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>

</template>

<script>
    import {BaiduMap, BmView, BmControl, BmMarker, BmAutoComplete, BmLocalSearch, BmGeolocation} from 'vue-baidu-map'

    export default {
        name: "MapPicker",
        props: {
            //此处一定要用value
            value: {
                type: String
            }
        },
        components: {
            BaiduMap, BmView, BmControl, BmMarker, BmAutoComplete, BmLocalSearch, BmGeolocation
        },
        watch: {
            //监听prop传的value，如果父级有变化了，将子组件的myValue也跟着变，达到父变子变的效果
            value(v) {
                this.location = v
            },
            //监听myValue，如果子组件中的内容变化了，通知父级组件，将新的值告诉父级组件，我更新了，父级组件接受到值后页就跟着变了
            //参考官网：https://cn.vuejs.org/v2/guide/components-custom-events.html#%E8%87%AA%E5%AE%9A%E4%B9%89%E7%BB%84%E4%BB%B6%E7%9A%84-v-model
            location(v) {
                this.$emit('input', v)
            }
        },
        mounted() {
            //this.init()
        },
        data() {
            return {
                location: this.value,
                BMap: null, // 地图组件是否就绪
                map: null,
                mapDialogVisible: false,
                keyword: '',
                zoom: 13,
                markerPosition: {lng: '', lat: ''},
                center: {lng: ' 106.554407', lat: '29.554717'},
                choosedLocation: {}
            }
        },
        methods: {
            onOpened() {
                this.keyword = ''

                if (this.location) {
                    this.zoom = 18
                    let arr = this.location.split(",")
                    this.markerPosition = {lng: arr[0], lat: arr[1]}
                    this.center = {lng: arr[0], lat: arr[1]}
                } else {
                    this.zoom = 13
                    this.init()
                }
            },
            init() {
                this.markerPosition = {lng: '', lat: ''}
                this.center = {lng: ' 106.554407', lat: '29.554717'}
            },
            submit() {
                if (!this.markerPosition.lng) {
                    return this.$message.warning('请先点击地图选择坐标')
                }
                this.location = this.markerPosition.lng + ',' + this.markerPosition.lat
                this.mapDialogVisible = false
            },
            // ready事件：在你需要二次开发或手动控制其子组件，可以使用在此事件中使用返回的 BMap 类和 map 实例进行手动控制
            onBaiduMapReady(e) {
                //console.log(e)
                this.BMap = e.BMap
                this.map = e.map
                this.map.setDefaultCursor("default");
                //const that = this
                // if (this.BMap) {
                //     // 获取定位地址信息并赋值给声明变量
                //     // Geolocation 对象的 getCurrentPosition()、watchPosition()、clearWatch() 方法详解 ：https://blog.csdn.net/zyz00000000/article/details/82774543
                //     const geolocation = new this.BMap.Geolocation()
                //     // 通过 getCurrentPosition() 方法：获取当前的位置信息
                //     geolocation.getCurrentPosition(res => {
                //         //console.log('返回详细地址和经纬度', res)
                //         const {lng, lat} = res.point
                //         const {province, city, district, street, street_number} = res.address
                //         that.center = {lng, lat}
                //         that.choosedLocation = {province, city, district, addr: street + street_number, lng, lat}
                //     })
                // }
            },
            /** *
             * 地图点击事件。
             */
            getClickInfo(e) {
                // 调整地图中心位置
                this.markerPosition.lng = e.point.lng
                this.markerPosition.lat = e.point.lat
                this.center = {
                    lng: e.point.lng,
                    lat: e.point.lat
                }
                // 此时已经可以获取到BMap类
                if (this.BMap) {
                    const that = this
                    // Geocoder() 类进行地址解析
                    // 创建地址解析器的实例
                    const geoCoder = new this.BMap.Geocoder()
                    // getLocation() 类--利用坐标获取地址的详细信息
                    // getPoint() 类--获取位置对应的坐标
                    geoCoder.getLocation(e.point, function (res) {
                        //console.log('获取经纬度', e.point, '获取详细地址', res)
                        const addrComponent = res.addressComponents
                        const surroundingPois = res.surroundingPois
                        const province = addrComponent.province
                        const city = addrComponent.city
                        const district = addrComponent.district
                        let addr = addrComponent.street
                        if (surroundingPois.length > 0 && surroundingPois[0].title) {
                            if (addr) {
                                addr += `-${surroundingPois[0].title}`
                            } else {
                                addr += `${surroundingPois[0].title}`
                            }
                        } else {
                            addr += addrComponent.streetNumber
                        }
                        that.choosedLocation = {province, city, district, addr, ...that.center}
                    })
                }
            },
            syncCenterAndZoom(e) {
                // 返回地图当前的缩放级别
                this.zoom = e.target.getZoom()
            },
            close() {
                this.init()
                this.mapDialogVisible = false
            }
        }
    }
</script>

<style>
    .mappicker {

    }

    .mappicker > div:nth-child(1) {
        height: 30px;
    }

    .mappicker > div:nth-child(2) {
        padding: 0px 0px;
    }

    /*.el-dialog__header {*/
    /*height: 30px;*/
    /*}*/

    /*.el-dialog__body {*/
    /*padding: 0px 0px;*/
    /*}*/

    .bm-view {
        width: 100%;
        height: 500px;
    }
</style>