<template>
    <el-tabs v-model="activeName" @tab-click="changeTab">
        <!--模板信息-->
        <el-tab-pane label="模板信息" name="first">
                <!--搜索栏-->
                <el-form :inline="true" :model="searchParams" ref="searchForm" label-width="90px" class="demo-form-inline">
                    <el-form-item prop="keyword" class="ml-10">
                        <el-input v-model="searchParams.keyword" placeholder="关键字"></el-input>
                    </el-form-item>
                    <el-form-item prop="sort_id" class="ml-10">
                        <el-select v-model="searchParams.sort_id" placeholder="模板类别">
                            <el-option label="全部" value= 0 ></el-option>
                            <el-option
                                    v-for="(item,index) in AllSortList"
                                    :key="item.id"
                                    :label="item.sort_name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button icon="el-icon-search" type="primary" @click="getListTextTpl()" :loading="isLoading">查询</el-button>
                        <el-button icon="el-icon-plus" type="primary" @click="create('TextTpl')" :loading="isLoading">创建模板</el-button>
                    </el-form-item>
                </el-form>
                <!--表单信息-->
                <el-table
                        :data="dataList"
                        stripe
                        style="width: 100%"
                        row-key="id">
                    <el-table-column
                            prop="id"
                            label="ID"
                    >
                    </el-table-column>

                    <el-table-column
                            prop="name"
                            label="模板名称">
                    </el-table-column>

                    <el-table-column
                            prop="sort_name"
                            label="模板类别">
                    </el-table-column>

                    <el-table-column
                            prop="cover"
                            label="模板图片">
                        <template slot-scope="scope">
                            <image-preview :file="scope.row.cover"/>
                        </template>
                    </el-table-column>

                    <el-table-column
                            prop="weight"
                            label="排序权重">
                    </el-table-column>

                    <el-table-column
                            prop="authority_desc"
                            label="使用权限">
                    </el-table-column>

                    <el-table-column
                            prop="forbidden_desc"
                            label="是否禁用">
                    </el-table-column>

                    <el-table-column
                            prop="created"
                            label="创建时间">
                        <template slot-scope="scope">
                            {{scope.row.create_time|time}}
                        </template>
                    </el-table-column>

                    <el-table-column
                            label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" class="c-blue" @click="confirmRemove(scope.row.id,'TextTpl')" icon="el-icon-help">
                                <a v-if="scope.row.is_forbidden == 0">禁用</a>
                                <a v-if="scope.row.is_forbidden == 1">启用</a>
                            </el-button>
                            <el-button type="text" class="c-blue" @click="editTextTplInfo(scope.$index)" icon="el-icon-edit-outline">编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!--翻页-->
                <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="searchParams.page"
                        :page-sizes="[10, 15, 20,50,100]"
                        :page-size="searchParams.size"
                        layout="total,sizes, prev, pager, next"
                        :total="searchParams.count">
                </el-pagination>

                <!--弹窗,通过el-dialog来进行限制-->
                <el-dialog :title="dialogTitleTextTpl" :visible.sync="dialogTextTplFormVisible" :close-on-click-modal="false" width="60%">
                    <el-form :model="TextTpl.form" label-width="100px">
                        <!--输入框-->
                        <el-form-item label="名称" required="">
                            <el-col :span="8">
                                <el-input v-model="TextTpl.form.name" clearable></el-input>
                            </el-col>
                        </el-form-item>

                        <!--select下拉框-->
                        <el-form-item label="模板类别" prop="sort_id" required="">
                            <el-select v-model="TextTpl.form.sort_id" placeholder="请选择模板类别">
                                <el-option label="选择类别" value= 0 ></el-option>
                                <el-option
                                        v-for="(item,index) in AllCanSortList"
                                        :key="item.id"
                                        :label="item.sort_name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="使用权限" required="">
                            <el-radio-group v-model="TextTpl.form.authority">
                                <el-radio
                                        v-for="(item,index) in AllAuthorityList"
                                        :label="index"
                                        :value="index">
                                    {{item}}
                                </el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item label="模板封面图" required="">
                            <el-upload
                                    action="/common/upload/multipart/"
                                    list-type="picture-card"
                                    :on-preview="handlePictureCardPreview"
                                    :on-remove="handleRemoveTextTpl"
                                    :on-success="handlePictureSuccessTextTpl"
                                    :limit="1"
                                    :file-list="TextTplPicList"
                            >
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisibleTextTpl">
                                <img width="100%" :src="dialogImageUrlTextTpl" alt="">
                            </el-dialog>
                        </el-form-item>

                        <el-form-item label="模板资源包">
<!--                            <el-upload-->
<!--                                    style="text-align:left; display: inline-block;"-->
<!--                                    action="/common/upload/multipart/"-->
<!--                                    :on-preview="handlePreviewFile"-->
<!--                                    :on-remove="handleRemoveFile"-->
<!--                                    :on-success="handleSuccessFile"-->
<!--                                    multiple-->
<!--                                    :limit="1"-->
<!--                                    :on-exceed="handleExceedFile"-->
<!--                                    :file-list="TextTplPatchList"-->
<!--                            >-->
                              <el-upload
                                  style="text-align:left; display: inline-block;"
                                  action="/common/upload/multipart_compressed_package/"
                                  :on-preview="handlePreviewFile"
                                  :on-remove="handleRemoveFile"
                                  :on-success="handleSuccessFile"
                                  multiple
                                  :limit="1"
                                  :on-exceed="handleExceedFile"
                                  :file-list="TextTplPatchList"
                              >
                                <el-button size="small" type="primary">点击上传</el-button>
                                <div slot="tip" class="el-upload__tip"></div>
                            </el-upload>
                        </el-form-item>

                        <el-form-item label="资源路径" prop="forbid">
                            <el-input v-model="TextTpl.form.resource" :disabled="true"></el-input>
                        </el-form-item>

                        <el-form-item label="排序权重" required="">
                            <el-col :span="8">
                                <el-input v-model.number="TextTpl.form.weight"></el-input>
                            </el-col>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary"  @click="CreateSubmitTextTpl('TextTpl')">立即创建</el-button>
                            <el-button  @click="CancelSubmitTextTpl('TextTpl')">取消</el-button>
                        </el-form-item>
                    </el-form>
                </el-dialog>

        </el-tab-pane>
        <!--模板类别-->
        <el-tab-pane label="模板类别" name="second">
            <el-button icon="el-icon-plus" type="primary" @click="create('sort')" :loading="isLoading" style="margin-left:85%">创建类别</el-button>
            <!--表单信息-->
            <el-table
                    :data="AllSortList"
                    stripe
                    style="width: 100%"
                    row-key="id">
                <el-table-column
                        prop="id"
                        label="ID"
                >
                </el-table-column>
                <el-table-column
                        prop="sort_name"
                        label="类别名称">
                </el-table-column>
                <el-table-column
                        prop="cover"
                        label="类别图片">
                    <template slot-scope="scope">
                        <image-preview :file="scope.row.cover"/>
                    </template>
                </el-table-column>

                <el-table-column
                        prop="weight"
                        label="排序权重">
                </el-table-column>

                <el-table-column
                        prop="authority_desc"
                        label="使用权限">
                </el-table-column>

                <el-table-column
                        prop="forbidden_desc"
                        label="是否禁用">
                </el-table-column>

                <el-table-column
                        prop="created"
                        label="创建时间">
                    <template slot-scope="scope">
                        {{scope.row.create_time|time}}
                    </template>
                </el-table-column>

                <el-table-column
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" class="c-blue" @click="confirmRemove(scope.row.id)" icon="el-icon-help">
                            <a v-if="scope.row.is_forbidden == 0">禁用</a>
                            <a v-if="scope.row.is_forbidden == 1">启用</a>
                        </el-button>

                        <el-button type="text" class="c-blue" @click="editSortInfo(scope.$index)" icon="el-icon-edit-outline">编辑
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!--弹窗-->
            <el-dialog :title="dialogTitleSort" :visible.sync="dialogSortFormVisible" :close-on-click-modal="false" width="50%">
                <el-form :model="AllSort.form" label-width="80px">
                    <!--输入框-->
                    <el-form-item label="名称" required="">
                        <el-col :span="8">
                            <el-input v-model="AllSort.form.sort_name" clearable ></el-input>
                        </el-col>
                    </el-form-item>

                    <!--单选框 传入的值为label-->
                    <el-form-item label="使用权限" required="">
                        <el-radio-group v-model="AllSort.form.authority">
                            <el-radio
                                    v-for="(item,index) in AllAuthorityList"
                                    :label="index"
                                    :value="index">
                                {{item}}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="类别图片" required="">
                        <el-upload
                                action="/common/upload/multipart/"
                                list-type="picture-card"
                                :on-preview="handleSortPictureCardPreview"
                                :on-remove="handleRemoveSortPic"
                                :on-success="handleSortPictureSuccess"
                                :limit="1"
                                :file-list = "SortPicList"
                        >
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="dialogSortVisible">
                            <img width="100%" :src="dialogSortImageUrl" alt="">
                        </el-dialog>
                    </el-form-item>

                    <el-form-item label="排序权重" required="">
                        <el-col :span="8">
                            <el-input v-model.number="AllSort.form.weight"></el-input>
                        </el-col>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary"  @click="CreateSubmitTextTpl('sort')">立即创建</el-button>
                        <el-button  @click="CancelSubmitTextTpl('sort')">取消</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>

        </el-tab-pane>
    </el-tabs>

</template>

<script>

    import ImagePreview from '../../components/ImagePreview'

    export default {
        name: "TextTplList",
        components: {
            ImagePreview
        },
        mounted() {
            this.getListTextTpl();
            this.getAllTextTplSort();
            this.getAllAuthorityList();
        },
        data() {
            return {
                isLoading: false,
                //搜索传参
                searchParams: {
                    keyword: '',
                    sort_id: '0',
                    page: 1,
                    count: 0,
                    size: 10,
                },
                //弹窗表单
                dialogTitleTextTpl:'创建模板',
                dialogTextTplFormVisible:false,
                TextTpl:{
                    form:{
                        id : 0,
                        name : '',
                        sort_id : '',
                        cover : '',
                        authority : '1',
                        weight : '0',
                        is_forbidden : '0',
                        resource:'',
                    }
                },
                //家具基础列表
                dataList: [],
                activeName: 'first',
                //图片上传相关
                dialogImageUrlTextTpl:'',
                dialogVisibleTextTpl: false,
                TextTplPicList:[],
                //类别相关
                //类别基础列表
                sortList:[],
                dialogTitleSort:'创建类别',
                dialogSortFormVisible:false,
                AllSort:{
                    form:{
                        id : 0,
                        sort_name : '',
                        cover : '',
                        sort_type : 1,
                        authority : 0,
                        weight : 0,
                        is_forbidden : 0,
                    }
                },
                //获取家具的所有分类(列表展示)
                AllSortList:[],
                //创建家具时，筛选出可用分类（筛选使用）
                AllCanSortList:[],
                //展示大图时对应的图片地址
                dialogSortImageUrl:'',
                //是否开启大图展示
                dialogSortVisible: false,
                SortPicList:[],
                //获取所有权限信息
                AllAuthorityList:[],
                TextTplPatchList:[]
            }
        },
        methods: {
            //切换栏目时触发
            changeTab(tab, event) {

            },
            //获取模板列表
            getListTextTpl() {
                this.isLoading = true;
                    this.$api.journal.text_tpl('List', this.$copy(this.searchParams)).then(result => {
                        if (result.errCode) {
                            this.$message.error(result.message);
                        } else {
                            this.dataList = result.data.list;
                            this.searchParams.count = result.data.total;
                        }
                    }).catch(error => {
                        this.$message.error(error);
                    }).then(() => {
                        this.isLoading = false;
                    })
            },
            //禁用模板 or 类别
            confirmRemove(id,type){
                if(!id){
                    this.$message.error('未查询到数据信息');
                    return;
                }
                if(type == 'TextTpl'){
                    this.$confirm('是否更改使用状态?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        //点击确认回调的函数
                        this.$api.journal.text_tpl('SetChange', {id:id}).then(result => {
                            if (result.errCode) {
                                this.$message.error(result.message);
                            } else {
                                this.getListTextTpl();
                                this.$message({
                                    type: 'success',
                                    message: '操作成功!'
                                });
                            }
                        }).catch(error => {
                            this.$message.error(error);
                        }).then(() => {
                            this.isLoading = false;
                        });
                    }).catch(() => {
                        //点击取消回调的函数
                        this.$message({
                            type: 'info',
                            message: '取消'
                        });
                    });
                }else{
                    this.$confirm('是否更改使用状态?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        //点击确认回调的函数
                        this.$api.journal.all_sort('SetChange', {id:id}).then(result => {
                            if (result.errCode) {
                                this.$message.error(result.message);
                            } else {
                                this.getAllTextTplSort();
                                this.$message({
                                    type: 'success',
                                    message: '操作成功!'
                                });
                            }
                        }).catch(error => {
                            this.$message.error(error);
                        }).then(() => {
                            this.isLoading = false;
                        });
                    }).catch(() => {
                        //点击取消回调的函数
                        this.$message({
                            type: 'info',
                            message: '取消'
                        });
                    });
                }
            },
            //创建模板or类别（打开弹窗）
            create(type) {
                if(type == 'TextTpl') {
                    this.dialogTextTplFormVisible = true;
                    this.TextTplPicList = [];
                    this.TextTplPatchList = [];
                    this.TextTpl.form = {
                        id : 0,
                        name : '',
                        sort_id : '',
                        cover : '',
                        authority : '1',
                        weight : '0',
                        is_forbidden : '0',
                    };
                }else{
                    this.dialogSortFormVisible = true;
                    this.SortPicList = [];
                    this.AllSort.form = {
                        id : 0,
                        sort_name : '',
                        cover : '',
                        sort_type : '2',
                        authority : '1',
                        weight : '0',
                        is_forbidden : '0',
                    };
                }
            },
            //提交创建模板
            CreateSubmitTextTpl(type){
                //模板相关
                if(type == 'TextTpl'){
                    this.$api.journal.text_tpl('Save', this.TextTpl.form,'post').then(result => {
                        if (result.errCode) {
                            this.$message.error(result.message);
                        } else {
                            this.getListTextTpl();
                            this.dialogTextTplFormVisible = false;
                        }
                    }).catch(error => {
                        this.$message.error(error);
                    }).then(() => {
                        this.isLoading = false;
                    });
                }else{
                    //类别相关
                    this.$api.journal.all_sort('Save', this.AllSort.form,'post').then(result => {
                        if (result.errCode) {
                            this.$message.error(result.message);
                        } else {
                            this.getAllTextTplSort();
                            this.dialogSortFormVisible = false;
                        }
                    }).catch(error => {
                        this.$message.error(error);
                    }).then(() => {
                        this.isLoading = false;
                    });
                }
            },
            //取消提交
            CancelSubmitTextTpl(type){
                if(type == 'TextTpl'){
                    this.dialogTextTplFormVisible = false;
                }else{
                    this.dialogSortFormVisible = false;
                }
            },
            //编辑类别
            editTextTplInfo(index){
                this.dialogTitleTextTpl = '编辑模板';
                this.dialogTextTplFormVisible = true;
                this.TextTplPicList = [];
                //对应copy的使用
                this.TextTpl.form = this.$copy(this.dataList[index]);
                //设置放大图片信息
                this.dialogImageUrlTextTpl = this.TextTpl.form.cover;
                let obj = new Object();
                obj.url = this.dialogImageUrlTextTpl;
                this.TextTplPicList.push(obj);
                this.TextTplPatchList = [];
            },
            //模板图片处理的具体方式
            handleRemoveTextTpl() {
                this.TextTpl.form.cover = '';
            },
            //放大镜
            handlePictureCardPreview(file) {
                this.dialogImageUrlTextTpl = file.url;
                this.dialogVisibleTextTpl = true;
            },
            //上传成功
            handlePictureSuccessTextTpl(result){
                if(result.errCode){
                    this.$message.error(result.message);
                }
                //成功的情况进行图片存储
                this.TextTpl.form.cover = result.data.object_url;
            },


            //类别上传图片涉及的绑定事件
            //移除图片
            handleRemoveSortPic() {
                this.AllSort.form.cover = '';
            },
            //图片成功上传
            handleSortPictureSuccess(result){
                if(result.errCode){
                    this.$message.error(result.message);
                }
                //成功的情况进行图片存储
                this.AllSort.form.cover = result.data.object_url;
            },
            //图片放大观察
            handleSortPictureCardPreview(file) {
                this.dialogSortVisible = true;
                this.dialogSortImageUrl = file.url;
            },

            //获取所有模板类别
            getAllTextTplSort(){
                let that = this;
                this.$api.journal.all_sort('SortInfo',{'type':2,'scene':1}).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.AllSortList = result.data;
                        that.AllCanSortList = [];
                        this.AllSortList.forEach(function(value){
                            if(value.is_forbidden == 0){
                                that.AllCanSortList.push(value);
                            }
                        });
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                });
            },
            //编辑类别
            editSortInfo(index){
                this.dialogTitleSort = '编辑类别';
                this.dialogSortFormVisible = true;
                this.SortPicList = [];
                this.AllSort.form = this.$copy(this.AllSortList[index]);
                //设置放大图片信息
                this.dialogSortImageUrl = this.AllSort.form.cover;
                let obj = new Object();
                obj.url = this.dialogSortImageUrl;
                this.SortPicList.push(obj);
            },

            //获取所有权限类别
            getAllAuthorityList(){
                this.$api.journal.all_sort('AuthorityInfo').then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.AllAuthorityList = result.data;
                    }
                }).catch(error => {
                    this.$message.error(error);
                }).then(() => {
                    this.isLoading = false;
                });
            },

            //分页相关
            //点击分页
            handleSizeChange(val) {
                this.searchParams.size = val;
                this.getListTextTpl();
            },

            handleCurrentChange(val) {
                this.searchParams.page = val;
                this.getListTextTpl();
            },

            //文件上传相关
            handlePreviewFile(file){
                console.log(file);
            },
            handleRemoveFile(file){
                console.log(file);
                this.TextTpl.form.resource = '';
            },
            handleExceedFile(){
                this.$message.error('当前只支持上传一个文件包');
            },
            handleSuccessFile(result){
                if(result.errCode){
                    this.$message.error(result.message);
                }
                this.TextTpl.form.resource = result.data.object_url;
            },
        }
    }
</script>

