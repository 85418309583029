<template>
    <div>
        <div ref="main">
            <quill-editor
                    v-show="!dialogHtmlEditorVisible"
                    style="line-height: 20px;"
                    ref="myQuillEditor"
                    v-model="editorContent"
                    :options="editorOption"
                    @blur="onBlur($event)"
                    @focus="onFocus($event)"
                    @ready="onReady($event)"
                    @change="onChange($event)">
                <div id="toolbar" slot="toolbar" :class={fixedToolbar:isFixed} class="ql-toolbar ql-snow" ref="toolbar">
                    <button class="ql-show_html" value="show_html" title="HTML代码"></button>
                    <!--Add list -->
                    <button class="ql-upload_image" value="upload_image" title="上传图片"></button>
                    <!--                <button class="ql-image" value="image" title="上传图片"></button>-->
                    <button class="ql-video" value="video" title="插入视频"></button>
                    <button class="ql-link" value="link" title="添加链接"></button>
                    <!-- Add a bold button -->
                    <button class="ql-bold" title="加粗">Bold</button>
                    <button class="ql-italic" title="斜体">Italic</button>
                    <button class="ql-underline" title="下划线">underline</button>
                    <button class="ql-strike" title="删除线">strike</button>
                    <button class="ql-blockquote" title="引用"></button>
                    <button class="ql-code-block" title="代码"></button>
                    <!--Add list -->
                    <button class="ql-list" value="ordered" title="有序列表"></button>
                    <button class="ql-list" value="bullet" title="无序列表"></button>
                    <!-- Add subscript and superscript buttons -->
                    <select class="ql-color" value="color" title="字体颜色"></select>
                    <select class="ql-background" value="background" title="背景颜色"></select>
                    <select class="ql-align" value="align" title="对齐"></select>
                    <!-- Add font size dropdown -->
                    <select class="ql-header" title="段落格式">
                        <option selected>段落</option>
                        <option value="1">标题1</option>
                        <option value="2">标题2</option>
                        <option value="3">标题3</option>
                        <option value="4">标题4</option>
                        <option value="5">标题5</option>
                        <option value="6">标题6</option>
                    </select>
                    <select class="ql-size" title="字体大小">
                        <option value="10px">10px</option>
                        <option value="12px">12px</option>
                        <option value="14px">14px</option>
                        <option value="16px" selected>16px</option>
                        <option value="18px">18px</option>
                        <option value="20px">20px</option>
                    </select>
                    <select class="ql-font" title="字体">
                        <option value="SimSun">宋体</option>
                        <option value="SimHei">黑体</option>
                        <option value="Microsoft-YaHei">雅黑</option>
                        <option value="KaiTi">楷体</option>
                        <option value="FangSong">仿宋</option>
                        <option value="Arial">Arial</option>
                    </select>
                    <button class="ql-clean" title="还原"></button>

                    <!-- You can also add your own -->
                </div>

            </quill-editor>
            <html-editor v-if="dialogHtmlEditorVisible" :html="html" @changed="onHtmlChanged" ref="htmlEditor"/>
            <ImageUploader v-if="dialogImageUploaderVisible" @changed="onImageUploaded"/>
        </div>
    </div>
</template>
<script>
    import ImageUploader from './uploader/Image'
    import HtmlEditor from './Html'
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import {Quill, quillEditor} from 'vue-quill-editor'

    Quill.prototype.getHtml = function () {
        return this.container.firstChild.innerHTML;
    }
    //自定义字体大小
    let Size = Quill.import('attributors/style/size')
    Size.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px']
    Quill.register(Size, true)
    //自定义字体类型
    let fonts = ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'Times-New-Roman', 'sans-serif', '宋体', '黑体']
    let Font = Quill.import('formats/font')
    Font.whitelist = fonts
    Quill.register(Font, true)
    export default {
        name: "Editor",
        props: {
            content: {
                type: String
            }
        },
        components: {
            quillEditor, ImageUploader, HtmlEditor
        },
        watch: {
            content: {
                handler() {
                    this.editorContent = this.$copy(this.content)
                }
            },
        },
        computed: {
            editor() {
                return this.$refs.myQuillEditor.quill
            }
        },
        mounted() {
            this.editor.container.style.height = `${300}px`
            this.isFixed = false;
            this.initButton()
            //this.register(Quill)
            window.addEventListener('scroll', this.handleScroll, true)
            this.toolBarT = this.$refs.toolbar.getBoundingClientRect().x;
        },
        data() {
            return {
                editT: 0,
                toolBarT: 0,
                isFixed: false,
                tabOffsetTop: 0,
                scrollTop: 0,
                html: '',
                dialogHtmlEditorVisible: false,
                dialogImageUploaderVisible: false,
                file: '',
                quill: {
                    getModule() {
                    },
                    insertEmbed() {
                    },
                    formatText() {
                    },
                    setSelection() {
                    },
                    getHtml() {
                    }
                },
                editorRangeIndex: '',
                editorContent: '',
                editorOption: {
                    placeholder: "输入文章内容",
                    modules: {
                        toolbar: {
                            container: '#toolbar',
                            handlers: {
                                shadeBox: null,
                                show_html: () => {
                                    //console.log(this.quill)
                                    //console.log(this.quill.getHtml())
                                    this.dialogHtmlEditorVisible = true
                                },
                                upload_image: () => {
                                    this.dialogImageUploaderVisible = true
                                    // let toolbar = this.quill.getModule('toolbar');
                                    // let range = this.quill.getSelection();
                                    // //  在当前光标位置插入图片
                                    // toolbar.quill.insertEmbed(range.index, 'image',file);
                                    // //  将光标移动到图片后面
                                    // toolbar.quill.setSelection(range.index + 1);
                                }
                            }
                        },
                        // toolbar: [
                        //     ['bold', 'italic', 'underline', 'strike'],    //加粗，斜体，下划线，删除线
                        //     ['blockquote', 'code-block'],     //引用，代码块
                        //     [{'header': 1}, {'header': 2}],        // 标题，键值对的形式；1、2表示字体大小
                        //     [{'list': 'ordered'}, {'list': 'bullet'}],     //列表
                        //     [{'script': 'sub'}, {'script': 'super'}],   // 上下标
                        //     [{'indent': '-1'}, {'indent': '+1'}],     // 缩进
                        //     [{'direction': 'rtl'}],             // 文本方向
                        //     [{'size': ['small', false, 'large', 'huge']}], // 字体大小
                        //     [{'header': [1, 2, 3, 4, 5, 6, false]}],     //二级标题
                        //     [{'color': []}, {'background': []}],     // 字体颜色，字体背景颜色
                        //     [{'font': []}],     //字体
                        //     [{'align': []}],    //对齐方式
                        //     ['clean'],    //清除字体样式
                        //     ['image', 'video']    //上传图片、上传视频
                        // ]
                    },
                }
            }
        },
        methods: {
            handleScroll() {
                this.editT = Math.abs(this.$store.state.editTop);
                let indexT = this.$store.state.indexTop;
                if (this.editT >= (this.toolBarT - indexT)) {
                    this.isFixed = true;
                } else {
                    this.isFixed = false;
                }
            },
            onHtmlChanged(html) {
                if (html !== false) {
                    this.quill.container.firstChild.innerHTML = html;
                }
                this.dialogHtmlEditorVisible = false
            },
            onImageUploaded(file) {
                this.dialogImageUploaderVisible = false
                if (!file) {
                    return
                }
                let toolbar = this.quill.getModule('toolbar');
                //在当前光标位置插入图片
                toolbar.quill.insertEmbed(this.editorRangeIndex, 'image', file.url)
                toolbar.quill.formatText(this.editorRangeIndex, 1, {"alt": file.name, "width": file.width ? file.width : 'auto'});
                //将光标移动到图片后面
                toolbar.quill.setSelection(this.editorRangeIndex + 1)
            },
            register(q) {
                //注册标签(因为在富文本编辑器中是没有div,table等标签的，需要自己去注册自己需要的标签)
                class div extends q.import('blots/block/embed') {
                }

                class table extends q.import('blots/block/embed') {
                }

                class tr extends q.import('blots/block/embed') {
                }

                class td extends q.import('blots/block/embed') {
                }

                div.blotName = div.tagName = 'div'
                table.blotName = table.tagName = 'table'
                tr.blotName = tr.tagName = 'tr'
                td.blotName = td.tagName = 'td'
                q.register(div)
                q.register(table)
                q.register(tr)
                q.register(td)
            },
            initButton: () => {
                const imageUploaderButton = document.querySelector('.ql-upload_image')
                imageUploaderButton.style.cssText = "font-size:18px"
                imageUploaderButton.innerText = ""
                imageUploaderButton.classList.add('el-icon-picture')
                imageUploaderButton.title = "上传图片"

                const htmlButton = document.querySelector('.ql-show_html')
                htmlButton.style.cssText = "font-size:20px"
                htmlButton.innerText = ""
                htmlButton.classList.add('el-icon-ali-code')
                htmlButton.title = "HTML代码"
            },
            onBlur() {
                //this.quill.enable(false)
            },
            onFocus() {
                this.editorRangeIndex = this.quill.getSelection().index

            },
            onReady(editor) {
                this.quill = editor
            },
            onChange() {
                //console.log(this.quill.getHtml())
                if (this.quill.getSelection()) {
                    this.editorRangeIndex = this.quill.getSelection().index
                }
                this.html = this.quill.getHtml()
                this.$emit('changed', this.editorContent)

            }
        },
        destroyed() {

            window.removeEventListener("scroll", this.handleScroll)
        },
    }
</script>

<style>
    .ql-snow .ql-picker.ql-size, .ql-snow .ql-picker.ql-header, .ql-snow .ql-picker.ql-font {
        width: 60px;
    }
</style>
<style scoped>
    .fixedToolbar {
        position: fixed;
        top: 120px;
        z-index: 10;
        background-color: #fff;
    }
</style>>