<template>
    <el-upload
            style="float: left"
            action="/common/upload/multipart/"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :limit="1"
            :on-exceed="handleExceed"
            :on-success="onSuccess"
            :auto-upload="true"
            :file-list="fileList">
        <el-tooltip class="item" effect="light" :content="tips" placement="bottom" v-if="!file">
            <el-button size="small" type="primary">点击上传</el-button>
        </el-tooltip>
    </el-upload>
</template>

<script>
    export default {
        name: "FileUploader",
        props: {
            //文件名称
            name: {
                type: String
            },
            //说明
            tips: {
                type: String
            },
            //文件URL
            value: {
                type: String
            },
            //文件类型
            type: {
                type: String
            }
        },
        data() {
            return {
                file: '',
                fileList: this.value ? [{name: '点击下载', url: this.value}] : []
            }
        },
        watch: {
            value(v) {
                this.file = v;
                this.fileList = this.file ? [{name: '点击下载', url: this.value}] : []
            },
            file(v) {
                this.$emit('input', v)
            }
        },
        mounted() {
            this.file = this.value
        },
        methods: {
            onSuccess(result) {
                if (result.errCode) {
                    return this.$message.error(result.message)
                }
                this.file = result.data.object_url
            },
            handleRemove() {
                this.file = ''
            },
            handlePreview(file) {
                window.location.href = file.url
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            beforeRemove(file) {
                return this.$confirm(`确定移除 ${file.name}？`);
            }

        }
    }
</script>

<style scoped>

</style>