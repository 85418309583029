<template>
    <el-dialog
            title="上传图片"
            :visible.sync="dialogVisible"
            width="40%"
    >
        <el-form :model="form" status-icon ref="form" label-width="120px" class="">
            <el-form-item label="上传图片：" prop="parent">
                <el-col :span="12">
                    <el-upload
                            ref="uploader"
                            style="width: 50px"
                            action="/common/upload/multipart/"
                            :show-file-list="false"
                            :on-progress="onProgress"
                            :on-change="onChange"
                            :on-success="onSuccess"
                            :before-upload="beforeUpload">
                        <el-popover
                                v-if="form.url"
                                placement="right-start"
                                :title="form.name"
                                width="500"
                                trigger="hover">
                            <a :href="form.url" target="_blank"><img :src="form.url" width="500"></a>
                            <img slot="reference" :src="form.url" width="30" height="30">
                        </el-popover>
                        <el-col v-else :space="4">
                            <el-button slot="default" type="primary" size="small" :disabled="isUploading">上传<i :class="{'el-icon-upload2 el-icon--right':!isUploading,'el-icon-loading el-icon--right':isUploading}"></i></el-button>
                        </el-col>
                        <!--                        <i v-else slot="default" :class="{'el-icon-upload2':!isUploading,'el-icon-loading':isUploading}"></i>-->
                    </el-upload>
                </el-col>
            </el-form-item>
            <el-form-item label="图片宽度：" prop="width">
                <el-col :span="12">
                    <el-input type="text" placeholder="默认为完整宽度" v-model="form.width"></el-input>
                </el-col>
            </el-form-item>
            <el-form-item label="图片高度：" prop="height">
                <el-col :span="12">
                    <el-input type="text" placeholder="默认为完整高度" v-model="form.height"></el-input>
                </el-col>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="close()">取 消</el-button>
            <el-button type="primary" @click="save()">插入</el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        name: "ImageUploader",
        props: {},
        mounted() {
            setTimeout(() => {
                this.$refs['uploader'].$refs['upload-inner'].handleClick()
            }, 1)
        },
        data() {
            return {
                dialogVisible: true,
                isUploading: false,
                file: '',
                fileUrl: '',
                fileName: '',
                form: {
                    name: '',
                    width: '',
                    height: '',
                    url: ''
                }
            }
        },
        watch: {},
        methods: {
            close() {
                this.dialogVisible = false
                this.$emit('changed', false)
            },
            save() {
                if (!this.form.url) {
                    return this.$message.warning('请先上传图片')
                } else {
                    this.$emit('changed', this.form)
                }
                this.form = {
                    name: '',
                    width: '',
                    height: '',
                    url: ''
                }
            },
            beforeUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isPNG = file.type === 'image/png';
                const isGif = file.type === 'image/gif';
                const isLt2M = file.size / 1024 / 1024 < 10;
                if (!isJPG && !isPNG && !isGif) {
                    return this.$message.error('上传图片只能是 JPG/png/gif 格式!');
                }
                if (!isLt2M) {
                    return this.$message.error('上传图片大小不能超过 10MB!');
                }
                return true;
            },
            onChange() {
                this.isUploading = false
            },
            onProgress() {
                this.form.url = ''
                this.isUploading = true
            },
            onSuccess(result) {
                if (result.errCode) {
                    return this.$message.error(result.message)
                }
                this.form.url = result.data.object_url
                this.form.name = result.data.original_name
            },
        }
    }
</script>

<style scoped>
    .el-form-item {
        margin-bottom: 22px;
    }
</style>