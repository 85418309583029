<template>
  <div>

  <!-- 分享图片   分享文字  -->
    <div class="setname">
    <div class="settopLogo">
      <div class="bannerTitle">奖品图片：</div>
      <div>
        <img
          style="height: 100px; width: 150px"
          :src="bannerimg"
          alt=""
          srcset=""
        />
      </div>
      <div v-if="bannerimgShow == 1" style="padding-top: 68px; margin-left: 20px">
        <el-button
          @click="setnew(1)"
          class="el-icon-upload2"
          size="small"
          type="primary"
          >上传</el-button
        >
      </div>
      <div v-else style="padding-top: 68px; margin-left: 20px">
        <el-button
          @click="setnew(1)"
          class="el-icon-edit"
          size="small"
          type="primary"
          >修改</el-button
        >
      </div>
      <input
        id="uploadImage1"
        type="file"
        ref="fileInput1"
        accept="image/*"
        @change="getFile(1)"
        style="display: none"
      />
    </div>




      <div class="bannerTitle" style="margin-left: 160px">
        分享文字：
      </div>
      <div>
        <input
          class="nameInp"
          v-model="titleText"
          placeholder="请输入"
          type="text"
        />
      </div>
    </div>



    <!-- 签到奖励   每日视频观看次数  -->
    <div class="setname">
      <div class="bannerTitle">签到奖励：</div>
      <div>
        <input
          class="nameInp"
          v-model="sign_reward"
          placeholder="请输入"
          type="text"
        />
      </div>

      <div class="bannerTitle" style="margin-left: 160px">
        每日视频观看次数：
      </div>
      <div>
        <input
          class="nameInp"
          v-model="video_times"
          placeholder="请输入"
          type="text"
        />
      </div>
    </div>

    <!-- 视频奖励   抽奖限制次数  -->
    <div class="setname">
      <div class="bannerTitle">视频奖励：</div>
      <div>
        <input
          class="nameInp"
          v-model="video_reward"
          placeholder="请输入"
          type="text"
        />
      </div>

      <div class="bannerTitle" style="margin-left: 160px">抽奖限制次数：</div>
      <div>
        <input
          class="nameInp"
          v-model="draw_times"
          placeholder="请输入"
          type="text"
        />
      </div>
    </div>

    <!-- 参与抽奖奖励   参与抽奖次数  -->
    <div class="setname">
      <div class="bannerTitle">参与抽奖奖励：</div>
      <div>
        <input
          class="nameInp"
          v-model="reward_draw"
          placeholder="请输入"
          type="text"
        />
      </div>

      <div class="bannerTitle" style="margin-left: 160px">默认赠送锦鲤：</div>
      <div>
        <input
          class="nameInp"
          v-model="koi_value"
          placeholder="请输入"
          type="text"
        />
      </div>
    </div>

    <!-- 分享空间奖励   分享空间次数  -->
    <div class="setname">
      <div class="bannerTitle">分享空间获取幸运值：</div>
      <div>
        <input
          class="nameInp"
          v-model="reward_share_qq"
          placeholder="请输入"
          type="text"
        />
      </div>

      <div class="bannerTitle" style="margin-left: 160px">分享空间次数：</div>
      <div>
        <input
          class="nameInp"
          v-model="share_qq_times"
          placeholder="请输入"
          type="text"
        />
      </div>
    </div>

    <!-- 分享到群奖励   分享到群次数  -->
    <div class="setname">
      <div class="bannerTitle">分享到群奖励：</div>
      <div>
        <input
          class="nameInp"
          v-model="reward_share_group"
          placeholder="请输入"
          type="text"
        />
      </div>

      <div class="bannerTitle" style="margin-left: 160px">分享到群次数：</div>
      <div>
        <input
          class="nameInp"
          v-model="share_group_times"
          placeholder="请输入"
          type="text"
        />
      </div>
    </div>

    <div style="width: 20%; margin: 40px auto 0px auto">
      <el-button style="width: 100%" type="primary" @click="sure"
        >确定</el-button
      >
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      sign_reward: "", // 签到奖励111
      video_times: "", // 每日限制观看视频次
      video_reward: "", // 视频奖励
      draw_times: "", // 抽奖限制次数
      reward_draw: "", //  参与抽奖奖励的锦鲤值
      koi_value: "", //  默认赠送锦鲤
      reward_share_qq: "", //  分享空间
      share_qq_times: "", // 次数
      reward_share_group: "", // 分享到群
      share_group_times: "", // 次数
      bannerimg: require("@/assets/images/bg.png"), //奖品图logo
      bannerimgShow:1,//上传或编辑
      titleText:''//分享文字
    };
  },
  created() {
    this.getinfo()
  },
  mounted() {},
  watch: {},
  methods: {
    getinfo: function () {
      this.isLoading = true;
      this.$api.luckDraw
        .getTask()
        .then((result) => {
          console.log(result);
          this.sign_reward = result.data.sign_reward;
          this.video_times = result.data.video_times;
          this.video_reward = result.data.video_reward;
          this.draw_times = result.data.draw_times;
          this.reward_draw = result.data.reward_draw;
          this.koi_value = result.data.koi_value;
          this.reward_share_qq = result.data.reward_share_qq;
          this.share_qq_times = result.data.share_qq_times;
          this.reward_share_group = result.data.reward_share_group;
          this.share_group_times = result.data.share_group_times;
          this.bannerimg = result.data.banner;
          this.titleText = result.data.title;
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    //触发选图
    setnew: function (e) {
      console.log(this.$refs);

      this.$refs.fileInput1.click();
    },
      //上传图片
    getFile: function (e) {
      var that = this;
      console.log(e);
      var file = "";
      file = document.getElementById("uploadImage1").files[0];

      console.log(file); //由打印的可以看到，图片    信息就在files[0]里面
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (data) => {
        console.log(data);
      };
      let formData = new FormData(); //通过formdata上传
      formData.append("file", file);

      axios.post("/common/upload/multipart", formData).then((res) => {
        console.log(res.data.data);
        //   预览的图片

        this.bannerimgShow = 2;
        this.bannerimg = res.data.data.object_url;
      });
    },


    // 确定按钮
    sure: function (e) {
      let data = {
        sign_reward: this.sign_reward,
        video_times: this.video_times,
        video_reward: this.video_reward,
        draw_times: this.draw_times,
        reward_draw: this.reward_draw,
        reward_share_qq: this.reward_share_qq,
        share_qq_times: this.share_qq_times,
        reward_share_group: this.reward_share_group,
        share_group_times: this.share_group_times,
        koi_value: this.koi_value,
        banner:this.bannerimg,
        title:this.titleText
      };
      this.isLoading = true;
      this.$api.luckDraw
        .taskSave(data)
        .then((result) => {
          console.log(result);
          this.$message.success("修改成功");
          // this.$emit('dialogVisible','false')
        })
        .catch((error) => {
          this.$message.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style>
.el-icon-upload2:before {
  float: right;
}
.el-icon-edit:before {
  float: right;
}
.settopLogo {
  display: flex;
  justify-items: center;
}
.bannerTitle {
  width: 100px;
  color: #606266;
  font-size: 14px;
}
.setname {
  display: flex;
  justify-items: center;
  align-items: center;
  margin-top: 20px;
}
.nameInp {
  height: 30px;
  padding-left: 10px;
  width: 223px;
  outline: none;


}
</style>





