<template>
    <!--更换业务员-->
    <div>
        <el-dialog title="更换业务员" :visible.sync="dialogClerkVisible" width="600" class="clerkDialog" @close="closeDialog">
            <div>确定将客户<span class="ml-5 mr-5 fs-weight-bold c-blue">张晓15100000000</span>转交给其他业务员？</div>
            <el-form :model="clerk" class="mt-30 clerkForm">

                <el-form-item label="业务员" label-width="80px">
                    <el-select v-model="clerk.region" placeholder="请选择业务员">
                        <el-option label="请选择业务员" value="shanghai"></el-option>
                        <el-option label="业务员1" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="转交备注" label-width="80px">
                    <el-input v-model="clerk.name" autocomplete="off" placeholder="选填"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="onSubmit">确认转交</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "ChangeSalesman",
        data(){
            return{
                dialogClerkVisible:true,
                clerk:{
                    salesman:'',
                    remark:''
                }
            }
        },
        methods:{
            onSubmit(){
                this.closeDialog();
            },
            closeDialog(){
                this.dialogClerkVisible = false;
                this.$emit('closeTransferClerk',this.dialogClerkVisible)
            }
        }
    }
</script>

<style scoped>

</style>